import { Component, Input, OnInit } from '@angular/core';

import { Skill } from '../../../models';

@Component({
  selector: 'app-skills-card',
  templateUrl: './skills-card.component.html',
  styleUrls: ['./skills-card.component.scss'],
})
export class SkillsCardComponent implements OnInit {
  @Input() skills: Skill[];
  @Input() title: string;

  public skillsForDisplay: string;

  constructor() {}

  ngOnInit(): void {
    this.skillsForDisplay = this.mergeSkillsIntoString();
  }

  private mergeSkillsIntoString(): string {
    if (this.skills?.length) {
      let skillsResult = this.skills.reduce((acc, skill) => acc + `${skill.name}, `, '');
      // remove the trailing comma and space character
      const newLength = skillsResult.length - 2;
      skillsResult = skillsResult.substr(0, newLength);
      return skillsResult;
    } else {
      return null;
    }
  }
}
