import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomProListComponent } from './custom-pro-list.component';
import { HeaderModule } from '../../components/organisms/header/header.module';
import { ProCardsListModule } from '../../components/organisms/pro-cards-list/pro-cards-list.module';
import { UserPageModule } from '../../components/templates/user-page/user-page.module';

const routes: Routes = [{ path: '', pathMatch: 'full', component: CustomProListComponent }];
@NgModule({
  declarations: [CustomProListComponent],
  imports: [CommonModule, RouterModule.forChild(routes), ProCardsListModule, HeaderModule, UserPageModule],
  exports: [CustomProListComponent],
})
export class CustomProListModule {}
