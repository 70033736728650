import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '../../atoms/button/button.module';
import { ModalModule } from '../../templates/modal/modal.module';
import { SignupModalComponent } from './signup-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SignupModalComponent],
  imports: [ButtonModule, CommonModule, FormsModule, ModalModule, ReactiveFormsModule, TranslateModule],
  exports: [SignupModalComponent],
})
export class SignupModalModule {}
