import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SERVICE_URL } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor(private http: HttpClient) {}

  sendVerificationTokenEmail(token: string, verificationRequestBody): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}auth/verification/web/${token}`, verificationRequestBody, {
      observe: 'response',
    });
  }

  refreshVerificationTokenEmail(token, refreshVerificationRequestBody): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}auth/refresh/web/${token}`, refreshVerificationRequestBody, {
      observe: 'response',
    });
  }

  sendVerificationTokenRoleUpgrade(token): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}auth/role-upgrade/verification/${token}`, {}, { observe: 'response' });
  }

  refreshVerificationTokenRoleUpgrade(token): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}auth/role-upgrade/refresh/${token}`, {}, { observe: 'response' });
  }

  sendVerificationTokenEmailChange(token): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}auth/email-change/verification/${token}`, {}, { observe: 'response' });
  }

  refreshVerificationTokenEmailChange(token): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}auth/refresh/${token}`, {}, { observe: 'response' });
  }
}
