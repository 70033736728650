import { createSelector } from '@ngrx/store';

import { AppState } from '../app.reducers';
import { FastBookingState } from './reducers/fast-booking.reducers';
import { Rate } from '../../models';

const selectFastBooking = (state: AppState) => state.fastBooking;

export const selectFastBookingProUsername = createSelector(
  selectFastBooking,
  (state: FastBookingState) => state.proUsername,
);

export const selectFastBookingProTruncatedProfile = createSelector(
  selectFastBooking,
  (state: FastBookingState) => state.proTruncatedProfile,
);

export const selectAvailabilityDaysForRateAndMonth = (rate: Rate, month: number) =>
  createSelector(selectFastBooking, (state: FastBookingState) => state.availability?.[rate.id]?.[month]);

export const selectAvailabilitySlotsForSelectedDay = createSelector(
  selectFastBooking,
  (state: FastBookingState) => state.selectedDayAvailabilitySlots,
);

export const selectLockedSlots = createSelector(selectFastBooking, (state: FastBookingState) => state.lockedSlots);
