import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { SERVICE_URL } from '../utils';
import { BookingInfo } from '../components/organisms/book-modal/book-modal.component';
import {
  ConfirmAndPayBooking,
  BookingAction,
  BookingRating,
  BookingRatingResults,
  BookingListStatus,
  BookingsByStatus,
  BookingByStatus,
} from '../models';
import { FastBookingData } from '../models/rates-and-services';

export enum BookingStatus {
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  PAST = 'past',
}

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private router: Router, private http: HttpClient) {}

  public sendBookingRequest(bookingRequestData: BookingInfo): Observable<any> {
    return this.http.post(`${SERVICE_URL.BOOKING}api/bookings`, { ...bookingRequestData }, { observe: 'response' });
  }

  public requestInstructionsEmail(params): Observable<any> {
    return this.http.post(`${SERVICE_URL.USER}webview/request-instructions-email`, params, { observe: 'response' });
  }

  public getAllBookings(status: BookingStatus): Observable<HttpResponse<BookingsByStatus>> {
    return this.http.get<BookingsByStatus>(`${SERVICE_URL.BOOKING}api/bookings/bookinglist/${status}`, {
      observe: 'response',
    });
  }

  public getBookingById(bookingId: number): Observable<any> {
    return this.http.get(`${SERVICE_URL.BOOKING}api/bookings/${bookingId}`, { observe: 'response' });
  }

  public getBookingsForChat(profileId: number): Observable<HttpResponse<BookingByStatus[]>> {
    return this.http.get<BookingByStatus[]>(`${SERVICE_URL.BOOKING}api/bookings/profile/${profileId}`, {
      observe: 'response',
    });
  }

  public getBookingBadgeNotificationCount(): Observable<any> {
    return this.http.get(`${SERVICE_URL.BOOKING}api/bookings/unread-count/`, { observe: 'response' });
  }

  public acceptOrDeclineBooking(bookingAction: BookingAction, bookingId: string): Observable<any> {
    return this.http.post(`${SERVICE_URL.BOOKING}api/actions/${bookingAction}/${bookingId}`, { observe: 'response' });
  }

  public rateBooking(bookingId: string | number, bookingRating: BookingRatingResults): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.BOOKING}api/rating/${bookingId}`,
      { ...bookingRating },
      { observe: 'response' },
    );
  }

  public confirmBooking(bookingId: number, confirmAndPayBookingRequestDTO: ConfirmAndPayBooking): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.BOOKING}api/actions/new/confirm/${bookingId}`,
      confirmAndPayBookingRequestDTO,
      { observe: 'response' },
    );
  }

  public confirmFastBooking(fastBookingData: FastBookingData): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.BOOKING}api/actions/instant-bookings`,
      { ...fastBookingData },
      { observe: 'response' },
    );
  }

  public confirmFastBookingAfter3DSecure(paymentIntentId: string, fastBookingData: FastBookingData): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.BOOKING}api/actions/instant-bookings/payment-intent/${paymentIntentId}/confirm`,
      { ...fastBookingData },
      { observe: 'response' },
    );
  }

  public acceptBooking(bookingId: number | string): Observable<any> {
    return this.http.post(`${SERVICE_URL.BOOKING}api/actions/accept/${bookingId}`, {}, { observe: 'response' });
  }

  public bookingSeen(bookingId: number | string): Observable<any> {
    return this.http.post(`${SERVICE_URL.BOOKING}api/seen/${bookingId}`, {}, { observe: 'response' });
  }

  public getBookingListStatus(): Observable<any> {
    return this.http.get(`${SERVICE_URL.BOOKING}api/bookings/bookinglist/status`);
  }

  public rescheduleMentorshipBooking(bookingId: number | string, rescheduleData: any): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.BOOKING}api/actions/instant-bookings/${bookingId}/reschedule`,
      { ...rescheduleData },
      { observe: 'response' },
    );
  }

  scheduleMentorshipInCalendar(calendarData: any): Observable<any> {
    return this.http.get(
      `${SERVICE_URL.BOOKING}api/calendar?calendar=${calendarData.calendar}&type=${calendarData.type}`,
      { observe: 'response' },
    );
  }

  scheduleMentorshipInAppleCalendar(calendarData: any): Observable<any> {
    return this.http.post(`${SERVICE_URL.BOOKING}api/calendar/download?calendar=${calendarData.calendar}`, null, {
      observe: 'response' as 'body',
      responseType: 'application/octet-stream' as 'json',
    });
  }

  public getAffiliateCampaigns(cid: string): Observable<any> {
    return this.http.get(`${SERVICE_URL.BOOKING}auth/affiliates/campaigns?cid=${cid}`, {
      observe: 'response',
    });
  }
}
