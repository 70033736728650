import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-active-filters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './active-filters.component.html',
  styleUrls: ['./active-filters.component.scss'],
})
export class ActiveFiltersComponent implements OnInit {
  @Input() count: number;
  constructor() {}

  ngOnInit(): void {}
}
