import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';
import { isPastMonth, daysRemainingInMonth } from '../../../utils';

@Component({
  selector: 'app-availability-datepicker',
  templateUrl: './availability-datepicker.component.html',
  styleUrls: ['./availability-datepicker.component.scss'],
})
export class AvailabilityDatepickerComponent implements OnInit, OnDestroy {
  @Input() enabled$: Observable<boolean>;
  @Input() availableDates$: Observable<any>;
  @Input() preselect: { startTime: number; endTime: number };

  private subscription$ = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  public changeDate(date): any {
    // const dayTimestamps = this.getStartAndEndTimestampForDay(date);
    this.store.dispatch(
      FastBookingActions.calendarDaySelected({
        startTime: date.startDateTime,
        endTime: date.endDateTime,
      }),
    );
  }

  public changeMonth(date): any {
    const monthTimestamps = this.getStartAndEndTimestampForMonth(date);
    const month = this.getMonthName(date);
    const days = daysRemainingInMonth(new Date(monthTimestamps.startDateTime));

    if (!isPastMonth(date))
      this.store.dispatch(
        FastBookingActions.calendarMonthSelected({
          startTime: monthTimestamps.startDateTime,
          days,
          month: month,
        }),
      );
  }

  // TODO (Milan): Related to handling change date (calendar date click), not used now, probably should be removed
  private getStartAndEndTimestampForDay(date: Date): { startDateTime: number; endDateTime: number } {
    return {
      startDateTime: date.setHours(0, 0, 0, 0),
      endDateTime: date.setHours(23, 59, 59, 999),
    };
  }

  private getStartAndEndTimestampForMonth(date: Date): { startDateTime: number; endDateTime: number } {
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0);
    const dayStart = new Date().setHours(0, 0, 0, 0);
    const firstDayTimestamp = monthStart > dayStart ? monthStart : dayStart;

    const lastDayTimestamp =
      date.getMonth() === 11
        ? new Date(date.getFullYear() + 1, 0, 1).setHours(0, 0, 0, 0)
        : new Date(date.getFullYear(), date.getMonth() + 1, 1).setHours(0, 0, 0, 0);

    return {
      startDateTime: firstDayTimestamp,
      endDateTime: lastDayTimestamp,
    };
  }

  private getMonthName(date: Date): number {
    return date.getMonth();
  }

  ngOnDestroy(): void {}
}
