import { BookingStatus } from '../../services';

export const PAYMENT_METHOD_ID_PLACEHOLDER = {
  NEW_CARD: 'newCard',
  NEW_BANK_ACCOUNT: 'newBankAccount',
};

export const BOOKING_ROLE = {
  BOOKEE: 'bookee',
  BOOKER: 'booker',
};

export const BOOKINGS_SELECT = {
  ALL_BOOKINGS: 'ALL_BOOKINGS',
  MUSIC_PRO: 'MUSIC_PRO',
  BOOKER: 'BOOKER',
};

export const BOOKING_CARD_BUTTON_LABEL = {
  // Because we do not have Book again functionality this title is currently changed
  BOOK_AGAIN: 'View Booking Details',
  VIEW_BOOKING_REQUEST: 'View Booking Request',
  VIEW_BOOKING_DETAILS: 'View Booking Details',
  EDIT_BOOKING_REQUEST: 'Edit Booking Request',
  CONFIRM_BOOKING: 'Confirm Booking',
  VIEW_MODIFICATION_REQUEST: 'View Modification Request',
};

export const STATUS_TITLE = {
  ACCEPTED: 'REQUEST ACCEPTED',
  COMPLETED: 'COMPLETE',
  CANCELED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  DECLINED: 'DECLINED',
  DISPUTED: 'DISPUTED',
  PENDING_REQUEST: 'PENDING REQUEST',
  PENDING_CONFIRMATION: 'PENDING CONFIRMATION',
  PAYMENT_FAILED: 'PAYMENT FAILED',
  MODIFICATION_REQUESTED: 'MODIFICATION REQUESTED',
  CONFIRMED: 'CONFIRMED',
  PROCESSING_PAYMENT: 'PROCESSING PAYMENT',
};

export const STATUS_CLASS = {
  SUCCESS_GREEN: 'success--green',
  X_RED: 'x--red',
  X_GRAY: 'x--gray',
  EXCLAMATION_GOLD: 'exclamation--gold',
};

export const PAY_CLASS = {
  PAY_DOLLAR: 'pay-dollar',
  PAY_LOGO: 'pay-logo',
};

export const BOOKING_STATUS_RESPONSE = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  PROCESSING_PAYMENT_FAILED: 'PROCESSING_PAYMENT_FAILED',
  MODIFICATION_REQUESTED: 'MODIFICATION_REQUESTED',
  CONFIRMED: 'CONFIRMED',
  PROCESSING_PAYMENT: 'PROCESSING_PAYMENT',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
  DECLINED: 'DECLINED',
  DISPUTED: 'DISPUTED',
};

export const STATUS_INFO_MAP = {
  [BookingStatus.PAST]: {
    [BOOKING_STATUS_RESPONSE.COMPLETED]: {
      bookee: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.COMPLETED },
      booker: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.COMPLETED },
    },
    [BOOKING_STATUS_RESPONSE.CANCELED]: {
      bookee: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.CANCELED },
      booker: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.CANCELED },
    },
    [BOOKING_STATUS_RESPONSE.EXPIRED]: {
      bookee: { class: STATUS_CLASS.X_GRAY, title: STATUS_TITLE.EXPIRED },
      booker: { class: STATUS_CLASS.X_GRAY, title: STATUS_TITLE.EXPIRED },
    },
    [BOOKING_STATUS_RESPONSE.DECLINED]: {
      bookee: { class: STATUS_CLASS.X_GRAY, title: STATUS_TITLE.DECLINED },
      booker: { class: STATUS_CLASS.X_GRAY, title: STATUS_TITLE.DECLINED },
    },
    [BOOKING_STATUS_RESPONSE.DISPUTED]: {
      bookee: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.DISPUTED },
      booker: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.DISPUTED },
    },
  },
  [BookingStatus.PENDING]: {
    [BOOKING_STATUS_RESPONSE.PENDING]: {
      bookee: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PENDING_REQUEST },
      booker: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PENDING_REQUEST },
    },
    // NOTE: This is the only status that displays different titles for Bookee and the Bookeer.
    // Because of this the whole map object had to separate bookee and booker objects.
    [BOOKING_STATUS_RESPONSE.ACCEPTED]: {
      bookee: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.PENDING_CONFIRMATION },
      booker: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.ACCEPTED },
    },
    [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT_FAILED]: {
      bookee: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.PAYMENT_FAILED },
      booker: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.PAYMENT_FAILED },
    },
  },
  [BookingStatus.UPCOMING]: {
    [BOOKING_STATUS_RESPONSE.MODIFICATION_REQUESTED]: {
      bookee: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.MODIFICATION_REQUESTED },
      booker: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.MODIFICATION_REQUESTED },
    },
    [BOOKING_STATUS_RESPONSE.CONFIRMED]: {
      bookee: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.CONFIRMED },
      booker: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.CONFIRMED },
    },
    [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT]: {
      bookee: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PROCESSING_PAYMENT },
      booker: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PROCESSING_PAYMENT },
    },
  },
};

export const STATUS_QUICK_INFO_MAP = {
  [BOOKING_STATUS_RESPONSE.PENDING]: {
    bookee: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PENDING_REQUEST },
    booker: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PENDING_REQUEST },
  },
  // NOTE: This is the only status that displays different titles for Bookee and the Bookeer.
  // Because of this the whole map object had to separate bookee and booker objects.
  [BOOKING_STATUS_RESPONSE.ACCEPTED]: {
    bookee: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.PENDING_CONFIRMATION },
    booker: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.ACCEPTED },
  },
  [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT_FAILED]: {
    bookee: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.PAYMENT_FAILED },
    booker: { class: STATUS_CLASS.X_RED, title: STATUS_TITLE.PAYMENT_FAILED },
  },
  [BOOKING_STATUS_RESPONSE.MODIFICATION_REQUESTED]: {
    bookee: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.MODIFICATION_REQUESTED },
    booker: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.MODIFICATION_REQUESTED },
  },
  [BOOKING_STATUS_RESPONSE.CONFIRMED]: {
    bookee: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.CONFIRMED },
    booker: { class: STATUS_CLASS.SUCCESS_GREEN, title: STATUS_TITLE.CONFIRMED },
  },
  [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT]: {
    bookee: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PROCESSING_PAYMENT },
    booker: { class: STATUS_CLASS.EXCLAMATION_GOLD, title: STATUS_TITLE.PROCESSING_PAYMENT },
  },
};

export const BUTTON_LABEL_MAP = {
  [BookingStatus.PAST]: {
    [BOOKING_STATUS_RESPONSE.COMPLETED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
    },
    [BOOKING_STATUS_RESPONSE.CANCELED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
    },
    [BOOKING_STATUS_RESPONSE.EXPIRED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
    },
    [BOOKING_STATUS_RESPONSE.DECLINED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
    },
    [BOOKING_STATUS_RESPONSE.DISPUTED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
    },
  },
  [BookingStatus.PENDING]: {
    [BOOKING_STATUS_RESPONSE.PENDING]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_REQUEST,
      booker: BOOKING_CARD_BUTTON_LABEL.EDIT_BOOKING_REQUEST,
    },
    [BOOKING_STATUS_RESPONSE.ACCEPTED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.CONFIRM_BOOKING,
    },
    [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT_FAILED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    },
  },
  [BookingStatus.UPCOMING]: {
    [BOOKING_STATUS_RESPONSE.MODIFICATION_REQUESTED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_MODIFICATION_REQUEST,
      booker: BOOKING_CARD_BUTTON_LABEL.VIEW_MODIFICATION_REQUEST,
    },
    [BOOKING_STATUS_RESPONSE.CONFIRMED]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    },
    [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT]: {
      bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
      booker: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    },
  },
};

export const BUTTON_LABEL_QUICK_INFO_MAP = {
  [BOOKING_STATUS_RESPONSE.DECLINED]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
  },
  [BOOKING_STATUS_RESPONSE.DISPUTED]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    booker: BOOKING_CARD_BUTTON_LABEL.BOOK_AGAIN,
  },
  [BOOKING_STATUS_RESPONSE.PENDING]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_REQUEST,
    booker: BOOKING_CARD_BUTTON_LABEL.EDIT_BOOKING_REQUEST,
  },
  [BOOKING_STATUS_RESPONSE.ACCEPTED]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    booker: BOOKING_CARD_BUTTON_LABEL.CONFIRM_BOOKING,
  },
  [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT_FAILED]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    booker: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
  },
  [BOOKING_STATUS_RESPONSE.MODIFICATION_REQUESTED]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_MODIFICATION_REQUEST,
    booker: BOOKING_CARD_BUTTON_LABEL.VIEW_MODIFICATION_REQUEST,
  },
  [BOOKING_STATUS_RESPONSE.CONFIRMED]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    booker: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
  },
  [BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT]: {
    bookee: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
    booker: BOOKING_CARD_BUTTON_LABEL.VIEW_BOOKING_DETAILS,
  },
};
