<div [ngSwitch]="displayStyle$ | async">
  <div class="notification-settings" *ngSwitchCase="'mainMenu'">
    <div class="notification-settings__controller flex column">
      <h3 class="notification-settings__title">{{ 'NOTIFICATION_SETTINGS.NOTIFICATION_SETTINGS' | translate }}</h3>
      <app-notification-settings-tab-group
        [notificationSettings$]="notificationSettings$"
        [activeTab]="selectedTab"
        (tabActivated)="showNotificationDetails($event)"
      ></app-notification-settings-tab-group>
    </div>
    <div class="notification-settings__content flex column">
      <app-notification-settings-menu
        [selectedSettings$]="selectedSettings$"
        (emitGroup)="displaySubgroupItems($event)"
      ></app-notification-settings-menu>
    </div>
  </div>
  <div class="notification-settings notification-settings-submenu" *ngSwitchCase="'submenu'">
    <div class="notification-settings__controller-submenu flex column" *ngIf="sideControls">
      <h6 class="notification-settings__controller-title">
        <img
          [src]="backArrow"
          alt="Notification Settings"
          class="notification-settings__controller-backArrow"
          (click)="onBackToNotificationSettings()"
        />
        <span *ngIf="isDesktopView">{{ backToTitle }}&nbsp;</span
        ><span class="notification-settings_notificationType">{{ notificationTypeTitle }}</span>
      </h6>
    </div>
    <div class="notification-settings__content flex column">
      <app-notification-settings-submenu
        [selectedSettingsItems$]="selectedSettingsItems$"
        (emitUpdateNotificationSettings)="updateNotificationSettings($event)"
      ></app-notification-settings-submenu>
    </div>
  </div>
</div>
