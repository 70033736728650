<div class="availability-slot-list flex column">
  <p class="availability-slot-list__title" [ngClass]="{ 'not-available': availableSlots.length === 0 }">
    {{ 'FAST_BOOKING.SELECT_STARTING_TIME' | translate }}
  </p>
  <p class="availability-slot-list__timezone-info">{{ 'FAST_BOOKING.TIME_ZONE_INFO' | translate }}</p>
  <div class="availability-slot-list__slots">
    <app-availability-slot
      *ngFor="let slot of availableSlotsDisplay"
      [slot]="slot"
      [isPreselected]="slot.id === preselectStartSlotId"
      (confirmed)="slotConfirmed(slot)"
    ></app-availability-slot>
    <span *ngIf="availableSlotsDisplay.length === 0" class="availability-slot-list__empty">
      {{ profileName }} {{ errorMsgNoSlotsAvailable }}</span
    >
  </div>
</div>
