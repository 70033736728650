<div class="notification-settings-menu">
  <a
    class="notification-settings-menu__card-link"
    (click)="emitSettingsGroup(settings)"
    *ngFor="let settings of notificationSettingsMenu"
  >
    <div class="notification-settings-menu__card card">
      <div class="notification-settings-menu__card-body" *ngIf="isMobileDevice">
        <div
          class="notification-settings-menu__card-content-mobile"
          [ngClass]="{ 'notification-settings-menu__card-content': settings?.description }"
        >
          <h4 class="notification-settings-menu__card-title card-title">{{ settings?.title }}</h4>
          <img [src]="arrowImage" alt="{{ settings?.title }}" class="notification-settings-menu__card-image" />
        </div>
        <p class="notification-settings-menu__card-subtitle card-subtitle" *ngIf="settings?.description">
          {{ settings?.description }}
        </p>
      </div>
      <div
        class="notification-settings-menu__card-body"
        [ngClass]="{ 'notification-settings-menu__card-content': settings?.description }"
        *ngIf="!isMobileDevice"
      >
        <div>
          <h4 class="notification-settings-menu__card-title card-title">{{ settings?.title }}</h4>
          <p class="notification-settings-menu__card-subtitle card-subtitle" *ngIf="settings?.description">
            {{ settings?.description }}
          </p>
        </div>
        <img [src]="arrowImage" alt="{{ settings?.title }}" class="notification-settings-menu__card-image" />
      </div>
    </div>
  </a>
</div>
