import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { SellerProfileCardComponent } from './seller-profile-card.component';
import { RateLabelModule } from '../rate-label/rate-label.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { VisibilityObserverModule } from '../../../utils/directives/visibility-observer/visibility-observer.module';

@NgModule({
  declarations: [SellerProfileCardComponent],
  imports: [
    ButtonModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    RateLabelModule,
    NgbTooltipModule,
    VisibilityObserverModule,
  ],
  exports: [SellerProfileCardComponent],
})
export class SellerProfileCardModule {}
