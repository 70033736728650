import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-time-label',
  templateUrl: './date-time-label.component.html',
  styleUrls: ['./date-time-label.component.scss'],
})
export class DateTimeLabelComponent implements OnInit {
  @Input() dateTime;

  constructor() {}

  ngOnInit(): void {}
}
