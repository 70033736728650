import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatComponent } from './chat.component';
import { MyMessageGroupModule } from '../../molecules/my-message-group/my-message-group.module';
import { OthersMessageGroupModule } from '../../molecules/others-message-group/others-message-group.module';
import { DateTimeLabelModule } from '../../atoms/date-time-label/date-time-label.module';
import { MessengerLabelModule } from '../../molecules/messenger-label/messenger-label.module';
import { MessengerInputModule } from '../../atoms/messenger-input/messenger-input.module';
import { NoDataModule } from '../../molecules/no-data/no-data.module';
import { BookingQuickInfoCardModule } from '../booking-quick-info-card/booking-quick-info-card.module';
import { AddPictureButtonModule } from '../../atoms/add-picture-button/add-picture-button.module';
import { MessengerInputGroupModule } from '../../molecules/messenger-input-group/messenger-input-group.module';
import { VisibilityObserverModule } from '../../../utils/directives/visibility-observer/visibility-observer.module';
import { MessengerImageModule } from '../../molecules/messenger-image/messenger-image.module';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    MyMessageGroupModule,
    OthersMessageGroupModule,
    DateTimeLabelModule,
    MessengerLabelModule,
    MessengerInputModule,
    NoDataModule,
    BookingQuickInfoCardModule,
    AddPictureButtonModule,
    MessengerInputGroupModule,
    VisibilityObserverModule,
    MessengerImageModule,
    PipesModule,
  ],
  exports: [ChatComponent],
})
export class ChatModule {}
