import { Component, Input, OnInit } from '@angular/core';

import { ProCardDTO } from '../../../models';

@Component({
  selector: 'app-pro-card',
  templateUrl: './pro-card.component.html',
  styleUrls: ['./pro-card.component.scss'],
})
export class ProCardComponent implements OnInit {
  @Input() proCardInfo: ProCardDTO;

  public buttonLabelContact: string;
  public buttonLabelProfile: string;

  constructor() {
    this.buttonLabelContact = 'Contact';
    this.buttonLabelProfile = 'Profile';
  }

  ngOnInit(): void {}
}
