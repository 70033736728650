import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { IInfoModalData } from './info-modal.config';
import { isNil } from 'lodash-es';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { BookingService } from '../../../services';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  @Input() infoModalData: IInfoModalData;

  public calendarType: string;

  public appleCalendar = 'APPLE';
  private calendarApiData: { calendar: string; type?: string };

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private bookingService: BookingService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  closeModal(event: any): any {
    // NOTE: To make the component more flexible, different behavior of closing the modal
    // happens for different input parameter (event). Pay attention to this when using the component or
    // adding more functionality.
    this.activeModal.close(event);
    if (this.infoModalData?.redirectTo && typeof event !== 'number') {
      this.redirectTo(this.infoModalData.redirectTo);
    }

    // Used for multiple buttons with redirect action
    if (this.infoModalData?.redirectToArr?.length && typeof event === 'number') {
      this.redirectTo(this.infoModalData.redirectToArr[event]);
    }
  }

  emitCalendar(event: any) {
    if (event !== this.appleCalendar) {
      this.calendarType = event;
      this.calendarApiData = {
        calendar: this.infoModalData.assignedId,
        type: event,
      };
      this.bookingService.scheduleMentorshipInCalendar(this.calendarApiData).subscribe((data) => {
        window.open(data.body.calendarDeeplink, '_blank');
      });
    } else {
      this.calendarType = this.appleCalendar;
      this.calendarApiData = {
        calendar: this.infoModalData.assignedId,
      };
      this.bookingService.scheduleMentorshipInAppleCalendar(this.calendarApiData).subscribe((data) => {
        const fileName = data.headers
          .get('content-disposition')
          .split(';')
          .find((n) => n.includes('filename="'))
          .replace('filename=', '')
          .replace('"', '')
          .replace('"', '')
          .trim();
        this.saveFile(data.body, fileName);
      });
    }
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, filename);
  }

  private redirectTo(url: string): void {
    if (!isNil(url)) this.router.navigateByUrl(url);
  }
}
