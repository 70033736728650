import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OthersImageComponent } from './others-image.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ImagePlaceholderModule } from '../image-placeholder/image-placeholder.module';

@NgModule({
  declarations: [OthersImageComponent],
  imports: [CommonModule, NgxGalleryModule, ImagePlaceholderModule],
  exports: [OthersImageComponent],
})
export class OthersImageModule {}
