<app-mentorship-modal>
  <app-seller-profile-card
    class="display"
    [noButtons]="true"
    [proTruncatedProfile]="truncatedProfile"
    (closeModal)="closeModal($event)"
  ></app-seller-profile-card>
  <div class="truncated-profile__rounded-corners-wrapper">
    <div class="book-modal-fast-checkout__content-container truncated-profile__content-container" #scrollMe>
      <img
        class="auth-modal__close book-modal-fast-checkout__close hide"
        src="../../../assets/images/icons/close-black.png"
        alt="Close icon"
        (click)="closeModal($event)"
      />
      <header class="book-modal__header book-modal__section">
        <p class="book-modal-fast-checkout__heading auth-modal__heading">Mentorship Session</p>
      </header>
      <form class="auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm()">
        <app-book-modal-section>
          <app-date-time-range-zone-label
            class="book-modal-fast-checkout__date-time-range-label"
            [startDate]="lockedAvailabilitySlots?.availabilitySlots[0]?.startDateTime"
            [endDate]="
              lockedAvailabilitySlots?.availabilitySlots[lockedAvailabilitySlots?.availabilitySlots.length - 1]
                ?.endDateTime
            "
          >
          </app-date-time-range-zone-label>
        </app-book-modal-section>

        <br />
        <br />

        <app-book-modal-section>
          <app-payment-details
            [bookingPrice]="lockedAvailabilitySlots?.price?.bookingPrice"
            [paymentDetailsTitle]="paymentDetailsTitle"
            [serviceFeePrice]="lockedAvailabilitySlots?.price?.buyersServiceFee"
            [totalPriceSum]="lockedAvailabilitySlots?.price?.buyersTotalAmount"
            [isSeller]="false"
          ></app-payment-details>
        </app-book-modal-section>

        <br />

        <app-add-payment-card
          class="book-modal-fast-checkout__add-payment-card"
          (confirmWithNewPaymentMethod)="confirmWithNewPaymentMethod($event)"
          (paymentMethodChanged)="onPaymentMethodChanged($event)"
          (processingConfirmationFinished)="onProcessingFinished($event)"
          (threeDSecureSuccess)="on3DSecureSuccess($event)"
          [addNewPaymentMethod$]="addNewPaymentMethod$"
          [cards]="cardsData"
          [cards$]="cardsData$"
          [preselectCard]="preselectCard$ | async"
          [start3DSecure$]="start3DSecure$"
          (scrollToBottom)="scrollContainerToBottom(scrollMe)"
        ></app-add-payment-card>

        <section class="book-modal-pay-to-confirm__disclaimer book-modal__disclaimer book-modal__section">
          {{ 'BOOK.I_AGREE_TO_THE' | translate
          }}<a
            class="auth-modal__disclaimer--link"
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            rel="noreferrer"
            target="_blank"
          >
            {{ 'BOOK.CANCELLATION_POLICY' | translate }}</a
          >,
          <a
            class="auth-modal__disclaimer--link"
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            rel="noreferrer"
            target="_blank"
            >{{ 'BOOK.REFUND_POLICY' | translate }}
          </a>
          {{ 'BOOK.AND_THE_JAMMCARD_BOOKINGS' | translate }}
          <a
            class="auth-modal__disclaimer--link"
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            rel="noreferrer"
            target="_blank"
          >
            {{ 'BOOK.TERMS_OF_SERVICE' | translate }}.&nbsp;
          </a>
          {{ 'BOOK.I_ALSO_AGREE_TO_PAY_THE_TOTAL_AMOUNT' | translate }}
        </section>

        <div class="book-modal__send-btn-container book-modal-fast-checkout__sent-btn-container">
          <app-button
            id="bookModalButton"
            [buttonDisabled]="!bookForm.valid || !selectedPaymentMethodId || processingConfirmation || fetchingCardData"
            [buttonLabel]="buttonLabel"
            [buttonType]="'submit'"
          >
          </app-button>
        </div>
      </form>
    </div>
  </div>
</app-mentorship-modal>
