import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from '../../atoms/button/button.module';
import { RequestQuickCardModule } from '../../molecules/request-quick-card/request-quick-card.module';
import { BookingSentModalComponent } from './booking-sent-modal.component';

@NgModule({
  declarations: [BookingSentModalComponent],
  imports: [CommonModule, RequestQuickCardModule, ButtonModule],
  exports: [BookingSentModalComponent],
})
export class BookingSentModalModule {}
