import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeNotificationsSectionComponent } from './change-notifications-section.component';
import { ChangeNotificationModule } from '../change-notification/change-notification.module';

@NgModule({
  declarations: [ChangeNotificationsSectionComponent],
  exports: [ChangeNotificationsSectionComponent],
  imports: [CommonModule, ChangeNotificationModule],
})
export class ChangeNotificationsSectionModule {}
