import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddPaymentCardModule } from '../add-payment-card/add-payment-card.module';
import { BookModalPayToConfirmComponent } from './book-modal-pay-to-confirm.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { AvatarGroupModule } from '../../molecules/avatar-group/avatar-group.module';
import { PaymentDetailsModule } from '../../molecules/payment-details/payment-details.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { RequestQuickCardModule } from '../../molecules/request-quick-card/request-quick-card.module';
import { FormsModule } from '@angular/forms';
import { BookModalPayToConfirmEntryComponent } from './book-modal-pay-to-confirm-entry.component';

@NgModule({
  declarations: [BookModalPayToConfirmComponent, BookModalPayToConfirmEntryComponent],
  imports: [
    AddPaymentCardModule,
    CommonModule,
    ModalModule,
    BookModalSectionModule,
    AvatarGroupModule,
    PaymentDetailsModule,
    ButtonModule,
    TranslateModule,
    RequestQuickCardModule,
    FormsModule,
  ],
  exports: [BookModalPayToConfirmComponent, BookModalPayToConfirmEntryComponent],
})
export class BookModalPayToConfirmModule {}
