import { Component, Input, OnInit } from '@angular/core';

import { Message } from '@twilio/conversations';

@Component({
  selector: 'app-others-message-group',
  templateUrl: './others-message-group.component.html',
  styleUrls: ['./others-message-group.component.scss'],
})
export class OthersMessageGroupComponent implements OnInit {
  @Input() avatarImage: string;
  @Input() messageGroup: Message | any;
  @Input() username: string;
  public profileId: number = null;

  constructor() {}

  ngOnInit(): void {
    this.profileId = parseInt(this.messageGroup.identity);
  }
}
