<div class="experience__title">
  <span class="db margin-b5">
    {{ experience?.profession }}
    <span *ngIf="experience?.bandOrStudio || experience?.artist"> at </span>
    <span class="experience__at">{{ experience?.bandOrStudio || experience?.artist }}</span>
  </span>
</div>
<p *ngIf="experience?.type === 'STUDIO'; else notStudio" class="experience__date db color--gray">
  {{ experience?.record }} ({{ experience?.year }})
</p>
<ng-template #notStudio>
  <p class="experience__date db color--gray">
    <span>
      {{ experience?.dateFrom | date: 'MMMM y' }}
    </span>
    <span *ngIf="experience?.dateTo"> - {{ experience?.dateTo | date: 'MMMM y' }} </span>
    <span *ngIf="!experience?.dateTo"> - Present</span>
  </p>
</ng-template>
<span class="experience__description db color--gray fz-smlr" *ngIf="!experience?.descriptionWithTags"
  >{{ experience?.description | slice: 0:60 }}{{ experience?.description?.length > 60 ? ' ...' : '' }}</span
>
<span
  class="experience__description db color--gray"
  *ngIf="experience?.descriptionWithTags"
  [innerHTML]="experience?.replacedExp"
></span>
