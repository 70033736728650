export enum RequestSentModalType {
  EMAIL = 'email',
  SUCCESS = 'success',
  FAIL = 'fail',
  WARNING = 'warning',
}

export enum RequestSentModalButtonType {
  VERIFICATION = 'verification',
  LOGIN = 'login',
}

export interface IRequestSentModalData {
  email?: string;
  emailInboxDescription?: string;
  emailInboxSubtitle?: string;
  requestSentBackgroundUrl?: string;
  requestSentButtonAction?: any;
  requestSentButtonLabel?: string;
  requestSentDescription?: string;
  requestSentTitle?: string;
  type?: RequestSentModalType;
  backButtonType?: RequestSentModalButtonType;
  backButtonLabel?: string;
  proProfileId?: number;
  proProfileUsername?: string;
  book?: boolean;
}

export class RequestSentModalData implements IRequestSentModalData {
  public constructor(
    public email?: string,
    public emailInboxDescription?: string,
    public emailInboxSubtitle?: string,
    public requestSentBackgroundUrl?: string,
    public requestSentButtonAction?: any,
    public requestSentButtonLabel?: string,
    public requestSentDescription?: string,
    public requestSentTitle?: string,
    public type: RequestSentModalType = RequestSentModalType.EMAIL,
    public backButtonType: RequestSentModalButtonType = RequestSentModalButtonType.LOGIN,
    public backButtonLabel?: string,
    public proProfileId?: number,
    public proProfileUsername?: string,
    public book?: boolean,
  ) {}
}
