import { Pipe, PipeTransform } from '@angular/core';
import { OPPORTUNITY } from '../../constants';

@Pipe({
  name: 'formatOpportunityMessage',
})
export class FormatOpportunityMessagePipe implements PipeTransform {
  transform(message: string): string {
    return message
      .replace(OPPORTUNITY.OPPORTUNITY_OPEN_TAG, '')
      .replace(OPPORTUNITY.OPPORTUNITY_CLOSE_TAG, '')
      .replace(OPPORTUNITY.TITLE_OPEN_TAG, '<h4>')
      .replace(OPPORTUNITY.TITLE_CLOSE_TAG, '</h4>')
      .replace(OPPORTUNITY.MESSAGE_OPEN_TAG, '<p>')
      .replace(OPPORTUNITY.MESSAGE_CLOSE_TAG, '</p>');
  }
}
