<app-edit-modal>
  <app-modal-header-card
    title="{{ 'PROFILE.EDIT_PROFILE' | translate }}"
    (closeModal)="closeModal()"
  ></app-modal-header-card>
  <div class="edit-profile-modal__container">
    <app-image-upload-card
      [currentImage]="currentProfileImage"
      [imageType]="PROFILE_IMAGE_TYPE"
    ></app-image-upload-card>
    <app-image-upload-card [currentImage]="currentCoverImage" [imageType]="COVER_IMAGE_TYPE"></app-image-upload-card>
    <app-edit-profile-form [profile]="profile"></app-edit-profile-form>
  </div>
</app-edit-modal>
