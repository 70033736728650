import { ConfirmAndPayBooking } from './booking';
import { Rate, RateSettingCurrency, ServiceProvision } from './profile';
import { IImage } from './';

export enum AvailabilitySlotStatus {
  FREE = 'FREE',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
}

export enum VideoSessionStatus {
  CAN_JOIN = 'CAN_JOIN',
  FINISHED = 'FINISHED',
  WAIT = 'WAIT',
}

export enum PageComponentTypes {
  FEATURED_LIST = 'FEATURED_LIST',
  CATEGORY_SECTION = 'CATEGORY_SECTION',
}

export interface LandingComponent {
  pageComponentType: string;
  title: string;
  headline: string;
  serviceProvisionType?: string;
  featuredMemberProfiles?: FeaturedProfile[];
  categories?: Category[];
}

export interface FeaturedMemberListDTO {
  content: FeaturedMemberList[];
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: false;
  };
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  last: boolean;
  number: number;
  size: number;
  empty: boolean;
}

export interface FeaturedMemberList {
  title: string;
  headline: string;
  featuredMemberProfiles: FeaturedProfile[];
}

export interface FeaturedProfile {
  profileId: number;
  name: string;
  verified: boolean;
  headline: string;
  profileImage: IImage;
  skills: SkillFeatured[];
  username: string;
  lowestRate: Rate;
  hasAvailability: boolean;
}

export interface Category {
  id: number;
  image: IImage;
  name: string;
  serviceType: string;
  skills: SkillFeatured[];
}

export interface SkillFeatured {
  id: number;
  name: string;
}

export interface AvailableDay {
  startDateTime: string;
  endDateTime: string;
  available: boolean;
  isAvailable?: boolean;
  originalIndex?: number;
}

export interface AvailabilitySlot {
  id: number;
  status: AvailabilitySlotStatus;
  startDateTime: string;
  endDateTime: string;
}

export interface AvailabilitySlotLocked {
  availabilitySlots: AvailabilitySlot[];
  price: {
    bookingPrice: number;
    currency: RateSettingCurrency;
    buyersServiceFee: number;
    buyersTotalAmount: number;
    sellersServiceFee: number;
    sellersTotalAmount: number;
  };
}

export interface FastBookingData {
  bookingDetails: {
    endDate: number;
    gigTitle: string;
    price?: number;
    sellerProfileId: number;
    startDate: number;
  };
  paymentDetails: ConfirmAndPayBooking;
  paymentIntentId?: string;
}

export interface FastBookingRouterData {
  modalEnteredFrom: string;
}

export interface JammboardRouterData {
  modalEnteredFrom: string;
}

export interface ServiceProvisionListDTO {
  content: ServiceProvision[];
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: false;
  };
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  last: boolean;
  number: number;
  size: number;
  empty: boolean;
}
