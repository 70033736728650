import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePlaceholderComponent } from './image-placeholder.component';

@NgModule({
  declarations: [ImagePlaceholderComponent],
  imports: [CommonModule],
  exports: [ImagePlaceholderComponent],
})
export class ImagePlaceholderModule {}
