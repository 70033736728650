import { Component, OnInit } from '@angular/core';

import { LocalStorageService } from '../../../services';
import { COOKIE_CONSENT_GIVEN, DISTINCT_UUID } from '../../../utils';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
  public disappear: boolean;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {}

  // public onDeclineClick(): void {
  //   this.localStorageService.add(COOKIE_CONSENT_GIVEN, false);
  //   this.disappear = true;
  // }

  public onAcceptClick(): void {
    this.localStorageService.add(COOKIE_CONSENT_GIVEN, true);
    this.disappear = true;
  }
}
