export const VERIFICATION = {
  MAGIC_LINK_BASE_URL: 'https://webview.jammcard.com/autologin/',
};

export const API_URL = {
  DEV: 'https://apis.dev-jammcard.com',
  STAGE: 'https://apis.staging-jammcard.com',
  PROD: 'https://apis.dev-jammcard.com',
};

export const APP_LINKS = {
  IOS: 'https://itunes.apple.com/us/app/jammcard/id879279559?mt=8',
  ANDROID: 'https://play.google.com/store/apps/details?id=com.jammcard&amp;hl=en_CA',
  FACEBOOK: 'https://www.facebook.com/jammcard',
  INSTAGRAM: 'https://instagram.com/jammcard/',
  TWITTER: 'https://twitter.com/jammcard',
  YOUTUBE: 'https://www.youtube.com/user/jammcard',
};

export const SOCIAL_PLATFORMS = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  YOUTUBE: 'YouTube',
};

export const SERVICE_URL = {
  BOOKING: '/api/booking/',
  MEDIA: '/api/media/',
  MESSAGING: '/api/messaging/',
  NOTIFICATIONS: '/api/notification/',
  PROFILE: '/api/profile/',
  USER: '/api/user/',
};

export const PROFILE_TYPE = {
  MEMBER: 'MEMBER',
  SELLER: 'SELLER',
  BUYER: 'BUYER',
};

export const URL = {
  JAMMCARD_COM: 'https://site.jammcard.com/',
  SITE: 'https://site.jammcard.com/',
  ABOUT: 'https://site.jammcard.com/about/',
  FAQ: 'https://site.jammcard.com/faq/',
  JAMMJAM: 'https://site.jammcard.com/jammjam/',
  SAMPLES: 'https://site.jammcard.com/samples/',
  MUSIC: 'https://site.jammcard.com/music/',
  RECORDS: 'https://site.jammcard.com/records/',
  BLOG: 'https://site.jammcard.com/blog/',
  BECOME_MEMBER: 'https://jammcard.typeform.com/to/g58t0g',
  BECOME_MENTOR: 'https://jammcard.typeform.com/to/IIqtCqjw#cohort=xxxxx',
  PRIVACY_POLICY: 'https://site.jammcard.com/privacy-policy/',
  TERMS_OF_SERVICE: 'https://site.jammcard.com/jammcard-terms-of-service/',
};

export const CONTACT_EMAIL = {
  TEAM: 'team@jammcard.com',
};

export const delayTime = 5500;

export const PLACEHOLDER_IMAGE_URL = {
  NO_AVATAR: '/assets/images/pages/profile/no-avatar.png',
  NO_COVER: '/assets/images/pages/profile/no-headline.jpg',
  NORMAL_LOGIN: '/assets/images/components/modals/login-cover.png',
};

export const AUTH_CONTROLLER = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
};

export const ASSETS_PATH = {
  LANDING_BACKGROUND: 'assets/images/pages/landing/landing-background.jpg',
  LANDING_HERO: 'assets/images/pages/landing/landing-hero-section--JPG.jpg',
  MENTORS_THUMBNAIL: 'assets/images/pages/landing/mentors-thumbnail.jpg',
  LOGO_MENTORS: 'assets/images/logos/jammcard-mentors.svg',
  LOGO_CLASSIC: '/assets/images/logos/jammcard--black.svg',
  NO_AVATAR: 'assets/images/pages/profile/no-avatar.png',
  VERIFIED_CHECKMARK: 'assets/images/icons/verified3x.png',
  CLOSE_BLACK_ICON: 'assets/images/icons/close-black.png',
  CLOSE_WHITE_ICON: 'assets/images/icons/close-white.png',
  QUESTION_MARK_ICON: 'assets/images/icons/question-mark.svg',
  JAMMCARD_MENTORS_LOGO_WHITE: 'assets/images/logos/jammcard-mentors--white.svg',
  JAMMCARD_LOGO_YELLOW_BACKGROUND: 'assets/images/pages/common/jammcard-logo-yellow-background.png',
};

export const REQUEST_SENT_BACKGROUND_URL = '/assets/images/components/request-sent-header.svg';

export const JAMM_LOGO_CIRCLE_URL = '/assets/images/logos/jammcard--circle.svg';

export const EVENT_NAME_PREFIX = {
  WEB_UI: 'web.ui',
};

// Local storage keys
export const ACCEPT_BOOKING_ID = 'accept-booking-id';
export const ACCEPT_BOOKING_ERROR = 'accept-booking-error';
export const BOOKING_GUEST = 'jammcard-booking-guest';
export const COOKIE_CONSENT_GIVEN = 'cookie-consent-given';
export const DISTINCT_UUID = 'distinct_uuid';
export const FIREBASE_TOKEN = 'push-device-tkn';
export const MOBILE_WEB_VIEW = 'mobile-web-view';
export const PROFILE_USERNAME = 'jammcard-profile-username';
export const REFRESH_TOKEN_INITIATED = 'refresh-token-initiated';
export const REDIRECTION_STATE_CHECK = 'redirection-check-state';
export const RESET_PASS = 'jammcard-reset-pass';
export const REGISTER_VIA_BOOK = 'register-via-book';
export const SERVICE_BOOKING_GUEST = 'jammcard-service-booking-guest';
export const USER = 'jammcard-user';
export const USER_EMAIL = 'jammcard-user-email';
export const USER_NAME = 'jammcard-user-name';
export const USER_ID = 'jammcard-user-id';
export const VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID = 'video-device-id';
export const VIDEO_CALL_SELECTED_AUDIO_DEVICE_ID = 'audio-device-id';
export const AFFILIATE_CAMPAIGN_DATA = 'affiliate-campaign-data';
export const INITIAL_REFERRER = 'initial-referrer';

// Session Storage keys
export const UTM_PARAMETERS = 'utm-parameters';

export const AUTH_PASSWORD_LENGTH = 6;

export const CHANGE_NOTIFICATIONS = {
  EMAIL: 'Email',
  PUSH_NOTIFICATIONS: 'Push notifications',
  SECTION: {
    TITLE: {
      NEW_FOLLOWER: 'New Follower',
      NEW_MESSAGE: 'New Message',
      NEW_COMMENT: 'New Comment',
      NEW_TAG: 'New Tag',
      NEW_UPVOTE: 'New Upvote',
    },
    // The following part of code was needed for original designs and is left in case there is the need to revert code to match the initial designs
    // SECTION: {
    //   MESSAGES: {
    //     TITLE: 'Messages',
    //     SUBTITLE: 'Receive message notifications from music pros and members',
    //   },
    //   BOOKING_STATUS_UPDATES: {
    //     TITLE: 'Booking Status Updates',
    //     SUBTITLE: 'Receive booking status updates, requests, and other reminders.',
    //   },
    //   REMINDERS: {
    //     TITLE: 'Reminders',
    //     SUBTITLE: 'Receive reminders around my confirmed bookings',
    //   },
    //   GENERAL_ANNOUNCEMENTS: {
    //     TITLE: 'General Announcements',
    //     SUBTITLE: 'Receive important community announcements, recommendations, & tips.',
    //   },
  },
};

export const META_CONTENT = {
  GENERAL_DESCRIPTION:
    'Jammcard is home to the world’s most talented and celebrated music professionals. Hire and collaborate with vetted pro musicians and your musical heroes for recording, live gigs, custom sounds, mentorship and more.',
  MENTORS_DESCRIPTION:
    'Get expert advice. Navigate your career. Develop your skills. Jammcard Mentors is an online platform offering live video mentorship sessions with the music industry’s leading professionals. Book a Mentor today.',
  FAQ_DESCRIPTION:
    'Get answers to your questions about Jammcard Mentors. ᐧ Who are Jammcard Mentors? ᐧ How do I book a Mentor? ᐧ How does the waitlist feature work? ᐧ I’m not a musician or in the music industry, can I still book a session with a Mentor? ᐧ How do I become a Mentor? ᐧ How do I contact my Mentor?',
  ABOUT_DESCRIPTION:
    'Jammcard Mentors is a music mentor marketplace offering 1-on-1 live video mentorship sessions with your musical heroes.  From industry insights to brand-building, mixing techniques, landing gigs, and getting feedback on your music, Jammcard Mentors offer expert advice to help you unlock your potential. Book a Mentor today.',
  // NOTE: Jammboard description should be udated with the client's text as soon as he provides it, the general description  is currently used
  JAMMBOARD_DESCRIPTION:
    'Jammcard is home to the world’s most talented and celebrated music professionals. Hire and collaborate with vetted pro musicians and your musical heroes for recording, live gigs, custom sounds, mentorship and more.',
};

export const NO_DATA = {
  TITLE: {
    BOOKINGS: {
      PENDING: 'No pending bookings',
      UPCOMING: 'No upcoming bookings',
      PAST: 'No past bookings',
    },
    MESSENGER: 'Your conversation will be displayed here',
    PROFILE: 'No content yet',
  },
  SUBTITLE: {
    BOOKINGS: {
      PENDING: 'check back later',
      UPCOMING: 'check back later',
      PAST: 'check back later',
    },
    MESSENGER: "looks like you don't have any messages",
    PROFILE: 'check back later',
  },
};

export const NON_EXISTENT = {
  TITLE: {
    PROFILE: 'Profile does not exist',
  },
  SUBTITLE: {
    PROFILE: '',
  },
};

export const TWILIO = {
  SYSTEM_MESSAGE_FLAG: '<@system>',
  SYSTEM_MESSAGE_IDENTITY: 'system',
};

export const OPPORTUNITY = {
  OPPORTUNITY_MESSAGE_IDENTITY: 'opportunity',
  OPPORTUNITY_OPEN_TAG: '<@opportunity>',
  OPPORTUNITY_CLOSE_TAG: '</@opportunity>',
  TITLE_OPEN_TAG: '<@title>',
  TITLE_CLOSE_TAG: '</@title>',
  MESSAGE_OPEN_TAG: '<@message>',
  MESSAGE_CLOSE_TAG: '</@message>',
};

export const SSR_PLACEHOLDERS = {
  BOOKINGS: {
    UPCOMING: 'Upcoming',
    PAST: 'Past',
    PENDING: 'Pending',
  },
  SETTINGS: {
    CHANGE_PASSWORD: 'Change Password',
    CONFIRM_NEW_PASSWORD: 'Confirm new password',
    CURRENT_PASSWORD: 'Current Password',
    NEW_PASSWORD: 'New password',
    PAYMENT_AND_PAYOUT: 'Payment & Payout',
    NOTIFICATION_SETTINGS: 'Notification Settings',
    EMAIL_NOTIFICATION: 'Email Notification',
    PUSH_NOTIFICATION: 'Push Notification',
  },
};

export const CONVERSATION_UNIQUE_NAME_SEPARATOR = '-';

export const STRIPE_AUTHORIZATION_URL = 'https://connect.stripe.com/express/oauth/authorize';

export const MODAL_DISMISS_REASON = {
  ROUTE_SWITCH: 'route switch',
  SIGN_UP_FROM_BOOK: 'sign up from book',
  FAST_BOOKING_CHECKOUT: 'fast booking checkout',
};

export const COMPONENTS_ALIAS = {
  MENTORS: 'MENTORS',
};

export const FEATURED_LIST = {
  MENTORS: 'MENTORS',
};

export const AVAILABILITY_MODAL_ENTERED_FROM = {
  PROFILE: 'profile',
  MENTORS: 'mentors',
  RESCHEDULE: 'reschedule',
  BOOKINGS: 'bookings',
};

export const JAMMBOARD_MODAL_ENTERED_FROM = {
  JAMMBOARD: 'jobs',
  MY_OPPORTUNITIES: 'my-opportunities',
};

//NOTE(Mladen): The following regex matches a exponential number pattern:
//              Examples of regex test resulting in true:
//              1e+58
//             -1e+88
//             +1e+9999999999999999999999999999999999999

export const ROUTE_TYPE_VALIDATOR = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/;

export const AVAILABILITY_RATE_DURATION_IN_MINUTES = 15;

export const BOOKING_DAYS_IN_ADVANCE_CONSTRAINT = 90;

export const PAYMENT_TYPES = {
  BANK: 'bank',
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMERICAN_EXPRESS: 'amex',
  DINERS_CLUB: 'diners',
  DISCOVER: 'discover',
  JCB: 'jcb',
  UNIONPAY: 'unionpay',
};

export const SECURITY_CODE_TYPES = {
  CSC: 'CSC',
  CVC: 'CVC',
};

export const EMAIL_VALIDATION_REGEX =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$";

export const MENTORS_ABOUT_URLS = {
  PIC_ONE: '../../../assets/images/pages/mentors-about/mentors-about-pic1.jpg',
  PIC_TWO: 'https://jammcard-data.s3.amazonaws.com/videos/about_mentorship.mp4',
  PIC_THREE: '../../../assets/images/pages/mentors-about/mentors-about-pic2.jpg',
  PIC_POSTER: '../../../assets/images/pages/mentors-about/about_mentors_video_initImg.png',
};

export const UTM_PARAMETERS_LIST = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export const HAS_AVAILABLE_SLOTS_BADGE = 'Book now';

export const MY_OPPORTUNITIES_STATUS = {
  POSTED: 'ACTIVE',
  FILLED: 'FILLED',
  DELETED: 'DELETED',
};
