import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-modal-section',
  templateUrl: './book-modal-section.component.html',
  styleUrls: ['./book-modal-section.component.scss'],
})
export class BookModalSectionComponent implements OnInit {
  @Input() sectionTitle?: string;
  @Input() sectionText?: string | number;

  constructor() {}

  ngOnInit(): void {}
}
