<div class="settings-tab-content__container">
  <form
    class="change-password__form flex column"
    [formGroup]="changePasswordForm"
    (ngSubmit)="submitForm(changePasswordForm)"
  >
    <div class="settings-tab-content__card flex column" [ngClass]="{ 'current-pass-hidden': hideCurrentPassField }">
      <header class="">
        <label class="change-password__heading">{{
          'SETTINGS.CHANGE_PASSWORD' | translate | ssrTranslate: changePasswordSsrPlaceholder
        }}</label>
      </header>
      <section [hidden]="hideCurrentPassField" class="change-password__section">
        <label class="change-password__section-title uppercase">{{
          'SETTINGS.CURRENT_PASSWORD' | translate | ssrTranslate: currentPasswordSsrPlaceholder
        }}</label>
        <input
          id="changePassInputCurrentPass"
          class="change-password__input auth-modal__input form-control flex align-center"
          type="password"
          name="currentPassword"
          formControlName="oldPass"
          required
          autocomplete="off"
          placeholder="{{ currentPassPlaceholder }}"
        />
        <div *ngIf="oldPass.invalid && (oldPass.dirty || !oldPass.pristine)">
          <p *ngIf="oldPass.errors.required" class="help-block">{{ 'SETTINGS.CURRENT_PASSWORD_REQUIRED' | translate }}</p>
          <p *ngIf="oldPass.errors.notCorrect" class="help-block">Incorrect password</p>
        </div>
      </section>

      <section class="change-password__section">
        <label class="change-password__section-title uppercase">{{
          'SETTINGS.NEW_PASSWORD' | translate | ssrTranslate: newPasswordSsrPlaceholder
        }}</label>
        <input
          id="changePassInputNewPass"
          class="change-password__input auth-modal__input form-control"
          type="password"
          name="newPassword"
          formControlName="newPass"
          required
          autocomplete="off"
          placeholder="{{ newPassPlaceholder }}"
        />
        <div *ngIf="newPass.invalid && (newPass.dirty || !newPass.pristine)">
          <p *ngIf="newPass.errors.required" class="help-block">New password is required</p>
          <p *ngIf="newPass.errors.minlength" class="help-block">Password must be longer than 6 characters</p>
        </div>
      </section>

      <section class="change-password__section">
        <label class="change-password__section-title uppercase">{{
          'SETTINGS.CONFIRM_NEW_PASSWORD' | translate | ssrTranslate: confirmNewPasswordSsrPlaceholder
        }}</label>
        <input
          id="changePassInputNewPassConfirm"
          class="change-password__input auth-modal__input form-control"
          type="password"
          name="newPasswordConfirm"
          formControlName="newPassConfirm"
          required
          autocomplete="off"
          placeholder="{{ confirmNewPassPlaceholder }}"
        />
        <div *ngIf="newPassConfirm.invalid && (newPassConfirm.dirty || !newPassConfirm.pristine)">
          <p *ngIf="newPassConfirm.errors.required" class="help-block">Confirm new password.</p>
        </div>
        <div
        *ngIf="changePasswordForm.errors?.passwordsMatch && (changePasswordForm.touched || changePasswordForm.dirty)"
      >
        <p class="help-block">Passwords need to match</p>
      </div>
      </section>
    </div>

    <div class="settings-tab-content__button-container flex row justify-start">
      <app-button
        id="changePasswordButtonReset"
        *ngIf="!hideCurrentPassField"
        [buttonDisabled]="changeRequestSending"
        [buttonLabel]="buttonLabel"
        [buttonType]="'submit'"
        [rounded]="true"
      >
      </app-button>
      <app-button
        id="changePasswordButton"
        *ngIf="hideCurrentPassField"
        [buttonDisabled]="changeRequestSending"
        [buttonLabel]="buttonLabel"
        [buttonType]="'submit'"
        [rounded]="true"
      >
      </app-button>
    </div>
  </form>
</div>
