import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkifyjs/string';

@Pipe({
  name: 'formatTextWithLinks',
})
export class FormatTextWithLinksPipe implements PipeTransform {
  transform(text: string): any {
    return text ? linkifyStr(text, { target: '_system' }) : text;
  }
}
