import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { concatMap, filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { selectProfileBasicById } from '../../../store/profile/profile.selectors';
import { BasicProfile } from '../../../models';
import { BookModalComponent } from './book-modal.component';
import { LocalStorageService } from '../../../services';
import { MODAL_DISMISS_REASON, USER_NAME, PLACEHOLDER_IMAGE_URL } from '../../../utils';

@Component({
  template: '',
})
export class BookModalEntryComponent implements OnInit, OnDestroy {
  public modalInstance: any;
  public bookModalData: any;
  public modal$: Subscription;
  public router$: Subscription;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
  ) {
    this.modal$ = this.route.parent.params
      .pipe(
        concatMap((param) => this.store.select(selectProfileBasicById, { id: param.id })),
        filter((profile) => profile !== ({} as BasicProfile) && profile !== undefined),
      )
      .subscribe((profile) => {
        this.openModal(profile);
      });

    // this.router$ = this.router.events
    //   .pipe(
    //     filter((event: NavigationStart) => event.navigationTrigger === 'popstate'),
    //     tap(() => this.modal$.unsubscribe()),
    //     take(1),
    //   )
    //   .subscribe(() => this.modalService.dismissAll());
  }

  openModal(profile: BasicProfile): void {
    this.bookModalData = {
      loggedUserName: this.localStorage.get(USER_NAME),
      isBookRoute: true,
      modalImage: profile?.coverImage ? profile.coverImage?.url : PLACEHOLDER_IMAGE_URL.NO_COVER,
      proName: profile?.name || 'Pro',
      profileImage: profile?.profileImage ? profile.profileImage?.url : PLACEHOLDER_IMAGE_URL.NO_AVATAR,
      proVerified: profile?.verified ? profile.verified : false,
      proCity: profile?.city ? profile.city : '',
      proId: profile?.profileId ? profile?.profileId : null,
      ratesAndServices: [],
    };

    this.modalInstance = this.modalService.open(BookModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    const bookModalData = this.bookModalData;
    this.modalInstance.componentInstance.fromParent = { bookModalData };

    this.modalInstance.result.then(
      () => this.router.navigate(['../'], { relativeTo: this.route }),
      (reason: any) => {
        if (reason !== MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK) {
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      },
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.modal$.unsubscribe();
    //this.router$.unsubscribe();
  }
}
