import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';

import { Message } from '@twilio/conversations';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import { CurrentUserShort } from '../../../models';
import { ConversationSelectedEventData, IConversationParticipant, IMessageGroup } from './messenger-content.config';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';
import { TwilioService } from '../../../services';
import { OPPORTUNITY, TWILIO } from '../../../utils';

// NOTE: After migrating from Twilio Programable Chat to the Twilio Conversations library
// a 'channel' became a 'conversation' and a 'member' became a 'participant',
// however the component names and the class names remained the same as before the migration
// and are still including the words 'channel' and 'member'
@Component({
  selector: 'app-messenger-content',
  templateUrl: './messenger-content.component.html',
  styleUrls: ['./messenger-content.component.scss'],
})
export class MessengerContentComponent implements OnInit, OnDestroy {
  @Input() conversationsListInView$: BehaviorSubject<boolean>;
  @Output('changeLayout') changeLayout = new EventEmitter<any>();

  public activeConversationUniqueName: string;
  public activeConversationOtherParticipant: IConversationParticipant;
  public hasQuickInfo: boolean;
  public loggedUserId: string;
  public loggedUserUsername: string;
  public loggedUserProfileImage: any;
  public profileId: number | string;

  public activeConversationMessageGroups$ = new Subject<IMessageGroup[]>();
  public activeConversationUniqueName$ = new Subject<string>();
  public activeConversationOtherParticipant$ = new Subject<IConversationParticipant>();
  public newMessage$ = new Subject<boolean>();

  private activeConversationLastMessage: Message;
  private activeConversationMessageGroups: IMessageGroup[];
  private activeConversationOldestMessageIndex: number;
  private activeConversationRawMessages: Message[] = [];
  private activeConversationHasMoreToLoad: boolean;

  private currentUserShort$: Observable<CurrentUserShort>;
  private subscription$: Subscription = new Subscription();

  constructor(private store: Store<AppState>, private twilioService: TwilioService) {}

  ngOnInit(): void {
    this.currentUserShort$ = this.store.pipe(select(selectCurrentUserShort));

    this.subscription$.add(
      this.currentUserShort$.subscribe((currentUserShort) => {
        if (currentUserShort) {
          this.loggedUserId = currentUserShort.id.toString();
          this.loggedUserProfileImage = currentUserShort.profileImage;
          this.loggedUserUsername = currentUserShort.username;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    if (this.activeConversationUniqueName) {
      // Do not use removeAllListener as it has side effects
      this.twilioService.activeConversation?.removeListener(
        'messageAdded',
        this.activeConversationMessageAddedListener,
      );
      this.twilioService.activeConversation?.removeListener('removed', this.activeConversationRemovedListener);
    }
    // TODO (Milan): Expected future feature is for active conversation not to be reset so when a user comes back to the Messenger page
    // the last opened conversation (active conversation) is initially shown with messages loaded. For now it is reset every time the user navigates away from
    // the Messenger page.
    this.twilioService.updateActiveConversation(null);
  }

  public async conversationSelected(e: ConversationSelectedEventData): Promise<void> {
    if (this.activeConversationUniqueName) {
      // Do not use removeAllListener as it has side effects
      this.twilioService.activeConversation?.removeListener(
        'messageAdded',
        this.activeConversationMessageAddedListener,
      );
      this.twilioService.activeConversation?.removeListener('removed', this.activeConversationRemovedListener);
    }
    this.activeConversationUniqueName = e.conversationUniqueName;
    this.changeLayout.emit({
      conversationsList: false,
      participantName: e.participantFullName,
    });
    this.activeConversationOtherParticipant = await this.twilioService.getOtherParticipant(
      this.activeConversationUniqueName,
    );
    this.activeConversationUniqueName$.next(this.activeConversationUniqueName);
    this.activeConversationOtherParticipant$.next(this.activeConversationOtherParticipant);

    this.setActiveConversation();
  }

  private async setActiveConversation(): Promise<void> {
    this.hasQuickInfo = false;
    this.activeConversationHasMoreToLoad = false;
    await this.twilioService.updateActiveConversation(this.activeConversationUniqueName);
    this.activeConversationMessageGroups = [];
    this.activeConversationMessageGroups$.next(this.activeConversationMessageGroups);
    this.newMessage$.next(false);
    this.twilioService.activeConversation.addListener('removed', this.activeConversationRemovedListener);
    this.setInitialMessageGroups();
  }

  // NOTE: this has to be an arrow function because of 'this' reference inside the resetActiveConversation fn
  private activeConversationRemovedListener = () => {
    this.resetActiveConversation();
  };

  public resetActiveConversation(): void {
    // Do not use removeAllListener as it has side effects
    this.twilioService.activeConversation?.removeListener('messageAdded', this.activeConversationMessageAddedListener);
    this.twilioService.activeConversation?.removeListener('removed', this.activeConversationRemovedListener);
    this.hasQuickInfo = false;
    this.activeConversationHasMoreToLoad = false;
    this.twilioService.updateActiveConversation(null);
    this.activeConversationUniqueName = null;
    this.activeConversationOtherParticipant = null;
    this.activeConversationUniqueName$.next(this.activeConversationUniqueName);
    this.activeConversationOtherParticipant$.next(this.activeConversationOtherParticipant);
    this.activeConversationMessageGroups = [];
    this.activeConversationMessageGroups$.next(this.activeConversationMessageGroups);
    this.activeConversationRawMessages = [];
    this.newMessage$.next(false);
  }

  private activeConversationMessageAddedListener = (message: Message) => this.addNewMessageToMessageGroups(message);

  private setInitialMessageGroups(): void {
    this.twilioService.getMessagesForActiveConversation().then(({ messages, conversationHasMoreToLoad }) => {
      this.activeConversationHasMoreToLoad = conversationHasMoreToLoad;
      this.activeConversationOldestMessageIndex = messages[0].index;
      this.activeConversationLastMessage = messages[messages.length - 1];
      this.activeConversationRawMessages = messages;
      this.activeConversationMessageGroups = this.makeMessageGroups(messages);
      this.activeConversationMessageGroups$.next(this.activeConversationMessageGroups);
      this.newMessage$.next(false);
      this.twilioService.activeConversation.addListener('messageAdded', this.activeConversationMessageAddedListener);
    });
  }

  private makeMessageGroups(messages: Message[]): IMessageGroup[] {
    let previousDateCreated: Date;
    let previousSender: string;
    let currentMessageGroup: IMessageGroup;
    let messageGroups: IMessageGroup[] = [];

    for (let i = 0; i < messages.length; i++) {
      if (!this.isSystemMessage(messages[i]) && !this.isOpportunityMessage(messages[i])) {
        if (i === 0) {
          previousSender = messages[i].author;
          previousDateCreated = messages[i].dateCreated;
          currentMessageGroup = { identity: messages[i].author, messages: [] };
        }

        if (
          messages[i].author === previousSender &&
          this.isTimeCloseEnough(previousDateCreated, messages[i].dateCreated)
        ) {
          currentMessageGroup.messages = [...currentMessageGroup.messages, messages[i]];
        } else {
          messageGroups = [...messageGroups, currentMessageGroup];
          currentMessageGroup = { identity: messages[i].author, messages: [messages[i]] };
          previousSender = messages[i].author;
          previousDateCreated = messages[i].dateCreated;
        }
      } else {
        // NOTE: Here system messages and opportunity messages are handled. A system message and an opportunity message are always a separate
        // message group containing only 1 message
        if (i !== 0) {
          messageGroups = [...messageGroups, currentMessageGroup];
        }
        currentMessageGroup = {
          identity: this.isSystemMessage(messages[i]) ? TWILIO.SYSTEM_MESSAGE_IDENTITY : messages[i].author,
          messages: [messages[i]],
          opportunity: !this.isSystemMessage(messages[i]),
        };
        previousSender = null;
        previousDateCreated = null;
      }
    }
    messageGroups = [...messageGroups, currentMessageGroup];
    return messageGroups;
  }

  private isSystemMessage(message: Message): boolean {
    return message.body && message.body?.search(TWILIO.SYSTEM_MESSAGE_FLAG) === 0;
  }

  private isOpportunityMessage(message: Message): boolean {
    return message.body && message.body?.search(OPPORTUNITY.OPPORTUNITY_OPEN_TAG) === 0;
  }

  private isTimeCloseEnough(previousTime: Date, currentTime: Date): boolean {
    return (
      previousTime &&
      currentTime &&
      previousTime.getMonth() === currentTime.getMonth() &&
      previousTime.getDate() === currentTime.getDate() &&
      previousTime.getHours() === currentTime.getHours() &&
      previousTime.getMinutes() === currentTime.getMinutes()
    );
  }

  private addNewMessageToMessageGroups(message: Message): void {
    const groupsLength = this.activeConversationMessageGroups.length;
    const previousSender = this.activeConversationMessageGroups[groupsLength - 1].identity;
    const previousGroupLength = this.activeConversationMessageGroups[groupsLength - 1].messages.length;
    const previousDateCreated = this.activeConversationMessageGroups[groupsLength - 1].messages[previousGroupLength - 1]
      .dateCreated;
    const previousMessageIsOpportunity = this.activeConversationMessageGroups[groupsLength - 1]?.opportunity;
    const messageIsOpportunity = this.isOpportunityMessage(message);

    if (
      message.author === previousSender &&
      this.isTimeCloseEnough(previousDateCreated, message.dateCreated) &&
      !previousMessageIsOpportunity &&
      !messageIsOpportunity
    ) {
      this.activeConversationMessageGroups[groupsLength - 1].messages = [
        ...this.activeConversationMessageGroups[groupsLength - 1].messages,
        message,
      ];
    } else {
      this.activeConversationMessageGroups = [
        ...this.activeConversationMessageGroups,
        {
          identity: this.isSystemMessage(message) ? TWILIO.SYSTEM_MESSAGE_IDENTITY : message.author,
          messages: [message],
          opportunity: messageIsOpportunity,
        },
      ];
    }
    this.activeConversationLastMessage = message;
    this.activeConversationMessageGroups$.next(this.activeConversationMessageGroups);
    this.newMessage$.next(true);
  }

  private combineActiveConversationMessageGroups(messages: Message[]): void {
    const messageGroupsToAdd = this.makeMessageGroups(messages);

    if (
      messageGroupsToAdd[messageGroupsToAdd.length - 1].identity !== this.activeConversationMessageGroups[0].identity ||
      messageGroupsToAdd[messageGroupsToAdd.length - 1]?.opportunity ||
      this.activeConversationMessageGroups[0]?.opportunity
    ) {
      this.activeConversationMessageGroups = [...messageGroupsToAdd, ...this.activeConversationMessageGroups];
      this.activeConversationMessageGroups$.next(this.activeConversationMessageGroups);
      this.newMessage$.next(false);
    } else {
      const messagesToCombine = this.activeConversationMessageGroups[0].messages;
      messageGroupsToAdd[messageGroupsToAdd.length - 1].messages = [
        ...messageGroupsToAdd[messageGroupsToAdd.length - 1].messages,
        ...messagesToCombine,
      ];
      this.activeConversationMessageGroups = [...messageGroupsToAdd, ...this.activeConversationMessageGroups.slice(1)];
      this.activeConversationMessageGroups$.next(this.activeConversationMessageGroups);
      this.newMessage$.next(false);
    }
  }

  public loadAnotherBatchOfMessages(): void {
    if (this.activeConversationHasMoreToLoad) {
      this.twilioService
        .getMessagesForActiveConversation(this.activeConversationOldestMessageIndex - 1)
        .then(({ messages, conversationHasMoreToLoad }) => {
          this.activeConversationHasMoreToLoad = conversationHasMoreToLoad;
          this.activeConversationOldestMessageIndex = messages[0].index;
          this.activeConversationRawMessages = [...messages, ...this.activeConversationRawMessages];
          this.combineActiveConversationMessageGroups(messages);
        });
    }
  }

  public updateReadIndex(): void {
    // NOTE: the index of the message obj received from the event (stored in the this.activeConversationLastMessage)
    // is more acurate than the info on the conversation itself (activeConversation.lastMessage.index) which was noticed
    // not to be up to date.
    if (
      this.twilioService.activeConversation.lastReadMessageIndex === null ||
      this.twilioService.activeConversation.lastReadMessageIndex < this.activeConversationLastMessage.index
    ) {
      this.twilioService.updateActiveConversation(
        this.activeConversationUniqueName,
        this.activeConversationLastMessage.index,
      );
    }
  }

  public hasBookingQuickInfo(event): any {
    this.hasQuickInfo = true;
  }
}
