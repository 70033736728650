<div class="image-upload-card__container flex column align-center">
  <div class="image-upload-card__title uppercase">
    {{ titleForDisplay }}
  </div>
  <div
    [ngClass]="{
      'current-image-profile-thumbnail': imageType === PROFILE_IMAGE_TYPE,
      'current-image-cover-thumbnail': imageType === COVER_IMAGE_TYPE
    }"
    class="flex align-center justify-center"
    style="background-image: url({{ imageForDisplay }});"
  >
    <div
      [ngClass]="{
        'current-image-profile-thumbnail__overlay': imageType === PROFILE_IMAGE_TYPE,
        'current-image-cover-thumbnail__overlay': imageType === COVER_IMAGE_TYPE
      }"
    ></div>
    <label class="add-image-button">
      <input type="file" (change)="onImageSelected($event)" accept="image/png, image/jpeg" />
    </label>
  </div>
</div>
