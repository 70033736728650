import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmailInboxCardComponent } from './email-inbox-card.component';

@NgModule({
  declarations: [EmailInboxCardComponent],
  exports: [EmailInboxCardComponent],
  imports: [CommonModule],
})
export class EmailInboxCardModule {}
