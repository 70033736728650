import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState } from './reducers/auth.reducers';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectCurrentUserShort = createSelector(selectAuthState, (auth) => auth.currentUserShort);

//TODO(Mladen): Handle the whole auth process through ngrx.
// export const isLoggedIn = createSelector(selectAuthState, (auth) => !!auth.user);
// export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn);
