import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonLabel: string;
  @Input() buttonType?: string;
  @Input() buttonStyle?: string = '';
  @Input() buttonDisabled?: boolean;
  @Input() buttonGrayDisabled?: boolean;
  @Input() inverted = false;
  @Input() icon?: string = '';
  @Input() rounded = false;
  @Input() roundedInverted = false;
  @Input() darkBorder = false;
  @Input() white = false;
  @Input() red = false;
  @Output() clicked = new EventEmitter<any>();

  constructor() {
    this.buttonType = this.buttonType || 'button';
  }

  ngOnInit(): void {}

  onClick(): void {
    this.clicked.emit();
  }
}
