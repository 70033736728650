import { createReducer, on } from '@ngrx/store';

import { FastBookingActions } from '../fast-booking.action-types';
import { AvailabilitySlot, AvailabilitySlotLocked, ServiceProvisionListDTO, TruncatedProfile } from '../../../models';

export interface ISubscribed {
  value: boolean;
  serviceId: number;
}

export interface FastBookingState {
  proUsername: string;
  proTruncatedProfile: TruncatedProfile;
  availability: any;
  selectedDayAvailabilitySlots: AvailabilitySlot[];
  lockedSlots: AvailabilitySlotLocked;
}

const initState: FastBookingState = {
  proUsername: null,
  proTruncatedProfile: null,
  availability: null,
  selectedDayAvailabilitySlots: null,
  lockedSlots: null,
};

const _fastBookingReducer = createReducer(
  initState,
  on(FastBookingActions.clearFastBooking, () => initState),
  on(FastBookingActions.setFastBookingTruncatedProfile, (state, action) => ({
    ...state,
    proTruncatedProfile: action.proProfile,
  })),
  on(FastBookingActions.setFastBookingProUsername, (state, action) => ({
    ...state,
    proUsername: action.username,
  })),
  on(FastBookingActions.truncatedProfileLoaded, (state, action) => ({
    ...state,
    proId: action.id,
    proTruncatedProfile: action.proProfile,
  })),
  on(FastBookingActions.truncatedProfileLoadingFailed, (state) => ({
    ...state,
    proId: null,
    proUsername: null,
    proTruncatedProfile: null,
  })),
  // TODO (Milan): Separate this into a function
  on(FastBookingActions.availableDaysLoaded, FastBookingActions.availableDaysLoadingFailed, (state, action) => ({
    ...state,
    availability: {
      ...state.availability,
      [action.rateId]: {
        ...state.availability?.[action.rateId],
        [action.month]: action.availableDays,
      },
    },
  })),
  on(FastBookingActions.availableSlotsLoaded, FastBookingActions.availableSlotsLoadingFailed, (state, action) => ({
    ...state,
    selectedDayAvailabilitySlots: action.availableSlots,
  })),
  on(FastBookingActions.availabilitySlotsLocked, (state, action) => ({
    ...state,
    lockedSlots: action.lockedSlots,
  })),
  on(FastBookingActions.availabilitySlotsLockingFailed, (state, action) => ({
    ...state,
    lockedSlots: null,
  })),
  on(FastBookingActions.clearAvailabilityInfo, (state, action) => ({
    ...state,
    availability: null,
    selectedDayAvailabilitySlots: null,
    lockedSlots: null,
  })),
  on(FastBookingActions.clearAvailabilitySelectedDay, (state, action) => ({
    ...state,
    selectedDayAvailabilitySlots: null,
  })),
);

export function fastBookingReducer(state, action): FastBookingState {
  return _fastBookingReducer(state, action);
}
