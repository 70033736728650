import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeagoShort',
})
export class TimeagoShortPipe implements PipeTransform {
  transform(timeagoString: string): string {
    const timeagoShort = timeagoString.includes('second')
      ? 'now'
      : timeagoString
          .replace(' ', '')
          .replace('ago', '')
          .replace('years', 'y')
          .replace('months', 'mo')
          .replace('weeks', 'w')
          .replace('days', 'd')
          .replace('hours', 'h')
          .replace('minutes', 'm')
          .replace('seconds', 's')
          .replace('year', 'y')
          .replace('month', 'mo')
          .replace('week', 'w')
          .replace('day', 'd')
          .replace('hour', 'h')
          .replace('minute', 'm')
          .replace('second', 's');
    return timeagoShort;
  }
}
