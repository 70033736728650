<app-mentorship-modal>
  <app-seller-profile-card
    (closeModal)="closeModal()"
    [noButtons]="true"
    [proTruncatedProfile]="proTruncatedProfile"
    class="display"
  ></app-seller-profile-card>

  <div class="notify-me-modal__container flex row justify-center">
    <div class="notify-me-modal__content-container flex column">
      <img
        (click)="closeModal()"
        alt="Close icon"
        class="auth-modal__close notify-me-modal__close hide"
        src="../../../assets/images/icons/close-black.png"
      />
      <div class="notify-me-modal__info-container flex column justify-center">
        <h3 *ngIf="isGuestUser" class="notify-me-modal__title notify-me-modal__title-guest">
          Get notified when {{ proTruncatedProfile.name }} drops mentorship slots
        </h3>
        <h3 *ngIf="!isGuestUser" class="notify-me-modal__title">
          Want to get notified when {{ proTruncatedProfile.name }} drops mentorship slots?
        </h3>
      </div>
      <app-button
        *ngIf="!isGuestUser"
        class="notify-me__book-button"
        (clicked)="onSubmit()"
        [rounded]="true"
        buttonLabel="Notify me"
      ></app-button>
      <form
        *ngIf="isGuestUser"
        (ngSubmit)="onSubmit()"
        [formGroup]="notifyMeForm"
        class="notify-me-modal__form auth-modal__form flex column"
      >
        <h4 class="h6 uppercase">Your email address</h4>
        <input
          autocomplete="off"
          class="notify-me-modal__input auth-modal__input form-control flex align-center"
          formControlName="email"
          id="signupModalInputEmail"
          name="email"
          required
          type="text"
          placeholder="Please enter your email address here"
        />
        <div *ngIf="email.invalid && (email.dirty || !email.pristine)" class="notify-me-modal__errors">
          <p *ngIf="email.errors.required" class="help-block">{{ 'SIGNUP.EMAIL_REQUIRED' | translate }}</p>
          <p *ngIf="email.errors.email" class="help-block">{{ 'SIGNUP.PLEASE_ENTER_VALID_EMAIL' | translate }}</p>
        </div>
        <app-button
          class="notify-me__book-button--guest"
          (clicked)="onSubmit(notifyMeForm)"
          [rounded]="true"
          buttonLabel="Submit"
        ></app-button>
      </form>
    </div>
  </div>
</app-mentorship-modal>
