import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalContactSupportComponent } from './book-modal-contact-support.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { FormsModule } from '@angular/forms';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { MessengerInputGroupModule } from '../../molecules/messenger-input-group/messenger-input-group.module';
import { MessengerInputModule } from '../../atoms/messenger-input/messenger-input.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { BookModalContactSupportEntryComponent } from './book-modal-contact-support-entry.component';
import { AutosizeModule } from 'ngx-autosize';

@NgModule({
  declarations: [BookModalContactSupportComponent, BookModalContactSupportEntryComponent],
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    BookModalSectionModule,
    MessengerInputGroupModule,
    MessengerInputModule,
    ButtonModule,
    AutosizeModule,
  ],
  exports: [BookModalContactSupportComponent, BookModalContactSupportEntryComponent],
})
export class BookModalContactSupportModule {}
