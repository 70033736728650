import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OthersMessageGroupComponent } from './others-message-group.component';
import { OthersMessageModule } from '../../atoms/others-message/others-message.module';
import { MessageAvatarModule } from '../../atoms/message-avatar/message-avatar.module';

import { PipesModule } from '../../../utils/pipes/pipes.module';
import { OthersImageModule } from '../../atoms/others-image/others-image.module';
import { MessengerLabelModule } from '../messenger-label/messenger-label.module';
import { DateTimeLabelModule } from '../../atoms/date-time-label/date-time-label.module';
import { MessengerOpportunityCardModule } from '../messenger-opportunity-card/messenger-opportunity-card.module';

@NgModule({
  declarations: [OthersMessageGroupComponent],
  imports: [
    CommonModule,
    OthersMessageModule,
    MessageAvatarModule,
    MessengerOpportunityCardModule,
    PipesModule,
    OthersImageModule,
    MessengerLabelModule,
    DateTimeLabelModule,
  ],
  exports: [OthersMessageGroupComponent],
})
export class OthersMessageGroupModule {}
