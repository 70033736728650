<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [header]="toast.headertext"
  [class]="toast.classname"
  [autohide]="toast.autohide"
  [delay]="toast.delay || 2500"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <div>
      {{ toast.textOrTpl }}
    </div>
  </ng-template>

  <img
    class="toast-close"
    src="../../../assets/images/icons/close-toast.svg"
    alt="Close icon"
    (click)="toastService.remove(toast)"
  />
</ngb-toast>
