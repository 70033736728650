import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';

import { catchError, map, share, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { CustomProList } from '../../models';
import { MetaService, TitleService } from '../../utils';
import { ProfileService, ToastService } from '../../services';

@Component({
  selector: 'app-custom-pro-list',
  templateUrl: './custom-pro-list.component.html',
  styleUrls: ['./custom-pro-list.component.scss'],
})
export class CustomProListComponent implements OnInit {
  public customProList$: Observable<CustomProList>;
  public hash: string = '';
  public messages: { 404: string; 400: string; default: string };
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private metaService: MetaService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.getProCardList(this.hash);

    this.document.body.classList.add('custom-pro-list-page');
  }

  public getProCardList(hash: string): void {
    this.customProList$ = this.profileService.getMemberListByHash(hash).pipe(
      map((res) => res.body),
      tap((res) => this.setupMetaAndTitle(res)),
      catchError((error) => {
        let message;
        switch (error.status) {
          case 404:
            message = 'List not found';
            break;
          case 400:
            message = 'User is disabled';
            break;
          default:
            message = 'Something went wrong';
        }
        return of(this.showMessage(message));
      }),
      share(),
    );
  }

  private setupMetaAndTitle(data: CustomProList): void {
    const { description } = data;
    const { name } = data;
    const title = `${name} | Jammcard`;

    this.titleService.setTitle(title);

    this.metaService.addTag({ name: 'description', content: description });
    this.metaService.addTag({ name: 'og:url', content: this.document.location.protocol + this.document.location.href });
    this.metaService.addTag({ name: 'og:title', content: title });
    this.metaService.addTag({ name: 'og:description', content: description });
    this.metaService.addTag({ name: 'twitter:title', content: title });
    this.metaService.addTag({ name: 'twitter:description', content: description });
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
