<div class="landing__container">
  <app-header class="landing__page-header"></app-header>
  <section class="landing__content-container">
    <a class="landing__logo-container" href="https://site.jammcard.com/">
      <img class="landing__logo" src="../../assets/images/logos/jammcard--white.svg" alt="Jammcard logo" />
    </a>
    <p class="landing__paragraph">{{ 'HOME.MUSIC_PROFESSIONALS' | translate }}</p>
    <a class="landing__btn-container" href="http://site.jammcard.com/">
      <app-button
        id="landingButton"
        class="landing__btn"
        [buttonLabel]="buttonLabel"
        (clicked)="onClick($event)"
      ></app-button>
    </a>
    <section class="landing__footer">{{ 'HOME.COPYRIGHT' | translate }}</section>
  </section>
</div>
