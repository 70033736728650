import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeNotificationsComponent } from './change-notifications.component';
import { ChangeNotificationsSectionModule } from '../../molecules/change-notifications-section/change-notifications-section.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { FormsModule } from '@angular/forms';
import { ChangeNotificationModule } from '../../molecules/change-notification/change-notification.module';

@NgModule({
  declarations: [ChangeNotificationsComponent],
  exports: [ChangeNotificationsComponent],
  imports: [CommonModule, ChangeNotificationsSectionModule, ButtonModule, FormsModule, ChangeNotificationModule],
})
export class ChangeNotificationsModule {}
