import { BookingStatus } from '../services';
import { BasicProfile } from './profile';
import { IImage } from './';
import { BookingCurrent } from '../store/booking/reducers/booking.reducers';

export enum PaymentMethodType {
  CARD = 'CARD',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export enum BookingAction {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

export enum BookingRole {
  SELLER = 'seller',
  BUYER = 'buyer',
}

export enum ContactSupportReason {
  BOOKING = 'Booking',
  GENERAL = 'General',
  MY_JAMMCARD_ACCOUNT = 'My Jammcard Account',
  PAYMENT_AND_REFUNDS = 'Payment & Refunds',
}

export enum ContactSupportReasonRequestParam {
  GENERAL_BOOKING = 'GENERAL_BOOKING',
  SPECIFIC_BOOKING = 'SPECIFIC_BOOKING',
  PAYMENT_AND_REFUNDS = 'PAYMENT_AND_REFUNDS',
  ACCOUNT = 'ACCOUNT',
  GENERAL = 'GENERAL',
}

export interface BookingsByStatus {
  bookingsWhereUserIsTheBookee: BookingByStatus[];
  bookingsWhereUserIsTheBooker: BookingByStatus[];
}

export interface BookingRating {
  id: number;
  createdAt: number | Date;
  updatedAt: number | Date;
  bookingExperienceByBooker: number;
  bookingExperienceByBooked: number;
  rateBooker: number;
  rateBooked: number;
  bookerFeedback: string;
  bookedFeedback: string;
}

export interface BookingRatingResults {
  bookingExperienceRating: number;
  feedback: string;
  partnerRating: number;
}

export interface BookingModification {
  id: number;
  createdAt: number | Date;
  updatedAt: number | Date;
  requestedNewBookingDates: number[] | Date[];
  modificationRequestedBy: string;
  modificationRequestStatus: BookingStatus;
  bookingChange: unknown;
  bookingModel: unknown;
}

export interface BookingPaymentDetails {
  id: number;
  createdAt: number | Date;
  updatedAt: number | Date;
  paymentIntentId: string;
  payoutDate: number | Date;
  paymentDate: number | Date;
  estimatedArrivalDate: number | Date;
  refundDate: number | Date;
  refundAmountBooker: number;
  cancellationFeeBooker: number;
  partialPayoutAmountBookee: number;
  missedEarningsBookee: number;
  paymentMethodType: number | string;
}

export interface BookingByStatus {
  assignedId: string;
  bookedServiceType?: string;
  bookee: Bookee;
  booker: Booker;
  bookingRating: BookingRating;
  buyersFee: number;
  cancellationReason: any;
  createdAt: number | Date;
  dates: number[] | Date[];
  endDate?: number;
  gigTitle: string;
  id: number;
  instant?: boolean;
  message: string;
  modificationRequests: BookingModification[];
  paymentDetails: BookingPaymentDetails;
  price: number;
  rated: boolean;
  seen: boolean;
  sellersFee: number;
  startDate?: number;
  status: string;
  updatedAt: number;
  totalPrice?: number;
  videoLink?: string;
}

export interface BookingsCardInfo {
  bookingsWhereUserIsTheBookee: BookingCardInfo[];
  bookingsWhereUserIsTheBooker: BookingCardInfo[];
}

// This is model for data for Booking card
// map BE response (that is of type BookingByStatus) into this model
export interface BookingCardInfo {
  bookee: Booker;
  booker: Booker;
  dates: number[] | Date[];
  bookedServiceType: string;
  gigTitle: string;
  id: number;
  instant?: boolean;
  seen: boolean;
  price: number;
  status: string;
  startDate?: number;
  endDate?: number;
  totalPrice?: number;
}

export interface BookingQuickInfoCardInfo {
  bookee: Booker;
  booker: Booker;
  gigTitle: string;
  id: number;
  price: number;
  totalPrice: number;
  status: string;
}

export interface Booker {
  city: string;
  name: string;
  profileId: number;
  profileImage: IImage;
  webViewUrl: string;
  videoLink?: string;
}

export interface Bookee extends Booker {}

export interface ConfirmAndPayBooking {
  paymentIntentId?: string;
  paymentMethodId: string;
  paymentMethodType: PaymentMethodType;
  bookingId?: number;
}

export interface BookingRating {
  bookingExperienceRating: number;
  partnerRating: number;
  feedback: string;
}

export interface ProfileImage {
  id: number;
  mediaId: number;
  url: string;
  thumbnailSmall?: string;
  thumbnailMedium?: string;
  thumbnailLarge?: string;
}

export interface PaymentCard {
  cardId: string;
  cardName: string;
  last4: string;
  default: boolean;
  expYear: number;
  expMonth: number;
  zipCode: string;
}

export interface PaymentCardView extends PaymentCard {
  expYearShort: string;
  securityCodeLabel: string;
  securityCodeLength: number;
}

export interface BookingModal {
  booking: BookingCurrent;
  buyer?: BasicProfile;
  contactSupportReason?: ContactSupportReason;
  seller?: BasicProfile;
  user?: BasicProfile;
  isSeller?: boolean;
  isRated?: boolean;
  openedFromWebView?: boolean;
}

export interface BookingListStatus {
  bookee: {
    hasPast: boolean;
    hasPending: boolean;
    hasUpcoming: boolean;
  };
  booker: {
    hasPast: boolean;
    hasPending: boolean;
    hasUpcoming: boolean;
  };
}

export interface AffiliateCampaign {
  id: number;
  cid: string;
  name: string;
  type: string;
  revenueSharePercentage: number;
  expirationDate: number;
}

export interface AffiliateCampaignsData {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  primaryContact: string;
  affiliateCampaigns: AffiliateCampaign[];
}
