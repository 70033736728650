import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BasicProfile, BookingRole } from '../../../models';
import { BookingService, ProfileService, ToastService } from '../../../services';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BookModalPendingRequestComponent } from './book-modal-pending-request.component';
import { BOOKING_STATUS_RESPONSE } from '../../../pages/bookings/bookings.config';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';
import { BookingActions } from '../../../store/booking/action-types';
import { getBookingFromUrl } from '../../../utils/operators';

@Component({
  template: '',
})
export class BookModalPendingRequestEntryComponent implements OnInit, OnDestroy {
  public modalInstance: any;
  public bookModalData: any;
  public modal$: Subscription;
  public sellerInfo$: Subscription;
  public router$: Subscription;

  constructor(
    public bookingService: BookingService,
    private modalService: NgbModal,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private toastService: ToastService,
  ) {}

  openModal(booking: BookingCurrent, buyer: BasicProfile): void {
    this.modalInstance = this.modalService.open(BookModalPendingRequestComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = { booking, buyer };
    this.store.dispatch(BookingActions.seenBookingById({ booking: booking.booking }));

    this.modalInstance.result.then(
      () => this.router.navigate(['bookings']),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH ? this.router.navigate(['bookings']) : null;
      },
    );
  }

  ngOnInit() {
    const bookingIdRouteParam = this.route.snapshot.params.bookingId;

    this.modal$ = this.store
      .select(selectCurrentBooking)
      .pipe(
        take(1),
        mergeMap((booking) => getBookingFromUrl(booking, bookingIdRouteParam, this.store)),
      )
      .subscribe((booking) => {
        if (
          bookingIdRouteParam !== booking.booking.id.toString() ||
          booking.booking.status !== BOOKING_STATUS_RESPONSE.PENDING ||
          booking.role !== BookingRole.SELLER
        ) {
          this.showMessage('Action not allowed for selected booking!');
          this.router.navigate(['bookings']);
        } else {
          this.profileService
            .getBasicProfile(booking.booking.booker.profileId)
            .subscribe((buyer) => this.openModal(booking, buyer.body));
        }
      });
  }

  ngOnDestroy() {
    this.modal$.unsubscribe();
    //this.router$.unsubscribe();
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
