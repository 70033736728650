<div class="seller-profile-card__overlay" style="background-image: url({{ coverImageForDisplay }})">
  <div class="seller-profile-card__container">
    <img class="seller-profile-card__close" [src]="closeIcon" alt="Close icon" (click)="onCloseClicked()" />
    <div class="seller-profile-card__data">
      <div class="seller-profile-card__logo">
        <img [src]="jammcardLogo" />
      </div>
      <div class="seller-profile-card__profile-image">
        <img [src]="profileImageForDisplay" />
      </div>
      <div class="seller-profile-card__info">
        <h2 class="seller-profile-card__name">
          {{ proTruncatedProfile?.name }}
          <img
            class="seller-profile-card__verified-checkmark"
            [src]="verifiedCheckmark"
            alt="Verified checkmark"
            *ngIf="proTruncatedProfile?.verified"
          />
        </h2>
        <p class="seller-profile-card__headline" *ngIf="proTruncatedProfile?.headline">
          {{ proTruncatedProfile?.headline }}
        </p>
        <p class="seller-profile-card__rate-label" *ngIf="proTruncatedProfile?.headline">
          {{ rateLabel }}
        </p>
        <app-rate-label
          class="seller-profile-card__rate"
          [rates]="proTruncatedProfile?.servicesProvision[0]?.rates"
        ></app-rate-label>
      </div>
    </div>
    <div
      *ngIf="isMobile"
      visibilityObserver
      (visible)="topVisible($event)"
      class="seller-profile-card__buttons-top-mark"
    ></div>
    <div *ngIf="!noButtons" class="seller-profile-card__buttons" [ngClass]="{ sticky: buttonShouldStick }">
      <app-button
        *ngIf="!hideBookButton && hasAvailability"
        class="seller-profile__book-button"
        buttonLabel="{{ 'BOOK.BOOK' | translate }}"
        [rounded]="true"
        (clicked)="onBookClicked()"
      ></app-button>
      <app-button
        *ngIf="!hideBookButton && !hasAvailability && !isSubscribed"
        class="seller-profile-card__notify-button"
        buttonLabel="{{ 'HOME.JOIN_WAITLIST' | translate }}"
        [rounded]="true"
        (clicked)="onNotifyMeClicked()"
        [ngbTooltip]="tipContent"
        triggers="hover"
        placement="bottom"
        [tooltipClass]="'seller-profile-card__notify-button-tooltip'"
      ></app-button>
      <app-button
        *ngIf="!hideBookButton && !hasAvailability && isSubscribed"
        class="seller-profile-card__notify-button"
        buttonLabel="{{ 'HOME.JOIN_WAITLIST' | translate }}"
        [rounded]="true"
        [buttonGrayDisabled]="isSubscribed"
      ></app-button>
      <!-- Leave Waitlist button is removed from truncated profile, logic will be temporary commented but not removed due to possibility to change it back -->
      <!-- <app-button
        *ngIf="!hideBookButton && !hasAvailability && isSubscribed"
        class="seller-profile-card__notify-button"
        buttonLabel="{{ 'HOME.LEAVE_WAITLIST' | translate }}"
        [rounded]="true"
        (clicked)="onUnsubscribeClicked()"
        triggers="hover"
        placement="bottom"
      ></app-button> -->
      <ng-template #tipContent>{{ 'HOME.GET_NOTIFIED_WHEN_SLOTS_OPEN_UP' | translate }}</ng-template>
      <!-- <a class="seller-profile-card__full-profile" routerLink="/{{ proTruncatedProfile?.username }}" target="_blank" rel="noopener noreferrer">
        <app-button buttonLabel="{{ 'HOME.FULL_PROFILE' | translate }}" [roundedInverted]="true"></app-button>
      </a> -->
    </div>
    <div
      *ngIf="isMobile"
      visibilityObserver
      (visible)="bottomVisible($event)"
      class="seller-profile-card__buttons-bottom-mark"
    ></div>
  </div>
</div>
