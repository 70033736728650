<div class="info-modal modal-fill-screen flex column">
  <section
    class="info-modal__header flex column justify-center align-center"
    [ngClass]="infoModalData?.backgroundImageClass"
  >
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
  </section>
  <section class="info-modal__content flex column align-center justify-center">
    <header class="info-modal__heading flex justify-center">
      <p class="info-modal__title" [ngClass]="{ 'info-modal__title-exit-mentorship': infoModalData?.exitMentorship }">
        {{ infoModalData?.title }}
      </p>
    </header>
    <p *ngIf="infoModalData?.description?.length" class="info-modal__description">
      {{ infoModalData?.description }}
      <br *ngIf="infoModalData?.subdescription?.length" />
      <label *ngIf="infoModalData?.subdescription?.length">
        {{ infoModalData?.subdescription }}
      </label>
    </p>
    <p *ngIf="calendarType" class="info-modal__date">
      <label class="calendarType">
        {{
          calendarType === appleCalendar
            ? ('INFO_MODAL.DOWNLOAD_APPLE_CALENDAR' | translate)
            : ('INFO_MODAL.CALENDAR_IN_NEW_TAB' | translate)
        }}
      </label>
    </p>
    <p *ngIf="infoModalData?.calendar && !calendarType" class="info-modal__date">
      <app-date-time-range-zone-label
        [startDate]="infoModalData?.bookingStardDate"
        [endDate]="infoModalData?.bookingEndDate"
      >
      </app-date-time-range-zone-label>
    </p>
    <p *ngIf="infoModalData?.calendar && !infoModalData?.openedFromWebView" class="info-modal__calendar">
      <app-calendar-select (emitCalendar)="emitCalendar($event)" [showCollapse]="true"></app-calendar-select>
    </p>
    <div
      class="info-modal__btn-container auth-modal__send-btn-container"
      *ngIf="!infoModalData?.exitMentorship && infoModalData?.buttonLabel"
    >
      <app-button
        (clicked)="closeModal($event)"
        [buttonDisabled]="false"
        [buttonLabel]="'INFO_MODAL_SUCCESS.' + infoModalData?.buttonLabel | translate"
        [buttonType]="'submit'"
        [rounded]="true"
      >
      </app-button>
    </div>
    <div
      class="info-modal__btn-container info-modal__double-btns auth-modal__send-btn-container"
      *ngIf="infoModalData?.exitMentorship"
    >
      <div class="info-modal__btn-groups">
        <app-button
          *ngIf="infoModalData?.buttonExitLabel"
          (clicked)="closeModal(true)"
          [buttonDisabled]="false"
          [buttonLabel]="'INFO_MODAL_SUCCESS.' + infoModalData?.buttonExitLabel | translate"
          [rounded]="true"
          [inverted]="true"
        >
        </app-button>
        <app-button
          *ngIf="infoModalData?.buttonStayLabel"
          (clicked)="closeModal()"
          [buttonDisabled]="false"
          [buttonLabel]="'INFO_MODAL_SUCCESS.' + infoModalData?.buttonStayLabel | translate"
          [rounded]="true"
        >
        </app-button>
      </div>
    </div>
    <div
      *ngIf="infoModalData?.redirectToArr?.length && infoModalData?.buttonLabelsArr?.length"
      class="auth-modal__send-btn-container"
    >
      <div class="info-modal__btn-groups no-max-width">
        <app-button
          *ngIf="infoModalData?.buttonLabelsArr[0]"
          (clicked)="closeModal(0)"
          [buttonDisabled]="false"
          [buttonLabel]="'INFO_MODAL_SUCCESS.' + infoModalData?.buttonLabelsArr[0] | translate"
          [rounded]="true"
          [inverted]="true"
        >
        </app-button>
        <app-button
          *ngIf="infoModalData?.buttonLabelsArr[1]"
          (clicked)="closeModal(1)"
          [buttonDisabled]="false"
          [buttonLabel]="'INFO_MODAL_SUCCESS.' + infoModalData?.buttonLabelsArr[1] | translate"
          [rounded]="true"
        >
        </app-button>
      </div>
    </div>
    <ng-content> </ng-content>
  </section>
</div>
