<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div class="book-modal-pay-to-confirm__content auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">
        How did <i>{{ bookModalPendingRequestData?.gigTitle }}</i> go?
      </p>
    </header>
    <form
      class="auth-modal__form flex column justify-content-between height-100"
      #bookForm="ngForm"
      (ngSubmit)="submitForm()"
    >
      <div>
        <app-book-modal-section>
          <app-request-quick-card [requestQuickCardData]="requestQuickCardData"></app-request-quick-card>
        </app-book-modal-section>

        <app-book-modal-section class="book-modal-rating__rating-section" [sectionTitle]="'Rating'">
          <app-rate-experience-group [name]="user?.name" [(starNumber)]="starNumber"></app-rate-experience-group>
        </app-book-modal-section>

        <app-book-modal-section [sectionTitle]="'Review'">
          <textarea
            class="book-modal-rating__input book-modal__input-text"
            [placeholder]="'Please leave a review on your work with ' + user?.name"
            type="text"
            name="review"
            #review="ngModel"
            [(ngModel)]="reviewText"
            required
            [maxlength]="255"
          ></textarea>
          <p *ngIf="reviewText?.length == 255" class="help-block">
            {{ 'BOOK.REVIEW_CANNOT_BE_LONGER_THAN_255_CHARACTERS' | translate }}
          </p>
        </app-book-modal-section>
      </div>

      <div class="book-modal-rating__button book-modal__send-btn-container">
        <app-button
          id="bookModalButton"
          [buttonDisabled]="!bookForm.valid && starNumber != 0 && starNumber != null"
          [buttonLabel]="buttonLabel"
          [buttonType]="'submit'"
        >
        </app-button>
        <section class="book-modal-rating__disclaimer">
          <p class="book-modal__disclaimer book-modal__input-text">
            {{ 'BOOK.RATINGS_DISCLAIMER' | translate }}
          </p>
        </section>
      </div>
    </form>
  </div>
</app-jammcard-modal>
