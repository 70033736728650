import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerInputGroupComponent } from './messenger-input-group.component';
import { AddPictureButtonModule } from '../../atoms/add-picture-button/add-picture-button.module';
import { MessengerInputModule } from '../../atoms/messenger-input/messenger-input.module';

@NgModule({
  declarations: [MessengerInputGroupComponent],
  imports: [CommonModule, AddPictureButtonModule, MessengerInputModule],
  exports: [MessengerInputGroupComponent],
})
export class MessengerInputGroupModule {}
