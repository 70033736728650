import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';

//TODO(Mladen) TODO(Jovana): Generalize data naming
export interface IAvatarGroupData {
  id?: number;
  username?: string;
  profileImage: string;
  proName: string;
  proVerified: boolean;
  proCity: string;
}

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss'],
})
export class AvatarGroupComponent implements OnInit {
  @Input() avatarGroupData: IAvatarGroupData;
  @Input() isClickable: boolean = false;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  public goToProfile() {
    if (!isNil(this.avatarGroupData.id) && !isNil(this.avatarGroupData.username) && this.isClickable)
      this.router.navigateByUrl(`${this.avatarGroupData.username}`);
  }
}
