import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RatesAccordionCardComponent } from './rates-accordion-card.component';

@NgModule({
  declarations: [RatesAccordionCardComponent],
  imports: [CommonModule],
  exports: [RatesAccordionCardComponent],
})
export class RatesAccordionCardModule {}
