<div class="payment-method-tab-group" #paymentMethodTabGroup>
  <app-payment-method-tab
    #paymentMethodTab
    *ngFor="let paymentMethod of paymentMethods$ | async"
    [paymentMethod]="paymentMethod"
    [activeTab$]="activeTab$"
    (click)="selectTab(paymentMethod)"
  >
  </app-payment-method-tab>
</div>
