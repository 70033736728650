import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyImageComponent } from './my-image.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { ImagePlaceholderModule } from '../image-placeholder/image-placeholder.module';

@NgModule({
  declarations: [MyImageComponent],
  imports: [CommonModule, NgxGalleryModule, ContentLoaderModule, ImagePlaceholderModule],
  exports: [MyImageComponent],
})
export class MyImageModule {}
