<div class="messenger-input__wrapper flex">
  <textarea
    [(ngModel)]="text"
    (keydown.enter)="sendMessageOnEnter(text, $event)"
    class="messenger-input"
    name="message"
    [minRows]="1"
    autosize
    required
    [placeholder]="'Type Message'"
    (focus)="inputFocus()"
    (blur)="inputBlur()"
  ></textarea>
  <button *ngIf="text.length" class="messenger-input__send--btn" (click)="sendMessage(text)">Send</button>
</div>
