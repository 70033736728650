import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BookingServicesModalComponent } from './booking-services-modal.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { EditModalModule } from '../edit-modal/edit-modal.module';
import { RateLabelModule } from '../../molecules/rate-label/rate-label.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BookingServicesModalComponent],
  imports: [ButtonModule, CommonModule, EditModalModule, RateLabelModule, TranslateModule],
  exports: [BookingServicesModalComponent],
})
export class BookingServicesModalModule {}
