import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { BookingInfo } from '../book-modal/book-modal.component';
import { isNil } from 'lodash-es';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-booking-sent-modal',
  templateUrl: './booking-sent-modal.component.html',
  styleUrls: ['./booking-sent-modal.component.scss'],
})
export class BookingSentModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public bookingDate: string;
  public gigTitle: string;
  public loggedUserName: NgbDateStruct;
  public profileImage: BookingInfo;
  public proName: string;
  public redirectToUrl: string;
  public fromParent: any;

  constructor(public activeModal: NgbActiveModal, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.bookingDate = this.fromParent.bookingDate;
    this.gigTitle = this.fromParent.gigTitle;
    this.loggedUserName = this.fromParent.loggedUserName;
    this.profileImage = this.fromParent.profileImage;
    this.proName = this.fromParent.proName;
    this.redirectToUrl = this.fromParent.redirectToUrl;
  }

  closeModal(event): any {
    this.activeModal.close();
    this.redirectTo(this.redirectToUrl);
  }

  private redirectTo(url: string): void {
    if (!isNil(url)) this.router.navigate([url]);
  }
}
