import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaModule } from '../media/media.module';
import { TruncatedMediaComponent } from './truncated-media.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  declarations: [TruncatedMediaComponent],
  imports: [CommonModule, MediaModule, PipesModule, SwiperModule],
  exports: [TruncatedMediaComponent],
})
export class TruncatedMediaModule {}
