import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { Store } from '@ngrx/store';
import { ProfileActions } from '../../../store/profile/action-types';
import { selectProfileBasicById } from '../../../store/profile/profile.selectors';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';
import { take, filter } from 'rxjs/operators';
import { ProfileService } from '../../../services';

@Component({
  selector: 'app-message-avatar',
  templateUrl: './message-avatar.component.html',
  styleUrls: ['./message-avatar.component.scss'],
})
export class MessageAvatarComponent implements OnInit {
  @Input() avatarImage;
  @Input() otherParticipantProfileId: number = null;
  @Input() otherParticipantUsername: string = null;

  constructor(private readonly router: Router, private store: Store, private profileService: ProfileService) {}

  ngOnInit(): void {}

  public goToProfile(): void {
    if (!this.otherParticipantProfileId && !this.otherParticipantUsername) {
      // this is the avatar of logged in user so get the username from the store
      this.store
        .select(selectCurrentUserShort)
        .pipe(take(1))
        .subscribe((currentUserShort) => {
          this.router.navigateByUrl(`${currentUserShort.username}`);
        });
    } else {
      if (this.otherParticipantUsername) {
        // this is the avatar of the other participant and the username is already fetched
        this.router.navigateByUrl(`${this.otherParticipantUsername}`);
      } else {
        // this is the avatar of the other participant but the username has to be fetched first
        this.store.dispatch(ProfileActions.loadBasicProfileInfoById({ id: this.otherParticipantProfileId }));
        this.store
          .select(selectProfileBasicById, { id: this.otherParticipantProfileId })
          .pipe(
            filter((profile) => !isNil(profile)),
            take(1),
          )
          .subscribe((profile) => {
            if (!isNil(this.otherParticipantProfileId)) this.router.navigateByUrl(`${profile.username}`);
          });
      }
    }
  }
}
