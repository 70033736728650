import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TruncatedMedia, BaseType } from './truncated-media.config';
import { getIframeSrc, IsBrowserService } from '../../../utils';
import { MediaModalComponent } from '../media-modal/media-modal.component';
import { Media, MediaList } from '../../../models';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PaginationOptions } from 'swiper/types/components/pagination';

@Component({
  selector: 'app-truncated-media',
  templateUrl: './truncated-media.component.html',
  styleUrls: ['./truncated-media.component.scss'],
})
export class TruncatedMediaComponent implements OnInit, AfterViewInit {
  @Input() media: MediaList;

  @ViewChild(SwiperDirective) swiperRef: SwiperDirective;

  public mediaHighlight: string = '';
  public mediaOthers: string[] = [''];
  public mediaAll: { url: string; isLoaded: boolean }[] = [];
  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    type: 'bullets',
    bulletActiveClass: 'jamm-swiper-pagination-bullet--active',
    bulletClass: 'jamm-swiper-pagination-bullet',
  };
  public swiperConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    centeredSlides: true,
    pagination: false,
    navigation: true,
  };

  constructor(private isBrowserService: IsBrowserService, private modalService: NgbModal) {}

  ngOnInit(): void {
    if (this.isBrowserService.isBrowser() && this.media?.medias?.length) {
      this.format(this.media.medias);
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowserService.isBrowser() && this.media?.medias?.length) {
      this.swiperConfig.pagination = this.pagination;
      this.swiperRef.setIndex(0);
      this.swiperRef.init();
    }
  }

  public format(media: Media[]): void {
    this.mediaHighlight = getIframeSrc(media[0].resourceUri, media[0].baseType);
    this.mediaOthers = [...media.slice(1)].map((media) => getIframeSrc(media.resourceUri, media.baseType));
    this.mediaAll = [...media]
      .map((media) => getIframeSrc(media.resourceUri, media.baseType))
      .map((media) => Object.assign({ url: media, isLoaded: false }));
  }

  public openModal(mediaUrl: string): void {
    const modalInstance = this.modalService.open(MediaModalComponent, {
      windowClass: 'media-modal-window',
    });
    modalInstance.componentInstance.fromParent = { mediaUrl };
  }
}
