import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GearBrand, Gender, Genre, IDataPageableResponse, SearchProfile, Skill } from '../models';
import { ISearch, ISearchDTO, latlonHollywood } from '../models/search';
import { SERVICE_URL } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  public getSkills(): Observable<HttpResponse<Skill[]>> {
    return this.http.get<Skill[]>(`${SERVICE_URL.PROFILE}api/profiles/skills`, { observe: 'response' });
  }

  public getGearBrands(): Observable<HttpResponse<GearBrand[]>> {
    return this.http.get<GearBrand[]>(`${SERVICE_URL.PROFILE}api/profiles/brands`, { observe: 'response' });
  }

  public getGenres(): Observable<HttpResponse<Genre[]>> {
    return this.http.get<Genre[]>(`${SERVICE_URL.PROFILE}api/profiles/genres`, { observe: 'response' });
  }

  public getUsersByName(
    name: string,
    page: number = 0,
    lat: number = latlonHollywood.lat,
    lon: number = latlonHollywood.lon,
  ): Observable<HttpResponse<IDataPageableResponse<Partial<SearchProfile>[]>>> {
    return this.http.get<IDataPageableResponse<Partial<SearchProfile>[]>>(
      `${SERVICE_URL.PROFILE}api/search/${name}?lat=${lat}&lon=${lon}&page=${page}`,
      {
        observe: 'response',
      },
    );
  }

  public getUsers(
    filter: ISearchDTO,
    page: number = 0,
  ): Observable<HttpResponse<IDataPageableResponse<SearchProfile[]>>> {
    return this.http.post<IDataPageableResponse<SearchProfile[]>>(
      `${SERVICE_URL.PROFILE}api/search/v3?page=${page}`,
      filter,
      {
        observe: 'response',
      },
    );
  }
}
