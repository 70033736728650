import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProfileFormComponent } from './edit-profile-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [EditProfileFormComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, PipesModule, ButtonModule, NgSelectModule],
  exports: [EditProfileFormComponent],
})
export class EditProfileFormModule {}
