import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from '../utils/auth.guard';
import { LandingComponent } from '../pages/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'mentors',
    loadChildren: () => import('../pages/mentors/mentors.module').then((m) => m.MentorsModule),
  },
  {
    path: 'mentors/about',
    loadChildren: () => import('../pages/mentors-about/mentors-about.module').then((m) => m.MentorsAboutModule),
  },
  {
    path: 'category',
    loadChildren: () => import('../pages/search-results/search-results.module').then((m) => m.SearchResultsModule),
  },
  {
    path: 'search-results',
    loadChildren: () => import('../pages/search-results/search-results.module').then((m) => m.SearchResultsModule),
  },
  {
    path: 'search',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('../pages/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('../pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'tos',
    loadChildren: () => import('../pages/terms-of-service/terms-of-service.module').then((m) => m.TermsOfServiceModule),
  },
  {
    path: 'bookings',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('../pages/bookings/bookings.module').then((m) => m.BookingsModule),
  },
  {
    path: 'messenger',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('../pages/messenger/messenger.module').then((m) => m.MessengerModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('../pages/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'my-opportunities',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('../pages/my-opportunities/my-opportunities.module').then((m) => m.MyOpportunitiesModule),
  },
  {
    path: 'jobs',
    loadChildren: () => import('../pages/jammboard/jammboard.module').then((m) => m.JammboardModule),
  },
  {
    path: 'seller-payout-method-confirm',
    loadChildren: () =>
      import('../pages/seller-payout-method-confirm/seller-payout-method-confirm.module').then(
        (m) => m.SellerPayoutMethodConfirmModule,
      ),
  },
  {
    path: 'video/:bookingId',
    loadChildren: () => import('../pages/video-call/video-call.module').then((m) => m.VideoCallModule),
  },
  {
    path: ':id',
    loadChildren: () => import('../pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'user/verification',
    loadChildren: () =>
      import('../pages/verification-email/verification-email.module').then((m) => m.VerificationEmailModule),
  },
  {
    path: 'user/email-change/verification',
    loadChildren: () =>
      import('../pages/verification-email-change/verification-email-change.module').then(
        (m) => m.VerificationEmailChangeModule,
      ),
  },
  {
    path: 'user/role-upgrade/verification',
    loadChildren: () =>
      import('../pages/verification-role-upgrade/verification-role-upgrade.module').then(
        (m) => m.VerificationRoleUpgradeModule,
      ),
  },
  {
    path: 'list/:name/:hash',
    loadChildren: () => import('../pages/custom-pro-list/custom-pro-list.module').then((m) => m.CustomProListModule),
  },
  {
    path: '**',
    component: LandingComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
