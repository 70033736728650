import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import { BookingService } from '../../../services';
import { BookingByStatus, BookingModal, BasicProfile, BookingRatingResults } from '../../../models';
import { BookModalData } from '../book-modal/book-modal.component';
import { deleteCurrentBooking } from '../../../store/booking/booking.actions';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { IRequestQuickCardData } from '../../molecules/request-quick-card/request-quick-card.component';
import { createBookModalData, createAvatarGroupData } from '../../../utils';
import { STATUS_TITLE } from '../../../pages/bookings/bookings.config';

@Component({
  selector: 'app-book-modal-rating',
  templateUrl: './book-modal-rating.component.html',
  styleUrls: ['./book-modal-rating.component.scss'],
})
export class BookModalRatingComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public bookModalPendingRequestData: BookingByStatus;
  public bookModalData: BookModalData;
  public fromParent: BookingModal;
  public avatarGroupData: IAvatarGroupData;
  public requestQuickCardData: IRequestQuickCardData;
  public buttonLabel: string;
  public paymentLabel: string;
  public user: BasicProfile;
  public starNumber: number;
  public reviewText: string;
  public bookingRatingResults: BookingRatingResults;

  constructor(
    public activeModal: NgbActiveModal,
    public bookingService: BookingService,
    public translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.translate.get('BOOK').subscribe((res: any) => {
      this.buttonLabel = res.SEND_REVIEW;
      this.paymentLabel = res.PAYMENT;
    });

    this.bookModalPendingRequestData = { ...this.fromParent.booking.booking };
    this.user = { ...this.fromParent.user };
    this.bookModalData = createBookModalData(this.user);
    this.avatarGroupData = createAvatarGroupData(this.user);

    this.requestQuickCardData = {
      bookingDate: this.bookModalPendingRequestData.dates[0],
      loggedUserName: this.bookModalPendingRequestData.bookee.name,
      profileImage: '',
      proName: this.bookModalPendingRequestData.bookee.name,
      gigTitle: this.bookModalPendingRequestData.gigTitle,
      requestStatusLabel: STATUS_TITLE[this.bookModalPendingRequestData.status],
      city: this.bookModalData?.proCity,
    };
  }

  closeModal(event): any {
    this.activeModal.close();
  }

  submitForm(): any {
    this.bookingRatingResults = {
      bookingExperienceRating: this.starNumber,
      feedback: this.reviewText,
      partnerRating: this.starNumber,
    };
    this.closeModal(undefined);
    this.bookingService
      .rateBooking(this.bookModalPendingRequestData.id, { ...this.bookingRatingResults })
      .subscribe(() => this.store.dispatch(deleteCurrentBooking()));
  }
}
