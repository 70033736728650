import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BasicProfile, ImageType } from '../../../models';
import { IInfoModalData } from '../../templates/info-modal/info-modal.config';

@Component({
  selector: 'app-edit-profile-modal-basic',
  templateUrl: './edit-profile-modal-basic.component.html',
  styleUrls: ['./edit-profile-modal-basic.component.scss'],
})
export class EditProfileModalBasicComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  private fromParent: { profile: BasicProfile };

  public currentCoverImage: string;
  public currentProfileImage: string;
  public profile: BasicProfile;
  public readonly PROFILE_IMAGE_TYPE = ImageType.PROFILE;
  public readonly COVER_IMAGE_TYPE = ImageType.COVER;
  // Mock data
  public infoModalData: IInfoModalData = { backgroundImageClass: 'jammcard' };

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.currentCoverImage = this.fromParent?.profile?.coverImage?.thumbnailMedium;
    this.currentProfileImage = this.fromParent?.profile?.profileImage?.thumbnailMedium;
    this.profile = this.fromParent?.profile;
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
