<section class="rate-experience-group__container">
  <p class="rated-experience-group__title">Rate your experience with {{ name }}</p>
  <div class="flex row" *ngIf="starEmptyNumberArray || starFullNumberArray">
    <img
      *ngFor="let i of starFullNumberArray"
      class="rated-experience-group__img"
      src="assets/images/icons/star-full.svg"
      alt="Star"
    />
    <img
      *ngFor="let i of starEmptyNumberArray"
      class="rated-experience-group__img"
      src="assets/images/icons/star-empty.svg"
      alt="Star"
    />
  </div>
</section>
