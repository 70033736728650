import { createSelector } from '@ngrx/store';
import { isNil } from 'lodash-es';

import { BasicProfileState } from './reducers/profile.reducers';
import { isRouteStringOrNumber } from '../../utils';
import { BasicProfile } from '../../models';

interface profileState {
  basic: BasicProfileState;
  media: any;
  extended: any;
}
export const selectProfile = (state: any) => state.profile;

export const selectProfileBasic = createSelector(selectProfile, (state: profileState) => state.basic);
export const selectProfileBasicById = createSelector(
  selectProfileBasic,
  (state: BasicProfileState, props: { id: number }) => selectByIdOrUsername(state, props.id),
);
export const selectProfileBasicByUsername = createSelector(selectProfileBasic, (state: any, props: { id: string }) =>
  selectByIdOrUsername(state, props.id),
);
export const isProfileBasicLoaded = createSelector(selectProfileBasic, (state) => state.isLoaded);
export const isProfileBasicLoadedWithServicesProvision = createSelector(
  selectProfileBasic,
  (state) => state.isLoadedWithServicesProvision,
);
export const profileDoesntExist = createSelector(selectProfileBasic, (state) => state.doesntExist);

export const selectProfileExtended = createSelector(selectProfile, (state: any) => state.extended);
export const selectProfileExtendedById = createSelector(selectProfileExtended, (state: any, props: { id: number }) =>
  state?.entities ? state?.entities[props.id] : {},
);
export const isProfileExtendedLoaded = createSelector(selectProfileExtended, (state) => state.isLoaded);

export const selectProfileMedia = createSelector(selectProfile, (state: any) => state.media);
export const selectProfileMediaById = createSelector(
  selectProfileMedia,
  (media: any, props: { id: number }) => media?.entities[props.id] ?? null,
);
export const isProfileMediaLoaded = createSelector(selectProfileMedia, (state) => state.isLoaded);

export const selectByIdOrUsername = (state: BasicProfileState, id: string | number): BasicProfile => {
  if (state.entities === {} || isNil(id)) {
    return null;
  }

  // NOTE: username can contain capital letters! Take into account when comparing!
  if (isRouteStringOrNumber(id) === 'string') {
    const profileByUsername = Object.values(state.entities)
      .filter((value) => value.username.toLowerCase() === (id as string).toLowerCase())
      .pop();

    return profileByUsername;
  } else {
    const profileById = state.entities[id];
    return profileById;
  }
};
