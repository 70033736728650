import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { SERVICE_URL } from '../utils';
import {
  GigStatus,
  ICityWithCoordinates,
  IDataPageableResponse,
  IGigMain,
  SkillFeatured,
  IGigCreateDTO,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class JammboardService {
  constructor(private authService: AuthenticationService, private http: HttpClient) {}

  public getOpportunities(
    loadGigsFromDateTime: number,
    skillsToFilterBy: SkillFeatured[] = null,
    cityWithCoordinatesDTO: ICityWithCoordinates = null,
    status: GigStatus = GigStatus.ACTIVE,
  ): Observable<HttpResponse<IDataPageableResponse<IGigMain[]>>> {
    let skillIds = skillsToFilterBy?.length > 0 ? skillsToFilterBy.map((item) => item.id) : null;
    return this.http.post<IDataPageableResponse<IGigMain[]>>(
      `${SERVICE_URL.PROFILE}api/opportunities/filter?status=${status}${
        loadGigsFromDateTime ? '&fromDateTime=' + loadGigsFromDateTime : ''
      }`,
      {
        skillIds,
        cityWithCoordinatesDTO,
        status,
      },
      { observe: 'response' },
    );
  }

  public getSingleOpportunity(id: number): Observable<HttpResponse<IGigMain>> {
    let epType = this.authService.isLoggedIn() ? 'api' : 'auth';
    return this.http.get<IGigMain>(`${SERVICE_URL.PROFILE}${epType}/opportunities/${id}`, { observe: 'response' });
  }

  // TODO (Milan): Finalize this method, currently used for adding test data
  public postOpportunity(gigData: IGigCreateDTO): Observable<HttpResponse<any>> {
    return this.http.post(
      `${SERVICE_URL.PROFILE}api/opportunities`,
      {
        title: gigData.title,
        description: gigData.description,
        price: gigData?.price,
        date: gigData?.date,
        cityWithCoordinatesDTO: gigData?.cityWithCoordinatesDTO,
        skillIds: gigData.skillIds,
      },
      { observe: 'response' },
    );
  }

  public editOpportunity(gigData: IGigCreateDTO, id: number): Observable<HttpResponse<any>> {
    return this.http.put(
      `${SERVICE_URL.PROFILE}api/opportunities/${id}`,
      {
        title: gigData.title,
        description: gigData.description,
        price: gigData?.price,
        date: gigData?.date,
        cityWithCoordinatesDTO: gigData?.cityWithCoordinatesDTO,
        skillIds: gigData.skillIds,
      },
      { observe: 'response' },
    );
  }

  public getSkillsFromExistingOpportunities(): Observable<HttpResponse<SkillFeatured[]>> {
    return this.http.get<SkillFeatured[]>(`${SERVICE_URL.PROFILE}api/opportunities/skills`, {
      observe: 'response',
    });
  }

  // NOTE: This EP returns a body of objects of type Skill, but we use it as objects of type SkillFeatured with fewer properties
  public getAllAvailableSkills(): Observable<HttpResponse<SkillFeatured[]>> {
    return this.http.get<SkillFeatured[]>(`${SERVICE_URL.PROFILE}api/profiles/skills`, { observe: 'response' });
  }

  public getOpportunitiesByStatus(
    status: string,
    page: number,
  ): Observable<HttpResponse<IDataPageableResponse<IGigMain[]>>> {
    return this.http.get<IDataPageableResponse<IGigMain[]>>(
      `${SERVICE_URL.PROFILE}api/opportunities?status=${status}&page=${page}&pageSize=10`,
      {
        observe: 'response',
      },
    );
  }

  public markOpportunity(id: number, filled: boolean): Observable<HttpResponse<any>> {
    return this.http.patch(
      `${SERVICE_URL.PROFILE}api/opportunities/${id}`,
      {
        filled,
      },
      { observe: 'response' },
    );
  }

  public deleteOpportunity(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${SERVICE_URL.PROFILE}api/opportunities/${id}`, { observe: 'response' });
  }
}
