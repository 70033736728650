import { Pipe, PipeTransform } from '@angular/core';

import { BOOKING_ROLE } from '../../../pages/bookings/bookings.config';

@Pipe({
  name: 'bookingRole',
})
export class BookingRolePipe implements PipeTransform {
  constructor() {}

  public transform(bookeeId: number, userId: number): string {
    return bookeeId.toString() === userId.toString() ? BOOKING_ROLE.BOOKEE : BOOKING_ROLE.BOOKER;
  }
}
