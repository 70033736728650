import { Pipe, PipeTransform } from '@angular/core';
import { FilterMembersLabel } from '../../../models/search';

@Pipe({
  name: 'searchFilterLabel',
})
export class SearchFilterLabelPipe implements PipeTransform {
  transform(isSearchingByName: boolean): FilterMembersLabel {
    return isSearchingByName ? FilterMembersLabel.EMPTY : FilterMembersLabel.FILTER_MEMBERS;
  }
}
