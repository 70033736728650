import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PaymentCard } from '../../../models';
import { PAYMENT_TYPES } from '../../../utils';

@Component({
  selector: 'app-payment-method-tab',
  templateUrl: './payment-method-tab.component.html',
  styleUrls: ['./payment-method-tab.component.scss'],
})
export class PaymentMethodTabComponent implements OnInit {
  @Input() paymentMethod: PaymentCard;
  @Input() activeTab$: Subject<PaymentCard>;

  public active: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.activeTab$.subscribe((activeTab) => {
      this.active = activeTab?.cardId === this.paymentMethod?.cardId;
    });
  }

  public getTypeImage(): string {
    switch (this.paymentMethod?.cardName) {
      case PAYMENT_TYPES.BANK:
        return 'assets/images/icons/payment-method-bank.svg';
      case PAYMENT_TYPES.VISA:
        return 'assets/images/icons/payment-method-visa.svg';
      case PAYMENT_TYPES.MASTERCARD:
        return 'assets/images/icons/payment-method-mastercard.svg';
      case PAYMENT_TYPES.AMERICAN_EXPRESS:
        return 'assets/images/icons/payment-method-amex.svg';
      case PAYMENT_TYPES.DINERS_CLUB:
        return 'assets/images/icons/payment-method-diners-club.svg';
      case PAYMENT_TYPES.JCB:
        return 'assets/images/icons/payment-method-jcb.svg';
      case PAYMENT_TYPES.DISCOVER:
        return 'assets/images/icons/payment-method-discover.svg';
      case PAYMENT_TYPES.UNIONPAY:
        return 'assets/images/icons/payment-method-unionpay.svg';
      default:
        return '';
    }
  }
}
