import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RateExperienceGroupComponent } from './rate-experience-group.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [RateExperienceGroupComponent],
  imports: [CommonModule, NgbRatingModule],
  exports: [RateExperienceGroupComponent],
})
export class RateExperienceGroupModule {}
