import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-others-message',
  templateUrl: './others-message.component.html',
  styleUrls: ['./others-message.component.scss'],
})
export class OthersMessageComponent implements OnInit {
  @Input() messageText;

  constructor() {}

  ngOnInit(): void {}
}
