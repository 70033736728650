import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppsButtonsGroupComponent } from './apps-buttons-group.component';

@NgModule({
  declarations: [AppsButtonsGroupComponent],
  exports: [AppsButtonsGroupComponent],
  imports: [CommonModule],
})
export class AppsButtonsGroupModule {}
