import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SSR_PLACEHOLDERS } from '../../../utils';

export enum TAB_STATES {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const SETTINGS_STATES = {
  NAVIGATION: 'navigation',
  PASSWORD: 'password',
  PAYMENT_AND_PAYOUT: {
    CARD_LIST: 'cardList',
    CARD_DETAILS: 'cardDetails',
  },
};

export interface NavStateChange {
  state: string;
  tabName: string;
  pageStates?: string;
}

@Component({
  selector: 'app-settings-content',
  templateUrl: './settings-content.component.html',
  styleUrls: ['./settings-content.component.scss'],
})
export class SettingsContentComponent implements OnInit {
  @Input() subStateChange$: BehaviorSubject<NavStateChange> = new BehaviorSubject(null);
  @Input() goBack$: BehaviorSubject<boolean>;
  @Output() stateChange: EventEmitter<NavStateChange> = new EventEmitter<NavStateChange>();
  @Output() subStateChange: EventEmitter<NavStateChange> = new EventEmitter<NavStateChange>();

  public routeList: string[] = ['password', 'payment-and-payout', 'notification-settings'];
  public readonly changePasswordSsrPlaceholder: string = SSR_PLACEHOLDERS.SETTINGS.CHANGE_PASSWORD;
  public readonly paymentAndPayoutPlaceholder: string = SSR_PLACEHOLDERS.SETTINGS.PAYMENT_AND_PAYOUT;
  public shouldShowNavigationTabs$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public stateChange$: BehaviorSubject<NavStateChange> = new BehaviorSubject(null);
  public activeId: number;
  public state: string;

  constructor() {}

  ngOnInit(): void {}

  public handleStateChange(state: NavStateChange): void {
    this.stateChange.emit(state);
    this.stateChange$.next(state);
  }

  public handleSubStateChange(state: NavStateChange): void {
    this.subStateChange$.next(state);
  }
}
