import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';

import { NotificationGroupSettings, NotificationSettings } from '../../../models';

export const IPAD_WIDTH_START = 1280;

@Component({
  selector: 'app-notification-settings-menu',
  templateUrl: './notification-settings-menu.component.html',
  styleUrls: ['./notification-settings-menu.component.scss'],
})
export class NotificationSettingsMenuComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  getWindowWidth(event?) {
    return window.innerWidth;
  }
  @Input() selectedSettings$: BehaviorSubject<NotificationSettings> = new BehaviorSubject(null);
  @Output() emitGroup: EventEmitter<NotificationGroupSettings> = new EventEmitter();

  private resizeObservable$: Observable<Event> = fromEvent(window, 'resize');
  private subscription$: Subscription;
  public notificationSettingsMenu: NotificationGroupSettings[];
  public arrowImage: string = 'assets/images/icons/arrow-right-notifications.svg';
  public isMobileDevice: boolean;

  constructor() {}

  ngOnInit(): void {
    this.selectedSettings$.subscribe((notificationSettings: NotificationSettings) => {
      this.notificationSettingsMenu = notificationSettings?.settings;
    });

    if (this.getWindowWidth() < IPAD_WIDTH_START) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }

    this.subscription$ = this.resizeObservable$.subscribe((event) => {
      this.setState(event);
    });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public emitSettingsGroup(settings: NotificationGroupSettings): void {
    this.emitGroup.emit(settings);
  }

  private setState(event: Event): void {
    //Mobile view and states
    if ((event.currentTarget as Window).innerWidth < IPAD_WIDTH_START) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }
  }
}
