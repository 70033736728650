<div
  class="jammboard-gig-main-card__container"
  [ngClass]="{ 'not-active': gigData.status !== opportunityStatusActive }"
  (click)="onCardClick()"
>
  <div class="jammboard-gig-main-card__header">
    <h4 class="jammboard-gig-main-card__title" [ngClass]="{ 'not-active': gigData.status !== opportunityStatusActive }">
      {{ gigData.title }}
    </h4>
    <div class="jammboard-gig-main-card__submenu-wrapper" *ngIf="showSubMenu">
      <div
        class="jammboard-gig-main-card__submenu"
        appClickOutside
        (clickOutside)="onClickOutside()"
        (click)="onSubMenuClick($event, isOpenSubMenu)"
      >
        <img src="assets/images/icons/vertical-three-dots.svg" />
      </div>
      <div
        class="jammboard-gig-main-card__submenu-menu"
        *ngIf="isOpenSubMenu && gigData.status === opportunityStatusActive"
      >
        <a class="dropdown__link add-arrow become-member__link flex align-center" (click)="onEditOpp($event)">
          {{ 'JAMMBOARD.EDIT' | translate }}
        </a>
        <a
          class="dropdown__link add-arrow become-member__link flex align-center"
          (click)="onMarkAsFilledOpp($event, true)"
        >
          {{ 'JAMMBOARD.MARK_AS_FILLED' | translate }}
        </a>
        <a class="dropdown__link add-arrow become-member__link flex align-center" (click)="onDeleteOpp($event)">
          {{ 'JAMMBOARD.DELETE' | translate }}
        </a>
      </div>
      <div
        class="jammboard-gig-main-card__submenu-menu"
        *ngIf="isOpenSubMenu && gigData.status === opportunityStatusFilled"
      >
        <a
          class="dropdown__link add-arrow become-member__link flex align-center"
          (click)="onMarkAsFilledOpp($event, false)"
        >
          {{ 'JAMMBOARD.MARK_AS_NOT_FILLED' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="jammboard-gig-main-card__info" [ngClass]="{ 'not-active': gigData.status !== opportunityStatusActive }">
    <p *ngIf="gigData?.date">
      <img
        [src]="gigData?.status === opportunityStatusActive ? calendarIcon : calendarGreyIcon"
        class="jammboard-gig-main-card__icon"
      />{{ gigData?.date | date: 'EEEE M/d/yyyy' }}
    </p>
    <p *ngIf="gigData.cityWithCoordinatesDTO?.city" class="jammboard-gig-main-card__city">
      <img
        [src]="gigData?.status === opportunityStatusActive ? locationIcon : locationGreyIcon"
        class="jammboard-gig-main-card__icon"
      />{{ gigData.cityWithCoordinatesDTO?.city }}
    </p>
  </div>
  <div class="jammboard-gig-main-card__skills">
    <div *ngFor="let skill of gigData.skills" class="jammboard-gig-main-card__skill-pill uppercase">
      {{ skill.name }}
    </div>
  </div>
  <div class="jammboard-gig-main-card__footer">
    <div
      class="jammboard-gig-main-card__price"
      *ngIf="gigData?.status === opportunityStatusActive"
      [ngClass]="{ 'price-hidden': !gigData?.price }"
    >
      {{ gigData?.price }}
    </div>
    <div class="jammboard-gig-main-card__status" *ngIf="gigData?.status !== opportunityStatusActive">
      {{ gigData?.status }}
    </div>
    <div class="jammboard-gig-main-card__poster">
      <div
        class="jammboard-gig-main-card__poster-text"
        [ngClass]="{ 'not-active': gigData.status !== opportunityStatusActive }"
      >
        {{ 'JAMMBOARD.FROM' | translate }}
      </div>
      <ng-container *ngIf="gigData?.postedBy; else bluredPoster">
        <img
          class="jammboar-gig-main-card__posted-by--avatar"
          [src]="gigData.postedBy.profileImage?.thumbnailSmall | profileImage"
          [ngClass]="{ 'not-active': gigData.status !== opportunityStatusActive }"
        />
        <div class="jammboard-gig-main-card__poster-link" (click)="onOwnerClick(gigData.postedBy.username)">
          <p [ngClass]="{ 'not-active': gigData.status !== opportunityStatusActive }">
            {{ gigData.postedBy.name }}
          </p>
        </div>
      </ng-container>
      <ng-template #bluredPoster>
        <img class="blured-poster-img" src="assets/images/components/gig-poster-blur.png" />
      </ng-template>
      <div *ngIf="isInlineCard" class="jammboard-gig-main-card__poster--logo">
        <div class="jammboard-gig-main-card__poster-text">on</div>
        <img src="assets/images/logos/jammcard--black.svg" />
      </div>
    </div>
  </div>
</div>
