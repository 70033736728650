import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatedExperienceGroupComponent } from './rated-experience-group.component';

@NgModule({
  declarations: [RatedExperienceGroupComponent],
  exports: [RatedExperienceGroupComponent],
  imports: [CommonModule],
})
export class RatedExperienceGroupModule {}
