import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IsBrowserService {
  private keys = new Map<string, StateKey<string>>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private readonly transferState: TransferState) {}

  public isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get<T>(key: string, defaultValue?: T): Observable<T> {
    if (!this.hasKey(key)) {
      return of(defaultValue || undefined);
    }
    return of(this.transferState.get<T>(this.getStateKey(key), defaultValue));
  }

  public set<T>(key: string, value: T): void {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set<T>(this.getStateKey(key), value || undefined);
    }
  }

  public hasKey(key: string): boolean {
    return this.transferState.hasKey(this.getStateKey(key));
  }

  public remove(key: string): void {
    if (!this.hasKey(key)) {
      return;
    }
    this.transferState.remove(this.getStateKey(key));
  }

  private getStateKey(key: string): StateKey<string> {
    if (this.keys.has(key)) {
      return this.keys.get(key);
    }
    this.keys.set(key, makeStateKey(key));
    return this.keys.get(key);
  }
}
