import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InfoService } from '../../../services';
import { Rate, RateSettingTimeUnits } from '../../../models';
import { EVENT_NAME_PREFIX } from '../../../utils';

@Component({
  selector: 'app-rate-select-pill',
  templateUrl: './rate-select-pill.component.html',
  styleUrls: ['./rate-select-pill.component.scss'],
})
export class RateSelectPillComponent implements OnInit {
  @Input() rates?: Rate[];
  @Input() preselectRateId: number;
  @Input() rescheduleRate?: Rate;
  @Output() rateSelected = new EventEmitter<Rate>();

  public displayAsPill: boolean;
  public selectedRate: Rate;

  constructor(private infoService: InfoService) {}

  ngOnInit(): void {
    if (this.rates?.length > 1 && !this.rescheduleRate) {
      this.displayAsPill = true;
      const index = this.rates.findIndex((rate) => rate.id === this.preselectRateId);
      this.selectedRate = index !== -1 ? { ...this.rates[index] } : { ...this.rates[0] };
    } else {
      this.displayAsPill = false;
      if (this.rescheduleRate) {
        this.selectedRate = { ...this.rescheduleRate };
      } else {
        this.selectedRate = { ...this.rates[0] };
      }
    }
    this.rateSelected.emit(this.selectedRate);
  }

  public onClick(rate: Rate): void {
    if (this.selectedRate.id !== rate.id) {
      this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
        element: 'rate-select',
        rate: rate.rateSetting.timeDurationAmount,
      });
      this.selectedRate = { ...rate };
      this.rateSelected.emit(this.selectedRate);
    }
  }

  public shortTimeUnitDisplay = {
    [RateSettingTimeUnits.MINUTES]: 'min.',
    [RateSettingTimeUnits.HOURS]: 'h',
    [RateSettingTimeUnits.DAYS]: 'd',
  };

  public sessionDurationLongDisplay = {
    [RateSettingTimeUnits.MINUTES]: {
      30: 'Half an hour session',
      60: 'One hour session',
    },
  };
}
