import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from '../../atoms/button/button.module';
import { EmailInboxCardModule } from '../../molecules/email-inbox-card/email-inbox-card.module';
import { RequestQuickCardModule } from '../../molecules/request-quick-card/request-quick-card.module';
import { RequestSentModalComponent } from './request-sent-modal.component';

@NgModule({
  declarations: [RequestSentModalComponent],
  imports: [CommonModule, ButtonModule, EmailInboxCardModule, RequestQuickCardModule],
  exports: [RequestSentModalComponent],
})
export class RequestSentModalModule {}
