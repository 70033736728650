import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ASSETS_PATH, VIDEO_CALL_SELECTED_AUDIO_DEVICE_ID, VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID } from '../../../utils';
import { VIDEO_ASSETS } from '../../../pages/video-call/video-call.config';
import { LocalStorageService, VideoService } from '../../../services';

@Component({
  selector: 'app-video-settings-modal',
  templateUrl: './video-settings-modal.component.html',
  styleUrls: ['./video-settings-modal.component.scss'],
})
export class VideoSettingsModalComponent implements OnInit, OnDestroy {
  @Output() audioOutputChanged = new EventEmitter<string>();
  @Output() disableAudio = new EventEmitter<{ disable: boolean; outisdeRoomTrigger: boolean }>();
  @Output() disableVideo = new EventEmitter<{ disable: boolean; outisdeRoomTrigger: boolean }>();

  private fromParent: any;

  public audioTabSelected = true;
  public videoTabSelected = false;
  public readonly cameraIcon = VIDEO_ASSETS.CAMERA;
  public readonly closeIcon = ASSETS_PATH.CLOSE_BLACK_ICON;
  public readonly speakerIcon = VIDEO_ASSETS.SPEAKER;

  public audioInputDevices: MediaDeviceInfo[];
  public localAudioInputDeviceId: string;
  public videoInputDevices: MediaDeviceInfo[];
  public localVideoInputDeviceId: string;
  public audioOutputDevices: MediaDeviceInfo[];
  public localAudioOutputDeviceId: string;
  public hasAudioInputDevices: boolean;
  public hasVideoInputDevices: boolean;
  public outsideRoomSettings: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private videoService: VideoService,
  ) {}

  ngOnInit(): void {
    this.localAudioInputDeviceId =
      this.localStorageService.get(VIDEO_CALL_SELECTED_AUDIO_DEVICE_ID) ||
      this.videoService.localAudioTrack?.mediaStreamTrack.getSettings().deviceId;
    this.localVideoInputDeviceId =
      this.localStorageService.get(VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID) ||
      this.videoService.localVideoTrack?.mediaStreamTrack.getSettings().deviceId;
    this.localAudioOutputDeviceId = this.fromParent.sinkId;
    this.outsideRoomSettings = this.fromParent.outsideRoomSettings;
    navigator.mediaDevices.addEventListener('devicechange', this.getDevicesInfo);
    this.getDevicesInfo();
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  private getDevicesInfo = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    // TODO (Milan): Maybe a flag for when devices are loaded

    this.audioInputDevices = devices.filter((device) => device.kind === 'audioinput');
    this.videoInputDevices = devices.filter((device) => device.kind === 'videoinput');
    this.audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput');
    this.hasAudioInputDevices = devices.some((device) => device.kind === 'audioinput');
    this.hasVideoInputDevices = devices.some((device) => device.kind === 'videoinput');
  };

  public onAudioTabClick(): void {
    if (!this.audioTabSelected) {
      this.videoTabSelected = false;
      this.audioTabSelected = true;
    }
  }

  public onVideoTabClick(): void {
    if (!this.videoTabSelected) {
      this.audioTabSelected = false;
      this.videoTabSelected = true;
    }
  }

  // If using ngSelect, the value passed with change event is the deviceId (no need for e.target.value)
  public onAudioInputChange(e: any): void {
    this.localStorageService.add(VIDEO_CALL_SELECTED_AUDIO_DEVICE_ID, e);
    this.videoService.localAudioTrack
      ?.restart({
        deviceId: { exact: e },
      })
      .then(() => {
        this.localAudioInputDeviceId = e;
      })
      .catch((err) => {
        this.videoService.handleVideoSessionError(err);
        this.localAudioInputDeviceId = e;
        this.disableAudio.emit({ disable: true, outisdeRoomTrigger: this.outsideRoomSettings });
      });
  }

  public onAudioOutputChange(e: any): void {
    this.audioOutputChanged.emit(e);
  }

  public onVideoInputChange(e: any): void {
    this.localStorageService.add(VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID, e);
    this.videoService.localVideoTrack
      ?.restart({
        deviceId: { exact: e },
      })
      .then(() => (this.localVideoInputDeviceId = e))
      .catch((err) => {
        this.videoService.handleVideoSessionError(err);
        this.localVideoInputDeviceId = e;
        this.disableVideo.emit({ disable: true, outisdeRoomTrigger: this.outsideRoomSettings });
      });
  }

  ngOnDestroy() {
    navigator.mediaDevices.removeEventListener('devicechange', this.getDevicesInfo);
  }
}
