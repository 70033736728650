import { Component, HostBinding, OnInit } from '@angular/core';

import { IInfoModalData } from '../../templates/info-modal/info-modal.config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-modal-thanks-for-contacting',
  templateUrl: './info-modal-thanks-for-contacting.component.html',
  styleUrls: ['./info-modal-thanks-for-contacting.component.scss'],
})
export class InfoModalThanksForContactingComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public infoModalData: IInfoModalData;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    // @ts-ignore
    const redirectTo = this.fromParent?.infoModalData?.redirectTo;

    // TODO (Milan): Check out how the labels are passed, it's not consistent. It should be the translated value
    // everywhere or, translate property name everywhere. Now it's mixed.
    this.translate.get('INFO_MODAL_CONTACT_SUPPORT').subscribe((res: any) => {
      this.infoModalData = {
        backgroundImageClass: 'jammcard',
        buttonLabel: 'DONE',
        title: res.THANKS_FOR_CONTACTING_SUPPORT,
        description: res.A_JAMMCARD_AGENT_WILL_BE_GETTING_BACK_TO_YOU_WITHIN,
        redirectTo: redirectTo || 'bookings',
      };
    });
  }
}
