import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChannelCardModule } from '../../molecules/channel-card/channel-card.module';
import { ChannelsListComponent } from './channels-list.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { VisibilityObserverModule } from '../../../utils/directives/visibility-observer/visibility-observer.module';

@NgModule({
  declarations: [ChannelsListComponent],
  imports: [CommonModule, ChannelCardModule, PipesModule, VisibilityObserverModule],
  exports: [ChannelsListComponent],
})
export class ChannelsListModule {}
