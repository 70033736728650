import { isEmptyValue } from '../utils';
import { IIdName } from './common';
import { Gender } from './profile';

export enum SearchTranslate {
  FILTER_SKILLS = 'SEARCH.SKILLS',
  FILTER_LOCATION = 'SEARCH.LOCATION',
  FILTER_GENRE = 'SEARCH.GENRE',
  FILTER_GENDER = 'SEARCH.GENDER',
  FILTER_GEAR = 'SEARCH.GEAR',
  NO_MATCHING_GENRES = 'SEARCH.NO_MATCHING_GENRES',
  NO_MATCHING_GEARS = 'SEARCH.NO_MATCHING_GEARS',
}
export enum SortByEnum {
  LOCATION = 'LOCATION',
  SKILL_ENDORSEMENTS = 'SKILL_ENDORSEMENTS',
  GENRE_ENDORSEMENTS = 'GENRE_ENDORSEMENTS',
  NUM_OF_FOLLOWERS = 'NUM_OF_FOLLOWERS',
  CITY_LOCATION = 'CITY_LOCATION',
}

export enum SortByEnumLabels {
  LOCATION = '',
}
export enum FilterMembersLabel {
  FILTER_MEMBERS = 'SEARCH.FILTER_MEMBERS',
  EMPTY = '',
}
export interface LatLon {
  lat: number;
  lon: number;
}

export interface LocationSearch {
  city: string;
  geoLocationOfTheCity: LatLon;
}

export enum SearchFilterType {
  SKILL = 'skill',
  LOCATION = 'location',
  GENRE = 'genre',
  GEAR = 'gear',
  GENDER = 'gender',
  IS_VERIFIED = 'verified',
}
export interface IFilterBadge {
  name: string;
  filterType: SearchFilterType;
}

export interface ISearchBase {
  gender?: Gender;
  cityWithLocationForSearch?: LocationSearch;
  sort?: SortByEnum;
  showOnlyVerified?: boolean;
  appliedFilterBadges?: IFilterBadge[];
}
export interface ISearch extends ISearchBase {
  skillIds?: IIdName[];
  brandIds?: IIdName[];
  genreIds?: IIdName[];
}

export interface ISearchDTO extends ISearchBase {
  skillIds?: number[];
  brandIds?: number[];
  genreIds?: number[];
}

export class Search implements ISearch {
  public appliedFilterBadges: IFilterBadge[] = [];
  constructor(
    public skillIds: IIdName[] = [],
    public brandIds: IIdName[] = [],
    public genreIds: IIdName[] = [],
    public gender: Gender = null,
    public cityWithLocationForSearch: LocationSearch = null,
    public sort: SortByEnum = SortByEnum.LOCATION,
    public showOnlyVerified: boolean = null,
  ) {}

  public get filterDTO(): ISearchDTO {
    return searchToDTO(
      this.skillIds,
      this.brandIds,
      this.genreIds,
      this.gender,
      this.cityWithLocationForSearch,
      this.sort,
      this.showOnlyVerified,
    );
  }

  public resetValues(): void {
    this.skillIds = [];
    this.brandIds = [];
    this.genreIds = [];
    this.gender = null;
    this.cityWithLocationForSearch = null;
    this.sort = SortByEnum.LOCATION;
    this.showOnlyVerified = null;
  }

  public updateFilterBadges(): void {
    const skillNames = this.skillIds.map((item) => ({
      name: item.name,
      filterType: SearchFilterType.SKILL,
    }));
    const gearNames = this.brandIds.map((item) => ({
      name: item.name,
      filterType: SearchFilterType.GEAR,
    }));
    const genreNames = this.genreIds.map((item) => ({
      name: item.name,
      filterType: SearchFilterType.GENRE,
    }));

    this.appliedFilterBadges = [, ...skillNames, ...gearNames, ...genreNames];

    if (this.cityWithLocationForSearch) {
      const locationName = { name: this.cityWithLocationForSearch?.city, filterType: SearchFilterType.LOCATION };
      this.appliedFilterBadges.push(locationName);
    }
    if (this.gender) {
      const genderName = { name: this.gender, filterType: SearchFilterType.GENDER };

      this.appliedFilterBadges.push(genderName);
    }
    if (this.showOnlyVerified) {
      const isVerified = {
        name: this.showOnlyVerified ? SearchFilterType.IS_VERIFIED : null,
        filterType: SearchFilterType.IS_VERIFIED,
      };
      this.appliedFilterBadges.push(isVerified);
    }
    this.appliedFilterBadges = this.appliedFilterBadges.filter((n) => n);
  }

  public applyFilterBadge(filterType: SearchFilterType): void {
    switch (filterType) {
      case SearchFilterType.GEAR:
        const gearNames = this.brandIds.map((item) => ({
          name: item.name,
          filterType,
        }));
        this.appliedFilterBadges = [...this.appliedFilterBadges, ...gearNames];
        break;
      case SearchFilterType.SKILL:
        const skillNames = this.skillIds.map((item) => ({
          name: item.name,
          filterType,
        }));
        this.appliedFilterBadges = [...this.appliedFilterBadges, ...skillNames];
        break;
      case SearchFilterType.GENRE:
        const genreNames = this.genreIds.map((item) => ({
          name: item.name,
          filterType,
        }));
        this.appliedFilterBadges = [...this.appliedFilterBadges, ...genreNames];
        break;
      case SearchFilterType.LOCATION:
        this.appliedFilterBadges = [
          ...this.appliedFilterBadges,
          { name: this.cityWithLocationForSearch?.city, filterType },
        ];
        break;
      case SearchFilterType.GENDER:
        this.appliedFilterBadges = [...this.appliedFilterBadges, { name: this.gender, filterType }];
        break;
      case SearchFilterType.IS_VERIFIED:
        this.appliedFilterBadges = [
          ...this.appliedFilterBadges,
          { name: this.showOnlyVerified ? SearchFilterType.IS_VERIFIED : '', filterType },
        ];
    }
  }

  public removeFilterBadge(index): void {
    this.appliedFilterBadges.splice(index, 1);
  }
}

export const latlonHollywood: LatLon = { lat: 34.090796, lon: -118.327286 };

export function searchToDTO(
  skillIdName: IIdName[] = null,
  brandIdName: IIdName[] = null,
  genreIdName: IIdName[] = null,
  gender: Gender = null,
  cityWithLocationForSearch: LocationSearch = null,
  sort: SortByEnum = SortByEnum.LOCATION,
  showOnlyVerified: boolean = null,
): ISearchDTO {
  const skillIds = skillIdName.map((skill) => skill.id);
  const brandIds = brandIdName.map((brand) => brand.id);
  const genreIds = genreIdName.map((genre) => genre.id);
  const obj = {
    ...(!isEmptyValue(skillIds) && { skillIds }),
    ...(!isEmptyValue(brandIds) && { brandIds }),
    ...(!isEmptyValue(genreIds) && { genreIds }),
    ...(!isEmptyValue(gender) && { gender }),
    ...(!isEmptyValue(cityWithLocationForSearch) && { cityWithLocationForSearch }),
    ...(!isEmptyValue(sort) && { sort }),
    ...(!isEmptyValue(showOnlyVerified) && { showOnlyVerified }),
  };
  return obj;
}
