import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalFastCheckoutComponent } from './book-modal-fast-checkout.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { RequestQuickCardModule } from '../../molecules/request-quick-card/request-quick-card.module';
import { PaymentDetailsModule } from '../../molecules/payment-details/payment-details.module';
import { AddPaymentCardModule } from '../add-payment-card/add-payment-card.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MentorshipModalModule } from '../../templates/mentorship-modal/mentorship-modal.module';
import { EditModalModule } from '../../templates/edit-modal/edit-modal.module';
import { SellerProfileCardModule } from '../../molecules/seller-profile-card/seller-profile-card.module';
import { DateTimeRangeZoneLabelModule } from '../../atoms/date-time-range-zone-label/date-time-range-zone-label.module';

@NgModule({
  declarations: [BookModalFastCheckoutComponent],
  imports: [
    CommonModule,
    ModalModule,
    BookModalSectionModule,
    RequestQuickCardModule,
    PaymentDetailsModule,
    AddPaymentCardModule,
    ButtonModule,
    TranslateModule,
    FormsModule,
    MentorshipModalModule,
    EditModalModule,
    SellerProfileCardModule,
    DateTimeRangeZoneLabelModule,
  ],
})
export class BookModalFastCheckoutModule {}
