import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { TwilioService } from '../../../services';

@Component({
  selector: 'app-messenger-input',
  templateUrl: './messenger-input.component.html',
  styleUrls: ['./messenger-input.component.scss'],
})
export class MessengerInputComponent implements OnInit {
  @Output('focus') focus = new EventEmitter<any>();
  @Output('blur') blur = new EventEmitter<any>();

  public text: string = '';
  constructor(private deviceDetectorService: DeviceDetectorService, private twilioService: TwilioService) {}

  ngOnInit(): void {}

  //TODO(Mladen): Place text validators here.
  public sendMessage(text: string): void {
    if (text.trim().length) {
      this.twilioService.sendMessage(text.trim());
      this.text = '';
    }
  }

  public sendMessageOnEnter(text: string, e: any): void {
    if (this.deviceDetectorService.isDesktop()) {
      if (text.trim().length) {
        e.preventDefault();
        this.text = text.trim();
        this.twilioService.sendMessage(this.text);
      }
      this.text = '';
    }
  }

  public inputFocus(): void {
    this.focus.emit();
  }

  public inputBlur(): void {
    this.blur.emit();
  }
}
