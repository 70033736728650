import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../../store/app.reducers';
import { PLACEHOLDER_IMAGE_URL } from '../../../utils';
import { ImageType } from '../../../models';
import { ProfileService, ToastService } from '../../../services';
import { ProfileActions } from '../../../store/profile/action-types';

@Component({
  selector: 'app-image-upload-card',
  templateUrl: './image-upload-card.component.html',
  styleUrls: ['./image-upload-card.component.scss'],
})
export class ImageUploadCardComponent implements OnInit, OnDestroy {
  @Input() currentImage: string;
  @Input() imageType: ImageType;

  private imageSuccessfullyAddedMessage: string;
  private pleaseProvidePngOrJpgMessage: string;
  private reader: FileReader;
  private somethingWentWrongMessage: string;

  public imageForDisplay: string;
  public titleForDisplay: string;
  public readonly PROFILE_IMAGE_TYPE = ImageType.PROFILE;
  public readonly COVER_IMAGE_TYPE = ImageType.COVER;

  constructor(
    private profileService: ProfileService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.reader = new FileReader();
    this.reader.addEventListener('load', this.imageLoadedEventListener);
    this.reader.addEventListener('error', this.loadErrorListener);

    this.translateService.get(['PROFILE', 'TOAST_MESSAGE']).subscribe((res) => {
      this.imageSuccessfullyAddedMessage = res.TOAST_MESSAGE.IMAGE_SUCCESSFULLY_ADDED;
      this.pleaseProvidePngOrJpgMessage = res.TOAST_MESSAGE.PLEASE_PROVIDE_PNG_OR_JPG;
      this.somethingWentWrongMessage = res.TOAST_MESSAGE.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN;

      if (this.imageType === this.PROFILE_IMAGE_TYPE) {
        this.imageForDisplay = this.currentImage || PLACEHOLDER_IMAGE_URL.NO_AVATAR;
        this.titleForDisplay = res.PROFILE.PROFILE_PHOTO;
      } else {
        this.imageForDisplay = this.currentImage || PLACEHOLDER_IMAGE_URL.NO_COVER;
        this.titleForDisplay = res.PROFILE.COVER_PHOTO;
      }
    });
  }

  ngOnDestroy(): void {
    this.reader?.removeEventListener('load', this.imageLoadedEventListener);
    this.reader?.removeEventListener('error', this.loadErrorListener);
  }

  public onImageSelected(event: any): void {
    const file = event.target.files[0];
    const fileType = file?.type as string;

    if (fileType?.startsWith('image/png') || fileType?.startsWith('image/jpeg')) {
      try {
        this.reader.readAsDataURL(file);
      } catch (e) {
        this.toastService.showMessage(this.somethingWentWrongMessage);
      }
    } else {
      this.toastService.showMessage(this.pleaseProvidePngOrJpgMessage);
    }
  }

  private imageLoadedEventListener = () => {
    const splitted = this.reader.result.toString().split(',');
    if (splitted[1]) {
      const base64Image = splitted[1];
      this.uploadImage(base64Image);
    } else {
      this.toastService.showMessage(this.somethingWentWrongMessage);
    }
  };

  private loadErrorListener = () => {
    this.toastService.showMessage(this.somethingWentWrongMessage);
  };

  private uploadImage(image: string): void {
    this.profileService.addImage(this.imageType, image).subscribe(
      (response: any) => {
        this.toastService.showMessage(this.imageSuccessfullyAddedMessage);
        this.imageForDisplay = response.body.thumbnailMedium;
        if (this.imageType === this.COVER_IMAGE_TYPE) {
          this.store.dispatch(ProfileActions.coverImageUpdated());
        }
      },
      (error: any) => {
        this.toastService.showMessage(this.somethingWentWrongMessage);
      },
    );
  }
}
