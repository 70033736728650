import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AvailabilitySlot } from '../../../models/rates-and-services';
import { EVENT_NAME_PREFIX } from '../../../utils';
import { InfoService } from '../../../services';

@Component({
  selector: 'app-availability-slot',
  templateUrl: './availability-slot.component.html',
  styleUrls: ['./availability-slot.component.scss'],
})
export class AvailabilitySlotComponent implements OnInit {
  @Input() slot: AvailabilitySlot;
  @Input() isPreselected: boolean = false;
  @Output() confirmed = new EventEmitter();

  public isClicked: boolean = false;
  public noSlotsMessage = '';
  public selectedSlot;

  constructor(private infoService: InfoService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get('AVAILABILITY').subscribe((res) => {
      this.noSlotsMessage = res['NO_AVAILABLE_SLOTS'];
    });
    this.isClicked = this.isPreselected;
  }

  public onClick(): void {
    this.isClicked = !this.isClicked;
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'availability-time-select',
      startTime: this.slot.startDateTime,
      endTime: this.slot.endDateTime,
    });
  }

  public onClickOutside(): void {
    if (this.isClicked) this.isClicked = false;
  }

  public onConfirm(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'availability-time-select-confirm',
      startTime: this.slot.startDateTime,
      endTime: this.slot.endDateTime,
    });
    this.confirmed.emit(this.slot);
  }
}
