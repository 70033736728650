import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-time-range-zone-label',
  templateUrl: './date-time-range-zone-label.component.html',
  styleUrls: ['./date-time-range-zone-label.component.scss'],
})
export class DateTimeRangeZoneLabelComponent implements OnInit {
  @Input() startDate: number;
  @Input() endDate: number;

  public timezone: string;

  constructor() {}

  ngOnInit(): void {
    const dateArr = Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(this.startDate);

    this.timezone = dateArr?.[dateArr.length - 1]?.type === 'timeZoneName' ? dateArr[dateArr.length - 1].value : null;
  }
}
