import { AppState } from '../app.reducers';
import { createSelector } from '@ngrx/store';
import { NotificationsState } from './reducers/notifications.reducers';

export const selectNotifications = (state: AppState) => state.notifications;

export const selectNotificationsBooking = createSelector(
  selectNotifications,
  (state: NotificationsState) => state.bookings,
);
export const selectNotificationsMessenger = createSelector(
  selectNotifications,
  (state: NotificationsState) => state.messenger,
);
