import { Component, Input, OnInit } from '@angular/core';

import { RequestSentModalType } from '../../organisms/request-sent-modal/request-sent.config';
import { JAMM_LOGO_CIRCLE_URL } from '../../../utils';

@Component({
  selector: 'app-email-inbox-card',
  templateUrl: './email-inbox-card.component.html',
  styleUrls: ['./email-inbox-card.component.scss'],
})
export class EmailInboxCardComponent implements OnInit {
  @Input() emailInboxSubtitle: string;
  @Input() emailInboxDescription: string;
  @Input() type: RequestSentModalType = RequestSentModalType.EMAIL;

  public jammLogoCircleUrl: string;

  constructor() {}

  ngOnInit(): void {
    this.jammLogoCircleUrl = JAMM_LOGO_CIRCLE_URL;
  }
}
