import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { BookModalConfirmedComponent } from './book-modal-confirmed.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { BookModalClickableLabelModule } from '../../molecules/book-modal-clickable-label/book-modal-clickable-label.module';
import { PriceTotalModule } from '../../molecules/price-total/price-total.module';
import { ModalModule } from '../../templates/modal/modal.module';
import { AvatarGroupModule } from '../../molecules/avatar-group/avatar-group.module';
import { PaymentDetailsModule } from '../../molecules/payment-details/payment-details.module';
import { BookingStatusLabelModule } from '../../atoms/booking-status-label/booking-status-label.module';
import { BookModalConfirmedEntryComponent } from './book-modal-confirmed-entry.component';
import { DateTimeRangeZoneLabelModule } from '../../atoms/date-time-range-zone-label/date-time-range-zone-label.module';
import { CalendarSelectModule } from '../../atoms/calendar-select/calendar-select.module';

@NgModule({
  declarations: [BookModalConfirmedComponent, BookModalConfirmedEntryComponent],
  imports: [
    CommonModule,
    ButtonModule,
    BookModalSectionModule,
    BookModalClickableLabelModule,
    PriceTotalModule,
    ModalModule,
    FormsModule,
    AvatarGroupModule,
    PaymentDetailsModule,
    TranslateModule,
    BookingStatusLabelModule,
    DateTimeRangeZoneLabelModule,
    CalendarSelectModule,
  ],
  exports: [BookModalConfirmedComponent, BookModalConfirmedEntryComponent],
})
export class BookModalConfirmedModule {}
