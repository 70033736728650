import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../components/atoms/button/button.module';
import { HeaderModule } from '../../components/organisms/header/header.module';
import { LandingComponent } from './landing.component';
@NgModule({
  declarations: [LandingComponent],
  imports: [ButtonModule, CommonModule, HeaderModule, RouterModule, TranslateModule],
  exports: [LandingComponent],
})
export class LandingModule {}
