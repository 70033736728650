import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-book-modal-clickable-label',
  templateUrl: './book-modal-clickable-label.component.html',
  styleUrls: ['./book-modal-clickable-label.component.scss'],
})
export class BookModalClickableLabelComponent implements OnInit {
  @Input() labelText: string;
  @Output() labelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  clicked(): void {
    this.labelClicked.emit();
  }
}
