import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthModalData } from '../../../models';
import { getNoBookProfileUrl, PLACEHOLDER_IMAGE_URL, reloadPage, AUTH_CONTROLLER } from '../../../utils';

@Component({
  selector: 'app-auth-controller-modal',
  templateUrl: './auth-controller-modal.component.html',
  styleUrls: ['./auth-controller-modal.component.scss'],
})
export class AuthControllerModalComponent implements OnInit {
  public authControllerModalData: AuthModalData;
  public modalType: string;
  public loginModalData: AuthModalData;
  public loginRequestSending: boolean;
  public signupModalData: AuthModalData;
  public buttonLabel: string;

  private fromParent: any;

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public modalService: NgbModal,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.authControllerModalData = this.fromParent.authControllerModalData;
    this.modalType = this.fromParent.modalType;
    this.translateLabel(this.modalType);
    if (this.authControllerModalData.normalModal) {
      this.authControllerModalData.modalImage = PLACEHOLDER_IMAGE_URL.NORMAL_LOGIN;
    }
  }

  public translateLabel(modalType): void {
    if (modalType === AUTH_CONTROLLER.LOGIN) {
      this.translate.get(AUTH_CONTROLLER.LOGIN).subscribe((res: any) => {
        this.buttonLabel = res.LOG_IN;
      });
    } else if (modalType === AUTH_CONTROLLER.SIGNUP) {
      this.translate.get(AUTH_CONTROLLER.SIGNUP).subscribe((res: any) => {
        this.buttonLabel = res.SIGNUP;
      });
    }
  }

  public closeModal(doNotReloadOrRedirect = false): any {
    const url = this.router.url;
    // TODO (Marko): All of the cases should be handled in the future,
    // this is for current flows available only on landing page and profile page.
    // If, in the feature, there is a lot of cases maybe we should provide navigateBackUrl route.

    // NOTE: Use the 'doNotReloadOrRedirect' flag to prevent reload when the modal closes due to a successful sign up
    // from the regular or mentorship booking flow, so the verification modal can be shown
    if (!doNotReloadOrRedirect && url.includes('/book') && !url.includes('/book-mentorship'))
      this.router.navigate([getNoBookProfileUrl(url)]);

    if (!doNotReloadOrRedirect && (url.includes('/featured') || url.includes('/book-mentorship')))
      reloadPage(this.router);
    this.activeModal.close();
  }

  public changeModalView(event): void {
    this.authControllerModalData = event;
  }

  public changeModalType(event): void {
    this.modalType = event;
    this.translateLabel(this.modalType);
  }
}
