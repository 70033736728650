import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AuthControllerModalComponent } from './auth-controller-modal.component';
import { LoginModalModule } from '../../organisms/login-modal/login-modal.module';
import { ModalModule } from '../modal/modal.module';
import { SignupModalModule } from '../../organisms/signup-modal/signup-modal.module';

@NgModule({
  declarations: [AuthControllerModalComponent],
  imports: [
    CommonModule,
    LoginModalModule,
    ModalModule,
    SignupModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [AuthControllerModalComponent],
})
export class AuthControllerModalModule {}
