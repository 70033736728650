import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OthersMessageComponent } from './others-message.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [OthersMessageComponent],
  exports: [OthersMessageComponent],
  imports: [CommonModule, PipesModule],
})
export class OthersMessageModule {}
