import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoModalSuccessComponent } from './info-modal-success.component';
import { InfoModalModule } from '../../templates/info-modal/info-modal.module';

@NgModule({
  declarations: [InfoModalSuccessComponent],
  imports: [CommonModule, InfoModalModule],
  exports: [InfoModalSuccessComponent],
})
export class InfoModalSuccessModule {}
