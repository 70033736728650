import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MyMessageGroupComponent } from './my-message-group.component';
import { MessageAvatarModule } from '../../atoms/message-avatar/message-avatar.module';
import { MyMessageModule } from '../../atoms/my-message/my-message.module';

import { PipesModule } from '../../../utils/pipes/pipes.module';
import { MyImageModule } from '../../atoms/my-image/my-image.module';
import { DateTimeLabelModule } from '../../atoms/date-time-label/date-time-label.module';
import { MessengerLabelModule } from '../messenger-label/messenger-label.module';
import { MessengerOpportunityCardModule } from '../messenger-opportunity-card/messenger-opportunity-card.module';

@NgModule({
  declarations: [MyMessageGroupComponent],
  imports: [
    CommonModule,
    MessageAvatarModule,
    MessengerOpportunityCardModule,
    MyMessageModule,
    MyImageModule,
    PipesModule,
    DateTimeLabelModule,
    MessengerLabelModule,
  ],
  exports: [MyMessageGroupComponent],
})
export class MyMessageGroupModule {}
