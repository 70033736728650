import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ASSETS_PATH } from '../../../utils';
import { BookingServices } from './booking-services-modal.config';
import { ServiceProvision } from '../../../models';

@Component({
  selector: 'app-booking-services-modal',
  templateUrl: './booking-services-modal.component.html',
  styleUrls: ['./booking-services-modal.component.scss'],
})
export class BookingServicesModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public readonly closeIcon = ASSETS_PATH.CLOSE_BLACK_ICON;
  public booking: string = 'Live or Recording';
  public bookButton: string = 'Send an offer';
  public bookDesc: string = 'Send an offer for a recording, live performance, or whatever your opportunity may be';
  public mentorshipDesc: string = '1-on-1 video mentorship sessions with music’s leading professionals';
  public mentorshipButton: string = 'Book';
  public bookingServices: BookingServices[] = [];
  public mentorshipTitle: string = 'Mentorship';

  private fromParent: any;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const servicesProvision = this.fromParent.modalData.servicesProvision;
    this.createBookServices(servicesProvision);
  }

  public createBookServices(serviceProvision: ServiceProvision[]): void {
    for (let i = 0; i < serviceProvision.length; i++) {
      const service: BookingServices = {
        type: serviceProvision[i].type,
        rates: serviceProvision[i].rates,
        hasAvailability: serviceProvision[i].hasAvailability,
        isViewerSubscribed: serviceProvision[i].isViewerSubscribed,
      };
      if (service.type === 'MENTORSHIP') {
        service.type = this.mentorshipTitle;
        service.description = this.mentorshipDesc;
        service.buttonLabel = this.mentorshipButton;
      }
      this.bookingServices.push(service);
    }

    this.bookingServices.push({
      type: this.booking,
      description: this.bookDesc,
      buttonLabel: this.bookButton,
    });
  }

  public onConfirm(service: BookingServices): void {
    if (service.type === this.mentorshipTitle) {
      service.type = service.type.toUpperCase();
    }
    this.activeModal.close(service);
  }

  onNotifyMeClicked(): void {
    const openModal = {
      nofifyMeRoute: `notify-me`,
    };
    this.activeModal.close(openModal);
  }

  public onCloseClicked(): void {
    this.activeModal.close();
  }
}
