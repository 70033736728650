import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RatesAccordionCardModule } from '../../molecules/rates-accordion-card/rates-accordion-card.module';
import { RatesAccordionComponent } from './rates-accordion.component';

@NgModule({
  declarations: [RatesAccordionComponent],
  imports: [CommonModule, NgbModule, RatesAccordionCardModule],
  exports: [RatesAccordionComponent],
  bootstrap: [RatesAccordionComponent],
})
export class RatesAccordionModule {}
