import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-price-total',
  templateUrl: './price-total.component.html',
  styleUrls: ['./price-total.component.scss'],
})
export class PriceTotalComponent implements OnInit {
  @Input() price: number;
  @Input() totalTitle: string;
  @Output() priceTotalInfoClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  infoClicked(): void {
    this.priceTotalInfoClicked.emit();
  }
}
