import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { JammboardService, ToastService } from '../../../services';
import { ICityWithCoordinates, SkillFeatured } from '../../../models';
import { isDateInPast } from '../../../utils';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';
import { SkillsFilterComponent } from '../../molecules/skills-filter/skills-filter.component';
import { LocationFilterComponent } from '../../molecules/location-filter/location-filter.component';

@Component({
  selector: 'app-manage-gig-modal',
  templateUrl: './manage-gig-modal.component.html',
  styleUrls: ['./manage-gig-modal.component.scss'],
})
export class ManageGigModalComponent implements OnInit {
  @Input() fromParent: {
    availableSkills: SkillFeatured[];
    manageGigForm?: any;
    cityWithCoordinatesDTO?: ICityWithCoordinates;
    shouldEditOpp?: boolean;
    gigId: number;
  };
  @Output() reloadGigs = new EventEmitter();
  @ViewChild(SkillsFilterComponent) skillsFilter: SkillsFilterComponent;
  @ViewChild(LocationFilterComponent) locationFilter: LocationFilterComponent;

  public availableSkills: SkillFeatured[];
  public datepickerIsOpen = false;
  public manageGigForm: FormGroup;
  public showSkillsError = false;
  public selectedSkills: SkillFeatured[] = [];
  public locationToFilterBy$ = new BehaviorSubject<ICityWithCoordinates>(null);
  public skillsToFilterBy$ = new BehaviorSubject<SkillFeatured[]>([]);
  public postOppButtonLabel: string;
  public shouldEditOpp: boolean;

  private gigId: number;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private jammboardService: JammboardService,
    private modalService: NgbModal,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.availableSkills = this.fromParent.availableSkills;
    this.shouldEditOpp = this.fromParent.shouldEditOpp || false;
    this.gigId = this.fromParent.gigId || null;

    this.shouldEditOpp
      ? (this.postOppButtonLabel = 'JAMMBOARD.EDIT_GIG')
      : (this.postOppButtonLabel = 'JAMMBOARD.POST_GIG');

    this.manageGigForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(1024)]],
      skills: [[], [Validators.required, Validators.minLength(1)]],
      description: ['', [Validators.required, Validators.maxLength(3000)]],
      price: ['', [Validators.maxLength(100)]],
      date: [null, [this.dateInPastValidator()]],
    });

    if (this.fromParent.manageGigForm) {
      this.manageGigForm.controls.title.setValue(this.fromParent.manageGigForm.title);
      this.manageGigForm.controls.description.setValue(this.fromParent.manageGigForm.description);
      this.manageGigForm.controls.price.setValue(this.fromParent.manageGigForm.price);
      // NOTE: For the 'date' pipe to have effect in the template and render in proper format
      // the date has to be initially set with a timeout
      setTimeout(() => {
        this.changeDate(this.fromParent.manageGigForm.date);
      }, 0);
      this.selectedSkills = [...this.fromParent.manageGigForm.skills];
      this.skillsToFilterBy$.next(this.selectedSkills);
      this.skillsChanged(this.selectedSkills);
      this.fromParent.cityWithCoordinatesDTO
        ? this.locationToFilterBy$.next(this.fromParent.cityWithCoordinatesDTO)
        : null;
    }
  }

  private dateInPastValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const setDate = control.value as Date;

      if (!!setDate) {
        const dateInPast = isDateInPast(setDate.getFullYear(), setDate.getMonth(), setDate.getDate());
        // NOTE: In case of unsuccessful validation a non-empty object must be returned hence the dummy content
        return dateInPast ? { dummy: 'dummyValue' } : null;
      }
      return null;
    };
  }

  get title(): AbstractControl {
    return this.manageGigForm.get('title');
  }

  get skills(): AbstractControl {
    return this.manageGigForm.get('skills');
  }

  get description(): AbstractControl {
    return this.manageGigForm.get('description');
  }

  get price(): AbstractControl {
    return this.manageGigForm.get('price');
  }

  get date(): AbstractControl {
    return this.manageGigForm.get('date');
  }

  public onSubmit(): void {
    this.manageGigForm.markAllAsTouched();

    if (this.manageGigForm.invalid) {
      setTimeout(() => {
        const element = this.document.getElementsByClassName('manage-gig-modal__error-scroll-to')?.[0];
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    } else {
      this.postOpp();
    }
  }

  public onMarkAsFilled(): void {
    this.jammboardService.markOpportunity(this.gigId, true).subscribe(
      (res) => {
        this.toastService.show('Your gig was successfuly marked as filled', {
          autohide: true,
          classname: 'success',
        });
        this.navigateBackToGigList();
      },
      () => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  public onDelete(): void {
    const modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    modalInstance.componentInstance.fromParent = {
      modalData: {
        title: 'Are you sure you want to delete this gig?',
        buttonExitLabel: 'YES',
        buttonStayLabel: 'NO',
        backgroundImageClass: 'success',
        exitMentorship: true,
      },
    };
    modalInstance.result.then((shouldDelete) => {
      if (shouldDelete) {
        this.jammboardService.deleteOpportunity(this.gigId).subscribe(
          (res) => {
            this.toastService.show('Your gig was successfuly deleted', {
              autohide: true,
              classname: 'error',
            });
            this.navigateBackToGigList();
          },
          () => {
            this.toastService.showMessage('Something went wrong');
          },
        );
      }
    });
  }

  private navigateBackToGigList(): void {
    const urlToNavigateTo = this.router.url.startsWith('/jobs') ? '/jobs?reload=true' : '/my-opportunities';
    this.router.navigateByUrl(urlToNavigateTo);
  }

  public toggleDatepicker(): void {
    this.datepickerIsOpen = !this.datepickerIsOpen;

    this.document.getElementById('dateSectionSeparator').scrollIntoView(true);
  }

  public closeDatepicker(): void {
    this.datepickerIsOpen = false;
  }

  public changeDate(date: Date): void {
    // NOTE: When selecting the same date as already set, the date display pipe does not
    // work properly for some reason. So disable setting the form date value to the same date
    const prevDate = this.manageGigForm.get('date').value;
    if (prevDate?.getTime() === date?.getTime()) {
      return;
    }
    this.manageGigForm.controls.date.setValue(date);
  }

  public skillsChanged(selectedSkills: SkillFeatured[]): void {
    if (!this.manageGigForm.get('skills').touched) {
      this.manageGigForm.get('skills').markAsTouched();
    }
    this.manageGigForm.controls.skills.setValue(selectedSkills.map((item) => item.id));
  }

  public postOpp() {
    const city = this.locationFilter.getFilterOutput();

    const formToSend = {
      title: this.manageGigForm.get('title').value,
      description: this.manageGigForm.get('description').value,
      skillIds: this.manageGigForm.get('skills').value,
      price: this.manageGigForm.get('price').value,
      date: this.manageGigForm.get('date').value?.getTime(),
      cityWithCoordinatesDTO: city,
    };

    const properServiceCall = this.shouldEditOpp
      ? this.jammboardService.editOpportunity(formToSend, this.gigId)
      : this.jammboardService.postOpportunity(formToSend);

    properServiceCall.subscribe(
      (res) => {
        this.reloadGigs.emit();
        this.openInfoModal();
      },
      (err) => {
        this.toastService.showMessage('Something went wrong, please try again');
      },
    );
  }

  public resetDate(): void {
    this.manageGigForm.controls.date.setValue(null);
  }

  public closeModal(event?: any): void {
    this.activeModal.close(event);
  }

  private openInfoModal(): void {
    const modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    const redirectionUrls = [];
    // NOTE: When opening the Info Modal, this means a gig was created or edited and the list of
    // gigs in the background should be refreshed.
    // My Opportunities page will automatically reload gigs in the 'active' tab when navigating to '/my-opportunities'
    // without 'tab=...' query param.
    // For the Jammboard (Gigs) page we have to trigger the reload somehow so 'reload=true' query param is passed so the page can act
    // accordingly
    redirectionUrls[0] = '/my-opportunities';
    redirectionUrls[1] = this.router.url.startsWith('/jobs') ? '/jobs?reload=true' : '/jobs';

    modalInstance.componentInstance.fromParent = {
      modalData: {
        description: this.shouldEditOpp ? 'Your gig was successfully edited' : 'Your gig was posted successfully',
        buttonLabelsArr: ['SEE_MY_GIGS', 'BACK_TO_GIGS'],
        backgroundImageClass: 'success',
        redirectToArr: [...redirectionUrls],
      },
    };
  }
}
