import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { ServiceProvision, TruncatedProfile } from '../../../models';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';
import { AuthenticationService, LocalStorageService, ProfileService, ToastService } from '../../../services';
import {
  MODAL_DISMISS_REASON,
  EVENT_NAME_PREFIX,
  constructURLAccordingToSourceType,
  MOBILE_WEB_VIEW,
} from '../../../utils';
import { AppState } from '../../../store/app.reducers';
import { InfoService } from '../../../services';

@Component({
  selector: 'app-notify-me-modal',
  templateUrl: './notify-me-modal.component.html',
  styleUrls: ['./notify-me-modal.component.scss'],
})
export class NotifyMeModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public proTruncatedProfile: TruncatedProfile;
  public serviceForBooking: ServiceProvision;
  public navigateBackUrl: string;
  public fromParent: {
    proTruncatedProfile: TruncatedProfile;
    serviceForBooking: ServiceProvision;
    navigateBackUrl: string;
  };
  public modalInstance: any;
  public serviceProvisionId: number;
  public notifyMeEmail: string;
  public notifyMeForm: FormGroup;
  public isGuestUser: boolean;

  private openedFromWebView: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    public profileService: ProfileService,
    public toastService: ToastService,
    private store: Store<AppState>,
    private infoService: InfoService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.navigateBackUrl = this.fromParent.navigateBackUrl;
    this.proTruncatedProfile = this.fromParent.proTruncatedProfile;
    this.serviceForBooking = this.fromParent.serviceForBooking;
    this.notifyMeEmail = this.authenticationService.getAuthTokens()?.email || null;
    this.isGuestUser = !this.authenticationService.isLoggedIn();

    this.notifyMeForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'change',
        },
      ],
    });
  }

  public get email(): FormControl {
    return this.notifyMeForm.get('email') as FormControl;
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public onSubmit(submitForm?: FormGroup): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'waitlist-submit',
      email: this.email.value,
    });

    if (this.isGuestUser && this.email.value.length === 0) {
      this.email.markAsDirty();
      this.email.setErrors({ required: true });
      return;
    }

    const serviceProvisionId = this.serviceForBooking.id;
    const email = this.notifyMeEmail ? this.notifyMeEmail : this.email.value;

    this.profileService.notifyMe(serviceProvisionId, email).subscribe(
      (response: any) => {
        if (response.status === 202) {
          this.openSuccessModal();
        }
      },
      (error) => {
        if (error.status === 409 && error.error.generalError.message === 'subscriptionExists') {
          this.openSuccessModal();
        } else if (
          this.email.value.length &&
          error.error?.fieldErrors.some((err) => err.errorType === '{emailNotValid}')
        ) {
          this.email.markAsDirty();
          this.email.setErrors({ email: true });
        } else if (error.status === 403 && error.error.generalError.message === 'selfSubscriptionNotAllowed') {
          this.toastService.showMessage("You can't subscribe to yourself :)");
        } else {
          this.toastService.showMessage('Something went wrong');
        }
      },
    );
  }

  public openSuccessModal(): void {
    this.modalService.dismissAll(MODAL_DISMISS_REASON.FAST_BOOKING_CHECKOUT);
    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    // TODO (Jovana): Add translations
    /* Check if feature is opened from WebView. 
-     If it is, modal close route should have extra query params to indicate WebView exit */
    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: `You are on the list!`,
        description: `You’ll be amongst the first to know when ${this.proTruncatedProfile.name} drops time slots `,
        buttonLabel: 'DONE',
        backgroundImageClass: 'success',
        redirectTo: constructURLAccordingToSourceType(
          this.navigateBackUrl,
          !!this.localStorageService.get(MOBILE_WEB_VIEW),
        ),
      },
    };
  }
}
