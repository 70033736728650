<div class="payment-methods-modal modal-fill-screen">
  <section class="flex row">
    <h1 class="payment-methods-modal__title">{{ 'PAYMENT_AND_PAYOUT.ADD_CARD' | translate }}</h1>
    <img
      class="payment-methods-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
  </section>
  <section class="payment-methods-modal__section">
    <app-add-payment-card-form
      [addCardOnlyBehavior]="true"
      [addNewPaymentMethod$]="addNewPaymentMethod$"
      (confirmWithNewPaymentMethod)="confirmWithNewPaymentMethod($event)"
      (processingConfirmationFinished)="processingConfirmationFinished()"
    >
    </app-add-payment-card-form>
  </section>
  <section class="payment-methods-modal__button">
    <app-button [buttonLabel]="buttonLabel" (clicked)="submitCard()" [buttonDisabled]="processingConfirmation">
    </app-button>
  </section>
</div>
