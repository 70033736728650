import { Pipe, PipeTransform } from '@angular/core';
import { isPastMonth } from '../../date-helper-functions';

@Pipe({
  name: 'datePastDisabled',
})
export class DatePastDisabledPipe implements PipeTransform {
  transform(isPastDisabled: boolean, year: number, month: number): boolean {
    return isPastDisabled && isPastMonth(new Date(year, month - 1, 1));
  }
}
