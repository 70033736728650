import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataLabelComponent } from './no-data-label.component';

@NgModule({
  declarations: [NoDataLabelComponent],
  exports: [NoDataLabelComponent],
  imports: [CommonModule],
})
export class NoDataLabelModule {}
