import { createAction, props } from '@ngrx/store';

export const firebaseConnected = createAction('[Notifications Effect] Firebase connected');
export const firebaseDisconnected = createAction('[Notifications Effect] Firebase disconnected');
export const firebaseListen = createAction('[Notifications Effect] Firebase listening for push');
export const incrementBookingCount = createAction('[Notifications] Booking Notification Count Increased by 1');
export const decrementBookingCount = createAction('[Notifications] Booking Notification Count Decreased by 1');
export const incrementMessengerCount = createAction('[Notifications] Messenger Notification Count Increased by 1');
export const decrementMessengerCount = createAction('[Notifications] Messenger Notification Count Decreased by 1');
export const setBookingCount = createAction('[Notifications] Booking Count Set', props<{ count: number }>());
export const setMessengerCount = createAction('[Notifications] Messenger Count Set', props<{ count: number }>());
export const newNotificationReceived = createAction(
  '[Notifications] New Notification Received',
  props<{ notification: any }>(),
);
