<app-jammcard-modal [modalImage]="bookModalData?.modalImage">
  <div class="auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">{{ title }}</p>
    </header>
    <form class="payout-details__content auth-modal__form flex column" #bookForm="ngForm">
      <app-book-modal-section>
        <app-request-quick-card [requestQuickCardData]="requestQuickCardData"></app-request-quick-card>
      </app-book-modal-section>
      <app-book-modal-section [sectionTitle]="'TRANSACTIONS'">
        <app-transaction-card [transactionCardData]="transactionCardData"></app-transaction-card
      ></app-book-modal-section>
    </form></div
></app-jammcard-modal>
