import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityMetadataMap, EntityDefinitionService } from '@ngrx/data';
import { TranslateModule } from '@ngx-translate/core';

import { BookModalComponent } from './book-modal.component';
import { BookModalEntryComponent } from './book-modal-entry.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { BookingEntityService } from '../../../services';
import { ClickOutsideModule } from '../../../utils/directives/click-outside/click-outside.module';
import { DatepickerModule } from '../../molecules/datepicker/datepicker.module';
import { ModalModule } from '../../templates/modal/modal.module';
import { RatesAccordionModule } from '../rates-accordion/rates-accordion.module';
import { AvatarGroupModule } from '../../molecules/avatar-group/avatar-group.module';

const entityMetadata: EntityMetadataMap = {
  Booking: {},
};

@NgModule({
  declarations: [BookModalComponent, BookModalEntryComponent],
  imports: [
    ButtonModule,
    CommonModule,
    ClickOutsideModule,
    DatepickerModule,
    FormsModule,
    ModalModule,
    RatesAccordionModule,
    TranslateModule,
    AvatarGroupModule,
  ],
  entryComponents: [BookModalComponent],
  providers: [BookingEntityService, NgbActiveModal],
  exports: [BookModalComponent, BookModalEntryComponent],
})
export class BookModalModule {
  constructor(private eds: EntityDefinitionService) {
    this.eds.registerMetadataMap(entityMetadata);
  }
}
