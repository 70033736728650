<div class="payment-methods settings-tab-content__container">
  <div class="payment-methods-tabs__container flex column">
    <h3 class="payment-methods__title">{{ 'PAYMENT_AND_PAYOUT.PAYMENT_METHODS' | translate }}</h3>
    <app-payment-method-tab-group
      [paymentMethods$]="paymentCards$"
      (tabActivated)="showPaymentDetails($event)"
    ></app-payment-method-tab-group>
    <app-button
      class="payment-methods__add"
      [buttonLabel]="addPaymentMethodLabel"
      [rounded]="true"
      [buttonDisabled]="processingConfirmation"
      (clicked)="addPaymentMethod()"
    ></app-button>
  </div>

  <div *ngIf="!!(paymentDetails$ | async)" class="payment-methods-card__container flex column">
    <div class="payment-methods-card__info settings-tab-content__card">
      <app-view-payment-card [cardInfo$]="paymentDetails$"></app-view-payment-card>
    </div>
    <div class="payment-methods-card__buttons flex">
      <app-button
        [buttonLabel]="deleteLabel"
        [rounded]="true"
        [red]="true"
        [buttonDisabled]="processingConfirmation"
        (clicked)="deleteCard()"
      ></app-button>
      <app-button
        [buttonLabel]="setAsDefaultLabel"
        [rounded]="true"
        [buttonDisabled]="selectedCard?.default || processingConfirmation"
        (clicked)="setCardAsDefault()"
      ></app-button>
    </div>
  </div>
</div>
