<app-jammcard-modal
  [modalImage]="authControllerModalData?.modalImage"
  [buttonLabel]="buttonLabel"
  [normalModal]="authControllerModalData?.normalModal"
>
  <div class="auth-modal__info auth-modal__mobile-scrollable flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <app-login-modal
      *ngIf="modalType === 'LOGIN'"
      [buttonLabel]="buttonLabel"
      [loginModalData]="authControllerModalData"
      (changeModal)="changeModalView($event)"
      (modalType)="changeModalType($event)"
    ></app-login-modal>
    <app-signup-modal
      *ngIf="modalType === 'SIGNUP'"
      [buttonLabel]="buttonLabel"
      [signupModalData]="authControllerModalData"
      (changeModal)="changeModalView($event)"
      (modalType)="changeModalType($event)"
      (closeModal)="closeModal($event)"
    ></app-signup-modal>
  </div>
</app-jammcard-modal>
