import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibilityObserverDirective } from './visibility-observer.directive';

@NgModule({
  declarations: [VisibilityObserverDirective],
  imports: [CommonModule],
  exports: [VisibilityObserverDirective],
})
export class VisibilityObserverModule {}
