import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { AuthModalData } from '../../../models';
import { RequestSentModalComponent } from '../request-sent-modal/request-sent-modal.component';
import { RequestSentModalButtonType } from '../request-sent-modal/request-sent.config';
import { REQUEST_SENT_VERIFICATION_MODAL } from './signup-modal.config';
import { AuthenticationService, InfoService, ToastService } from '../../../services';
import { AUTH_PASSWORD_LENGTH, EVENT_NAME_PREFIX, PLACEHOLDER_IMAGE_URL, AUTH_CONTROLLER } from '../../../utils';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
})
export class SignupModalComponent implements OnInit {
  @Input() linkSent: any;
  @Input() signupModalData: AuthModalData;
  @Input() buttonLabel: string;
  @Output() changeModal: EventEmitter<AuthModalData> = new EventEmitter(null);
  @Output() modalType: EventEmitter<string> = new EventEmitter(null);
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);

  public book: boolean;
  public loginModalData: AuthModalData;
  public modalImage: string;
  public passwordDisplayed: boolean;
  public proName: any;
  public proProfileUsername: string;
  public signupForm: FormGroup;
  public signupRequestSending: boolean;
  public storageUUID: string;
  public submitted: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private infoService: InfoService,
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    public router: Router,
    private store: Store<AppState>,
    private toastService: ToastService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.store.subscribe((res) => {
      // TODO (Milan): this param can be the actual id of a profile or it can (and most likely will) be a username
      this.proProfileUsername = res.router.state.params.id;
    });

    this.signupForm = this.formBuilder.group({
      fullName: ['', { validators: [Validators.required] }],
      email: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'change',
        },
      ],
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(AUTH_PASSWORD_LENGTH)],
        },
      ],
    });
    this.passwordDisplayed = false;
    this.book = this.signupModalData.book ? this.signupModalData.book : false;
    this.storageUUID = this.signupModalData.storageUUID;
    if (this.signupModalData.normalModal) {
      this.signupModalData.modalImage = PLACEHOLDER_IMAGE_URL.NORMAL_LOGIN;
    }
  }

  public get email(): FormControl {
    return this.signupForm.get('email') as FormControl;
  }

  public get password(): FormControl {
    return this.signupForm.get('password') as FormControl;
  }

  public get fullName(): FormControl {
    return this.signupForm.get('fullName') as FormControl;
  }

  submitForm(form): any {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'sign-up-button',
      name: this.fullName.value,
      email: this.email.value,
    });

    this.signupRequestSending = true;
    this.submitted = true;

    this.authenticationService
      .signup(
        this.fullName.value,
        this.email.value,
        this.password.value,
        this.book,
        this.proProfileUsername,
        this.storageUUID,
      )
      .subscribe(
        (response: any) => {
          if (response.status === 201) {
            this.signupRequestSending = false;
            this.openVerificationModal();
          } else {
            this.toastService.showMessage('Something went wrong');
          }
        },
        (error) => {
          this.signupRequestSending = false;
          if (error.status === 401) {
            this.password.setErrors({ invalid: true });
          } else if (error.status === 422) {
            // Go through all validators of inputs and mark dirty every input that is empty
            Object.keys(this.signupForm.value).forEach((validator) => {
              if (!this[validator].value) {
                this[validator].markAsDirty();
              }
            });
            if (this.fullName.value && error.error?.fieldErrors.some((err) => err.errorType === 'invalidFullName')) {
              this.fullName.markAsDirty();
              this.fullName.setErrors({ fullName: true });
            } else if (this.email.value && error.error?.fieldErrors.some((err) => err.errorType === 'invalidEmail')) {
              this.email.markAsDirty();
              this.email.setErrors({ email: true });
            } else if (
              this.email.value &&
              error.error?.fieldErrors.some((err) => err.errorType === 'emailAlreadyExists')
            ) {
              this.password.markAsDirty();
              this.email.setErrors({ notUnique: true });
            } else if (
              this.password.value &&
              error.error?.fieldErrors.some((err) => err.errorType === 'passwordLengthNotSatisfied')
            ) {
              this.password.markAsDirty();
              this.password.setErrors({ minlength: true });
            }
          } else if (error.status === 403 && error.error?.generalError.message === 'userHasNotBeenVerified') {
            this.toastService.showMessage(
              'This account has not been verified. Please check your email, verify account, then come back.',
            );
          } else if (error.status === 404) {
            this.email.setErrors({ notUnique: true });
          } else {
            this.toastService.showMessage('Something went wrong');
          }
        },
      );
  }

  openLoginModal(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, { element: 'open-login-in-form' });
    this.loginModalData = this.signupModalData;
    this.changeModal.emit(this.loginModalData);
    this.modalType.emit(AUTH_CONTROLLER.LOGIN);
  }

  public openVerificationModal(): void {
    const doNotReloadOrRedirect = true;
    this.closeModal.emit(doNotReloadOrRedirect);
    const modalInstance = this.modalService.open(RequestSentModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });
    const requestSentModalData = REQUEST_SENT_VERIFICATION_MODAL;
    requestSentModalData.requestSentDescription = `We’ve emailed a verification link to ${this.email.value}. Click the link in that email to finish creating your account.`;
    requestSentModalData.backButtonType = RequestSentModalButtonType.VERIFICATION;
    requestSentModalData.email = this.email.value;
    requestSentModalData.proProfileUsername = this.proProfileUsername;
    requestSentModalData.book = this.book;
    modalInstance.componentInstance.fromParent = { requestSentModalData };
  }

  toggleDisplayPassword(): any {
    this.passwordDisplayed = !this.passwordDisplayed;
  }
}
