import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { MentorshipSessionRatingModalComponent } from './mentorship-session-rating-modal.component';
import { MentorshipModalModule } from '../../templates/mentorship-modal/mentorship-modal.module';
import { RateExperienceGroupModule } from '../../molecules/rate-experience-group/rate-experience-group.module';

@NgModule({
  declarations: [MentorshipSessionRatingModalComponent],
  imports: [ButtonModule, CommonModule, FormsModule, MentorshipModalModule, RateExperienceGroupModule, TranslateModule],
  exports: [MentorshipSessionRatingModalComponent],
})
export class MentorshipSessionRatingModalModule {}
