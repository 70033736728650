import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castNumber',
})
export class CastNumberPipe implements PipeTransform {
  transform(value: any): number {
    let transformedValue;
    if (!isNaN(value)) {
      transformedValue = value;
    } else if (value === null || value === undefined) {
      transformedValue = 0;
    } else {
      transformedValue = value.toString().length ? 1 : 0;
    }
    return transformedValue;
  }
}
