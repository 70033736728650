import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { CookieConsentComponent } from './cookie-consent.component';

@NgModule({
  declarations: [CookieConsentComponent],
  imports: [ButtonModule, CommonModule],
  exports: [CookieConsentComponent],
})
export class CookieConsentModule {}
