/// <reference path = "../../../../node_modules/@types/google.maps/index.d.ts" />
// NOTE: The above line has to be on top. It connects the appropriate typings for google.maps
// and has to be done in this way since the google.maps API is injected as a <script> tag in index.html
// and not installed as a package.

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, switchMap } from 'rxjs/operators';

import { ToastService } from '../../../services';

import { ICityWithCoordinates, IGoogleLocation } from '../../../models';

const INITIAL_LOCATIONS: IGoogleLocation[] = [
  //USA
  { name: 'Los Angeles, CA', latitude: 34.0522342, longitude: -118.2436849 },
  { name: 'Nashville, TN', latitude: 36.1626638, longitude: -86.7816016 },
  { name: 'Atlanta, GA', latitude: 33.7489954, longitude: -84.3879824 },
  { name: 'New York, NY', latitude: 40.7127753, longitude: -74.0059728 },
  { name: 'Philadelphia, PA', latitude: 39.9525839, longitude: -75.1652215 },
  //UK
  { name: 'London, UK', latitude: 51.5072178, longitude: -0.1275862 },
  { name: 'Manchester, UK', latitude: 53.4807593, longitude: -2.2426305 },
  //EU
  { name: 'Copenhagen, Denmark', latitude: 55.6760968, longitude: 12.5683371 },
  { name: 'Stockholm, Sweden', latitude: 59.32932349, longitude: 18.0685808 },
  //Other
  { name: 'Remote - Anywhere', latitude: 0, longitude: 0 },
];

const COMPONENT_RESTRICTIONS = { country: ['us', 'uk', 'dk', 'se'] };
const TYPES = ['(cities)'];

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
})
export class LocationFilterComponent implements OnInit, OnDestroy {
  @Input() locationToFilterBy$: Subject<ICityWithCoordinates>;
  @Input() expandable = true;
  @Input() showActiveLabel?: boolean = false;
  @ViewChild('locationSearchField') locationSearchField: ElementRef<HTMLInputElement>;
  @ViewChild('initialSuggestionsDDControl') initialSuggestionsDDControl: NgbDropdown;
  @ViewChild('initialDropdownMenu') initialDropdownMenu: ElementRef<HTMLElement>;

  public readonly initialLocations = INITIAL_LOCATIONS;
  public googleLocationPredictions: google.maps.places.AutocompletePrediction[];
  public loadingInProgress: boolean;
  // TODO (Milan): Seems like IGoogleLocation is not needed, could use ICityWithCoordinates everywhere
  public selectedLocation: IGoogleLocation;
  public showInitialSuggestions = true;

  public searchTerm$ = new BehaviorSubject<string>('');

  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken;
  private autocompleteService: google.maps.places.AutocompleteService;
  private geocoder: google.maps.Geocoder;
  private placesService: google.maps.places.PlacesService;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.locationToFilterBy$?.subscribe((location) => {
      if (location) {
        this.selectedLocation = {
          name: location?.city,
          latitude: location.lat,
          longitude: location.lon,
        };
      } else {
        this.selectedLocation = null;
      }
    });

    this.searchTerm$
      .pipe(
        skip(1),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          if (searchTerm === '') {
            this.showInitialSuggestions = true;
            return of(null);
          } else {
            this.loadingInProgress = true;
            return from(
              this.autocompleteService.getPlacePredictions({
                sessionToken: this.autocompleteSessionToken,
                input: searchTerm,
                componentRestrictions: COMPONENT_RESTRICTIONS,
                types: TYPES,
              }),
            );
          }
        }),
      )
      .subscribe((res) => {
        this.loadingInProgress = false;
        if (!res) {
          // initial suggestions already show, so do nothing
        } else {
          this.googleLocationPredictions = res.predictions;
          this.showInitialSuggestions = false;
        }
      });

    // Create a new session token.
    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();

    this.autocompleteService = new google.maps.places.AutocompleteService();

    let dummyDiv: HTMLDivElement = document.createElement('div');
    this.placesService = new google.maps.places.PlacesService(dummyDiv);

    this.geocoder = new google.maps.Geocoder();
  }

  ngOnDestroy(): void {
    this.locationToFilterBy$?.unsubscribe();
  }

  public getCurrentLocation(): void {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.geocoder
          .geocode({ location: { lat: position.coords.latitude, lng: position.coords.longitude } })
          .then((result) => {
            // NOTE: Not sure if this will always find what is should. Check if it can be checked somehow
            let locality = result.results[0].address_components.find((item) => item.types.includes('locality'));

            if (locality) {
              this.locationSearchField.nativeElement.value = locality.long_name;
              this.searchLocation();
            } else {
              throw new Error('no locality found');
            }
          })
          .catch(() => {
            this.toastService.showMessage(
              'There was a problem accessing your current location. Please try a different location.',
            );
          });
      },
      () => {
        this.toastService.showMessage(
          'There was a problem accessing your current location. Please try a different location.',
        );
      },
    );
  }

  public setSelectedLocation(location: IGoogleLocation = { name: null, latitude: null, longitude: null }): void {
    this.selectedLocation = {
      ...location,
    };
  }

  public async googleSuggestionClick(prediction: google.maps.places.AutocompletePrediction): Promise<void> {
    this.loadingInProgress = true;

    try {
      let placeResult = await this.getDetailsWrapper(prediction.place_id);
      this.loadingInProgress = false;
      this.setSelectedLocation(placeResult);
      this.locationSearchField.nativeElement.value = '';
      this.showInitialSuggestions = true;
    } catch (e) {
      this.loadingInProgress = false;
      this.toastService.showMessage('Something went wrong. Please try again.');
    }
    // NOTE: One session ends with the details fetching, create new session token
    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  // NOTE: When performing logic directly inside getDetails callback (second argument), the code gets executed
  // outside of Angular zone and view rerendering happens with a huge delay. Wraping it with a Promise like this
  // solves that problem.
  private getDetailsWrapper(placeId: string): Promise<IGoogleLocation> {
    return new Promise((resolve, reject) => {
      this.placesService.getDetails(
        {
          placeId,
          fields: ['geometry', 'name'],
          sessionToken: this.autocompleteSessionToken,
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
            resolve({
              name: place.name,
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            });
          } else {
            reject(null);
          }
        },
      );
    });
  }

  public searchLocation(): void {
    this.searchTerm$.next(this.locationSearchField.nativeElement.value.trim());
  }

  public onInputValueChange(): void {
    let trimmedSearchTerm = this.locationSearchField.nativeElement.value.trim();
    if (trimmedSearchTerm === '') {
      this.showInitialSuggestions = true;
    } else {
      this.autocompleteService.getPlacePredictions({
        sessionToken: this.autocompleteSessionToken,
        input: trimmedSearchTerm,
        componentRestrictions: COMPONENT_RESTRICTIONS,
        types: TYPES,
      });
    }
  }

  // NOTE: Universally named methods used by parent to get and reset the selected filter values
  public getFilterOutput(): ICityWithCoordinates {
    if (this.selectedLocation) {
      return {
        city: this.selectedLocation.name,
        lat: this.selectedLocation.latitude,
        lon: this.selectedLocation.longitude,
      };
    } else return null;
  }

  public resetFilter(): void {
    this.selectedLocation = null;
  }
}
