import { Component, Input, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AUTH_CONTROLLER } from '../../../utils';
import { AuthModalData } from '../../../models';
import { AuthControllerModalComponent } from '../../templates/auth-controller-modal/auth-controller-modal.component';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
})
export class LoginButtonComponent implements OnInit {
  @Input() proProfile: any;

  public loginModalData: AuthModalData;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.loginModalData = {
      normalModal: true,
    };
  }

  openLoginModal(event): void {
    const loginModalData = this.loginModalData;
    const modalInstance = this.modalService.open(AuthControllerModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });
    modalInstance.componentInstance.fromParent = {
      modalType: AUTH_CONTROLLER.LOGIN,
      authControllerModalData: loginModalData,
    };
  }
}
