<section class="booking-quick-info-card__container flex justify-content-between">
  <div class="booking-quick-info-card__section flex column justify-start">
    <app-status-label [statusClass]="statusClass" [statusTitle]="statusTitle"></app-status-label>
    <div class="booking-quick-info-card__gig-title">{{ cardContent?.gigTitle }}</div>
  </div>
  <div class="booking-quick-info-card__section flex row justify-content-between align-center">
    <label class="booking-quick-info-card__price">${{ cardContent?.totalPrice }}</label>
    <app-card-button [buttonLabel]="buttonLabel" [buttonType]="'submit'" (clicked)="openBooking()"></app-card-button>
  </div>
</section>
