import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaComponent } from './media.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MediaComponent],
  imports: [CommonModule, PipesModule, TranslateModule],
  exports: [MediaComponent],
})
export class MediaModule {}
