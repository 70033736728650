import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  ChildActivationStart,
  RoutesRecognized,
  ActivationEnd,
  ParamMap,
} from '@angular/router';
import { TransferState } from '@angular/platform-browser';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { AppState, NGRX_STATE } from '../store/app.reducers';
import { environment } from '../environments/environment';
import {
  MetaService,
  IsBrowserService,
  COOKIE_CONSENT_GIVEN,
  DISTINCT_UUID,
  MODAL_DISMISS_REASON,
  REFRESH_TOKEN_INITIATED,
  AFFILIATE_CAMPAIGN_DATA,
  INITIAL_REFERRER,
  UTM_PARAMETERS,
  UTM_PARAMETERS_LIST,
} from '../utils';
import { AuthenticationService, LocalStorageService, SessionStorageService } from '../services';
import { AuthActions } from '../store/auth/action-types';
import { clearAvailabilityInfo, clearFastBooking } from '../store/fast-booking/fast-booking.actions';
import { AffiliateCampaign } from '../models';

import { Inject, Optional } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public cookieConsentGiven: boolean;
  public isBrowser: boolean;
  title = 'jammcard-web-app';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private isBrowserService: IsBrowserService,
    private localStorageService: LocalStorageService,
    private metaService: MetaService,
    private modalService: NgbModal,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private sessionStorageService: SessionStorageService,
    private readonly transferState: TransferState,
    public translate: TranslateService,
    @Optional() @Inject('referrer') public referrer: string,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    // NOTE: Rehydrate client-side NgRx initial state with the one saved server-side
    this.isBrowser = this.transferState.hasKey<any>(NGRX_STATE);
    this.isBrowser ? this.onBrowser() : this.onServer();
  }

  ngOnInit() {
    this.isBrowser = this.isBrowserService.isBrowser();
    if (!this.isBrowser) {
      if (!environment.production) {
        this.metaService.addTag({ name: 'robots', content: 'noindex' });
      }

      if (!!this.referrer) {
        this.isBrowserService.set(INITIAL_REFERRER, this.referrer);
      }
    } else {
      // If the application was closed in the middle of refreshing the token, the REFRESH_TOKEN_INITIATED flag would stay set to 'true'
      // so here it is initially reset to false
      this.localStorageService.add(REFRESH_TOKEN_INITIATED, false);

      this.cookieConsentGiven = this.localStorageService.get(COOKIE_CONSENT_GIVEN);

      if (this.authenticationService.isLoggedIn()) {
        this.store.dispatch(AuthActions.loginSuccess({}));
      } else {
        const distinctUUID = this.localStorageService.get(DISTINCT_UUID);
        if (!distinctUUID) this.localStorageService.add(DISTINCT_UUID, uuidv4());
      }

      this.checkAffiliateCampaignDataForExpiry();
      this.isBrowserService.get(INITIAL_REFERRER).subscribe((referrer) => {
        if (!!referrer && !this.localStorageService.get(INITIAL_REFERRER)) {
          this.localStorageService.add(INITIAL_REFERRER, {
            initialReferrer: referrer,
            initialReferringDomain: new URL(referrer as string).hostname,
          });
        }
      });

      // The app component gets initialized before routing takes place, so initial queryParams would be an empty object.
      // That's why we wait for the ActivationEnd event and then check for query parameters and unsubscribe with take(1).
      this.router.events
        .pipe(
          filter((event) => event instanceof ActivationEnd),
          take(1),
        )
        .subscribe(() => {
          // Check for UTM params
          const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
          const utmParamsPresent = queryParamMap.keys.some((key) => UTM_PARAMETERS_LIST.includes(key));
          if (utmParamsPresent) {
            this.putUtmParamsInSessionStorage(queryParamMap);
          }
        });

      this.closeModalOnRouteChange();
    }
  }

  private onServer(): void {
    this.transferState.onSerialize(NGRX_STATE, () => {
      let state;
      this.store
        .subscribe((savedState: any) => {
          state = savedState;
        })
        .unsubscribe();
      return state;
    });
  }

  private onBrowser(): void {
    const state = this.transferState.get<any>(NGRX_STATE, null);
    this.transferState.remove(NGRX_STATE);
    this.store.dispatch({ type: 'SET_ROOT_STATE', payload: state });
  }

  private closeModalOnRouteChange() {
    this.router.events
      .pipe(filter((event) => event instanceof ChildActivationStart || event instanceof RoutesRecognized))
      .subscribe((event) => {
        this.store.dispatch(clearFastBooking());
        this.store.dispatch(clearAvailabilityInfo());
        this.modalService.dismissAll(MODAL_DISMISS_REASON.ROUTE_SWITCH);
      });
  }

  private checkAffiliateCampaignDataForExpiry(): void {
    const currentTime = new Date().getTime();
    const affiliateCampaignData: AffiliateCampaign = this.localStorageService.get(AFFILIATE_CAMPAIGN_DATA);
    if (!!affiliateCampaignData && currentTime > affiliateCampaignData.expirationDate) {
      this.localStorageService.delete(AFFILIATE_CAMPAIGN_DATA);
    }
  }

  private putUtmParamsInSessionStorage(queryParamMap: ParamMap): void {
    let utmParamsObj = {};
    queryParamMap.keys.forEach((key) => {
      if (UTM_PARAMETERS_LIST.includes(key)) {
        utmParamsObj = {
          ...utmParamsObj,
          [key]: queryParamMap.get(key),
        };
      }
    });

    // Utm parameters should be tracked only for the duration of the session, that's why sessionStorage is used
    // instead of localStorage
    this.sessionStorageService.add(UTM_PARAMETERS, utmParamsObj);
  }
}
