import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

import { NotificationSettings } from '../../../models';

export const notificationTypes = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH',
};

@Component({
  selector: 'app-notification-settings-tab-group',
  templateUrl: './notification-settings-tab-group.component.html',
  styleUrls: ['./notification-settings-tab-group.component.scss'],
})
export class NotificationSettingsTabGroupComponent implements OnInit {
  @Input() notificationSettings$: BehaviorSubject<NotificationSettings[]>;
  @Input() activeTab: NotificationSettings;
  @Output() tabActivated: EventEmitter<NotificationSettings> = new EventEmitter();

  public activeTab$: BehaviorSubject<NotificationSettings> = new BehaviorSubject<NotificationSettings>(null);
  public notificationType: string = '';

  private notificationSettings: NotificationSettings[];

  constructor() {}

  ngOnInit(): void {
    this.notificationSettings$.pipe(skip(1)).subscribe((notificationSetting) => {
      this.notificationSettings = notificationSetting;
      if (!this.activeTab) {
        this.activeTab = this.notificationSettings[0];
      }
      this.selectTab(this.activeTab);
    });
  }

  public selectTab(selectedTab: NotificationSettings): void {
    this.activeTab = selectedTab;
    this.activeTab$.next(selectedTab);
    this.tabActivated.emit(selectedTab);
  }
}
