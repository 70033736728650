import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import * as Clipboard from 'clipboard';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { IGigMain } from '../../../models';
import { InfoModalSuccessComponent } from '../../organisms/info-modal-success/info-modal-success.component';
import { ManageGigModalComponent } from '../../organisms/manage-gig-modal/manage-gig-modal.component';
import { AuthControllerModalComponent } from '../../templates/auth-controller-modal/auth-controller-modal.component';
import { AUTH_CONTROLLER, MODAL_DISMISS_REASON, MY_OPPORTUNITIES_STATUS } from '../../../utils';
import {
  AuthenticationService,
  JammboardService,
  ProfileService,
  TempStorageService,
  ToastService,
} from '../../../services';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';
import { filter, take } from 'rxjs/operators';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-jammboard-gig-main-card-details',
  templateUrl: './jammboard-gig-main-card-details.component.html',
  styleUrls: ['./jammboard-gig-main-card-details.component.scss'],
})
export class JammboardGigMainCardDetailsComponent implements OnInit, OnDestroy {
  @Output() reload = new EventEmitter<boolean>();
  public opportunity: IGigMain;
  public isCurrentUserGigOwner: boolean = false;
  public isUserLoggedIn: boolean;
  public text: string = '';
  private modalInstance: any;

  public readonly calendarIcon = 'assets/images/icons/calendar-new.svg';
  public readonly locationIcon = 'assets/images/icons/location.svg';
  public readonly opportunityStatusActive = MY_OPPORTUNITIES_STATUS.POSTED;
  public readonly opportunityStatusFilled = MY_OPPORTUNITIES_STATUS.FILLED;

  private copyButton = new Clipboard('.copy-to-clipboard', { text: () => this.opportunity.shareUrl });
  private copiedToClipboardMessage = 'Job link copied to clipboard.';
  private pleaseTryAgainMessage = 'Something went wrong, please try again.';

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private jammboardService: JammboardService,
    private modalService: NgbModal,
    private profileService: ProfileService,
    private router: Router,
    private store: Store,
    private tempStorageService: TempStorageService,
    private toastService: ToastService,
  ) {}

  private fromParent: {
    opportunity: IGigMain;
  };

  ngOnInit(): void {
    this.opportunity = this.fromParent.opportunity;
    this.isUserLoggedIn = this.authService.isLoggedIn();
    if (this.isUserLoggedIn) {
      this.store
        .select(selectCurrentUserShort)
        .pipe(
          filter((currentUserShort) => !isNil(currentUserShort)),
          take(1),
        )
        .subscribe((currentUserShort) => {
          this.isCurrentUserGigOwner = this.opportunity.postedBy.id === currentUserShort.id;
        });
    }

    this.copyButton.on('success', this.onCopySuccess);
    this.copyButton.on('error', this.onCopyError);
  }

  ngOnDestroy(): void {
    this.copyButton.destroy();
  }

  public sendMessage(text: string): void {
    if (text.trim().length) {
      this.profileService.opportunityShowInterest(this.opportunity.id, text.trim()).subscribe(
        (res) => {
          this.text = '';
          this.openSuccessModal();
        },
        () => {
          this.toastService.showMessage('Something went wrong');
        },
      );
    }
  }

  public showLoginModal(): void {
    this.tempStorageService.storeData({ redirectTo: this.router.url }).subscribe(
      (res) => {
        const storageUUID = res.body?.uuid || null;

        this.modalService.dismissAll(MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK);
        const modalInstance = this.modalService.open(AuthControllerModalComponent, {
          windowClass: 'modal-window',
          backdrop: 'static',
        });

        modalInstance.componentInstance.fromParent = {
          modalType: AUTH_CONTROLLER.LOGIN,
          authControllerModalData: {
            normalModal: true,
            storageUUID,
          },
        };
      },
      (_) => {
        this.toastService.showMessage('Something went wrong, please try again.');
      },
    );
  }

  public closeModal(): any {
    this.activeModal.close();
  }

  public onProfileClick(username: string): void {
    this.activeModal.close(username);
  }

  public onEdit(): void {
    this.jammboardService.getAllAvailableSkills().subscribe(
      (res) => {
        const availableSkills = res.body.slice();

        const modalInstanceRef = this.modalService.open(ManageGigModalComponent, {
          windowClass: 'data-input-modal-window',
          backdrop: 'static',
        });

        modalInstanceRef.componentInstance.fromParent = {
          availableSkills,
          manageGigForm: {
            title: this.opportunity.title || null,
            skills: this.opportunity.skills || null,
            description: this.opportunity.description || null,
            price: this.opportunity.price || null,
            date: this.opportunity.date ? new Date(this.opportunity.date) : null,
          },
          cityWithCoordinatesDTO: this.opportunity.cityWithCoordinatesDTO,
          shouldEditOpp: true,
          gigId: this.opportunity.id,
        };
      },
      () => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  public onMarkAsNotFilled(): void {
    this.jammboardService.markOpportunity(this.opportunity.id, false).subscribe(
      (res) => {
        this.toastService.show('Your gig was successfuly marked as not filled', {
          autohide: true,
          classname: 'success',
        });
        // NOTE: navigate back to gig list.
        // For My opportunities page when on 'filled' tab, the url contains query params '?tab=filled' so to refresh the list
        // only a change in query params is needed for the page logic to reload the list, so we add a dummy param to the url
        // in that case 'rld=true' but the name and the value of the param are not important
        const urlToNavigateTo = this.router.url.startsWith('/jobs')
          ? '/jobs?reload=true'
          : '/my-opportunities?tab=filled&rld=true';
        this.router.navigateByUrl(urlToNavigateTo);
      },
      () => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  private openSuccessModal(): void {
    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: 'Message sent successfully!',
        description: 'Your interest was noted',
        buttonLabel: 'BACK_TO_GIGS',
        backgroundImageClass: 'success',
      },
    };

    this.modalInstance.result.then(() => {
      return this.closeModal();
    });
  }

  private onCopySuccess = (e: any) => {
    this.toastService.showMessage(this.copiedToClipboardMessage);
  };

  private onCopyError = (e: any) => {
    this.toastService.showMessage(this.pleaseTryAgainMessage);
  };
}
