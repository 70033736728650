import { createAction, props } from '@ngrx/store';
import { BookingsByStatus, BookingRole, BookingByStatus } from '../../models';

export const loadPastBookings = createAction('[Booking] Load Past Bookings');
export const loadPendingBookings = createAction('[Booking] Load Pending Bookings');
export const loadUpcomingBookings = createAction('[Booking] Load Upcoming Bookings');

export const pastBookingsLoaded = createAction('[Booking Effect] Loaded Past Bookings', props<{ pastBookings }>());
export const pendingBookingsLoaded = createAction(
  '[Booking Effect] Loaded Pending Bookings',
  props<{ pendingBookings }>(),
);
export const upcomingBookingsLoaded = createAction(
  '[Booking Effect] Loaded Upcoming Bookings',
  props<{ upcomingBookings }>(),
);

export const openBookModal = createAction('[Booking] User Opened Booking Modal', props<{ bookingId: number }>());
export const openBookModalSuccess = createAction(
  '[Booking] User Opened Booking Modal',
  props<{ booking: BookingByStatus; role: BookingRole }>(),
);

export const openAcceptBookingModalBuyer = createAction(
  '[Booking] Buyer Opened Accept Booking Modal',
  props<{ bookingId: number }>(),
);
export const openAcceptBookingModalBuyerSuccess = createAction(
  '[Booking Effect] Buyer Opened Accept Booking Modal',
  props<{ booking: any }>(),
);

export const openPendingBookModalSeller = createAction('[Booking] Seller Opened Pending Booking Modal');

export const viewBookingRequestSeller = createAction(
  '[Booking] Seller Viewed Booking Request',
  props<{ id: number }>(),
);

export const openAcceptBookingModalSeller = createAction(
  '[Booking] Seller Opened Accept Booking Modal',
  props<{ bookingId: number }>(),
);
export const openAcceptBookingModalSellerSuccess = createAction(
  '[Booking Effect] Seller Opened Accept Booking Modal',
  props<{ booking: any }>(),
);
export const openConfirmedBookingModalSeller = createAction(
  '[Booking] Seller Opened Confirmed Booking Modal',
  props<{ bookingId: number }>(),
);
export const openConfirmedBookingModalSellerSuccess = createAction(
  '[Booking Effect] Seller Opened Confirmed Booking Modal',
  props<{ booking: any }>(),
);
export const openCompletedBookingModalSeller = createAction(
  '[Booking] Seller Opened Completed Booking Modal',
  props<{ bookingId: number }>(),
);
export const openCompletedBookingModalSellerSuccess = createAction(
  '[Booking] Seller Opened Completed Booking Modal Success',
  props<{ bookingId: number }>(),
);
export const openCompletedBookingModalBuyer = createAction(
  '[Booking] Buyer Opened Completed Booking Modal',
  props<{ bookingId: number }>(),
);
export const openCompletedBookingModalBuyerSuccess = createAction(
  '[Booking] Buyer Opened Completed Booking Modal Success',
  props<{ bookingId: number }>(),
);

export const acceptBookingRequestSeller = createAction('[Booking] Seller Accepted Booking Request');
export const confirmBookingRequestSeller = createAction('[Booking] Seller Confirmed Booking Request');
export const confirmBookingRequestSellerSuccess = createAction('[Booking Effect] Seller Booking Confirmation Success');
export const confirmBookingRequestSellerFail = createAction('[Booking Effect] Seller Booking Confirmation Fail');
export const addPayoutMethodSeller = createAction('[Booking] Seller Clicked Add Payout Method');
export const addPayoutMethodSellerSuccess = createAction('[Booking Effect] Seller Add Payout Method Success');
export const addPayoutMethodSellerFail = createAction('[Booking Effect] Seller Add Payout Method Fail');

export const openConfirmBookingModalBuyer = createAction(
  '[Booking] Buyer Opened Confirm Booking Modal',
  props<{ bookingId: number }>(),
);
export const openConfirmBookingModalBuyerSuccess = createAction(
  '[Booking Effect] Buyer Opened Confirm Booking Modal',
  props<{ booking: any }>(),
);
export const openConfirmedBookingModalBuyer = createAction(
  '[Booking] Buyer Opened Confirmed Booking Modal',
  props<{ bookingId: number }>(),
);
export const openConfirmedBookingModalBuyerSuccess = createAction(
  '[Booking Efect] Buyer Opened Confirmed Booking Modal',
  props<{ booking: any }>(),
);

export const addPayoutMethodBuyer = createAction('[Booking] Buyer Clicked Add Payout Method');
export const addPayoutMethodBuyerSuccess = createAction('[Booking Effect] Buyer Add Payout Method Success');
export const addPayoutMethodBuyerFail = createAction('[Booking Effect] Buyer Add Payout Method Fail');
export const confirmBookingRequestBuyer = createAction('[Booking] Buyer Confirmed Booking Request');
export const confirmBookingRequestBuyerSuccess = createAction('[Booking Effect] Buyer Booking Confirmation Success');
export const confirmBookingRequestBuyerFail = createAction('[Booking Effect] Buyer Booking Confirmation Fail');

export const reportBuyer = createAction('[Booking] Buyer Reported');
export const reportSeller = createAction('[Booking] Seller Reported');
export const contactSupport = createAction('[Booking] User Contacted Support');
export const openInstallAppModal = createAction('[Booking] Install App Modal Opened');
export const deleteAllBookings = createAction('[Booking] All Bookings Cleared');
export const deleteCurrentBooking = createAction('[Booking Effect] Current Booking Deleted');

export const seenBookingById = createAction(
  '[Booking] User Has Seen The Booking',
  props<{ booking: BookingByStatus }>(),
);
export const seenBookingByIdSuccess = createAction('[Booking] User Has Seen The Booking Success');
export const loadCurrentBookingFromUrl = createAction(
  '[Booking] Load Current Booking From Url',
  props<{ bookingId: number }>(),
);
export const loadCurrentBookingFromUrlSuccess = createAction(
  '[Booking] Load Current Booking From Url Success',
  props<{ booking: BookingByStatus; role: BookingRole }>(),
);

export const loadCurrentBookingFromUrlFailed = createAction('[Booking] Load Current Booking From Url Failed');
