<div class="media-modal-container flex align-center justify-center">
  <div class="media-modal flex column align-self-center">
    <iframe
      id="{{ mediaIndex }}"
      allow="autoplay"
      width="100%"
      height="562.5"
      [src]="mediaUrl + '?autoplay=true&auto_play=true' | safe: 'resourceUrl'"
      [title]="mediaCaption"
      allowfullscreen
    ></iframe>
  </div>
</div>
