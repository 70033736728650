import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavStateChange } from '../settings-content/settings-content.component';

@Component({
  selector: 'app-payment-and-payout',
  templateUrl: './payment-and-payout.component.html',
  styleUrls: ['./payment-and-payout.component.scss'],
})
export class PaymentAndPayoutComponent implements OnInit {
  @Input() stateChange$: BehaviorSubject<NavStateChange>;
  @Output() stateChange: EventEmitter<NavStateChange> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public handleStateChange(state: NavStateChange): void {
    this.stateChange.emit(state);
  }
}
