<div class="info-modal flex column">
  <section class="info-modal__header flex row justify-center align-center">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <app-request-quick-card
      class="flex justify-content-center"
      [bookingDate]="bookingDate"
      [gigTitle]="gigTitle"
      [loggedUserName]="loggedUserName"
      [profileImage]="profileImage"
      [proName]="proName"
      [requestStatusLabel]="'Request sent!'"
    ></app-request-quick-card>
  </section>

  <section class="info-modal__content flex column align-center justify-start">
    <header class="">
      <p class="info-modal__title">We sent your booking request to {{ proName }}!</p>
    </header>
    <p class="info-modal__description">
      We’re currently implementing the full booking flow onto the web. In the meantime, please install the Jammcard app
      on your phone to access all the features and be able to negotiate with the pro :) In the app, you can also browse
      through our thousands of vetted music pros while you’re waiting to hear back.
    </p>
    <!--    <div class="booking-sent-modal__store-btns flex row">-->
    <!--      <a-->
    <!--        class="booking-sent-modal__store-btn"-->
    <!--        href="https://itunes.apple.com/us/app/jammcard/id879279559?mt=8"-->
    <!--        target="_blank"-->
    <!--        rel="noreferrer"-->
    <!--        ><img-->
    <!--          class="store-button__apple"-->
    <!--          src="../../../assets/images/logos/store-apple.png"-->
    <!--          alt="Download on App Store Button"-->
    <!--          title="Download on App Store Button"-->
    <!--      /></a>-->
    <!--      <a-->
    <!--        class="booking-sent-modal__store-btn"-->
    <!--        href="https://play.google.com/store/apps/details?id=com.jammcard&amp;hl=en_CA"-->
    <!--        target="_blank"-->
    <!--        rel="noreferrer"-->
    <!--        ><img-->
    <!--          class="store-button__google"-->
    <!--          src="../../../assets/images/logos/store-google.png"-->
    <!--          alt="Download on Google Play Store Button"-->
    <!--          title="Download on Google Play Button"-->
    <!--      /></a>-->
    <!--    </div>-->

    <div class="info-modal__btn-container auth-modal__send-btn-container">
      <app-button
        id="bookingSentModalButton"
        (clicked)="closeModal($event)"
        [buttonDisabled]="false"
        [buttonLabel]="'Done'"
        [buttonType]="'submit'"
      >
      </app-button>
    </div>
  </section>
</div>
