<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div id="bookModalInfo" class="auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">{{ 'BOOK.BOOK' | translate }} {{ bookModalData?.proName }}!</p>
    </header>
    <form class="auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm(bookingInfo)">
      <section class="book-modal__section">
        <p class="book-modal__headline uppercase">{{ 'BOOK.BOOKING_MUSIC_PRO' | translate }}</p>
        <app-avatar-group [avatarGroupData]="avatarGroupData"></app-avatar-group>
      </section>

      <hr />

      <section class="book-modal__section">
        <p class="book-modal__headline book-modal__headline__type uppercase">{{ 'BOOK.BOOKING_TYPE' | translate }}</p>
        <p class="book-modal__description">{{ 'BOOK.BOOKING_TYPE_DESCRIPTION' | translate }}</p>
        <div class="booking-type-group">
          <div class="custom-control custom-radio">
            <input
              id="bookingTypeLive"
              type="radio"
              class="custom-control-input"
              name="bookingType"
              [value]="bookingTypeLive | uppercase"
              [(ngModel)]="bookingInfo.serviceProvisionType"
              required
            />
            <label class="custom-control-label" for="bookingTypeLive">{{ 'BOOK.LIVE' | translate }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input
              id="bookingTypeRecording"
              type="radio"
              class="custom-control-input"
              name="bookingType"
              [value]="bookingTypeRecording | uppercase"
              [(ngModel)]="bookingInfo.serviceProvisionType"
              required
            />
            <label class="custom-control-label" for="bookingTypeRecording">{{ 'BOOK.RECORDING' | translate }}</label>
          </div>
        </div>
      </section>

      <hr />

      <section class="book-modal__section">
        <p class="book-modal__headline uppercase">{{ 'BOOK.BOOKING_TITLE' | translate }}</p>
        <textarea
          id="bookModalInputGigTitle"
          class="book-modal__gig-title book-modal__input-text"
          type="text"
          name="bookingGigTitle"
          #bookingGigTitle="ngModel"
          [(ngModel)]="bookingInfo.gigTitle"
          [placeholder]="bookingTitlePlaceholder"
          [maxlength]="255"
          [rows]="1"
          required
          autosize
        ></textarea>
        <p *ngIf="bookingInfo.gigTitle.length == 255" class="help-block">
          {{ 'BOOK.BOOKING_TITLE_NO_LONGER_THAN_255' | translate }}
        </p>
        <div *ngIf="bookingGigTitle.invalid && (bookingGigTitle.dirty || !bookingGigTitle.pristine)">
          <p *ngIf="bookingGigTitle.errors.required" class="help-block">
            {{ 'BOOK.BOOKING_TITLE_REQUIRED' | translate }}
          </p>
        </div>
      </section>

      <hr id="bookModalDateTitleSeparator" />

      <section class="book-modal__section">
        <p class="uppercase book-modal__headline">{{ 'BOOK.BOOKING_DATE' | translate }}</p>
        <div class="book-modal__booking-date-container flex row">
          <div
            class="book-modal__booking-date-input flex row"
            (click)="toggleDatepicker()"
            appClickOutside
            (clickOutside)="closeDatepicker()"
          >
            <span *ngIf="!dateSelected" class="book-modal__booking-date-label book-modal__input-text">
              {{ 'BOOK.CLICK_TO_SELECT_DATE' | translate }}</span
            >
            <span *ngIf="dateSelected">{{ Date | date: 'longDate' }}</span>
            <app-datepicker
              id="bookModalInputDate"
              [isOpen]="isOpen"
              [bookingDate]="Date"
              [isBookModalDatepicker]="true"
              (toggleDatepicker)="toggleDatepicker()"
              (dateChange)="changeDate($event)"
            ></app-datepicker>
          </div>
        </div>
      </section>

      <hr />

      <section class="book-modal__section">
        <p class="book-modal__headline uppercase">{{ 'BOOK.PAYMENT_OFFER' | translate }}</p>
        <div class="book-modal__payment-offer-container">
          <input
            id="bookModalInputPrice"
            class="book-modal__payment-offer book-modal__input-text"
            type="number"
            name="bookingPrice"
            #bookingPrice="ngModel"
            [(ngModel)]="bookingInfo.price"
            required
            (ngModelChange)="validatePrice($event)"
          />
        </div>
        <p *ngIf="bookingInfo.price > 900000" class="help-block">
          {{ 'BOOK.PAYMENT_OFFER_NO_HIGHER_THAN' | translate }}
        </p>
        <p *ngIf="bookingInfo.price == 0 || (bookingInfo.price && bookingInfo.price < 1)" class="help-block">
          {{ 'BOOK.PAYMENT_OFFER_NO_LOWER_THAN' | translate }}
        </p>
        <div *ngIf="bookingPrice.invalid && (bookingPrice.dirty || !bookingPrice.pristine)">
          <p *ngIf="bookingPrice.errors.required" class="help-block">{{ 'BOOK.PAYMENT_OFFER_REQUIRED' | translate }}</p>
        </div>
      </section>

      <hr />

      <section class="book-modal__section">
        <p class="book-modal__headline uppercase">{{ 'BOOK.MESSAGE' | translate }}</p>
        <textarea
          id="bookModalInputMessage"
          class="book-modal__input-text"
          type="text"
          name="bookingMessage"
          #bookingMessage="ngModel"
          [(ngModel)]="bookingInfo.message"
          [placeholder]="messagePlaceholder"
          [maxlength]="1500"
          [rows]="5"
          autosize
          required
          (ngModelChange)="scrollElementIntoView('bookModalInfo')"
        ></textarea>
        <p *ngIf="bookingInfo.message.length == 1500" class="help-block">
          {{ 'BOOK.MESSAGE_MUST_BE_LONGER' | translate }}
        </p>
        <div *ngIf="bookingMessage.invalid && (bookingMessage.dirty || !bookingMessage.pristine)">
          <p *ngIf="bookingMessage.errors.required" class="help-block">{{ 'BOOK.MESSAGE_REQUIRED' | translate }}</p>
          <p *ngIf="bookingMessage.errors.maxlength" class="help-block">
            {{ 'BOOK.MESSAGE_MUST_BE_LONGER' | translate }}
          </p>
        </div>
      </section>

      <hr />

      <section class="book-modal__section">
        <p class="book-modal__disclaimer book-modal__input-text">
          {{ 'BOOK.DISCLAIMER' | translate }}
        </p>
      </section>

      <div class="book-modal__send-btn-container">
        <app-button
          id="bookModalButton"
          [buttonDisabled]="!bookForm.valid || !dateSelected || bookingRequestSending"
          [buttonLabel]="buttonLabel"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
    </form>
  </div>
</app-jammcard-modal>
