<app-datepicker
  class="availability-datepicker"
  [isOpen]="true"
  [isAvailabilityDatepicker]="true"
  [disablePastMonths]="true"
  [availableDates$]="availableDates$"
  [enabled$]="enabled$"
  [preselect]="preselect"
  (dateChange)="changeDate($event)"
  (monthChange)="changeMonth($event)"
></app-datepicker>
