import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { RequestSentModalComponent } from '../request-sent-modal/request-sent-modal.component';
import { AuthenticationService, LocalStorageService, ToastService } from '../../../services';
import {
  AUTH_PASSWORD_LENGTH,
  FormValidator,
  RESET_PASS,
  USER_ID,
  checkIfTheSameString,
  isQuerySearchable,
  SSR_PLACEHOLDERS,
} from '../../../utils';
import { CHANGE_PASSWORD_VERIFICATION_MODAL_DATA } from './change-password.config';

interface ChangePassword {
  oldPass: string;
  newPass: string;
  newPassConfirm: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public buttonLabel: string;
  public changePasswordForm: FormGroup;
  public changeRequestSending: boolean;
  public checkIfTheSameString: any;
  public confirmNewPassPlaceholder: string;
  public currentPassCorrect = false;
  public currentPassPlaceholder: string;
  public hideCurrentPassField: boolean;
  public isQuerySearchable: any;
  public newPassPlaceholder: string;
  public password: ChangePassword;
  public validPassword$: Subscription;
  public readonly changePasswordSsrPlaceholder: string = SSR_PLACEHOLDERS.SETTINGS.CHANGE_PASSWORD;
  public readonly currentPasswordSsrPlaceholder: string = SSR_PLACEHOLDERS.SETTINGS.CURRENT_PASSWORD;
  public readonly confirmNewPasswordSsrPlaceholder: string = SSR_PLACEHOLDERS.SETTINGS.CONFIRM_NEW_PASSWORD;
  public readonly newPasswordSsrPlaceholder: string = SSR_PLACEHOLDERS.SETTINGS.NEW_PASSWORD;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private localStorage: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private toastService: ToastService,
    public translate: TranslateService,
  ) {
    this.password = {
      oldPass: '',
      newPass: '',
      newPassConfirm: '',
    };
    this.checkIfTheSameString = checkIfTheSameString;
    this.isQuerySearchable = isQuerySearchable;
  }

  ngOnInit(): void {
    this.translate.get('SETTINGS').subscribe((res: any) => {
      this.currentPassPlaceholder = res.ENTER_CURRENT_PASSWORD;
      this.newPassPlaceholder = res.ENTER_NEW_PASSWORD;
      this.confirmNewPassPlaceholder = res.CONFIRM_PASSWORD;
      this.buttonLabel = res.CHANGE_PASSWORD;
    });

    this.changePasswordForm = this.formBuilder.group(
      {
        oldPass: [
          '',
          {
            validators: [Validators.required],
          },
        ],
        newPass: [
          '',
          {
            validators: [Validators.required, Validators.minLength(AUTH_PASSWORD_LENGTH)],
            updateOn: 'blur',
          },
        ],
        newPassConfirm: [
          '',
          {
            validators: [Validators.required, Validators.minLength(AUTH_PASSWORD_LENGTH)],
            updateOn: 'change',
          },
        ],
      },
      { validators: FormValidator.passwordsMatch },
    );

    this.checkIfCurrentPasswordValid();
    this.hideCurrentPassField = !!this.localStorage.get(RESET_PASS);
    this.currentPassCorrect = this.hideCurrentPassField;
  }

  public checkIfCurrentPasswordValid(): void {
    this.validPassword$ = this.oldPass.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((query: string) => query?.length >= 1),
      )
      .subscribe((value) => {
        this.authenticationService.checkIfCurrentPassValid(this.oldPass.value).subscribe(
          (response) => {
            if (response.status === 200) {
              this.currentPassCorrect = true;
              return;
            }
          },
          (error) => {
            if (error.status === 400) {
              this.oldPass.setErrors({ notCorrect: true });
              this.currentPassCorrect = false;
            } else {
              this.currentPassCorrect = false;
              this.toastService.showMessage('Something went wrong');
            }
          },
        );
      });
  }
  public get oldPass(): FormControl {
    return this.changePasswordForm.get('oldPass') as FormControl;
  }

  public get newPass(): FormControl {
    return this.changePasswordForm.get('newPass') as FormControl;
  }

  public get newPassConfirm(): FormControl {
    return this.changePasswordForm.get('newPassConfirm') as FormControl;
  }

  //TODO(Mladen):Wait for clients to make up their mind on the topic of Toastr vs Modal
  public openSuccessModal(): void {
    this.validPassword$.unsubscribe();
    const modalInstance = this.modalService.open(RequestSentModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });
    const requestSentModalData = CHANGE_PASSWORD_VERIFICATION_MODAL_DATA;
    modalInstance.componentInstance.fromParent = { requestSentModalData };
  }

  submitForm(event): any {
    if (!this.currentPassCorrect && !this.hideCurrentPassField) {
      this.oldPass.markAsDirty();
      this.oldPass.setErrors({ notCorrect: true });
    } else {
      this.changeRequestSending = true;
      this.authenticationService
        .resetPassword(this.oldPass.value, this.newPass.value, this.newPassConfirm.value)
        .subscribe(
          (response: any) => {
            if (response.status === 200) {
              this.changeRequestSending = false;
              this.toastService.showMessage('Password successfully changed.');
              this.localStorage.delete(RESET_PASS);
              const loggedUserId = this.localStorage.get(USER_ID);
              // Removed because of client request
              // this.router.navigate([loggedUserId]);
            }
          },
          (error) => {
            this.changeRequestSending = false;
            if (error.status === 401) {
              this.toastService.showMessage('Something went wrong');
            } else if (error.status === 422) {
              if (!this.newPass.value) {
                this.newPass.markAsDirty();
              } else if (!this.newPassConfirm.value) {
                this.newPassConfirm.markAsDirty();
              } else if (error.error?.fieldErrors.some((err) => err.errorType === 'passwordLengthNotSatisfied')) {
                if (
                  error.error.fieldErrors.find(
                    (err) => err.errorType === 'passwordLengthNotSatisfied' && err.fieldName === 'newPassword',
                  )
                ) {
                  this.newPass.markAsDirty();
                  this.newPass.setErrors({ minlength: true });
                } else if (
                  error.error.fieldErrors.find(
                    (err) => err.errorType === 'passwordLengthNotSatisfied' && err.fieldName === 'confirmPassword',
                  )
                ) {
                  this.newPassConfirm.markAsDirty();
                  this.newPassConfirm.setErrors({ minlength: true });
                }
              }
            } else {
              this.toastService.showMessage('Something went wrong');
            }
          },
        );
    }
  }

  public ngOnDestroy(): void {
    this.validPassword$.unsubscribe();
  }
}
