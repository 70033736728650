import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, of, pipe } from 'rxjs';
import { isNil } from 'lodash-es';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../app.reducers';
import { FastBookingActions } from './fast-booking.action-types';
import { ProfileService, ToastService } from '../../services';
import { MODAL_DISMISS_REASON } from '../../utils';
import { selectAvailabilityDaysForRateAndMonth, selectFastBookingProTruncatedProfile } from './fast-booking.selectors';
import { BookModalFastCheckoutComponent } from '../../components/organisms/book-modal-fast-checkout/book-modal-fast-checkout.component';

@Injectable()
export class FastBookingEffects {
  public loadTruncatedProfile = createEffect(() => {
    return this.actions$.pipe(
      ofType(FastBookingActions.loadTruncatedProfile),
      filter((action) => !isNil(action.id)),
      switchMap((action) =>
        combineLatest([this.store.select(selectFastBookingProTruncatedProfile), of(action)]).pipe(take(1)),
      ),
      switchMap((data) => {
        const truncatedProfile = data[0];
        const action = data[1];
        if (
          isNil(truncatedProfile) ||
          (action.id.toLowerCase() !== truncatedProfile?.username.toLowerCase() &&
            action.id !== truncatedProfile.profileId.toString())
        ) {
          return this.profileService.getTruncatedProfile(action.id).pipe(
            map((res) =>
              FastBookingActions.truncatedProfileLoaded({
                id: action.id,
                proProfile: res.body,
              }),
            ),
            catchError(() =>
              this.translateService.get('TOAST_MESSAGE').pipe(
                tap((res) => {
                  this.toastService.showMessage(res.SOMETHING_WENT_WRONG);
                }),
                map(() => FastBookingActions.truncatedProfileLoadingFailed()),
              ),
            ),
          );
        } else {
          return of(
            FastBookingActions.truncatedProfileLoaded({
              id: action.id,
              proProfile: truncatedProfile,
            }),
          );
        }
      }),
    );
  });

  public loadAvailabileDays = createEffect(() =>
    this.actions$.pipe(
      ofType(FastBookingActions.loadAvailableDays),
      switchMap((action) => {
        return combineLatest([
          this.store.select(selectAvailabilityDaysForRateAndMonth(action.rate, action.month)),
          of(action),
        ]).pipe(take(1));
      }),
      switchMap((data) => {
        const availableDays = data[0];
        const action = data[1];

        if (isNil(availableDays)) {
          return this.profileService
            .getAvailableDays(
              action.startTime,
              action.days,
              action.rate.rateSetting.id,
              action.rate.serviceProvisionId || action.serviceId,
            )
            .pipe(
              map((res) =>
                FastBookingActions.availableDaysLoaded({
                  availableDays: res.body.availableDays,
                  rateId: action.rate.id,
                  month: action.month,
                }),
              ),
              catchError(() =>
                this.translateService.get('TOAST_MESSAGE').pipe(
                  tap((res) => {
                    this.toastService.showMessage(res.SOMETHING_WENT_WRONG);
                  }),
                  map(() =>
                    FastBookingActions.availableDaysLoadingFailed({
                      availableDays: null,
                      rateId: action.rate.id,
                      month: action.month,
                    }),
                  ),
                ),
              ),
            );
        } else {
          return of(
            FastBookingActions.availableDaysLoaded({ availableDays, rateId: action.rate.id, month: action.month }),
          );
        }
      }),
    ),
  );

  public loadAvailabilitySlots = createEffect(() =>
    this.actions$.pipe(
      ofType(FastBookingActions.loadAvailableSlots),
      switchMap((action) =>
        this.profileService.getAvailableSlots(action.startTime, 1, action.serviceProvisionId).pipe(
          map((res) => FastBookingActions.availableSlotsLoaded({ availableSlots: res.body.availabilitySlots })),
          catchError(() =>
            this.translateService.get('TOAST_MESSAGE').pipe(
              tap((res) => {
                this.toastService.showMessage(res.SOMETHING_WENT_WRONG);
              }),
              map(() => FastBookingActions.availableSlotsLoadingFailed({ availableSlots: null })),
            ),
          ),
        ),
      ),
    ),
  );

  public lockAvailabilitySlots = createEffect(() =>
    this.actions$.pipe(
      ofType(FastBookingActions.lockAvailabilitySlots),
      switchMap((action) =>
        this.profileService.lockAvailableSlots(action.availabilitySlotsIds, action.rateId).pipe(
          map((res) => {
            return FastBookingActions.availabilitySlotsLocked({
              lockedSlots: res.body,
              rateId: action.rateId,
              backUrl: action.backUrl,
              openedFromWebView: action.openedFromWebView,
            });
          }),
          catchError((error) =>
            this.translateService.get('TOAST_MESSAGE').pipe(
              tap((res) => {
                let errorMessage = res.SOMETHING_WENT_WRONG;
                let errorClass = 'black';

                if (error?.status === 400) {
                  errorMessage = res.SEEMS_THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE;
                  errorClass = 'error';
                } else if (error?.status === 403) {
                  errorMessage = res.SEEMS_THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE;
                  errorClass = 'error';
                } else if (error?.status === 412) {
                  errorMessage = res.SEEMS_THE_SELECTED_RATE_IS_NO_LONGER_AVAILABLE;
                  errorClass = 'error';
                }
                this.toastService.showMessage(errorMessage, errorClass);
              }),
              map(() => FastBookingActions.availabilitySlotsLockingFailed()),
            ),
          ),
        ),
      ),
    ),
  );

  public lockAvailabilitySlotsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FastBookingActions.availabilitySlotsLocked),
        map((action) => {
          return {
            backUrl: action.backUrl,
            lockedSlots: action.lockedSlots,
            rateId: action.rateId,
            openedFromWebView: action.openedFromWebView,
          };
        }),
        pipe(withLatestFrom(this.store.select(selectFastBookingProTruncatedProfile))),
        map(([lockedAvailabilitySlotsWithBackUrl, truncatedProfile]) => {
          this.modalService.dismissAll(MODAL_DISMISS_REASON.FAST_BOOKING_CHECKOUT);
          const modalInstance = this.modalService.open(BookModalFastCheckoutComponent, {
            windowClass: 'mentorship-modal-window fast-checkout-modal-window',
            backdrop: 'static',
          });
          modalInstance.componentInstance.fromParent = {
            truncatedProfile,
            lockedAvailabilitySlots: lockedAvailabilitySlotsWithBackUrl.lockedSlots,
            rateId: lockedAvailabilitySlotsWithBackUrl.rateId,
            backUrl: lockedAvailabilitySlotsWithBackUrl.backUrl,
            openedFromWebView: lockedAvailabilitySlotsWithBackUrl.openedFromWebView,
          };
          modalInstance.result.then(
            () => {
              this.store.dispatch(FastBookingActions.clearFastBooking());
              this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
              return this.router.navigateByUrl(lockedAvailabilitySlotsWithBackUrl.backUrl);
            },
            (reason: string) => {
              if (reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH) {
                this.store.dispatch(FastBookingActions.clearFastBooking());
                this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
                return this.router.navigateByUrl(lockedAvailabilitySlotsWithBackUrl.backUrl);
              } else {
                return null;
              }
            },
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private modalService: NgbModal,
    private profileService: ProfileService,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private toastService: ToastService,
  ) {}
}
