import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { InfoService } from '../../../services';
import { EVENT_NAME_PREFIX } from '../../../utils';
import { PAYMENT_METHOD_ID_PLACEHOLDER } from '../../../pages/bookings/bookings.config';
import { PaymentCard, PaymentMethodType } from '../../../models';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-payment-card',
  templateUrl: './add-payment-card.component.html',
  styleUrls: ['./add-payment-card.component.scss'],
})
export class AddPaymentCardComponent implements OnInit, OnDestroy {
  @Input() addNewPaymentMethod$: Subject<string>;
  @Input() cards: PaymentCard[];
  @Input() cards$: BehaviorSubject<PaymentCard[]>;
  @Input() preselectCard: string;
  @Input() start3DSecure$: Subject<any>;
  @Input() viewOnly: boolean = false;
  @Output() confirmWithNewPaymentMethod = new EventEmitter<any>();
  @Output() paymentMethodChanged = new EventEmitter<any>();
  @Output() processingConfirmationFinished = new EventEmitter<any>();
  @Output() threeDSecureSuccess = new EventEmitter<any>();
  @Output() scrollToBottom = new EventEmitter<void>();

  public addNewPaymentMethodSelected: boolean = false;
  public cardNumberError: string;
  public cardExpDateError: string;
  public cardCvcError: string;
  public clearCardForm$ = new Subject<boolean>();

  constructor(@Inject(DOCUMENT) private document: Document, private infoService: InfoService) {}

  ngOnInit(): void {
    this.cards$.pipe(filter((cards) => !!cards)).subscribe((cards) => {
      if (!cards?.length) {
        this.selectAddNewPaymentMethodButton();
      } else {
        const newlyAddedCard = cards.find((card) => card.cardId === this.preselectCard);
        if (newlyAddedCard) {
          this.addNewPaymentMethodSelected = false;
          this.clearCardForm$.next(true);
          this.paymentMethodChanged.emit({
            paymentMethodId: newlyAddedCard.cardId,
            paymentMethodType: PaymentMethodType.CARD,
          });
        }
      }
    });
  }

  ngOnDestroy(): void {}

  /* For now only cards as payment method are taken into consideration, bank accounts will have bancAccountId instead of cardId field*/
  public onRadioChange(event: any, card: any): void {
    if (event.target.checked) {
      if (!card) {
        this.selectAddNewPaymentMethodButton();
      } else {
        this.addNewPaymentMethodSelected = false;
        this.clearCardForm$.next(true);
        this.paymentMethodChanged.emit({ paymentMethodId: card.cardId, paymentMethodType: PaymentMethodType.CARD });
        this.scrollToBottom.emit();
      }
    }
  }

  public selectAddNewPaymentMethodButton(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, { element: 'add-new-payment-method' });
    this.addNewPaymentMethodSelected = true;
    this.paymentMethodChanged.emit({
      paymentMethodId: PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD,
      paymentMethodType: PaymentMethodType.CARD,
    });
    this.scrollToBottom.emit();
  }

  public handleConfirmWithNewPaymentMethod(event): void {
    this.confirmWithNewPaymentMethod.emit({
      paymentMethodId: event.paymentMethodId,
      paymentMethodType: event.paymentMethodType,
    });
  }

  public handleThreeDSecureSuccess(event): void {
    this.threeDSecureSuccess.emit({
      paymentIntentId: event.paymentIntentId,
      paymentMethodId: event.paymentMethodId,
      paymentMethodType: event.paymentMethodType,
      bookingId: event.bookingId,
    });
  }

  public handleProcessingConfirmationFinished(e: any): void {
    this.processingConfirmationFinished.emit(e);
  }
}
