import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, finalize, tap, switchMap } from 'rxjs/operators';
import { Intercom } from 'ng-intercom';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { LocalStorageService } from './local-storage.service';
import { ContactSupportReasonRequestParam, User } from '../models';
import {
  COOKIE_CONSENT_GIVEN,
  SERVICE_URL,
  USER_EMAIL,
  USER,
  USER_NAME,
  DISTINCT_UUID,
  reloadPage,
  SERVICE_BOOKING_GUEST,
  AFFILIATE_CAMPAIGN_DATA,
  VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID,
  INITIAL_REFERRER,
} from '../utils';
import { AppState } from '../store/app.reducers';
import { AuthActions } from '../store/auth/action-types';
import { NotificationService } from './notification.service';
import { BookingActions } from '../store/booking/action-types';
import { TempStorageService } from './temp-storage.service';

const dummyRegisterObj = {
  phoneNumber: null,
  gender: null,
  city: 'Los Angeles',
  location: {
    lat: 34.05,
    lon: -118.24,
  },
  intention: 'BOOKER',
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    public intercom: Intercom,
    private tempStorageService: TempStorageService,
  ) {
    this.userSubject = new BehaviorSubject<User>(this.localStorage.get(USER));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public checkIfCurrentPassValid(currentPassword): Observable<any> {
    return this.http.post<any>(
      `${SERVICE_URL.USER}api/users/password`,
      {
        currentPassword,
      },
      {
        observe: 'response',
      },
    );
  }

  // TODO (Milan): Refactor this to separate additional logic into NgRx effects
  // uuid argument refers to temporary storage identifier, anonymousDistinctId is v4 uuid generated for analytics
  login(email: string, password: string, uuid?: string): any {
    const anonymousDistinctId = this.localStorage.get(DISTINCT_UUID);
    return this.http
      .post<any>(`${SERVICE_URL.USER}auth/v2/login`, { email, password, anonymousDistinctId }, { observe: 'response' })
      .pipe(
        map((response) => {
          // this.localStorage.add(USER, response.body);
          this.store.dispatch(AuthActions.authUpdate({ user: response.body }));
          this.localStorage.add(USER_EMAIL, email);
          this.userSubject.next(response.body);

          return response;
        }),
        switchMap((response) => {
          if (uuid) {
            return this.tempStorageService.fetchData(uuid).pipe(
              map((tempDataResponse) => {
                if (tempDataResponse && tempDataResponse?.status === 200) {
                  this.localStorage.add(SERVICE_BOOKING_GUEST, tempDataResponse.body);
                }
                return response;
              }),
            );
          } else {
            return of(response);
          }
        }),
        map((response) => response),
      );
  }

  logout(): any {
    const fireBaseToken = this.notificationService.token;
    return this.http
      .post(
        `${SERVICE_URL.USER}api/users/logout`,
        { identifier: fireBaseToken, service: 'WEB' },
        {
          observe: 'response',
        },
      )
      .pipe(finalize(() => this.removeUserInfoFromStorage()));
  }

  removeUserInfoFromStorage(): void {
    // remove user from local storage to log user out
    const cookieConsentGiven = this.localStorage.get(COOKIE_CONSENT_GIVEN);
    const affiliateCampaignData = this.localStorage.get(AFFILIATE_CAMPAIGN_DATA);
    const initialReferrerData = this.localStorage.get(INITIAL_REFERRER);
    // save the cookie consent value and set it after clearing the store
    const videoDeviceId = this.localStorage.get(VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID);
    this.store.dispatch(BookingActions.deleteAllBookings());
    this.localStorage.clear();
    // TODO(jovana): clear entire store
    this.store.dispatch(AuthActions.userShortLoaded({ user: null }));
    this.store.dispatch(AuthActions.authUpdateSuccess());
    this.localStorage.add(COOKIE_CONSENT_GIVEN, cookieConsentGiven);
    this.localStorage.add(AFFILIATE_CAMPAIGN_DATA, affiliateCampaignData);
    this.localStorage.add(VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID, videoDeviceId);
    this.localStorage.add(DISTINCT_UUID, uuidv4());
    if (!!initialReferrerData) {
      this.localStorage.add(INITIAL_REFERRER, initialReferrerData);
    }
    this.userSubject.next(null);
    this.intercom.shutdown();
    reloadPage(this.router);
  }

  signup(
    fullName: string,
    email: string,
    password: string,
    book: boolean,
    proProfileUsername?: string,
    storageUUID: string = null,
  ): any {
    return this.http
      .post<any>(
        `${SERVICE_URL.USER}auth/v2/signup/without-code/web`,
        {
          fullName,
          email,
          password,
          book,
          // proProfileId,
          proProfileUsername,
          storageUUID,
          ...dummyRegisterObj,
        },
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.localStorage.add(USER, response.body);
          this.localStorage.add(USER_EMAIL, email);
          this.localStorage.add(USER_NAME, fullName);
          this.userSubject.next(response.body);
          return response;
        }),
      );
  }

  refreshToken(accessToken: string, refreshToken: string): Observable<any> {
    return this.http
      .post<any>(`${SERVICE_URL.USER}auth/refresh-token`, {
        access_token: accessToken,
        refresh_token: refreshToken,
      })
      .pipe(
        tap((response) =>
          this.userSubject.next({
            ...this.userValue,
            access_token: response.access_token,
            refresh_token: response.refresh_token,
          }),
        ),
        //TODO(Mladen): Delete if refresh token is working
        // tap((response) => {
        //   const roles = this.localStorage.get(USER)?.roles;
        //   this.localStorage.add(USER, { roles, ...response });
        // }),
      );
  }

  setAuthTokens(user: User): User {
    const roles = this.localStorage.get(USER)?.roles;
    this.localStorage.add(USER, { roles, ...user });
    this.userSubject.next(user);
    return user;
  }

  getAuthTokens(): any {
    return this.localStorage.get(USER);
  }

  isLoggedIn(): boolean {
    const user = this.localStorage.get(USER);
    // @ts-ignore
    return user && user.access_token;
  }

  public resetPassword(oldPass: string, newPass: string, confirmPass: string): Observable<any> {
    return this.http.put<any>(
      `${SERVICE_URL.USER}api/users/password`,
      {
        newPassword: newPass,
        confirmPassword: confirmPass,
      },
      {
        observe: 'response',
      },
    );
  }

  public resendVerificationEmail(resendVerificationEmailBody: any): Observable<unknown> {
    return this.http.post<unknown>(`${SERVICE_URL.USER}auth/verification_email/resend`, resendVerificationEmailBody, {
      observe: 'response',
    });
  }

  public requestNewPassword(email: string): Observable<any> {
    return this.http.post<any>(
      `${SERVICE_URL.USER}auth/forgot_password`,
      {
        email,
      },
      {
        observe: 'response',
      },
    );
  }

  public contactSupport({
    bookingSymbolicId,
    contactReason,
    email,
    message,
  }: {
    bookingSymbolicId: string;
    contactReason: ContactSupportReasonRequestParam;
    email: string;
    message: string;
  }): Observable<any> {
    return this.http.post<any>(
      `${SERVICE_URL.USER}api/users/contact-support`,
      {
        bookingSymbolicId,
        contactReason,
        email,
        message,
      },
      {
        observe: 'response',
      },
    );
  }
}
