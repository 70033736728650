import { FormGroup, ValidationErrors } from '@angular/forms';

type ValidatorControl = (control: FormGroup) => ValidationErrors | null;
type ValidatorNames = 'passwordsMatch';
type FormValidator = {
  [key in ValidatorNames]: ValidatorControl;
};

export const FormValidator: FormValidator = {
  passwordsMatch: (control) => {
    const newPass = control.get('newPass');
    const newPassConfirm = control.get('newPassConfirm');

    return newPass &&
      newPassConfirm &&
      newPass.value &&
      newPassConfirm.value &&
      newPass.value !== newPassConfirm.value
      ? { passwordsMatch: true }
      : null;
  },
};
