import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveFiltersModule } from '../../atoms/active-filters/active-filters.module';

import { LocationFilterComponent } from './location-filter.component';

@NgModule({
  declarations: [LocationFilterComponent],
  imports: [CommonModule, NgbAccordionModule, NgbDropdownModule, TranslateModule, ActiveFiltersModule],
  exports: [LocationFilterComponent],
})
export class LocationFilterModule {}
