import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationSettingsComponent } from './notification-settings.component';
import { NotificationSettingsMenuModule } from '../../molecules/notification-settings-menu/notification-settings-menu.module';
import { NotificationSettingsSubmenuModule } from '../../molecules/notification-settings-submenu/notification-settings-submenu.module';
import { NotificationSettingsTabGroupModule } from '../../molecules/notification-settings-tab-group/notification-settings-tab-group.module';

@NgModule({
  declarations: [NotificationSettingsComponent],
  imports: [
    CommonModule,
    NotificationSettingsMenuModule,
    NotificationSettingsSubmenuModule,
    NotificationSettingsTabGroupModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [NotificationSettingsComponent],
})
export class NotificationSettingsModule {}
