import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { CurrentUserShort, User } from '../../../models';
import { AuthActions } from '../action-types';

export interface AuthState {
  currentUserShort: CurrentUserShort;
}

export const initialAuthState: AuthState = {
  currentUserShort: null,
};

const _authReducer = createReducer(
  initialAuthState,
  on(AuthActions.userShortLoaded, (state, action) => {
    return { ...state, currentUserShort: action.user };
  }),
  on(AuthActions.userShortReloaded, (state, action) => {
    return { ...state, currentUserShort: action.user };
  }),
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}

export const reducers: ActionReducerMap<any> = {
  user: authReducer,
};
