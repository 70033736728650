<div class="video-settings-modal__container">
  <img class="video-settings-modal__close" [src]="closeIcon" (click)="closeModal()" alt="Close icon" />
  <div class="video-settings__menu">
    <h6 class="video-settings__menu-title">Settings</h6>
    <div class="video-settings__menu-option-wrapper">
      <div
        class="video-settings__menu-option audio-option"
        [ngClass]="{ selected: audioTabSelected }"
        (click)="onAudioTabClick()"
      >
        <img [src]="speakerIcon" alt="Speaker" />
        <span class="video-settings__menu-option-title"> Audio </span>
      </div>
    </div>
    <div class="video-settings__menu-option-wrapper">
      <div
        class="video-settings__menu-option video-option"
        [ngClass]="{ selected: videoTabSelected }"
        (click)="onVideoTabClick()"
      >
        <img [src]="cameraIcon" alt="Camera" />
        <span class="video-settings__menu-option-title"> Video </span>
      </div>
    </div>
  </div>
  <div class="video-settings__options-content">
    <ng-container *ngIf="audioTabSelected" class="video-settings__audio">
      <div class="video-settings__input-wrapper">
        <div class="video-settings__input">
          <span class="video-settings__input-title">Microphone</span>
          <ng-select
            class="video-settings__select"
            (change)="onAudioInputChange($event)"
            [(ngModel)]="localAudioInputDeviceId"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option *ngFor="let device of audioInputDevices" [value]="device.deviceId">
              {{ device.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div *ngIf="!outsideRoomSettings && localAudioOutputDeviceId" class="video-settings__input-wrapper">
        <div class="video-settings__input">
          <span class="video-settings__input-title">Audio</span>
          <ng-select
            class="video-settings__select"
            (change)="onAudioOutputChange($event)"
            [(ngModel)]="localAudioOutputDeviceId"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option *ngFor="let device of audioOutputDevices" [value]="device.deviceId">
              {{ device.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="videoTabSelected" class="video-settings__video">
      <div class="video-settings__input-wrapper">
        <div class="video-settings__input">
          <span class="video-settings__input-title">Camera</span>
          <ng-select
            class="video-settings__select"
            (change)="onVideoInputChange($event)"
            [(ngModel)]="localVideoInputDeviceId"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option *ngFor="let device of videoInputDevices" [value]="device.deviceId">
              {{ device.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </ng-container>
  </div>
</div>
