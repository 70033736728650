import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map, filter, switchMap } from 'rxjs/operators';
import { isNil } from 'lodash-es';

import { AuthActions } from '../auth/action-types';
import { NotificationService } from '../../services';
import { NotificationsActions } from './action-types';

@Injectable()
export class NotificationsEffects {
  public initFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userShortLoaded),
      filter((action) => !isNil(action.user)),
      switchMap(() => this.notificationService.requestPermission()),
      // TODO (Milan): left this code since the new implementation should be tested
      // and improved if possible
      // tap((token) => (this.notificationService.token = token)),
      // switchMap((token) => this.notificationService.authFirebase(token)),
      map(() => this.notificationService.receiveMessage()),
      map(() => NotificationsActions.firebaseConnected()),
    ),
  );

  public newNotificationReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.newNotificationReceived),
      map((action) => action.notification.data),
      map((notification) => {
        return NotificationsActions.setBookingCount({ count: parseInt(notification.numOfUnreadBookings) });
      }),
    ),
  );

  constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
