import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { reducers, metaReducers } from './app.reducers';
import { CustomSerializer } from './router/custom-router.serializer';
import { AuthEffects } from './auth/auth.effects';
import { FastBookingEffects } from './fast-booking/fast-booking.effects';
import { MessengerEffects } from './messenger/messenger.effects';
import { NotificationsEffects } from './notifications/notifications.effects';
import { extModules } from '../environments/build-specifics/redux-dev-tools';

//TODO(Mladen): Work in progress. Think of a better way to handle EntityDataModule default config.
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true,
      },
    }),
    EffectsModule.forRoot([AuthEffects, FastBookingEffects, MessengerEffects, NotificationsEffects]),
    EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: CustomSerializer,
    }),
    extModules,
  ],
})
export class AppStoreModule {}
