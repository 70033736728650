<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div id="bookModalInfo" class="auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">
        {{ avatarGroupData?.proName }} {{ 'BOOK.IS_REQUESTING_TO_BOOK_YOU' | translate }}
      </p>
    </header>
    <form class="auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm()">
      <app-book-modal-section
        [sectionTitle]="bookingIdNumberTitle"
        [sectionText]="bookModalPendingRequestData?.assignedId"
      >
      </app-book-modal-section>

      <hr />

      <app-book-modal-section
        *ngIf="bookModalPendingRequestData?.bookedServiceType"
        [sectionTitle]="serviceProvisionType"
        [sectionText]="bookModalPendingRequestData?.bookedServiceType | titlecase"
      >
      </app-book-modal-section>

      <hr *ngIf="bookModalPendingRequestData?.bookedServiceType" />

      <app-book-modal-section [sectionTitle]="gigTitleTitle" [sectionText]="bookModalPendingRequestData?.gigTitle">
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="bookerTitle">
        <app-avatar-group [avatarGroupData]="avatarGroupData" [isClickable]="true"></app-avatar-group
      ></app-book-modal-section>
      <hr />

      <app-book-modal-section
        [sectionTitle]="requestedBookingDateTitle"
        [sectionText]="bookModalPendingRequestData?.dates[0] | date: 'EEEE, MMMM d'"
      >
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="paymentUponConformation">
        <app-payment-details
          [bookingPrice]="bookModalPendingRequestData?.price"
          [serviceFeePrice]="bookModalPendingRequestData?.sellersFee"
          [isSeller]="true"
        ></app-payment-details>
      </app-book-modal-section>

      <hr />

      <section class="book-modal__section">
        <p class="book-modal-pending-request__disclaimer book-modal__disclaimer book-modal__input-text">
          {{ 'BOOK.BY_ACCEPTING_THIS_REQUEST_I_AGREE_TO_THE' | translate
          }}<a
            class="auth-modal__disclaimer--link"
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            rel="noreferrer"
            target="_blank"
          >
            {{ 'BOOK.CANCELLATION_POLICY' | translate }}</a
          >,
          <a
            class="auth-modal__disclaimer--link"
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            rel="noreferrer"
            target="_blank"
            >{{ 'BOOK.REFUND_POLICY' | translate }}
          </a>
          {{ 'BOOK.AND_THE_JAMMCARD_BOOKINGS' | translate }}
          <a
            class="auth-modal__disclaimer--link"
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            rel="noreferrer"
            target="_blank"
          >
            {{ 'BOOK.TERMS_OF_SERVICE' | translate }}.&nbsp;
          </a>
          {{ 'BOOK.I_ALSO_AGREE_TO_THE_PAYOUT_AMOUNT' | translate }}
        </p>
      </section>

      <div class="book-modal__send-btn-container">
        <app-button
          id="bookModalButton"
          [buttonDisabled]="!bookForm.valid || processingConfirmation"
          [buttonLabel]="buttonLabel"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
    </form>
  </div>
</app-jammcard-modal>
