import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { isNil } from 'lodash-es';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BasicProfile, BookingRole } from '../../../models';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BookModalAddPayoutComponent } from './book-modal-add-payout.component';
import { BOOKING_STATUS_RESPONSE } from '../../../pages/bookings/bookings.config';
import { ProfileService, ToastService } from '../../../services';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';

@Component({
  template: '',
})
export class BookModalAddPayoutEntryComponent implements OnInit, OnDestroy {
  public modalInstance: any;
  public bookModalData: any;
  public modal$: Subscription;
  public sellerInfo$: Subscription;
  public router$: Subscription;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private router: Router,
    private profileService: ProfileService,
    private toastService: ToastService,
  ) {}

  openModal(booking: BookingCurrent, user: BasicProfile): void {
    this.modalInstance = this.modalService.open(BookModalAddPayoutComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = { booking, user };
    this.modalInstance.result.then(
      () => this.router.navigate(['bookings']),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH ? this.router.navigate(['bookings']) : null;
      },
    );
  }

  ngOnInit() {
    //TODO(Mladen): Switch double sub to switchMap concatLatestFrom operators. Napravi action/effect za load basicprofila prema ID-u
    this.modal$ = this.store.select(selectCurrentBooking).subscribe((booking) => {
      if (
        isNil(booking.role) ||
        isNil(booking.booking) ||
        booking.booking?.id !== window.history.state?.pendingBookingNavigatedFrom ||
        booking.booking?.status !== BOOKING_STATUS_RESPONSE.PENDING ||
        booking.role !== BookingRole.SELLER
      ) {
        this.showMessage('Action not allowed!');
        this.router.navigate(['bookings']);
      } else {
        const profileId = booking.booking.booker.profileId;
        this.profileService.getBasicProfile(profileId).subscribe((user) => this.openModal(booking, user.body));
      }
    });
  }

  ngOnDestroy() {
    this.modal$.unsubscribe();
    //this.router$.unsubscribe();
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
