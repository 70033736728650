<div class="view-payment-card flex column">
  <h3 class="view-payment-card__title">Card ending in {{ (cardInfo$ | async)?.last4 }}</h3>
  <div class="view-payment-card__section view-payment-card__number">
    <label class="view-payment-card__label">
      CARD NUMBER
      <div class="view-payment-card__info">{{ (cardInfo$ | async)?.last4 | hideCardInfo }}</div>
    </label>
  </div>
  <div class="view-payment-card__section view-payment-card__section--flex flex">
    <div class="view-payment-card__expiry">
      <label class="view-payment-card__label">
        EXP. DATE
        <div class="view-payment-card__info">
          {{ (cardInfo$ | async)?.expMonth | number: '2.0' }}/{{ (cardInfo$ | async)?.expYear }}
        </div>
      </label>
    </div>
    <div class="view-payment-card__cvc">
      <label class="view-payment-card__label">
        {{ (cardInfo$ | async)?.securityCodeLabel }}
        <div class="view-payment-card__info">
          {{ (cardInfo$ | async).securityCodeLength | securityCode }}
        </div>
      </label>
    </div>
  </div>
  <div class="view-payment-card__section view-payment-card__zip">
    <label class="view-payment-card__label">
      ZIP CODE
      <div class="view-payment-card__info" type="text">{{ (cardInfo$ | async)?.zipCode || 'N/A' }}</div>
    </label>
  </div>
</div>
