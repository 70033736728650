import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as FileSaver from 'file-saver';
import * as Clipboard from 'clipboard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { BookingByStatus, BookingModal } from '../../../models';
import { BookModalData } from '../book-modal/book-modal.component';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { BookingService, ProfileService } from '../../../services';
import { createBookModalData, createAvatarGroupData } from '../../../utils';
import { ToastService } from '../../../services';

@Component({
  selector: 'app-book-modal-confirmed',
  templateUrl: './book-modal-confirmed.component.html',
  styleUrls: ['./book-modal-confirmed.component.scss'],
})
export class BookModalConfirmedComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'host-fill-height';

  public bookModalConfirmedRequestData: BookingByStatus;
  public bookModalData: BookModalData;
  public avatarGroupData: IAvatarGroupData;
  public buttonLabel: string;
  public isSeller: boolean;
  public fromParent: BookingModal;
  public priceTotal: number;

  public avatarGroupTitle: string;
  public bookerTitle: string;
  public bookingDatesTitle: string;
  public bookingIdNumberTitle: string;
  public bookingMusicProTitle: string;
  public bookingServiceTypeTitle: string;
  public bookingTitlePlaceholder: string;
  public contactSupportTitle: string;
  public gigTitleTitle: string;
  public manageBookingTitle: string;
  public menteeTitle: string;
  public mentorTitle: string;
  public messagePlaceholder: string;
  public openedFromWebView: boolean;
  public payoutTitle: string;
  public rescheduleTitle: string;
  public totalTitle: string;
  public videoMeetingLink: string;
  public videoMeetingLinkTitle: string;

  private copyButton = new Clipboard('.copy-to-clipboard');
  private copiedToClipboardMessage = 'Copied to clipboard.';
  private pleaseTryAgainMessage = 'Something went wrong, please try again.';
  private appleCalendar = 'APPLE';
  private calendarApiData: { calendar: string; type?: string };

  constructor(
    public activeModal: NgbActiveModal,
    public bookingService: BookingService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    public profileService: ProfileService,
    public toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.translate.get('BOOK').subscribe((res: any) => {
      this.buttonLabel = res.ACCEPT;
      this.bookingIdNumberTitle = res.BOOKING_ID_NUMBER;
      this.bookingDatesTitle = res.BOOKING_DATE;
      this.bookingServiceTypeTitle = res.BOOKING_TYPE;
      this.bookingMusicProTitle = res.BOOKING_MUSIC_PRO;
      this.bookerTitle = res.BOOKER;
      this.bookingTitlePlaceholder = res.REMOTE_RECORDING_FOR_MY_PROJECT;
      this.contactSupportTitle = res.CONTACT_SUPPORT;
      this.gigTitleTitle = res.GIG_TITLE;
      this.messagePlaceholder = res.INTRODUCE_YOURSELF;
      this.manageBookingTitle = res.MANAGE_BOOKING;
      this.menteeTitle = res.MENTOR;
      this.mentorTitle = res.MENTEE;
      this.rescheduleTitle = res.RESCHEDULE;
      this.videoMeetingLinkTitle = res.VIDEO_MEETING_LINK;

      // @ts-ignore
      this.isSeller = this.fromParent.isSeller;
      this.payoutTitle = this.isSeller ? res.PAYOUT : res.PAYMENT;

      this.copyButton.on('success', this.onCopySuccess);
      this.copyButton.on('error', this.onCopyError);
    });

    this.openedFromWebView = this.fromParent.openedFromWebView;

    this.bookModalConfirmedRequestData = { ...this.fromParent.booking.booking };
    const user = { ...this.fromParent.user };

    this.avatarGroupTitle = this.getAvatarGroupTitle();

    this.bookModalData = createBookModalData(user);
    this.avatarGroupData = createAvatarGroupData(user);

    // TODO (Milan): bookee.videoLink is where Zoom link is stored if available, this property is going to be removed when
    // we transition fully to Twilio Video so then this check will not be needed;
    this.videoMeetingLink =
      this.bookModalConfirmedRequestData?.bookee?.videoLink || this.bookModalConfirmedRequestData?.videoLink;

    if (this.isSeller) {
      this.priceTotal = this.bookModalConfirmedRequestData.price - this.bookModalConfirmedRequestData.sellersFee;
    } else {
      this.priceTotal = this.bookModalConfirmedRequestData.price + this.bookModalConfirmedRequestData.buyersFee;
    }
  }

  getAvatarGroupTitle(): string {
    if (this.bookModalConfirmedRequestData?.instant) {
      return this.isSeller ? this.mentorTitle : this.menteeTitle;
    } else {
      return this.isSeller ? this.bookerTitle : this.bookingMusicProTitle;
    }
  }

  closeModal(event): any {
    this.activeModal.close();
  }

  submitForm(): any {
    // This is mock function, real action should be put here
    this.closeModal(undefined);
  }

  emitCalendar(event: any) {
    if (event !== this.appleCalendar) {
      this.calendarApiData = {
        calendar: this.bookModalConfirmedRequestData.assignedId,
        type: event,
      };
      this.bookingService.scheduleMentorshipInCalendar(this.calendarApiData).subscribe((data) => {
        window.open(data.body.calendarDeeplink, '_blank');
      });
    } else {
      this.calendarApiData = {
        calendar: this.bookModalConfirmedRequestData.assignedId,
      };
      this.bookingService.scheduleMentorshipInAppleCalendar(this.calendarApiData).subscribe((data) => {
        const fileName = data.headers
          .get('content-disposition')
          .split(';')
          .find((n) => n.includes('filename="'))
          .replace('filename=', '')
          .replace('"', '')
          .replace('"', '')
          .trim();
        this.saveFile(data.body, fileName);
      });
    }
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, filename);
  }

  public openPayoutDetails(): void {
    this.router.navigate([`/bookings/${this.bookModalConfirmedRequestData.id}/payout-details`]);
  }

  public openContactSupport(): void {
    // Does not redirect where expected when using relativeTo parameter
    // this.router.navigate(['support'], { relativeTo: this.route });
    this.router.navigate([`bookings/${this.bookModalConfirmedRequestData.id}/confirmed/support`]);
  }

  public openReschedulingFlow(): void {
    this.router.navigateByUrl(
      `${this.router.routerState.snapshot.url}/reschedule?username=${this.avatarGroupData?.username}&bookingId=${this.bookModalConfirmedRequestData.id}`,
    );
  }

  private onCopySuccess = (e: any) => {
    this.toastService.showMessage(this.copiedToClipboardMessage);
  };

  private onCopyError = (e: any) => {
    this.toastService.showMessage(this.pleaseTryAgainMessage);
  };

  ngOnDestroy(): void {
    this.copyButton.destroy();
  }
}
