import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionCardComponent } from './transaction-card.component';
import { PriceTotalModule } from '../price-total/price-total.module';

@NgModule({
  declarations: [TransactionCardComponent],
  exports: [TransactionCardComponent],
  imports: [CommonModule, PriceTotalModule],
})
export class TransactionCardModule {}
