import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-message-group',
  templateUrl: './my-message-group.component.html',
  styleUrls: ['./my-message-group.component.scss'],
})
export class MyMessageGroupComponent implements OnInit {
  @Input() avatarImage: any;
  @Input() messageGroup: any;
  @Input() lastSeenMessageIndex: number;
  public profileId: number = null;
  constructor() {}

  ngOnInit(): void {
    this.profileId = parseInt(this.messageGroup.identity);
  }
}
