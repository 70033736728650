import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PLACEHOLDER_IMAGE_URL } from '../../../utils';

@Component({
  selector: 'app-jammcard-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';
  @Input() buttonLabel: any;
  @Input() modalImage: any;
  @Input() normalModal: any;

  public backgroundStyles: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.modalImage.length) {
      this.modalImage = PLACEHOLDER_IMAGE_URL.NORMAL_LOGIN;
      this.normalModal = true;
    }
    this.backgroundStyles = {
      'background-image': this.normalModal
        ? 'url(' + this.modalImage + ')'
        : 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(20, 26, 49, 0.9) 92%), url(' + this.modalImage + ')',
      'background-size': 'cover',
      'background-position': 'center center',
      'background-repeat': 'no-repeat',
    };
  }
}
