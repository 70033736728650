import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsModalAddCardComponent } from './payment-methods-modal-add-card.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { MentorshipModalModule } from '../../templates/mentorship-modal/mentorship-modal.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { AddPaymentCardFormModule } from '../add-payment-card-form/add-payment-card-form.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PaymentMethodsModalAddCardComponent],
  imports: [CommonModule, ModalModule, MentorshipModalModule, AddPaymentCardFormModule, ButtonModule, TranslateModule],
  exports: [PaymentMethodsModalAddCardComponent],
})
export class PaymentMethodsModalAddCardModule {}
