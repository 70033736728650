import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailabilityDatepickerComponent } from './availability-datepicker.component';
import { DatepickerModule } from '../datepicker/datepicker.module';

@NgModule({
  declarations: [AvailabilityDatepickerComponent],
  exports: [AvailabilityDatepickerComponent],
  imports: [CommonModule, DatepickerModule],
})
export class AvailabilityDatepickerModule {}
