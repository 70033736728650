<div class="channel-card__outer-container flex justify-center">
  <div
    *ngIf="isLoaded"
    class="channel-card__inner-container flex justify-center align-start"
    [ngClass]="{ 'channel-selected': isConversationSelected }"
    tabindex="0"
  >
    <div class="channel-card__content flex justify-center align-center">
      <div class="channel-card__image-wrapper">
        <div
          class="channel-card__image"
          style="background-image: url({{ participant.profileImage?.thumbnailSmall | profileImage }});"
        ></div>
      </div>
      <div class="channel-card__text-content">
        <div class="channel-card__name flex align-center">
          <img
            *ngIf="isUnread"
            src="../assets/images/icons/unread.svg"
            alt="Message unread"
            class="channel-card__unread"
          />{{ participant.name }}
        </div>
        <div class="channel-card__message">
          {{ lastMessage | formatSystemMessage | formatOpportunityMessengerTitle | messagePreview }}
        </div>
      </div>
      <div class="channel-card__updated-date">{{ updatedDate | timeago | timeagoShort }}</div>
    </div>
  </div>
</div>
<!-- TODO(Mladen): Figure out a way to scale svgs inside of lib -->
<facebook-content-loader
  *ngIf="!isLoaded"
  [viewBox]="'0 0 300 70'"
  class="channel-card__placeholder"
></facebook-content-loader>
