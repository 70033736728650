<!-- Template when there is multiple groups -->
<ng-container *ngIf="settingsItems.groups">
  <div class="notification-settings-menu__card card" *ngFor="let settings of settingsItems.groups">
    <div class="notification-settings-menu__card-body">
      <div class="notification-settings-menu__card-header">
        <span>
          <h4 class="notification-settings-menu__card-title card-title">{{ settings.title }}</h4>
          <p class="notification-settings-menu__card-subtitle card-subtitle" *ngIf="settings.description">
            {{ settings.description }}
          </p>
        </span>
        <span>
          <app-button-switch
            [isChecked]="checkParentState(settings.items)"
            (changeState)="onSwitchedParent($event, settings.items)"
          ></app-button-switch>
        </span>
      </div>
      <div class="notification-settings-menu__card-content">
        <app-notification-settings-items
          [settingsItems]="settings.items"
          (emitSwitchedState)="onSwitchedState($event, settings.items)"
        ></app-notification-settings-items>
      </div>
    </div>
  </div>
</ng-container>

<!-- Template when there is no multiple groups -->
<ng-container *ngIf="!settingsItems.groups">
  <div class="notification-settings-menu__card card">
    <div class="notification-settings-menu__card-body">
      <div class="notification-settings-menu__card-header">
        <span>
          <h4 class="notification-settings-menu__card-title card-title">{{ settingsItems.title }}</h4>
          <p class="notification-settings-menu__card-subtitle card-subtitle" *ngIf="settingsItems.description">
            {{ settingsItems.description }}
          </p>
        </span>
        <span *ngIf="settingsItems.items.length > 1">
          <app-button-switch
            [isChecked]="checkParentState(settingsItems.items)"
            (changeState)="onSwitchedParent($event, settingsItems.items)"
          ></app-button-switch>
        </span>
      </div>
      <div class="notification-settings-menu__card-content">
        <app-notification-settings-items
          [settingsItems]="settingsItems.items"
          (emitSwitchedState)="onSwitchedState($event, settingsItems.items)"
        ></app-notification-settings-items>
      </div>
    </div>
  </div>
</ng-container>
