import { Pipe, PipeTransform } from '@angular/core';
import { padStart } from 'lodash';

@Pipe({
  name: 'hideCardInfo',
})
export class HideCardInfoPipe implements PipeTransform {
  transform(last4: string): string {
    return padStart(last4, 19, '•••• ');
  }
}
