import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit {
  @Input() bookingPrice: number;
  @Input() isSeller: boolean;
  @Input() paymentDetailsTitle?: string;
  @Input() serviceFeePrice: number;
  @Input() totalPriceSum?: number;

  get totalPrice(): number {
    if (this.totalPriceSum) {
      return this.totalPriceSum;
    } else {
      return this.isSeller ? this.bookingPrice - this.serviceFeePrice : this.bookingPrice + this.serviceFeePrice;
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
