import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { IInfoModalData } from '../../templates/info-modal/info-modal.config';

@Component({
  selector: 'app-info-modal-success',
  templateUrl: './info-modal-success.component.html',
  styleUrls: ['./info-modal-success.component.scss'],
})
export class InfoModalSuccessComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';
  @Input() infoModalData: IInfoModalData;

  constructor() {}

  ngOnInit(): void {
    // @ts-ignore
    this.infoModalData = this.fromParent.modalData;
  }
}
