import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewPaymentCardComponent } from './view-payment-card.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [ViewPaymentCardComponent],
  imports: [CommonModule, PipesModule],
  exports: [ViewPaymentCardComponent],
})
export class ViewPaymentCardModule {}
