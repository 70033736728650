import { Pipe, PipeTransform } from '@angular/core';
import { SortByEnum } from '../../../models/search';
import { TranslateService } from '@ngx-translate/core';

enum SearchSortTranslations {
  LOCATION = 'SEARCH.SORT_BY_LOCATION',
  GENRE = 'SEARCH.SORT_BY_GENRE_ENDORSEMENTS',
  FOLLOWERS = 'SEARCH.SORT_BY_NUM_OF_FOLLOWERS',
  SKILL = 'SEARCH.SORT_BY_SKILL_ENDORSEMENTS',
}
@Pipe({
  name: 'searchSortCase',
})
export class SearchSortCasePipe implements PipeTransform {
  private translations;

  constructor(private translateService: TranslateService) {
    this.translateService.get(Object.values(SearchSortTranslations)).subscribe((res) => {
      this.translations = { ...res };
    });
  }

  transform(sort: SortByEnum): string {
    switch (sort) {
      case SortByEnum.CITY_LOCATION:
        return this.translations[SearchSortTranslations.LOCATION];
      case SortByEnum.LOCATION:
        return this.translations[SearchSortTranslations.LOCATION];
      case SortByEnum.GENRE_ENDORSEMENTS:
        return this.translations[SearchSortTranslations.GENRE];
      case SortByEnum.NUM_OF_FOLLOWERS:
        return this.translations[SearchSortTranslations.FOLLOWERS];
      case SortByEnum.SKILL_ENDORSEMENTS:
        return this.translations[SearchSortTranslations.SKILL];
      default:
        return '';
    }
  }
}
