import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodTabGroupComponent } from './payment-method-tab-group.component';
import { PaymentMethodTabModule } from '../payment-method-tab/payment-method-tab.module';

@NgModule({
  declarations: [PaymentMethodTabGroupComponent],
  imports: [CommonModule, PaymentMethodTabModule],
  exports: [PaymentMethodTabGroupComponent],
})
export class PaymentMethodTabGroupModule {}
