import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';

import { NavStateChange } from '../settings-content/settings-content.component';
import { NAV_STATES } from '../../molecules/navigation/navigation.component';
import { NotificationGroupSettings, NotificationSettings } from '../../../models';
import { NotificationService, ToastService } from '../../../services';

export const IPAD_WIDTH_START = 1280;
export const MOBILE_WIDTH_START = 768;

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  @Input() stateChange$: BehaviorSubject<NavStateChange>;
  @Output() stateChange: EventEmitter<NavStateChange> = new EventEmitter();
  @Output() subStateChange: EventEmitter<NavStateChange> = new EventEmitter();

  public notificationSettings$: BehaviorSubject<NotificationSettings[]> = new BehaviorSubject(null);
  public selectedSettings$: BehaviorSubject<NotificationSettings> = new BehaviorSubject(null);
  public selectedSettingsItems$: BehaviorSubject<NotificationGroupSettings> = new BehaviorSubject(null);
  public selectedSettingsItems: NotificationGroupSettings;

  public displayStyle$: BehaviorSubject<'mainMenu' | 'submenu'> = new BehaviorSubject('mainMenu');

  public sideControls: boolean = true;
  public backArrow: string;
  public backToTitle: string = 'Back to ';
  public notificationTypeTitle: string = '';
  public navState: string;
  public isDesktopView: boolean;
  public state: NavStateChange;
  public selectedTab: NotificationSettings;

  private resizeObservable$: Observable<Event> = fromEvent(window, 'resize');
  private subscription$: Subscription;
  private displayStyle: string;
  private arrowTabletIcon: string = '/assets/images/icons/arrow-left-black-notifications.svg';
  private arrowDesktopIcon: string = '/assets/images/icons/arrow-left-notifications.svg';

  @HostListener('window:resize', ['$event'])
  getWindowWidth(event?) {
    return window.innerWidth;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.subscription$ = this.activatedRoute?.queryParams?.subscribe((params) => {
      if (params.tab === 'notification-settings' && !params.details && this.displayStyle !== 'mainMenu') {
        this.onBackToNotificationSettings();
      }
    });

    if (this.getWindowWidth() < IPAD_WIDTH_START) {
      this.navState = NAV_STATES.MOBILE_INITIAL;
      this.backArrow = this.arrowTabletIcon;
      this.sideControls = true;
      this.isDesktopView = false;
      if (this.getWindowWidth() < MOBILE_WIDTH_START) {
        this.navState = NAV_STATES.MOBILE_SELECTED;
        this.sideControls = false;
      }
    } else {
      this.navState = NAV_STATES.DESKTOP;
      this.sideControls = true;
      this.backArrow = this.arrowDesktopIcon;
      this.isDesktopView = true;
    }

    this.subscription$.add(
      this.resizeObservable$.subscribe((event) => {
        this.setState(event);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public getNotificationSettings(): void {
    this.notificationService.getAccountNotificationSettings().subscribe(
      (res) => {
        this.notificationSettings$.next(res.body.settings);
        if (!this.selectedTab) {
          this.selectedSettings$.next(res.body.settings[0]);
        }
      },
      (error) => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  public handleStateChange(state: NavStateChange): void {
    this.stateChange.emit(state);
  }

  public handleSubmenuChange(state: NavStateChange): void {
    this.subStateChange.emit(state);
  }

  updateNotificationSettings(event: boolean): void {
    if (event) {
      this.getNotificationSettings();
    }
  }

  public onBackToNotificationSettings(): void {
    this.getNotificationSettings();
    this.state = {
      state: this.navState,
      tabName: 'Notification Settings',
    };

    this.displayStyle$.next('mainMenu');
    this.displayStyle = 'mainMenu';
    this.handleSubmenuChange(this.state);
  }

  public showNotificationDetails(settings: NotificationSettings): void {
    if (!!settings) {
      this.selectedSettings$.next(settings);
      this.notificationTypeTitle = settings.notificationType.toLowerCase() + ' ' + 'Notification';
      this.selectedTab = settings;
    } else {
      this.selectedSettings$.next(null);
    }
  }

  public displaySubgroupItems(settings: NotificationGroupSettings): void {
    this.displayStyle$.next('submenu');
    this.displayStyle = 'submenu';
    this.selectedSettingsItems = settings;
    this.selectedSettingsItems$.next(settings);

    this.state = {
      state: this.navState,
      tabName: this.notificationTypeTitle,
      pageStates: 'notification-details',
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        details: this.state.pageStates,
      },
      queryParamsHandling: 'merge',
    });

    this.handleStateChange(this.state);
  }

  private setState(event: Event): void {
    //Mobile view and states
    if ((event.currentTarget as Window).innerWidth < IPAD_WIDTH_START) {
      this.navState = NAV_STATES.MOBILE_INITIAL;
      this.backArrow = this.arrowTabletIcon;
      this.sideControls = true;
      this.isDesktopView = false;
      if ((event.currentTarget as Window).innerWidth < MOBILE_WIDTH_START) {
        this.sideControls = false;
        this.navState = NAV_STATES.MOBILE_SELECTED;
      }
    } else {
      this.sideControls = true;
      this.navState = NAV_STATES.DESKTOP;
      this.backArrow = this.arrowDesktopIcon;
      this.isDesktopView = true;
    }
    this.state.state = this.navState;
    this.state.pageStates ? this.handleStateChange(this.state) : this.handleSubmenuChange(this.state);
  }
}
