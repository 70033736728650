import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-messenger-image',
  templateUrl: './messenger-image.component.html',
  styleUrls: ['./messenger-image.component.scss'],
})
export class MessengerImageComponent implements OnInit {
  @Input() imageUrl: string =
    'https://images.unsplash.com/photo-1614191697800-ff83bd175cee?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80';

  constructor() {}

  ngOnInit(): void {}
}
