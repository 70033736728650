import { ButtonSwitchModule } from '../../atoms/button-switch/button-switch.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationSettingsSubmenuComponent } from './notification-settings-submenu.component';
import { NotificationSettingsItemsModule } from '../../atoms/notification-settings-items/notification-settings-items.module';

@NgModule({
  declarations: [NotificationSettingsSubmenuComponent],
  imports: [ButtonSwitchModule, CommonModule, NotificationSettingsItemsModule],
  exports: [NotificationSettingsSubmenuComponent],
})
export class NotificationSettingsSubmenuModule {}
