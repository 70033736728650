import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import { ToastService, TwilioService } from '../../services';
import { MessengerActions } from './messenger.action-types';
import { AuthActions } from '../auth/action-types';

@Injectable()
export class MessengerEffects {
  public userShortLoadedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userShortLoaded),
      filter((action) => !isNil(action.user)),
      concatMap((action) => this.twilioService.getTwilioToken(action?.user?.id)),
      map((res) => {
        return MessengerActions.twilioTokenLoaded({ token: res.body.token });
      }),
      catchError(() =>
        this.translateService.get('TOAST_MESSAGE').pipe(
          tap((res) => {
            this.toastService.showMessage(res.MESSENGER_FAILED_TO_CONNECT);
          }),
          map(() => MessengerActions.twilioTokenFailedToLoad()),
        ),
      ),
    ),
  );

  public twilioTokenLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessengerActions.twilioTokenLoaded),
      tap((action) => this.twilioService.startupTwilioConversations(action.token)),
      map(() => MessengerActions.twilioChatClientLoaded()),
    ),
  );

  public userLoggedOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        concatMap(() => of(this.twilioService.resetChatClient())),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private translateService: TranslateService,
    private twilioService: TwilioService,
  ) {}
}
