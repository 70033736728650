import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule, BrowserTransferStateModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AutosizeModule } from 'ngx-autosize';
import { IntercomModule } from 'ng-intercom';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TimeagoModule } from 'ngx-timeago';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BookModalComponent } from '../components/organisms/book-modal/book-modal.component';
import { BookModalModule } from '../components/organisms/book-modal/book-modal.module';
import { BookModalPendingRequestComponent } from '../components/organisms/book-modal-pending-request/book-modal-pending-request.component';
import { BookModalPendingRequestModule } from '../components/organisms/book-modal-pending-request/book-modal-pending-request.module';
import { BookingSentModalModule } from '../components/organisms/booking-sent-modal/booking-sent-modal.module';
import { BookingSentModalComponent } from '../components/organisms/booking-sent-modal/booking-sent-modal.component';
import { InfoModalSuccessComponent } from '../components/organisms/info-modal-success/info-modal-success.component';
import { InfoModalSuccessModule } from '../components/organisms/info-modal-success/info-modal-success.module';
import { ModalComponent } from '../components/templates/modal/modal.component';
import { ModalModule } from '../components/templates/modal/modal.module';
import { LandingModule } from '../pages/landing/landing.module';
import { MediaModalComponent } from '../components/organisms/media-modal/media-modal.component';
import { MediaModalModule } from '../components/organisms/media-modal/media-modal.module';
import { MessengerModule } from '../pages/messenger/messenger.module';
import { ToastModule } from '../components/atoms/toast/toast.module';
import { RequestSentModalComponent } from '../components/organisms/request-sent-modal/request-sent-modal.component';

import { AuthControllerModalModule } from '../components/templates/auth-controller-modal/auth-controller-modal.module';
import { AuthControllerModalComponent } from '../components/templates/auth-controller-modal/auth-controller-modal.component';
import { BrowserstateInterceptor, IsBrowserService, JwtInterceptor, LocalStorage } from '../utils';
import { ErrorInterceptor } from '../utils/error.interceptor';
import { PipesModule } from '../utils/pipes/pipes.module';
import { ClickOutsideModule } from '../utils/directives/click-outside/click-outside.module';
import { AppStoreModule } from '../store/app-store.module';
import { environment } from '../environments/environment';
import { InfoModalModule } from '../components/templates/info-modal/info-modal.module';
import { INTERCOM_KEY } from './app.config';
import { BookModalAddPayoutComponent } from '../components/organisms/book-modal-add-payout/book-modal-add-payout.component';
import { BookModalAddPayoutModule } from '../components/organisms/book-modal-add-payout/book-modal-add-payout.module';
import { BookModalPayToConfirmComponent } from '../components/organisms/book-modal-pay-to-confirm/book-modal-pay-to-confirm.component';
import { BookModalPayToConfirmModule } from '../components/organisms/book-modal-pay-to-confirm/book-modal-pay-to-confirm.module';
import { BookModalConfirmedModule } from '../components/organisms/book-modal-confirmed/book-modal-confirmed.module';
import { BookModalConfirmedComponent } from '../components/organisms/book-modal-confirmed/book-modal-confirmed.component';
import { InstallAppModalComponent } from '../components/organisms/install-app-modal/install-app-modal.component';
import { InstallAppModalModule } from '../components/organisms/install-app-modal/install-app-modal.module';
import { BookModalCompleteComponent } from '../components/organisms/book-modal-complete/book-modal-complete.component';
import { BookModalCompleteModule } from '../components/organisms/book-modal-complete/book-modal-complete.module';
import { BookModalRatingComponent } from '../components/organisms/book-modal-rating/book-modal-rating.component';
import { BookModalRatingModule } from '../components/organisms/book-modal-rating/book-modal-rating.module';
import { InfoModalThanksForContactingComponent } from '../components/organisms/info-modal-thanks-for-contacting/info-modal-thanks-for-contacting.component';
import { InfoModalThanksForContactingModule } from '../components/organisms/info-modal-thanks-for-contacting/info-modal-thanks-for-contacting.module';
import { BookModalContactSupportComponent } from '../components/organisms/book-modal-contact-support/book-modal-contact-support.component';
import { BookModalContactSupportModule } from '../components/organisms/book-modal-contact-support/book-modal-contact-support.module';
import { BookModalPayoutDetailsModule } from '../components/organisms/book-modal-payout-details/book-modal-payout-details.module';
import { BookModalPayoutDetailsComponent } from '../components/organisms/book-modal-payout-details/book-modal-payout-details.component';
import { EditProfileModalBasicModule } from '../components/organisms/edit-profile-modal-basic/edit-profile-modal-basic.module';
import { EditProfileModalBasicComponent } from '../components/organisms/edit-profile-modal-basic/edit-profile-modal-basic.component';
import { FastBookingAvailabilityModalModule } from '../components/organisms/fast-booking-availability-modal/fast-booking-availability-modal.module';
import { FastBookingAvailabilityModalComponent } from '../components/organisms/fast-booking-availability-modal/fast-booking-availability-modal.component';
import { CookieConsentModule } from '../components/molecules/cookie-consent/cookie-consent.module';
import { CookieConsentComponent } from '../components/molecules/cookie-consent/cookie-consent.component';
import { CustomProListModule } from '../pages/custom-pro-list/custom-pro-list.module';
import { WebpackTranslateLoader } from '../build/translate/webpack-translate-loader';
import { TruncatedProfileModalComponent } from '../components/organisms/truncated-profile-modal/truncated-profile-modal.component';
import { TruncatedProfileModalModule } from '../components/organisms/truncated-profile-modal/truncated-profile-modal.module';
import { BookingServicesModalModule } from '../components/templates/booking-services-modal/booking-services-modal.module';
import { BookingServicesModalComponent } from '../components/templates/booking-services-modal/booking-services-modal.component';
import { BookModalFastCheckoutModule } from '../components/organisms/book-modal-fast-checkout/book-modal-fast-checkout.module';
import { BookModalFastCheckoutComponent } from '../components/organisms/book-modal-fast-checkout/book-modal-fast-checkout.component';
import { PaymentMethodsModalAddCardComponent } from '../components/organisms/payment-methods-modal-add-card/payment-methods-modal-add-card.component';
import { PaymentMethodsModalAddCardModule } from '../components/organisms/payment-methods-modal-add-card/payment-methods-modal-add-card.module';
import { ManageGigModalComponent } from '../components/organisms/manage-gig-modal/manage-gig-modal.component';
import { ManageGigModalModule } from '../components/organisms/manage-gig-modal/manage-gig-modal.module';
import { MentorshipSessionRatingModalModule } from '../components/organisms/mentorship-session-rating-modal/mentorship-session-rating-modal.module';
import { MentorshipSessionRatingModalComponent } from '../components/organisms/mentorship-session-rating-modal/mentorship-session-rating-modal.component';
import { NotifyMeModalComponent } from '../components/organisms/notify-me-modal/notify-me-modal.component';
import { NotifyMeModalModule } from '../components/organisms/notify-me-modal/notify-me-modal.module';
import { VideoSettingsModalComponent } from '../components/organisms/video-settings-modal/video-settings-modal.component';
import { VideoSettingsModalModule } from '../components/organisms/video-settings-modal/video-settings-modal.module';
import { UnsubscribeModalComponent } from '../components/templates/unsubscribe-modal/unsubscribe-modal.component';
import { UnsubscribeModalModule } from '../components/templates/unsubscribe-modal/unsubscribe-modal.module';
import { JammboardGigMainCardDetailsModule } from '../components/molecules/jammboard-gig-main-card-details/jammboard-gig-main-card-details.module';
import { JammboardGigMainCardDetailsComponent } from '../components/molecules/jammboard-gig-main-card-details/jammboard-gig-main-card-details.component';

const APP_COMPONENT_MODULES: object[] = [
  ToastModule,
  AuthControllerModalModule,
  BookModalModule,
  BookModalFastCheckoutModule,
  BookModalPendingRequestModule,
  BookModalAddPayoutModule,
  BookModalPayToConfirmModule,
  BookModalConfirmedModule,
  BookModalContactSupportModule,
  BookModalCompleteModule,
  BookModalPayoutDetailsModule,
  BookModalPayoutDetailsModule,
  BookModalRatingModule,
  BookingSentModalModule,
  BookingServicesModalModule,
  CookieConsentModule,
  CustomProListModule,
  EditProfileModalBasicModule,
  FastBookingAvailabilityModalModule,
  InfoModalSuccessModule,
  InfoModalThanksForContactingModule,
  InfoModalModule,
  InstallAppModalModule,
  ManageGigModalModule,
  JammboardGigMainCardDetailsModule,
  MediaModalModule,
  ModalModule,
  MentorshipSessionRatingModalModule,
  NotifyMeModalModule,
  TruncatedProfileModalModule,
  PaymentMethodsModalAddCardModule,
  VideoSettingsModalModule,
  UnsubscribeModalModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    APP_COMPONENT_MODULES,
    AutosizeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    FormsModule,
    HttpClientModule,
    IntercomModule.forRoot({
      appId: INTERCOM_KEY,
      updateOnRouterChange: true,
    }),
    LandingModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    MessengerModule,
    NgxGalleryModule,
    PipesModule,
    ReactiveFormsModule,
    AutosizeModule,
    AppStoreModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    TimeagoModule.forRoot(),
  ],
  providers: [
    Meta,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: BrowserstateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LocalStorage,
      useFactory: (isBrowserService: IsBrowserService) => {
        return isBrowserService.isBrowser()
          ? window.localStorage
          : {
              getItem() {},
              setItem() {},
              removeItem() {},
              clear() {},
            };
      },
      deps: [IsBrowserService],
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AuthControllerModalComponent,
    BookModalComponent,
    BookModalFastCheckoutComponent,
    BookModalPendingRequestComponent,
    BookModalAddPayoutComponent,
    BookModalPayToConfirmComponent,
    BookModalConfirmedComponent,
    BookModalContactSupportComponent,
    BookModalCompleteComponent,
    BookModalPayoutDetailsComponent,
    BookModalRatingComponent,
    BookingSentModalComponent,
    BookingServicesModalComponent,
    CookieConsentComponent,
    EditProfileModalBasicComponent,
    FastBookingAvailabilityModalComponent,
    InfoModalSuccessComponent,
    InfoModalThanksForContactingComponent,
    InstallAppModalComponent,
    JammboardGigMainCardDetailsComponent,
    ManageGigModalComponent,
    MediaModalComponent,
    ModalComponent,
    MentorshipSessionRatingModalComponent,
    NotifyMeModalComponent,
    RequestSentModalComponent,
    TruncatedProfileModalComponent,
    PaymentMethodsModalAddCardComponent,
    VideoSettingsModalComponent,
    UnsubscribeModalComponent,
  ],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}

// Angular v10 and above accept only strict generic types of modules
// This code is added for installed libraries which are not updated for absolute compatibility with Angular v10 and above
// Basically this code will declare that if ModuleWithProviders doesn't have own it's type, it will be considered as type <any>
declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
