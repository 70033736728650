<app-date-time-label [dateTime]="messageGroup?.messages[0].dateCreated"></app-date-time-label>
<section class="others-message-group__container flex row justify-start">
  <app-message-avatar
    class="message-avatar__container flex column"
    [avatarImage]="avatarImage | profileImage"
    [otherParticipantProfileId]="profileId"
    [otherParticipantUsername]="username"
  ></app-message-avatar>
  <div class="flex column align-start">
    <ng-container *ngIf="!messageGroup?.opportunity; else opportunityMessage">
      <div *ngFor="let message of messageGroup?.messages">
        <app-others-message
          *ngIf="message.type === 'text'; else messageMedia"
          [messageText]="message.body"
        ></app-others-message>
        <ng-template #messageMedia>
          <app-others-image [media]="message.media"></app-others-image>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #opportunityMessage>
      <app-messenger-opportunity-card [message]="messageGroup?.messages[0]"></app-messenger-opportunity-card>
    </ng-template>
  </div>
</section>
