<app-mentorship-modal class="info-modal">
  <app-seller-profile-card
    (closeModal)="closeModal(false)"
    [noButtons]="true"
    [proTruncatedProfile]="proTruncatedProfile"
  ></app-seller-profile-card>

  <div class="info-modal__content">
    <h1 class="info-modal__title">{{ 'HOME.LEAVE_WAITLIST_MSG' | translate }}</h1>
    <div class="info-modal__btn-container mx-auto">
      <app-button
        class="unsubscribe-button"
        (clicked)="closeModal(true)"
        [rounded]="true"
        buttonLabel="Yes"
      ></app-button>
      <app-button
        class="unsubscribe-button"
        (clicked)="closeModal(false)"
        [rounded]="true"
        [inverted]="true"
        buttonLabel="No"
      ></app-button>
    </div>
  </div>
</app-mentorship-modal>
