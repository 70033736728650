import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

interface VisibilitySubject {
  entry: IntersectionObserverEntry;
  observer: IntersectionObserver;
}

interface VisibilityResult {
  htmlElement: HTMLElement;
  isVisible: boolean;
  wasVisible: boolean;
}

@Directive({
  selector: '[visibilityObserver]',
})
export class VisibilityObserverDirective {
  @Input() public debounceTime: number = 0;
  @Input() public threshold: number = 1;
  @Input() public once: boolean = false;

  @Output() visible = new EventEmitter<VisibilityResult>();

  private observer: IntersectionObserver | undefined;
  private subject$ = new Subject<VisibilitySubject>();
  private wasVisible: boolean = false;
  private final: boolean;
  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.createObserver();
  }

  ngAfterViewInit() {
    this.startObservingElements();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.next();
    this.subject$.complete();
  }

  private isVisible(element: HTMLElement): Promise<boolean> {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio === 1);
        observer.disconnect();
      });

      observer.observe(element);
    });
  }

  private createObserver() {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold,
    };

    //TODO(Mladen):Complete the optional ignore intersect functionality.
    //const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver(
      (entries, observer) => entries.forEach((entry) => this.subject$.next({ entry, observer })),
      options,
    );
  }

  private startObservingElements() {
    if (!this.observer) {
      return;
    }

    this.observer.observe(this.element.nativeElement);

    this.subject$.pipe(delay(this.debounceTime), filter(Boolean)).subscribe(async ({ entry, observer }) => {
      if (this.once && this.wasVisible) return;
      const target = entry.target as HTMLElement;
      const isStillVisible = await this.isVisible(target);

      this.wasVisible = isStillVisible;
      this.visible.emit({ htmlElement: target, isVisible: isStillVisible, wasVisible: this.wasVisible });
    });
  }
}
