<app-navigation
  [routeList]="routeList"
  (stateChange)="handleStateChange($event)"
  [goBack$]="goBack$"
  [handleNotifications$]="subStateChange$"
>
  <app-change-password *appNavigationContent></app-change-password>
  <app-payment-and-payout
    *appNavigationContent
    [stateChange$]="stateChange$"
    (stateChange)="handleStateChange($event)"
  ></app-payment-and-payout>
  <app-notification-settings
    *appNavigationContent
    [stateChange$]="stateChange$"
    (stateChange)="handleStateChange($event)"
    (subStateChange)="handleSubStateChange($event)"
  ></app-notification-settings>
</app-navigation>
