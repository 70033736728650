import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingStatusLabelComponent } from './booking-status-label.component';

@NgModule({
  declarations: [BookingStatusLabelComponent],
  exports: [BookingStatusLabelComponent],
  imports: [CommonModule],
})
export class BookingStatusLabelModule {}
