import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TitleService } from '../../utils';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
})
export class MessengerComponent implements OnInit, OnDestroy {
  public conversationsListInView$ = new BehaviorSubject<boolean>(true);
  public activeConversationParticipantName: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: TitleService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activeConversationParticipantName = null;
    this.document.documentElement.style.height = '100%';
    this.document.body.style.height = '100%';
    this.document.getElementById('root-div').style.height = '100%';

    this.translate.get('MESSENGER').subscribe((res: any) => {
      this.titleService.setTitle(res.MESSENGER_PAGE_TITLE);
    });
  }

  ngOnDestroy(): void {
    this.document.documentElement.style.height = '';
    this.document.body.style.height = '';
    this.document.getElementById('root-div').style.height = '';
  }

  public backToConversationsList(): void {
    this.conversationsListInView$.next(true);
  }

  public changeLayout(e: any): void {
    let { conversationsList, participantName } = e;

    this.conversationsListInView$.next(conversationsList);
    this.activeConversationParticipantName = participantName;
  }
}
