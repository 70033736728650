import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { BookModalData } from '../book-modal/book-modal.component';
import { BookingModal, ContactSupportReason, ContactSupportReasonRequestParam } from '../../../models';
import { createBookModalData } from '../../../utils';
import { AuthenticationService, ToastService } from '../../../services';
import { InfoModalThanksForContactingComponent } from '../info-modal-thanks-for-contacting/info-modal-thanks-for-contacting.component';

@Component({
  selector: 'app-book-modal-contact-support',
  templateUrl: './book-modal-contact-support.component.html',
  styleUrls: ['./book-modal-contact-support.component.scss'],
})
export class BookModalContactSupportComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';
  @Input() bookingId?: string | number;

  public bookModalData: BookModalData;
  private contactSupportReason: ContactSupportReason;
  public fromParent: BookingModal;
  public showBookingIdSection: boolean;
  private modalInstance: NgbModalRef;
  public processingSending: boolean;

  public bookingIdInput: string;
  public emailInput: string;
  public messageInput: string;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    // @ts-ignore
    const booking = { ...this.fromParent.booking.booking };
    const user = { ...this.fromParent.user };

    this.contactSupportReason = this.fromParent.contactSupportReason;
    // @ts-ignore
    this.bookingId = booking.assignedId;
    this.bookModalData = createBookModalData(user);

    this.showBookingIdSection =
      this.contactSupportReason === ContactSupportReason.BOOKING ||
      this.contactSupportReason === ContactSupportReason.PAYMENT_AND_REFUNDS;
  }

  public closeModal(event): any {
    this.activeModal.close();
  }

  public submitForm(): void {
    let requestObj: any;

    this.processingSending = true;

    if (this.contactSupportReason === ContactSupportReason.BOOKING) {
      if (this.bookingId) {
        requestObj = {
          bookingSymbolicId: this.bookingId.toString(),
          contactReason: ContactSupportReasonRequestParam.SPECIFIC_BOOKING,
          email: this.emailInput,
          message: this.messageInput,
        };
      } else {
        requestObj = {
          bookingSymbolicId: this.bookingIdInput,
          contactReason: ContactSupportReasonRequestParam.GENERAL_BOOKING,
          email: this.emailInput,
          message: this.messageInput,
        };
      }
    }

    // Other reasons for contacting support will have different requestObj formed

    this.authService.contactSupport(requestObj).subscribe(
      (res) => {
        this.modalInstance = this.modalService.open(InfoModalThanksForContactingComponent, {
          windowClass: 'modal-window',
          backdrop: 'static',
        });

        this.modalInstance.componentInstance.fromParent = {
          infoModalData: {
            redirectTo: 'bookings',
          },
        };
      },
      (error) => {
        this.showError('Something went wrong!');
        this.processingSending = false;
      },
    );
  }

  private showError(message: string): void {
    this.toastService.showMessage(message);
  }
}
