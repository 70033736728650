import { Component, Input, OnInit } from '@angular/core';

import { Conversation } from '@twilio/conversations';

import { IConversationParticipant } from '../../organisms/messenger-content/messenger-content.config';

@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss'],
})
export class ChannelCardComponent implements OnInit {
  @Input() conversationData: Conversation;
  @Input() isConversationSelected: boolean;
  @Input() lastMessage: string;
  @Input() participant: IConversationParticipant;
  @Input() isLoaded: boolean = false;
  @Input() isUnread: boolean;
  @Input() updatedDate: number;

  constructor() {}

  ngOnInit(): void {}
}
