import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { NotificationsActions } from '../action-types';

export interface NotificationsState {
  bookings: number;
  messenger: number;
}

export const initState: NotificationsState = {
  bookings: 0,
  messenger: 0,
};

const _notificationReducer = createReducer(
  initState,
  on(NotificationsActions.incrementBookingCount, (state) => ({
    ...state,
    bookings: state.bookings + 1,
  })),
  on(NotificationsActions.decrementBookingCount, (state) => ({
    ...state,
    bookings: state.bookings ? state.bookings - 1 : 0,
  })),
  on(NotificationsActions.setBookingCount, (state, action) => ({
    ...state,
    bookings: action.count,
  })),
  on(NotificationsActions.incrementMessengerCount, (state) => ({
    ...state,
    messenger: state.messenger + 1,
  })),
  on(NotificationsActions.decrementMessengerCount, (state) => ({
    ...state,
    messenger: state.messenger ? state.messenger - 1 : 0,
  })),
  on(NotificationsActions.setMessengerCount, (state, action) => ({
    ...state,
    messenger: action.count,
  })),
);

export function notificationReducer(state, action): NotificationsState {
  return _notificationReducer(state, action);
}
