import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { AccessManager } from 'twilio-common';
import {
  Client,
  Conversation,
  ConversationUpdateReason,
  Message,
  Participant,
  ParticipantUpdateReason,
  User,
} from '@twilio/conversations';
import { BehaviorSubject, EMPTY, from, Observable, of, Subject } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ToastService } from './toast.service';
import { CONVERSATION_UNIQUE_NAME_SEPARATOR, SERVICE_URL } from '../utils';
import {
  MESSAGES_TO_LOAD_IN_BATCH,
  ParticipantAttributes,
  IConversation,
  MEDIA_PREVIEW_PLACEHOLDER,
  IConversationParticipant,
  IMessengerNotificationResponse,
} from '../components/organisms/messenger-content/messenger-content.config';
import { NotificationsActions } from '../store/notifications/action-types';
import { IDataPageableResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TwilioService {
  private accessManager: AccessManager;
  private chatClient: Client;
  private errorGeneral: string;
  private errorMessageSendingFailed: string;
  private errorMessengerConnectionFailed: string;
  private hasMoreConversationsToLoad: boolean = true;
  private loadConversationsFromDateTime: number;
  private profileId: number;
  private subbedConversations: IConversation[] = [];

  public activeConversation: Conversation;
  // NOTE: contains the last scroll position of active conversation
  public activeConversationPreviousScroll: number;
  // NOTE: Index of last message seen by other participant
  public activeConversationLastSeenMessageIndex: number;
  public searchTerm: string = '';

  public clientConnected$ = new BehaviorSubject<boolean>(null);
  public subbedConversations$ = new BehaviorSubject<IConversation[]>(null);
  public activeConversationLastSeenMessageIndex$ = new BehaviorSubject<number>(null);
  public searchTerm$ = new Subject<string>();

  constructor(
    private http: HttpClient,
    private store: Store,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  public getTwilioToken(id: number): Observable<any> {
    this.profileId = id;
    return this.http.post(`${SERVICE_URL.MESSAGING}api/chat/authorize`, { identity: id }, { observe: 'response' });
  }

  public startupTwilioConversations(token: string): void {
    this.translate.get('TOAST_MESSAGE').subscribe((res) => {
      this.errorGeneral = res.SOMETHING_WENT_WRONG_PLEASE_REFRESH_PAGE;
      this.errorMessageSendingFailed = res.SENDING_MESSAGE_FAILED;
      this.errorMessengerConnectionFailed = res.MESSENGER_FAILED_TO_CONNECT;
    });

    this.chatClient = new Client(token);
    this.accessManager = new AccessManager(token);
    this.initializeChatClient();
  }

  public async sendMessage(message: string | FormData): Promise<void> {
    if (message) {
      const participants = await this.getParticipantsForConversation(this.activeConversation);
      // NOTE: Check the participants array on the conversation. If someone 'leaves' the conversation
      // his object would get removed from the array and that participant needs to be rejoined to the conversation first
      const otherParticipantId = this.getOtherIdFromConversationUniqueName(this.activeConversation.uniqueName);
      const loggedUserIsInConversation = participants.some((item) => {
        return item.identity === this.profileId.toString();
      });
      const otherUserIsInConversation = participants.some((item) => {
        return item.identity === otherParticipantId;
      });

      of(EMPTY)
        .pipe(
          switchMap(() => {
            return !loggedUserIsInConversation
              ? this.rejoinConversation(this.activeConversation.uniqueName, this.profileId)
              : of(EMPTY);
          }),
          switchMap(() => {
            return !otherUserIsInConversation
              ? this.rejoinConversation(this.activeConversation.uniqueName, Number.parseInt(otherParticipantId))
              : of(EMPTY);
          }),
          switchMap(() => {
            return from(this.activeConversation.sendMessage(message));
          }),
        )
        .subscribe(
          (index) => {
            this.activeConversation.advanceLastReadMessageIndex(index);
          },
          () => {
            this.toastService.showMessage(this.errorMessageSendingFailed);
          },
        );
    }
  }

  // initializeChatClient() is called once per user session/login
  public initializeChatClient(): void {
    this.chatClient.on('stateChanged', async (state) => {
      if (state === 'initialized') {
        this.setTokenListeners();
        this.clientConnected$.next(true);
        this.loadUserSubbedConversations();
        this.initializeMessengerBadgeNotificationCount();
        this.subscribeToSearchTerm();
        this.chatClient.addListener('conversationLeft', this.conversationLeftListener);
        this.chatClient.addListener('participantUpdated', this.clientParticipantUpdatedListener);
        this.chatClient.addListener('conversationUpdated', this.clientConversationUpdatedListener);
      } else {
        this.toastService.showMessage(this.errorMessengerConnectionFailed);
      }
    });
  }

  // resetChatClient() is called on user logout
  public resetChatClient(): void {
    this.chatClient.removeAllListeners();
    this.chatClient = null;
    this.accessManager.removeAllListeners();
    this.accessManager = null;
    this.hasMoreConversationsToLoad = true;
    this.loadConversationsFromDateTime = null;
    this.subbedConversations = [];
    this.subbedConversations$.next(this.subbedConversations);
    // NOTE: Set to null for reset (logout) and '' for refetching all conversations
    this.searchTerm$.next(null);
  }

  public setTokenListenersAndReconnect(): void {
    this.setTokenListeners();

    // NOTE: When refocusing the tab, this method will be called and event listeners re-attached, but if the Twilio token has already expired
    // while the tab was out of focus the exipration listener would not be triggered. That is why we check the current connection state here so
    // we can update the Twilio token if needed. This is our locally set connectionState. The chatClient has a connectionState parameter but after
    // token expires it needs about one minute to show the disconnection state so it is not used.

    this.clientConnected$
      .pipe(
        take(1),
        filter((clientConnected) => !clientConnected),
        switchMap(() => this.getTwilioToken(this.profileId)),
      )
      .subscribe(
        (tokenRes) => this.accessManagerUpdateToken(tokenRes.body),
        () => {
          this.toastService.showMessage(this.errorGeneral);
        },
      );
  }

  private setTokenListeners(): void {
    // NOTE: If for any reason the app reloads while the tab is not in focus, these event listeners would already be set when the
    // user refocuses so we check if they are already set before setting them again
    if (!this.accessManager?.listeners('tokenExpired').includes(this.tokenExpiredListener))
      this.accessManager?.on('tokenExpired', this.tokenExpiredListener);
    if (!this.accessManager?.listeners('tokenUpdated').includes(this.tokenUpdatedListener))
      this.accessManager?.on('tokenUpdated', this.tokenUpdatedListener);
    // Also remove the idle state handler
    this.accessManager?.off('tokenExpired', this.goToIdleState);
  }

  public removeTokenListeners(): void {
    this.accessManager?.off('tokenExpired', this.tokenExpiredListener);
    this.accessManager?.off('tokenUpdated', this.tokenUpdatedListener);
    this.accessManager?.once('tokenExpired', this.goToIdleState);
  }

  private goToIdleState = () => {
    // When tab is out of focus and Twilio token expires we set connection to false so we know on tab refocus that the token needs
    // to be updated. Also this will show loading on the Messenger page until client is ready.
    this.clientConnected$.next(false);
  };

  private tokenExpiredListener = () => {
    this.getTwilioToken(this.profileId).subscribe((tokenRes) => {
      this.accessManagerUpdateToken(tokenRes.body);
    });
  };

  private accessManagerUpdateToken(tokenRes): void {
    this.accessManager?.updateToken(tokenRes.token).catch((e) => {
      this.toastService.showMessage(this.errorMessengerConnectionFailed);
    });
  }

  private tokenUpdatedListener = () => {
    this.chatClient
      .updateToken(this.accessManager.token)
      .then(() => {
        this.clientConnected$.next(true);
      })
      .catch(() => {
        this.toastService.showMessage(this.errorMessengerConnectionFailed);
      });
  };

  private subscribeToSearchTerm(): void {
    this.searchTerm$.subscribe((searchTerm) => {
      if (searchTerm !== null) {
        this.searchTerm = searchTerm.trim();
        // reset conversation list, this logic is the same for when searchTerm is cleared
        // or when it is set to some actual term. The fetching method itself checks for the
        // content of the search term and acts accordingly
        this.hasMoreConversationsToLoad = true;
        this.loadConversationsFromDateTime = null;
        this.subbedConversations = [];
        this.subbedConversations$.next(this.subbedConversations);
        this.loadUserSubbedConversations();
      } else {
        // NOTE: searchTerm$ emits null only on logout
        this.searchTerm = '';
      }
    });
  }

  public initializeMessengerBadgeNotificationCount(): void {
    this.getMessengerBadgeNotificationCount().subscribe(
      (res) => {
        this.store.dispatch(NotificationsActions.setMessengerCount({ count: res.body.unreadConversationsCount }));
      },
      () => {
        this.toastService.showMessage(this.errorGeneral);
      },
    );
  }

  private conversationLeftListener = async (conversation: Conversation) => {
    // TODO (Milan): The conversation can be left from the mobile app while being unread and that would result
    // in Messenger badge number being inaccurate after removing the conversation from the list. Maybe recalculate the
    // badge number here?

    const conversationIndex = this.subbedConversations.findIndex((subb) => subb.uniqueName === conversation.uniqueName);
    if (conversationIndex !== -1) {
      this.subbedConversations.splice(conversationIndex, 1);
      this.subbedConversations$.next(this.subbedConversations);
    }

    // NOTE: The case when conversation left is the current active conversation also needs handling inside messenger-content.component.ts
    // where all the UI related stuff needs to be reset in this case. Event 'removed' gets emitted (on the conversation) when logged in user leaves a conversation.
  };

  private clientParticipantUpdatedListener = ({
    participant,
    updateReasons,
  }: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => {
    if (updateReasons.some((reason) => reason === 'lastReadMessageIndex')) {
      let updatedConversationIndex = this.subbedConversations.findIndex(
        (conversation) => conversation.uniqueName === participant.conversation.uniqueName,
      );

      if (updatedConversationIndex !== -1) {
        if (participant.identity === this.profileId.toString()) {
          const prevIsUnread = this.subbedConversations[updatedConversationIndex].hasUnreadMessages;
          // NOTE: If the event is for currently active conversation and the chat view is scrolled to the
          // bottom (auto advancing lastReadMessageIndex) do not change message badge or unread blue dot
          if (
            prevIsUnread ||
            this.activeConversation?.uniqueName !== participant.conversation.uniqueName ||
            this.activeConversationPreviousScroll !== 0
          ) {
            this.subbedConversations[updatedConversationIndex].hasUnreadMessages = false;
            this.initializeMessengerBadgeNotificationCount();
          }
        } else if (
          this.subbedConversations[updatedConversationIndex].uniqueName === this.activeConversation?.uniqueName
        ) {
          this.activeConversationLastSeenMessageIndex = participant.lastReadMessageIndex;
          this.activeConversationLastSeenMessageIndex$.next(this.activeConversationLastSeenMessageIndex);
        } else {
          // NOTE: this branch is needed to refresh badge count when a user is logged in from multiple different Browsers/tabs
          // to reflect up-to-date state
          this.initializeMessengerBadgeNotificationCount();
        }
      }
    }
  };

  private clientConversationUpdatedListener = async ({
    conversation,
    updateReasons,
  }: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => {
    if (updateReasons[0] === 'lastMessage') {
      const lastMessage = await this.getLastMessageForConversation(conversation);
      let isUnread: boolean;
      let prevIsUnread: boolean = false;
      // NOTE: If the logged user sends a message we don't want to show the unread mark on the conversations list
      if (lastMessage?.author === this.profileId.toString()) {
        isUnread = false;
      } else {
        // NOTE: set to actual value only of not currently in the conversation and scrolled to bottom
        if (
          this.activeConversation?.uniqueName !== conversation.uniqueName ||
          this.activeConversationPreviousScroll !== 0
        ) {
          isUnread =
            conversation.lastReadMessageIndex !== null
              ? conversation.lastReadMessageIndex < conversation.lastMessage.index
              : true;
        } else {
          isUnread = false;
        }
      }

      // NOTE: It is important that finding of conversation index happens after the first 'await' line. Otherwise
      // when multiple events get triggered at the same time, indexes would be found for all updated conversations
      // first and then the rest of the asynchronous logic would be executed, the first sorting of conversations would
      // make already calculated indexes non accurate.
      let conversationIndex = this.subbedConversations.findIndex(
        (subbConversation) => subbConversation.uniqueName === conversation.uniqueName,
      );

      if (conversationIndex === -1) {
        // The conversation updated is a new conversation that should be added to the conversations list
        // NOTE: In the case of search results, only the conversations matching the search term should be added
        let otherParticipant = await this.getOtherParticipant(conversation.uniqueName);

        if (!this.searchTerm || (this.searchTerm && this.matchesSearchTerm(otherParticipant.name))) {
          const conversationToAdd: IConversation = {
            conversationSid: conversation.sid,
            uniqueName: conversation.uniqueName,
            lastUpdateTime: lastMessage.dateCreated.getTime(),
            lastMessage:
              !lastMessage.attachedMedia || !lastMessage.attachedMedia?.length
                ? lastMessage.body
                : MEDIA_PREVIEW_PLACEHOLDER,
            hasUnreadMessages: isUnread,
            otherParticipant,
          };

          this.subbedConversations.unshift(conversationToAdd);
        }
      } else {
        // The conversation updated is in the conversations list already
        prevIsUnread = this.subbedConversations[conversationIndex].hasUnreadMessages;
        const conversationToAdd = {
          ...this.subbedConversations[conversationIndex],
          lastUpdateTime: lastMessage.dateCreated.getTime(),
          lastMessage:
            !lastMessage.attachedMedia || !lastMessage.attachedMedia?.length
              ? lastMessage.body
              : MEDIA_PREVIEW_PLACEHOLDER,
          hasUnreadMessages: isUnread,
        };

        this.subbedConversations.splice(conversationIndex, 1);
        this.subbedConversations.unshift(conversationToAdd);
      }

      this.subbedConversations$.next(this.subbedConversations);

      if (!prevIsUnread && isUnread) {
        this.initializeMessengerBadgeNotificationCount();
      }
    }
  };

  public loadUserSubbedConversations(): void {
    if (this.hasMoreConversationsToLoad) {
      this.getUserConversations().subscribe(
        (res) => {
          this.hasMoreConversationsToLoad = !res.body.last && !res.body.empty;
          // NOTE: Check for duplicates after loading. New conversations can be added
          // to the list by two async actions: loading the next page of sorted conversations
          // or reacting to a newMessage event on a conversation. A race condition between these
          // two actions can occur so it can end up with duplicate data.
          res.body.content.forEach((conversation) => {
            let conversationAlreadyLoaded = this.subbedConversations.some(
              (subbedConversation) => subbedConversation.uniqueName === conversation.uniqueName,
            );
            if (!conversationAlreadyLoaded) {
              this.subbedConversations.push(conversation);
            }
          });
          this.subbedConversations$.next(this.subbedConversations);
          this.loadConversationsFromDateTime = this.subbedConversations[
            this.subbedConversations.length - 1
          ]?.lastUpdateTime;
        },
        () => {
          this.toastService.showMessage(this.errorGeneral);
        },
      );
    }
  }

  public async updateActiveConversation(
    conversationUniqueName: string,
    indexToAdvanceTo: number = null,
  ): Promise<void> {
    // TODO (Milan): See if setting of event listeners on the active conversation can also be done here
    if (conversationUniqueName === null) {
      this.activeConversation = null;
      this.activeConversationPreviousScroll = null;
      this.activeConversationLastSeenMessageIndex = null;
      this.activeConversationLastSeenMessageIndex$.next(this.activeConversationLastSeenMessageIndex);
      return;
    } else {
      let activeConversationIndex: number;
      return this.getConversationByUniqueNameForUser(conversationUniqueName)
        .then((conversation) => {
          this.activeConversation = conversation;
          if (indexToAdvanceTo) {
            this.activeConversation.advanceLastReadMessageIndex(indexToAdvanceTo);
            return;
          } else {
            activeConversationIndex = this.subbedConversations.findIndex(
              (conversation) => conversation.uniqueName === this.activeConversation.uniqueName,
            );

            // When a user selects a conversation from the list, all messages within are marked as read (no marking message by message).
            // If the channel is new it will have 'null' for lastReadMessageIndex so that case has to be considered too.
            if (this.subbedConversations[activeConversationIndex].hasUnreadMessages) {
              this.activeConversation.advanceLastReadMessageIndex(this.activeConversation.lastMessage.index);
            }
            const fetchOtherParticipantLastReadMessageIndex = true;
            return fetchOtherParticipantLastReadMessageIndex;
          }
        })
        .then((shouldFetch) => {
          if (shouldFetch) {
            const otherParticipantId = this.getOtherIdFromConversationUniqueName(this.activeConversation.uniqueName);
            // NOTE: This promise will reject if the other participant has left the conversation, in that case
            // use the lastReadMessageIndex returned from BE as a fallback (catch block)
            return this.activeConversation.getParticipantByIdentity(otherParticipantId);
          } else {
            return;
          }
        })
        .then((participant) => {
          if (participant) {
            this.activeConversationLastSeenMessageIndex = participant.lastReadMessageIndex;
            this.activeConversationLastSeenMessageIndex$.next(this.activeConversationLastSeenMessageIndex);
          }
          return;
        })
        .catch((e) => {
          // NOTE: the lastReadMessageIndex on the conversation object could potentially be stale (edge-case)
          // but it is the only possible fallback
          this.activeConversationLastSeenMessageIndex = this.subbedConversations?.[
            activeConversationIndex
          ].otherParticipant.lastReadMessageIndex;
          this.activeConversationLastSeenMessageIndex$.next(this.activeConversationLastSeenMessageIndex);
        });
    }
  }

  public async getOtherParticipant(conversationUniqueName: string): Promise<IConversationParticipant> {
    let otherParticipantData: IConversationParticipant = {
      name: '-',
      profileId: null,
      profileImage: {
        id: null,
        mediaId: null,
        thumbnailLarge: null,
        thumbnailMedium: null,
        thumbnailSmall: '',
        url: null,
      },
      username: null,
      lastReadMessageIndex: null,
    };

    const conversationIndex = this.subbedConversations.findIndex(
      (conversation) => conversation.uniqueName === conversationUniqueName,
    );
    if (conversationIndex !== -1) {
      // no need to fetch additional data as other participant info is already present as part of loaded conversations
      otherParticipantData = {
        ...this.subbedConversations[conversationIndex].otherParticipant,
        profileImage: { ...this.subbedConversations[conversationIndex].otherParticipant.profileImage },
      };
    } else {
      const otherParticipantId = this.getOtherIdFromConversationUniqueName(conversationUniqueName);

      if (otherParticipantId) {
        const otherUser = await this.getUserByIdentity(otherParticipantId);
        // NOTE - Marko: Check if 'unkown' is nessesarry
        const otherUserAttributes = (otherUser?.attributes as unknown) as ParticipantAttributes;

        if (!!otherUserAttributes) {
          otherParticipantData = {
            name: otherUserAttributes.fullName,
            profileId: otherUserAttributes.profileId,
            profileImage: {
              id: null,
              mediaId: null,
              thumbnailLarge: null,
              thumbnailMedium: null,
              thumbnailSmall: otherUserAttributes.profileImage,
              url: null,
            },
            username: null,
            lastReadMessageIndex: null,
          };
        }
      }
    }

    return new Promise<IConversationParticipant>((resolve, _) => resolve(otherParticipantData));
  }

  private getOtherIdFromConversationUniqueName(conversationUniqueName: string): string {
    const participantIds = conversationUniqueName.split(CONVERSATION_UNIQUE_NAME_SEPARATOR);
    return participantIds.find((id) => id !== this.profileId.toString());
  }

  private matchesSearchTerm(participantName: string): boolean {
    return !!this.searchTerm ? participantName.toLowerCase().search(this.searchTerm.toLowerCase()) !== -1 : false;
  }

  // Our BE Twilio Conversations methods

  public getUserConversations(): Observable<HttpResponse<IDataPageableResponse<IConversation[]>>> {
    return this.http.get<IDataPageableResponse<IConversation[]>>(
      `${SERVICE_URL.MESSAGING}api/chat/conversations?${
        this.loadConversationsFromDateTime ? 'fromDateTime=' + this.loadConversationsFromDateTime : ''
      }&pageSize=10${this.searchTerm ? '&searchTerm=' + this.searchTerm : ''}`,
      { observe: 'response' },
    );
  }

  // NOTE: A delay is needed to allow the EP to retreive fresh information. Workaround agreed upon and used
  // for web and mobile apps. Potential work to be done in the future. BE and FE.
  public getMessengerBadgeNotificationCount(): Observable<HttpResponse<IMessengerNotificationResponse>> {
    return of(1).pipe(
      delay(1000),
      switchMap(() =>
        this.http.get<IMessengerNotificationResponse>(`${SERVICE_URL.MESSAGING}api/chat/conversations/unread-count`, {
          observe: 'response',
        }),
      ),
    );
  }

  public rejoinConversation(conversationId: string, profileId: number): Observable<HttpResponse<any>> {
    return this.http.post(
      `${SERVICE_URL.MESSAGING}api/chat/channel/rejoin?channel_id=${conversationId}&profile_id=${profileId}`,
      {},
      { observe: 'response' },
    );
  }

  // end of BE Twilio Conversations methods

  // Twilio API methods with error handling

  public async getConversationByUniqueNameForUser(uniqueName: string): Promise<Conversation> {
    return this.chatClient
      .getConversationByUniqueName(uniqueName)
      .then((conversation) => conversation)
      .catch((err) => {
        // TODO (Milan): Remove after testing Twilio Conversation is done
        console.log('getConversationByUniqueNameForUser error: ', err);
        this.toastService.showMessage(this.errorGeneral);
        return null;
      });
  }

  public async getParticipantsForConversation(conversation: Conversation): Promise<Participant[]> {
    return conversation
      .getParticipants()
      .then((participants) => participants)
      .catch((err) => {
        // TODO (Milan): Remove after testing Twilio Conversation is done
        console.log('getParticipantsForConversation error: ', err);
        this.toastService.showMessage(this.errorGeneral);
        return [];
      });
  }

  public async getUserByIdentity(identity: string): Promise<User> {
    return this.chatClient
      .getUser(identity)
      .then((user) => user)
      .catch((err) => {
        // TODO (Milan): Remove after testing Twilio Conversation is done
        console.log('getUserByIdentity error: ', err);
        this.toastService.showMessage(this.errorGeneral);
        return null;
      });
  }

  public async getLastMessageForConversation(conversation: Conversation): Promise<Message> {
    return conversation
      .getMessages(1)
      .then((messagePaginator) => messagePaginator.items[0])
      .catch((err) => {
        // TODO (Milan): Remove after testing Twilio Conversation is done
        console.log('getLastMessageForConversation error: ', err);
        this.toastService.showMessage(this.errorGeneral);
        return null;
      });
  }

  public async getMessagesForActiveConversation(
    indexFrom?: number,
  ): Promise<{ messages: Message[]; conversationHasMoreToLoad: boolean }> {
    return this.activeConversation
      .getMessages(MESSAGES_TO_LOAD_IN_BATCH, indexFrom)
      .then((messagePaginator) => ({
        messages: messagePaginator.items,
        conversationHasMoreToLoad: messagePaginator.hasPrevPage,
      }))
      .catch((err) => {
        // TODO (Milan): Remove after testing Twilio Conversation is done
        console.log('getMessagesForActiveConversation error: ', err);
        this.toastService.showMessage(this.errorGeneral);
        return { messages: [], conversationHasMoreToLoad: false };
      });
  }

  // end Twilio methods with error handling
}
