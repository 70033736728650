import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DatepickerComponent } from './datepicker.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [CommonModule, PipesModule],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
