import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent implements OnInit {
  @Input() buttonLabel: string;
  @Input() buttonType?: string;
  @Input() buttonDisabled: boolean;
  @Input() inverted = false;
  @Output() clicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.clicked.emit();
  }
}
