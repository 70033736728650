import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutMethodInfoComponent } from './payout-method-info.component';

@NgModule({
  declarations: [PayoutMethodInfoComponent],
  imports: [CommonModule],
  exports: [PayoutMethodInfoComponent],
})
export class PayoutMethodInfoModule {}
