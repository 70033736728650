<div class="messenger__container">
  <app-header
    class="messenger__app-header"
    [ngClass]="{ 'hide-show': !(conversationsListInView$ | async) }"
  ></app-header>
  <app-messenger-mobile-nav
    class="messenger-mobile-nav-wrapper"
    [ngClass]="{ 'hide-show': (conversationsListInView$ | async) }"
    [conversationParticipantName]="activeConversationParticipantName"
    (backToConversationsList)="backToConversationsList()"
  ></app-messenger-mobile-nav>
  <app-messenger-content
    [conversationsListInView$]="conversationsListInView$"
    (changeLayout)="changeLayout($event)"
    [ngClass]="{ 'change-height': conversationsListInView$ | async }"
  ></app-messenger-content>
</div>
