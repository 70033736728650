import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessengerService {
  public searchMessages(term: string): Observable<any> {
    return of(term);
  }
}
