import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthenticationService } from '../services';
import { Actions, ofType } from '@ngrx/effects';
import { AuthActions } from '../store/auth/action-types';
import { User } from '../models';

const EXCLUDE_ENDPOINTS = ['/api/user/auth/refresh-token'];

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private user: User;
  constructor(private authenticationService: AuthenticationService, private action$: Actions) {
    this.action$.pipe(ofType(AuthActions.authUpdateSuccess)).subscribe(() => {
      this.user = this.authenticationService.getAuthTokens();
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.user = this.authenticationService.getAuthTokens();
    // @ts-ignore
    const isLoggedIn = this.user && this.user.access_token;
    let setHeaders: any = {
      'local-date-time': this.toIsoString(new Date()),
    };
    if (isLoggedIn) {
      if (!EXCLUDE_ENDPOINTS.includes(request.url)) {
        setHeaders = {
          Authorization: `Bearer ${this.user.access_token}`,
          ...setHeaders,
        };
      }
    }

    request = request.clone({
      setHeaders,
    });
    return next.handle(request);
  }

  // format to ISO 8601 standard, example: 2021-09-24T12:17:41+02:00
  private toIsoString(date: Date) {
    // invert the sign for time zone offset
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    // pad with '0' if the value is a single-digit
    const pad = (num: number) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    );
  }
}
