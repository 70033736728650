import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HeaderModule } from '../../components/organisms/header/header.module';
import { MessengerComponent } from './messenger.component';
import { MessengerContentModule } from '../../components/organisms/messenger-content/messenger-content.module';
import { MessengerMobileNavModule } from '../../components/atoms/messenger-mobile-nav/messenger-mobile-nav.module';

const routes: Routes = [{ path: '', pathMatch: 'full', component: MessengerComponent }];

@NgModule({
  declarations: [MessengerComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MessengerContentModule,
    MessengerMobileNavModule,
    RouterModule.forChild(routes),
  ],
  exports: [MessengerComponent],
})
export class MessengerModule {}
