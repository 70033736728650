import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalPayoutDetailsComponent } from './book-modal-payout-details.component';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { FormsModule } from '@angular/forms';
import { RequestQuickCardModule } from '../../molecules/request-quick-card/request-quick-card.module';
import { TransactionCardModule } from '../../molecules/transaction-card/transaction-card.module';
import { BookModalPayoutDetailsEntryComponent } from './book-modal-payout-details-entry.component';
import { ModalModule } from '../../templates/modal/modal.module';

@NgModule({
  declarations: [BookModalPayoutDetailsComponent, BookModalPayoutDetailsEntryComponent],
  imports: [
    CommonModule,
    BookModalSectionModule,
    FormsModule,
    RequestQuickCardModule,
    TransactionCardModule,
    ModalModule,
  ],
  exports: [BookModalPayoutDetailsComponent, BookModalPayoutDetailsEntryComponent],
})
export class BookModalPayoutDetailsModule {}
