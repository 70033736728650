import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsComponent } from './payment-methods.component';
import { PaymentMethodTabGroupModule } from '../../molecules/payment-method-tab-group/payment-method-tab-group.module';
import { ViewPaymentCardModule } from '../../molecules/view-payment-card/view-payment-card.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentMethodsModalAddCardModule } from '../payment-methods-modal-add-card/payment-methods-modal-add-card.module';
import { PaymentMethodsModalAddCardComponent } from '../payment-methods-modal-add-card/payment-methods-modal-add-card.component';

@NgModule({
  declarations: [PaymentMethodsComponent],
  imports: [CommonModule, PaymentMethodTabGroupModule, ViewPaymentCardModule, ButtonModule, TranslateModule],
  exports: [PaymentMethodsComponent],
})
export class PaymentMethodsModule {}
