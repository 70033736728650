import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { ClickOutsideModule } from '../../../utils/directives/click-outside/click-outside.module';
import { DatepickerModule } from '../../molecules/datepicker/datepicker.module';
import { ManageGigModalComponent } from './manage-gig-modal.component';
import { LocationFilterModule } from '../../molecules/location-filter/location-filter.module';
import { SkillsFilterModule } from '../../molecules/skills-filter/skills-filter.module';

@NgModule({
  declarations: [ManageGigModalComponent],
  imports: [
    ButtonModule,
    ClickOutsideModule,
    CommonModule,
    DatepickerModule,
    FormsModule,
    LocationFilterModule,
    ReactiveFormsModule,
    SkillsFilterModule,
    TranslateModule,
  ],
  exports: [ManageGigModalComponent],
})
export class ManageGigModalModule {}
