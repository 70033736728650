import { Component, Input, OnInit } from '@angular/core';

import { CHANGE_NOTIFICATIONS } from '../../../utils';
import { NotificationService, ToastService } from '../../../services';
import { NotificationsList } from '../../../models';

@Component({
  selector: 'app-change-notifications',
  templateUrl: './change-notifications.component.html',
  styleUrls: ['../change-notifications/change-notifications.component.scss'],
})
export class ChangeNotificationsComponent implements OnInit {
  @Input() sectionTitle: any;
  @Input() sectionSubtitle: any;

  // The following part of code was used for initial design that might be used in the future

  // public messagesTitle = CHANGE_NOTIFICATIONS.SECTION.MESSAGES.TITLE;
  // public bookingStatusUpdatesTitle = CHANGE_NOTIFICATIONS.SECTION.BOOKING_STATUS_UPDATES.TITLE;
  // public remindersTitle = CHANGE_NOTIFICATIONS.SECTION.REMINDERS.TITLE;
  // public generalAnnouncementsTitle = CHANGE_NOTIFICATIONS.SECTION.GENERAL_ANNOUNCEMENTS.TITLE;
  // public messagesSubtitle = CHANGE_NOTIFICATIONS.SECTION.MESSAGES.SUBTITLE;
  // public bookingStatusUpdatesSubtitle = CHANGE_NOTIFICATIONS.SECTION.BOOKING_STATUS_UPDATES.SUBTITLE;
  // public remindersSubtitle = CHANGE_NOTIFICATIONS.SECTION.REMINDERS.SUBTITLE;
  // public generalAnnouncementsSubtitle = CHANGE_NOTIFICATIONS.SECTION.GENERAL_ANNOUNCEMENTS.SUBTITLE;

  // The part of code before this line was used for initial design that might be used in the future

  public newFollower = CHANGE_NOTIFICATIONS.SECTION.TITLE.NEW_FOLLOWER;
  public newMessage = CHANGE_NOTIFICATIONS.SECTION.TITLE.NEW_MESSAGE;
  public newComment = CHANGE_NOTIFICATIONS.SECTION.TITLE.NEW_COMMENT;
  public newTag = CHANGE_NOTIFICATIONS.SECTION.TITLE.NEW_TAG;
  public newUpvote = CHANGE_NOTIFICATIONS.SECTION.TITLE.NEW_UPVOTE;

  public changeNotificationsTitle = 'Notifications';
  public buttonLabel = 'Save changes';

  public notificationsList: NotificationsList;

  constructor(private notificationService: NotificationService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.notificationService.getNotificationsSettings().subscribe((response) => {
      this.notificationsList = { ...response.body };
    });
  }

  public saveNotificationsSettings(): void {
    this.notificationService.changeNotificationsSettings(this.notificationsList).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.toastService.showMessage('Notifications settings saved');
        } else {
          this.toastService.showMessage('Something went wrong');
        }
      },
      (error) => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  public setNotificationValue(name: string, event: Event): void {
    this.notificationsList[name] = event;
  }
}
