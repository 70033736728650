import { Pipe, PipeTransform } from '@angular/core';
import {
  isDateInPast,
  isDateInFuture,
  isTodayAfternoon,
  isSelectedDate,
  isAvailableDate,
  isToday,
} from '../../date-helper-functions';

const options = {
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  maxFutureDaysAllowed: 80,
};

@Pipe({
  name: 'datepickerClass',
})
export class DatepickerClassPipe implements PipeTransform {
  transform(
    day: unknown,
    month: unknown,
    year: unknown,
    isAvailabilityDatepicker: boolean,
    calendarIsEnabled: boolean,
    disableToday: boolean,
    bookingDate: Date,
    availableDates,
    noFutureLimit: boolean,
  ): unknown {
    if (isAvailabilityDatepicker && !calendarIsEnabled) {
      return {
        'is-today': isToday(year, month, day),
        'disable-date': true,
      };
    } else {
      const inPast = isDateInPast(year, month, day);
      const isFuture = !noFutureLimit && isDateInFuture(year, month, day, options.maxFutureDaysAllowed);
      const isAfternoon = isTodayAfternoon(year, month, day, disableToday);
      const isActive = isSelectedDate(year, month, day, bookingDate, isAvailabilityDatepicker);

      if (isAvailabilityDatepicker) {
        const isAvailable = isAvailableDate(year, month, day, availableDates);
        return {
          'is-today': isToday(year, month, day),
          'no-date': !day,
          'disable-date': inPast || isFuture || isAfternoon || !isAvailable,
          active: isActive,
          available: isAvailable,
        };
      } else {
        return {
          'no-date': !day,
          'disable-date': inPast || isFuture || isAfternoon,
          active: isActive,
        };
      }
    }
  }
}
