<header class="auth-modal__header">
  <p class="auth-modal__heading">Resetting Password</p>
</header>
<form class="auth-modal__form flex column" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit(resetPasswordForm)">
  <section>
    <div class="auth-modal__text-section">
      Please enter the email for your Jammcard account. <br />
      If you no longer have access to it, please contact Jammcard support.
    </div>
    <div class="auth-modal__install-info-content">
      <div class="auth-modal__input-section">
        <div class="auth-modal__email">
          <input
            id="loginModalInputEmail"
            class="auth-modal__input form-control"
            type="text"
            formControlName="email"
            name="resetPassEmail"
            required
          />
          <span class="floating-label">Email Address</span>
        </div>

        <div *ngIf="email.invalid && (email.dirty || !email.pristine)">
          <p *ngIf="email.errors.required" class="help-block">Email is required.</p>
          <p *ngIf="email.errors.email" class="help-block">Please enter a valid email.</p>
          <p *ngIf="email.errors.notFound" class="help-block">Email not found.</p>
        </div>

        <div class="auth-modal__send-btn-container">
          <app-button
            id="loginModalButton"
            [buttonDisabled]="resetRequestSending"
            [buttonLabel]="buttonLabel"
            [buttonType]="'submit'"
          >
          </app-button>
        </div>

        <div class="auth-modal__forgot-pass-container flex justify-start">
          <p class="auth-modal__text-section marked" (click)="backToLogin($event)">Back to Log In</p>
        </div>
      </div>
    </div>
  </section>
</form>
