import { IIdName, IIdNameImage, IImage } from './';

export enum ProfileType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
}

export enum ImageType {
  PROFILE = 'PROFILE',
  COVER = 'COVER',
}

export enum RateSettingTimeUnits {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export enum RateSettingCurrency {
  USD = 'USD',
}

export enum ServiceType {
  RECORDING = 'RECORDING',
  FEATURE = 'FEATURE',
  LIVE = 'LIVE',
  CONSULTING = 'CONSULTING',
  MENTORSHIP = 'MENTORSHIP',
}

export interface Location {
  lat: number;
  lon: number;
}

export interface EmailPhone {
  id: number;
  email: string;
  phone: string;
}

export interface StatusUpdate {
  alreadyCommented: boolean;
  alreadyUpvoted: boolean;
  commentCount: number;
  date: number;
  id: number;
  text: string;
  voteCount: number;
}

export interface AuthModalData {
  modalImage?: string;
  normalModal?: boolean;
  proName?: string;
  book?: boolean;
  storageUUID?: string;
  redirectTo?: string;
}

export interface BasicProfile {
  addedExperiences: boolean;
  addedMultimedia: boolean;
  addedSkills: boolean;
  appVersions: any;
  biography: string;
  blocked: boolean;
  city: string;
  coverImage: IImage;
  disabled: boolean;
  eligibleForMentorship: boolean;
  elsewhere: EmailPhone;
  facebookId: number;
  followedByYou: boolean;
  gender: Gender;
  hasSeenBookingTutorial: boolean;
  headline: string;
  location: Location;
  loginEmail: string;
  name: string;
  profileId: number;
  profileImage: IImage;
  profileType: string;
  statusUpdate: StatusUpdate;
  servicesProvision: ServiceProvision[];
  username: string;
  verified: boolean;
  website: string;
}

export interface SearchProfile {
  profileId: number;
  profileType: string;
  name: string;
  headline: string;
  profileImage: IImage;
  followedByYou: boolean;
  distance: number;
  city: string;
  skills: IIdName[];
  servicesProvision: ServiceProvisionShort[];
  verified: boolean;
  elsewhere: {
    email: string;
  };
}

export interface TruncatedProfile {
  experiences: Experience[];
  headline: string;
  medias: MediaList;
  name: string;
  username: string;
  profileId: number;
  profileImage: IImage;
  coverImage: IImage;
  verified: boolean;
  servicesProvision: ServiceProvision[];
  hasAvailability: boolean;
}

export interface ProfileContact {
  profileId: number;
  name: string;
  verified: boolean;
  headline: string;
  profileImage: IImage;
  contactEmail?: string;
}

export interface BasicProfileDTO {
  availability: boolean;
  city: string;
  gender: string;
  headline: string;
  location: Location;
  name: string;
  website: string;
}

export interface ProCardDTO {
  contactEmail: string;
  headline: string;
  name: string;
  profileId: number | string;
  profileImage: IImage;
  username: string;
  verified: boolean;
}

export interface CustomProList {
  client: string;
  description: string;
  name: string;
  memberProfiles: ProCardDTO[];
}

export interface ExtendedProfile {
  experiences: Experience[];
  eventDates: EventDate[];
  skills: Skill[];
  genres: Genre[];
  groupedGears: GroupedGear[];
  education: Education[];
  servicesProvision: ServiceProvision[];
}

export interface Experience {
  id: number;
  profession: string;
  bandOrStudio: string;
  dateFrom: number;
  dateTo: number;
  description: string;
  type: string;
  year: number;
  artist: string;
  record: string;
  descriptionTagList: string[];
  descriptionWithTags: string;
  date: number;
  replacedExp: string[];
}

export interface EventDate {
  id: number;
  date: number;
  venue: string;
  bandOrCollaborators: string;
  city: string;
  link: string;
}
export interface Skill {
  id: number;
  name: string;
  numOfEndorsements: number;
  alreadyEndorsed: boolean;
}
export interface Genre {
  id: number;
  name: string;
  numOfEndorsements: number;
  alreadyEndorsed: boolean;
  newlyAdded?: boolean;
}

export interface GroupedGear {
  brand: Brand;
  gears: Gear[];
}

export interface Brand {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Gear {
  id: number;
  productName: string;
  brand: string;
}

export interface GearBrand extends IIdNameImage {}

export interface Education {
  createdAt?: number;
  department?: string;
  description?: string;
  educationInstitution: {
    institutionName: string;
  };
  finishYear?: number | string;
  id?: number;
  ongoing?: boolean;
  profileId?: number;
  updatedAt?: number | string;
  title?: string;
  startYear?: number;
}

export interface Media {
  id: number;
  resourceUri: string;
  baseType: string;
  caption: string;
  profileId: number;
  imageUri: string;
  description: string;
  length: string;
  voteCount: number;
  commentCount: number;
  date: number;
  alreadyUpvoted: boolean;
  alreadyCommented: boolean;
  captionWithTags: string;
  descriptionWithTags: string;
  orderId: number;
  featured: boolean;
}

export interface MediaList {
  medias: Media[];
  totalNumberOfElements?: number;
}

// TODO (Milan): This is the old Rate interface that should be removed but there is code relying on this interface
export interface RateOld {
  id: number;
  profileId: number;
  createdAt: number;
  updatedAt: number;
  serviceType: string;
  serviceName: string;
  serviceRate: number;
  rateFrequency: string;
}

// Update this data model, separate rescheduleRate from normal Rate
export interface Rate {
  id?: number;
  amount?: number;
  rateSetting: RateSetting;
  // for RescheduleRate
  serviceProvisionId?: number;
  serviceProvisionOwner?: boolean;
}

export interface RateSetting {
  id: number;
  rateDescription: string;
  timeDurationAmount: number | string;
  timeDurationUnit: RateSettingTimeUnits;
  currency: RateSettingCurrency;
}

export interface ServiceProvisionShort {
  type: ServiceType;
  skills: Skill[];
}
export interface ServiceProvision {
  profileId?: number;
  id: number;
  type: ServiceType;
  skills: Skill[];
  rates: Rate[];
  description?: string;
  hasAvailability?: boolean;
  isViewerSubscribed: boolean;
}
