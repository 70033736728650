import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService, BookingService, LocalStorageService, TwilioService } from '../../../services';
import { CurrentUserShort } from '../../../models';
import {
  ASSETS_PATH,
  CONTACT_EMAIL,
  IsBrowserService,
  PLACEHOLDER_IMAGE_URL,
  PROFILE_TYPE,
  URL,
  USER_ID,
  USER_NAME,
} from '../../../utils';
import { LOGO_BLACK_SMALL_SIMPLE, SETTINGS_ICON } from './header.config';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';
import { NotificationsActions } from '../../../store/notifications/action-types';
import {
  selectNotificationsBooking,
  selectNotificationsMessenger,
} from '../../../store/notifications/notifications.selectors';
import { AuthActions } from '../../../store/auth/action-types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() logoToDisplay = ASSETS_PATH.LOGO_CLASSIC;
  @Input() logoLink = '/search';

  public readonly logoBlackSmallSimple = LOGO_BLACK_SMALL_SIMPLE;
  public readonly supportEmail = CONTACT_EMAIL.TEAM;
  public readonly settingsIcon = SETTINGS_ICON;

  public currentUserShort: CurrentUserShort;
  public id: any;
  public username: string;
  public loggedUserId: number;
  public loggedUserName: any;
  public loggedUserImage: string;
  public url: any;
  public userIsLoggedIn: boolean;

  public bookingsNotificationsNumber: number;
  public bookingsNotificationsNumber$: Observable<number>;
  public messengerNotificationsNumber$: Observable<number>;

  private subscription$: Subscription = new Subscription();
  private visibility$: Subscription = new Subscription();

  public loggedUserIsBuyer = false;

  constructor(
    private authenticationService: AuthenticationService,
    public bookingService: BookingService,
    @Inject(DOCUMENT) private document: Document,
    private isBrowserService: IsBrowserService,
    private localStorage: LocalStorageService,
    private router: Router,
    private store: Store,
    private twilioService: TwilioService,
  ) {
    this.url = URL;
  }

  ngOnInit(): void {
    this.loggedUserImage = PLACEHOLDER_IMAGE_URL.NO_AVATAR;
    this.userIsLoggedIn = this.authenticationService.isLoggedIn();

    if (this.isBrowserService.isBrowser()) {
      this.getLoggedUserDataShort();
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.visibility$.unsubscribe();
  }

  public navigateLogo(): void {
    const currentRoute = this.router.url;
    if (currentRoute.includes('mentors')) {
      this.router.navigate(['/mentors']);
    } else {
      this.router.navigate(['/search']);
    }
  }

  public initializeBookingBadgeNotificationCount(): void {
    this.bookingService.getBookingBadgeNotificationCount().subscribe((response) => {
      if (response.status === 200) {
        this.store.dispatch(NotificationsActions.setBookingCount({ count: response.body.unreadBookingsCount }));
      }
    });
  }

  public resetNotificationsCount(): void {
    this.initializeBookingBadgeNotificationCount();
    this.twilioService.setTokenListenersAndReconnect();
  }

  public getLoggedUserDataShort(): void {
    this.subscription$.add(
      this.store.select(selectCurrentUserShort).subscribe((userShort) => {
        if (userShort) {
          this.username = userShort.username;
          this.currentUserShort = userShort;
          this.loggedUserName = this.currentUserShort.name;
          this.loggedUserId = this.currentUserShort.id;
          this.loggedUserIsBuyer = this.isBuyer(this.currentUserShort.profileType);
          this.loggedUserImage = this.currentUserShort?.profileImage
            ? this.currentUserShort?.profileImage.thumbnailSmall
            : PLACEHOLDER_IMAGE_URL.NO_AVATAR;

          this.localStorage.add(USER_ID, this.currentUserShort.id.toString());
          this.localStorage.add(USER_NAME, this.loggedUserName);

          // Notifications related setup
          this.userIsLoggedIn = true;
          this.initializeBookingBadgeNotificationCount();
          this.bookingsNotificationsNumber$ = this.store.select(selectNotificationsBooking);
          this.messengerNotificationsNumber$ = this.store.select(selectNotificationsMessenger);

          this.visibility$.unsubscribe();
          this.visibility$ = fromEvent(this.document, 'visibilitychange')
            .pipe(map(() => this.document.visibilityState))
            .subscribe((state) => {
              if (state === 'visible') {
                this.resetNotificationsCount();
              } else {
                this.twilioService.removeTokenListeners();
              }
            });
        } else {
          this.currentUserShort = null;
          this.userIsLoggedIn = false;
          this.visibility$.unsubscribe();
        }
      }),
    );
  }

  public logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  private isBuyer(profileType): any {
    return profileType === PROFILE_TYPE.BUYER;
  }
}
