import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoModalComponent } from './info-modal.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { CalendarSelectModule } from '../../atoms/calendar-select/calendar-select.module';
import { DateTimeRangeZoneLabelModule } from '../../atoms/date-time-range-zone-label/date-time-range-zone-label.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InfoModalComponent],
  imports: [CommonModule, TranslateModule, ButtonModule, CalendarSelectModule, DateTimeRangeZoneLabelModule],
  exports: [InfoModalComponent],
})
export class InfoModalModule {}
