import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalCompleteComponent } from './book-modal-complete.component';
import { BookModalCompleteEntryComponent } from './book-modal-complete-entry.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { BookingStatusLabelModule } from '../../atoms/booking-status-label/booking-status-label.module';
import { AvatarGroupModule } from '../../molecules/avatar-group/avatar-group.module';
import { PriceTotalModule } from '../../molecules/price-total/price-total.module';
import { FormsModule } from '@angular/forms';
import { BookModalClickableLabelModule } from '../../molecules/book-modal-clickable-label/book-modal-clickable-label.module';
import { RateExperienceGroupModule } from '../../molecules/rate-experience-group/rate-experience-group.module';
import { RatedExperienceGroupModule } from '../../molecules/rated-experience-group/rated-experience-group.module';
import { BookModalPayoutDetailsModule } from '../book-modal-payout-details/book-modal-payout-details.module';

@NgModule({
  declarations: [BookModalCompleteComponent, BookModalCompleteEntryComponent],
  imports: [
    CommonModule,
    ModalModule,
    BookModalSectionModule,
    BookingStatusLabelModule,
    AvatarGroupModule,
    PriceTotalModule,
    FormsModule,
    BookModalClickableLabelModule,
    RateExperienceGroupModule,
    RatedExperienceGroupModule,
    BookModalPayoutDetailsModule,
  ],
  exports: [BookModalCompleteComponent, BookModalCompleteEntryComponent],
})
export class BookModalCompleteModule {}
