<div
  class="more__dropdown-container"
  (openChange)="checkDropdown($event)"
  ngbDropdown
  placement="center-top"
  *ngIf="!showCollapse"
>
  <div class="more__icon" id="moreDropdown" ngbDropdownToggle>
    <span class="calendar-span">
      <img
        width="19px"
        class="calendar-img"
        src="assets/images/icons/calendar{{ calendarData?.value }}.svg"
        alt="Calendar"
      />
      {{ calendarData?.text | translate | titlecase }}
    </span>
    <img *ngIf="!isToggled" class="arrow" src="assets/images/icons/arrow-down-see-more.svg" alt="Arrow Down" />
    <img *ngIf="isToggled" class="arrow" src="assets/images/icons/arrow-up-see-more.svg" alt="Arrow Up" />
  </div>
  <div class="more__dropdown" ngbDropdownMenu aria-labelledby="moreDropdown">
    <a
      *ngFor="let calendar of calendarItems; let i = index"
      class="dropdown__link"
      (click)="onCalendar(calendar)"
      [ngClass]="{ 'dropdown__link--active': calendarData.text === calendar.text ? true : false }"
      ngbDropdownItem
    >
      <span class="calendar-span">
        <img
          [ngStyle]="{ width: calendar?.width }"
          class="calendar-img"
          src="assets/images/icons/calendar-{{ calendar?.value }}.svg"
          alt="Calendar"
        />
        {{ calendar?.text | translate }}
      </span>
    </a>
  </div>
</div>

<div class="more__dropdown-container" *ngIf="showCollapse">
  <div
    class="more__icon"
    id="moreDropdown"
    (click)="isCollapsed = !isCollapsed; checkDropdown(isCollapsed)"
    [attr.aria-expanded]="isCollapsed"
    aria-controls="collapseExample"
    data-toggle="collapse"
    data-target="#collapse"
  >
    <span class="calendar-span">
      <img
        width="19px"
        class="calendar-img"
        src="assets/images/icons/calendar{{ calendarData?.value }}.svg"
        alt="Calendar"
      />
      {{ calendarData?.text | translate | titlecase }}
    </span>
    <img *ngIf="!isToggled" class="arrow" src="assets/images/icons/arrow-down-see-more.svg" alt="Arrow Down" />
    <img *ngIf="isToggled" class="arrow" src="assets/images/icons/arrow-up-see-more.svg" alt="Arrow Up" />
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="!isCollapsed">
    <div class="more__dropdown">
      <div
        *ngFor="let calendar of calendarItems; let i = index"
        class="dropdown__link"
        (click)="onCalendar(calendar)"
        [ngClass]="{ 'dropdown__link--active': calendarData.text === calendar.text ? true : false }"
      >
        <span class="calendar-span">
          <img
            [ngStyle]="{ width: calendar?.width }"
            class="calendar-img"
            src="assets/images/icons/calendar-{{ calendar?.value }}.svg"
            alt="Calendar"
          />
          {{ calendar?.text | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
