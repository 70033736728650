import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotificationGroupSettings, NotificationSettingsItems } from '../../../models';
import { NotificationService } from '../../../services';

export interface NotificationList {
  ids: number[];
}

@Component({
  selector: 'app-notification-settings-submenu',
  templateUrl: './notification-settings-submenu.component.html',
  styleUrls: ['./notification-settings-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsSubmenuComponent implements OnInit {
  @Input() selectedSettingsItems$: BehaviorSubject<NotificationGroupSettings> = new BehaviorSubject(null);
  @Output() emitUpdateNotificationSettings: EventEmitter<boolean> = new EventEmitter(false);

  public settingsItems: NotificationGroupSettings;
  public isParentActive: boolean = false;
  public ListID: Array<number> = [];

  constructor(private notificationService: NotificationService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectedSettingsItems$.subscribe((res) => {
      if (!!res) {
        this.settingsItems = res;
      }
    });
  }

  public checkParentState(items: NotificationSettingsItems[]): boolean {
    return items.some((item) => item.active);
  }

  public onSwitchedParent(isSwitched: boolean, items: NotificationSettingsItems[]): void {
    this.ListID = [];
    items.forEach((item) => {
      this.ListID.push(item.id);
      item.active = isSwitched;
    });
    const list = {
      ids: this.ListID,
    };
    isSwitched ? this.enableSingleNotification(list, items) : this.disableSingleNotification(list, items);
  }

  public enableSingleNotification(list: NotificationList, items: NotificationSettingsItems[]): void {
    this.notificationService.enableAccountNotificationSettings(list).subscribe(
      () => {
        this.emitUpdateNotificationSettings.emit(false);
      },
      (error) => {
        items.forEach((item) => {
          if (list.ids.includes(item.id)) {
            item.active = false;
          }
          this.checkParentState(items);
          this.ref.detectChanges();
        });
      },
    );
  }

  public disableSingleNotification(list: NotificationList, items: NotificationSettingsItems[]): void {
    this.notificationService.disableAccountNotificationSettings(list).subscribe(
      () => {
        this.emitUpdateNotificationSettings.emit(false);
      },
      (error) => {
        items.forEach((item) => {
          if (list.ids.includes(item.id)) {
            item.active = true;
          }
          this.checkParentState(items);
          this.ref.detectChanges();
        });
      },
    );
  }

  public onSwitchedState(settingsItem: NotificationSettingsItems, items: NotificationSettingsItems[]): void {
    this.checkParentState(items);
    this.ListID = [];
    this.ListID.push(settingsItem.id);
    const list: NotificationList = {
      ids: this.ListID,
    };
    settingsItem.active ? this.enableSingleNotification(list, items) : this.disableSingleNotification(list, items);
  }
}
