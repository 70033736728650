import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BookingByStatus, BookingModal } from '../../../models';
import { BookModalData } from '../book-modal/book-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalStorageService, ToastService } from '../../../services';

import {
  ACCEPT_BOOKING_ID,
  createBookModalData,
  REDIRECTION_STATE_CHECK,
  STRIPE_AUTHORIZATION_URL,
} from '../../../utils';
import { STRIPE } from '../../../environments/environment';

@Component({
  selector: 'app-book-modal-add-payout',
  templateUrl: './book-modal-add-payout.component.html',
  styleUrls: ['./book-modal-add-payout.component.scss'],
})
export class BookModalAddPayoutComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public bookModalAddPayoutData: BookingByStatus;
  public bookModalData: BookModalData;
  public buttonLabel: string;
  public fromParent: BookingModal;
  private redirectionCheckState: any;

  constructor(
    private activeModal: NgbActiveModal,
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.bookModalAddPayoutData = { ...this.fromParent.booking.booking };
    const buyer = { ...this.fromParent.buyer };
    this.bookModalData = createBookModalData(buyer);
    this.buttonLabel = 'Add Payout method';
  }

  closeModal(event): any {
    this.activeModal.close();
  }

  submitForm(): any {
    if (!this.fromParent?.booking?.booking?.id) {
      this.showError('Something went wrong!');
    } else {
      this.localStorageService.add(ACCEPT_BOOKING_ID, this.fromParent.booking.booking.id);
      // The state to be checked upon redirection from Stripe website
      this.redirectionCheckState = Math.random().toString(36).slice(2);
      this.localStorageService.add(REDIRECTION_STATE_CHECK, this.redirectionCheckState);
      // Redirect url for local testing
      // this.document.location.href = `${STRIPE_AUTHORIZATION_URL}?redirect_uri=http://localhost:4200/seller-payout-method-confirm&client_id=${STRIPE.CLIENT_ID}&state=${this.redirectionCheckState}`;
      this.document.location.href = `${STRIPE_AUTHORIZATION_URL}?redirect_uri=${STRIPE.REDIRECT_BASE_URI}&client_id=${STRIPE.CLIENT_ID}&state=${this.redirectionCheckState}`;
    }
  }

  private showError(message: string): void {
    this.toastService.showMessage(message);
  }
}
