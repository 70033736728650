import { Component, Input, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MediaModalComponent } from '../media-modal/media-modal.component';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  @Input() userProfile: any;

  public activeIframe: any;
  public selectedMediaIndex: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.activeIframe = null;
  }

  setActiveIframe(index, url, caption): any {
    if (this.activeIframe !== null) {
      return;
    }
    this.selectedMediaIndex = this.userProfile[index];
    this.openModal(this.selectedMediaIndex, url, caption);
  }

  openModal(mediaIndex, mediaUrl, mediaCaption): void {
    const modalInstance = this.modalService.open(MediaModalComponent, {
      windowClass: 'media-modal-window',
    });
    modalInstance.componentInstance.fromParent = { mediaIndex, mediaUrl, mediaCaption };
  }

  getIframeSrc(videoId, service): any {
    if (service === 'YOUTUBE') {
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return `https://w.soundcloud.com/player/?url=${videoId}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true`;
    }
  }

  formatItemDescription(description): any {
    return this.decodeHtml(description);
  }

  formatItemCaption(caption): any {
    return this.decodeHtml(caption);
  }

  decodeHtml(html): any {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
