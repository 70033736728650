import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apps-buttons-group',
  templateUrl: './apps-buttons-group.component.html',
  styleUrls: ['./apps-buttons-group.component.scss'],
})
export class AppsButtonsGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
