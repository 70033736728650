import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { startWith, take } from 'rxjs/operators';

import { AppState } from '../../../store/app.reducers';
import { AvailabilitySlot } from '../../../models/rates-and-services';
import { AVAILABILITY_RATE_DURATION_IN_MINUTES, MOBILE_WEB_VIEW, MODAL_DISMISS_REASON } from '../../../utils';
import { constructURLAccordingToSourceType, formatDateIntToHours } from '../../../utils/helper-functions';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';
import { BookingService, LocalStorageService, ToastService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingAction } from '../../../models';
import { BookingActions } from '../../../store/booking/action-types';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';

@Component({
  selector: 'app-availability-slot-list',
  templateUrl: './availability-slot-list.component.html',
  styleUrls: ['./availability-slot-list.component.scss'],
})
export class AvailabilitySlotListComponent implements OnInit, OnDestroy {
  @Input() slots: Observable<AvailabilitySlot[]>;
  @Input() rate: Observable<number>;
  @Input() preselectSlotIds: number[];
  @Input() profileName: string;
  @Input() isRescheduleRoute?: boolean;
  @Input() openedFromWebView: boolean;

  public availableSlots: AvailabilitySlot[] = [];
  public availableSlotsDisplay: AvailabilitySlot[] = [];
  public currentSlots: AvailabilitySlot[] = [];
  public currentRate: number;
  public errorMsgNoSlotsAvailable: string = '';
  public preselectStartSlotId: number;
  public subscription$ = new Subscription();
  public modalInstance: any;

  public bookingStardDate: string;
  public bookingEndDate: string;
  public assignedId: string;

  private errorMsgSelectedTimeSlotNotAvailable: string;

  constructor(
    public bookingService: BookingService,
    public modalService: NgbModal,
    public route: ActivatedRoute,
    private store: Store<AppState>,
    private translate: TranslateService,
    private toastService: ToastService,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.translate.get(['AVAILABILITY', 'TOAST_MESSAGE']).subscribe((res) => {
      this.errorMsgNoSlotsAvailable = res.AVAILABILITY.NO_AVAILABLE_SLOTS;
      this.errorMsgSelectedTimeSlotNotAvailable = res.TOAST_MESSAGE.THE_TIME_SLOT_YOU_SELECTED_IS_NO_LONGER_AVAILABLE;
    });

    this.subscription$.add(
      combineLatest([this.slots.pipe(startWith([])), this.rate]).subscribe(([slots, rateInMinutes]) => {
        const rate = this.formatRate(rateInMinutes);
        const currentDateInt = new Date().valueOf();

        this.currentRate = rate;
        this.currentSlots = slots;
        this.availableSlots = this.calculateAvailableSlots(slots, rate);
        this.availableSlotsDisplay = this.availableSlots
          .filter((slot) => +slot.startDateTime > currentDateInt)
          .map((slot) =>
            Object.assign(
              {},
              {
                ...slot,
                startDateTime: formatDateIntToHours(+slot.startDateTime),
                endDateTime: formatDateIntToHours(+slot.endDateTime),
              },
            ),
          );
      }),
    );

    // Preselect the time slot if the preselectSlotIds input was set from the parent component
    if (this.preselectSlotIds) {
      this.subscription$.add(
        this.slots.pipe(take(1)).subscribe((slots) => {
          this.preselectStartSlotId = this.preselectSlotIds[0];
          const slotIndex = slots.findIndex((slot) => slot.id === this.preselectStartSlotId);
          if (slotIndex === -1) {
            // the slot was taken in the meantime
            this.toastService.showMessage(this.errorMsgSelectedTimeSlotNotAvailable);
          } else {
            this.store.dispatch(
              FastBookingActions.availabilitySlotsInitiateLock({
                availabilitySlotsIds: this.preselectSlotIds,
                openedFromWebView: this.openedFromWebView,
              }),
            );
          }
        }),
      );
    }
  }

  public formatRate(rate: number = 60): number {
    return rate / AVAILABILITY_RATE_DURATION_IN_MINUTES - 1;
  }

  //NOTE(Mladen): Yes, these are for loops.
  public calculateAvailableSlots(slots: AvailabilitySlot[], rate: number): AvailabilitySlot[] {
    let availableSlots = [];
    for (let i = 0; i <= slots.length; i++) {
      if (i + rate <= slots.length - 1) {
        let current = slots[i];
        for (let j = i; j <= i + rate; j++) {
          if (j === i + rate) {
            availableSlots.push(current);
            i = i + rate;
            break;
          } else if (slots[j]?.endDateTime === slots[j + 1]?.startDateTime) continue;
          else {
            i = j;
            break;
          }
        }
      }
    }
    return availableSlots;
  }

  public slotConfirmed(slot: AvailabilitySlot) {
    const firstId = this.currentSlots.findIndex((current) => current.id === slot.id);
    const lastId = firstId + this.currentRate + 1;
    const ids = this.currentSlots.slice(firstId, lastId).map((slot) => slot.id);
    if (this.isRescheduleRoute) {
      const bookingId = this.route.snapshot.queryParams.bookingId;
      const confirmedSlotTimestamp = this.currentSlots.find((element) => element.id === slot.id);
      const confirmedEndSlot = this.currentSlots.find((slot) => slot.id === ids[ids.length - 1]);

      const rescheduleData = {
        newStartTime: confirmedSlotTimestamp.startDateTime,
      };

      this.bookingStardDate = confirmedSlotTimestamp.startDateTime;
      this.bookingEndDate = confirmedEndSlot.endDateTime;

      if (!this.localStorage.get(MOBILE_WEB_VIEW)) {
        this.store.select(selectCurrentBooking).subscribe((booking) => {
          this.assignedId = booking?.booking?.assignedId;
        });

        if (!this.assignedId) {
          this.bookingService.getBookingById(bookingId).subscribe(
            (booking) => (this.assignedId = booking.body.assignedId),
            (error) => {
              this.toastService.showMessage('Something went wrong');
            },
          );
        }
      }

      this.bookingService.rescheduleMentorshipBooking(bookingId, rescheduleData).subscribe(
        (response) => {
          if (response.status === 202) {
            this.openSuccessModal();
            this.store.dispatch(BookingActions.loadUpcomingBookings());
          }
        },
        (error) => {
          if (error.status === 403 && error.error.generalError.message === 'bookingAlreadyStarted') {
            this.toastService.showMessage("Booking has already started, you can't reschedule it");
          } else if (error.status === 403 && error.error.generalError.message === 'slotsAreNotFree') {
            this.toastService.showMessage('Slots are not free');
          } else {
            this.toastService.showMessage('Something went wrong');
          }
        },
      );
    } else {
      this.store.dispatch(
        FastBookingActions.availabilitySlotsInitiateLock({
          availabilitySlotsIds: ids,
          openedFromWebView: this.openedFromWebView,
        }),
      );
    }
  }

  public openSuccessModal(): void {
    this.modalService.dismissAll(MODAL_DISMISS_REASON.FAST_BOOKING_CHECKOUT);
    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    // TODO (Jovana): Add translations
    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: `Your session is booked!`,
        description: `A link to the video chat has been sent to your email and is also in your booking tab. Make sure to be on time and ready for your Mentor!`,
        buttonLabel: 'DONE',
        backgroundImageClass: 'success',
        redirectTo: constructURLAccordingToSourceType('/bookings?tab=upcoming', this.openedFromWebView, true),
        calendar: true,
        assignedId: this.assignedId,
        bookingStardDate: this.bookingStardDate,
        bookingEndDate: this.bookingEndDate,
        openedFromWebView: this.openedFromWebView,
      },
    };
  }

  public ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
