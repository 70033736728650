export const VIDEO_ASSETS = {
  CAMERA: 'assets/images/icons/camera.svg',
  CAMERA_OFF: 'assets/images/icons/camera-off.svg',
  HANGUP: 'assets/images/icons/hang-up.svg',
  JAMMCARD_MENTORS_LOGO: 'assets/images/logos/jammcard-mentors--white.svg',
  MICROPHONE: 'assets/images/icons/microphone.svg',
  MICROPHONE_OFF: 'assets/images/icons/microphone-off-white.svg',
  SETTINGS: 'assets/images/icons/three-vertical-dots.svg',
  SPEAKER: 'assets/images/icons/speaker.svg',
};

export const RATING_TIME_THRESHOLD = 10;

export const REVIEW_MAX_LENGTH = 255;

export const NOT_SUPPORTED_MESSAGE = {
  ANDROID:
    'This browser is not currently supported. For the best experience log in on a desktop or try a different browser.',
  DESKTOP: 'This browser is not currently supported, please use a different browser.',
  IOS: '',
};

// TODO (Milan): Trying to connect to a room that expired, error name: TwilioError, message: Unable to create Room - maybe handle this, this can happen when
// the preview screen with loaded room status and available button is left open and Room expires in the meantime, clicking the launch button would
// trigger this error

export const VIDEO_ERRORS = {
  NotFoundError: 'Please make sure you have an input device connected and enabled for the browser in system settings.',
  NotAllowedError: 'Please allow Jammcard to access your input devices in browser settings and reload the page.',
  // The TypeError will never happen (it is thrown when the app is not served through https nor through localhost)
  TypeError: 'TypeError',
  NotReadableError:
    'Please close all other apps or tabs that have reserved your input device and reload the page. If the problem persists please restart your browser.',
  OverconstrainedError:
    'The selected input device could not be used, please select a different device. If the problem persists please reload the page.',
  // In Firefox AbortError gets thrown instead of NotReadableError that is thrown in Chrome
  AbortError:
    'Please close all other apps or tabs that have reserved your input device and reload the page. If the problem persists please restart your browser.',
  // connection errors:
  53000: 'Please make sure you have a stable internet connection.',
  53006: 'There was a problem with the server, please try again in a while.',
  53105: 'The Room you are trying to connect to is full.',
  53106: "The Room you are trying to connect to doesn't exist.",
  53405: 'Please make sure you have a stable internet connection. If you use a firewall please allow the traffic to Twilio to go through.',
  // disconnected errors:
  53001: 'Please make sure you have a stable internet connection.',
  53002: 'There was a connection problem. Please rejoin the Room',
  RoomCompleted: 'The session has ended.',
  // This will happen when you connect from two devices/tabs as a single user
  53205: 'You might already be logged in on a different device.',
  // Setting of audio output device is still an experimental feature in some browsers like Safari and user should be notified
  SetAudioOutputError: 'Changing of audio output device is not supported by this browser.',
  // Fallback if no specific error is found
  GeneralError: 'There was an internal error, please reload the page and try again.',
};
