import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '../../atoms/button/button.module';
import { ModalModule } from '../../templates/modal/modal.module';
import { ResetPasswordModule } from '../../molecules/reset-password/reset-password.module';
import { LoginModalComponent } from './login-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginModalComponent],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    ResetPasswordModule,
    TranslateModule,
  ],
  exports: [LoginModalComponent],
})
export class LoginModalModule {}
