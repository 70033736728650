import { Pipe, PipeTransform } from '@angular/core';

import { OPPORTUNITY, TWILIO } from '../../constants';

@Pipe({
  name: 'formatSystemMessage',
})
export class FormatSystemMessagePipe implements PipeTransform {
  transform(message: any): string {
    return message.replace(TWILIO.SYSTEM_MESSAGE_FLAG, '').replace(OPPORTUNITY.MESSAGE_OPEN_TAG, '');
  }
}
