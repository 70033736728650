import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payout-method-info',
  templateUrl: './payout-method-info.component.html',
  styleUrls: ['./payout-method-info.component.scss'],
})
export class PayoutMethodInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
