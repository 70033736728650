import { AngularFireMessaging } from '@angular/fire/messaging';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, Subscription, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';

import { FIREBASE_TOKEN, SERVICE_URL } from '../utils';
import { NotificationsList } from '../models';
import { NotificationsActions } from '../store/notifications/action-types';
import { LocalStorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public token = '';
  public subscription: Subscription = new Subscription();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private store: Store,
    private localStorageService: LocalStorageService,
  ) {}

  public requestPermission(): Observable<void> {
    // NOTE: this.angularFireMessaging.requestToken is an alternative Observable that should
    // contain info on permission AND tokenChanges both in a single observable. As concluded, it does not work properly
    // so two separate observables also provided by angularFireMessaging are used.
    let tempToken: string;

    this.token = this.localStorageService.get(FIREBASE_TOKEN);
    this.angularFireMessaging.tokenChanges
      .pipe(
        switchMap((token) => {
          // NOTE: Dont execute token updating logic if the token didn't change from previous state
          if (this.token !== token) {
            tempToken = token;
            return this.authFirebase(tempToken);
            // NOTE: No error handilng since this is writing to BE and nothing should be shown
            // in the UI if the call does not succeed
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe((res) => {
        // NOTE: Save  locally only if successfully saved on BE
        if (res?.status === 201) {
          this.token = tempToken;
          this.localStorageService.add(FIREBASE_TOKEN, this.token);
        }
      });
    return this.angularFireMessaging.requestPermission;
  }

  receiveMessage() {
    this.subscription.add(
      this.angularFireMessaging.messages.subscribe((notification: Notification) => {
        // NOTE: Notifications of type 'MESSAGE' are already handled by event listeners on the Twilio chat client,
        // this logic is used only to set the bookings count badge.
        if (notification.data.notificationType !== 'MESSAGE') {
          this.store.dispatch(NotificationsActions.newNotificationReceived({ notification }));
        }
      }),
    );
  }

  changeNotificationsSettings(notificationsList: NotificationsList): Observable<any> {
    return this.http.put(`${SERVICE_URL.NOTIFICATIONS}api/notifications/settings`, notificationsList, {
      observe: 'response',
    });
  }

  getNotificationsSettings(): Observable<any> {
    return this.http.get(`${SERVICE_URL.NOTIFICATIONS}api/notifications/settings`, {
      observe: 'response',
    });
  }

  getAccountNotificationSettings(): Observable<any> {
    return this.http.get(`${SERVICE_URL.NOTIFICATIONS}api/notification-settings`, {
      observe: 'response',
    });
  }

  enableAccountNotificationSettings(notificationList: any): Observable<any> {
    return this.http.post(`${SERVICE_URL.NOTIFICATIONS}api/notification-settings/enable`, notificationList, {
      observe: 'response',
    });
  }

  disableAccountNotificationSettings(notificationList: any): Observable<any> {
    return this.http.post(`${SERVICE_URL.NOTIFICATIONS}api/notification-settings/disable`, notificationList, {
      observe: 'response',
    });
  }

  public authFirebase(token: string): Observable<HttpResponse<any>> {
    if (!token) return EMPTY;

    return this.http.post(
      `${SERVICE_URL.NOTIFICATIONS}api/device-tokens`,
      { token, platform: 'WEB' },
      {
        observe: 'response',
      },
    );
  }
}
