import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddPaymentCardFormModule } from '../add-payment-card-form/add-payment-card-form.module';

import { AddPaymentCardComponent } from './add-payment-card.component';

@NgModule({
  declarations: [AddPaymentCardComponent],
  imports: [CommonModule, AddPaymentCardFormModule],
  exports: [AddPaymentCardComponent],
})
export class AddPaymentCardModule {}
