import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChannelsListModule } from '../channels-list/channels-list.module';
import { ChatModule } from '../chat/chat.module';
import { MessengerContentComponent } from './messenger-content.component';
import { MyMessageModule } from '../../atoms/my-message/my-message.module';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [MessengerContentComponent],
  imports: [ChannelsListModule, ChatModule, CommonModule, MyMessageModule, PipesModule],
  exports: [MessengerContentComponent],
})
export class MessengerContentModule {}
