<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div class="book-modal-confirmed__info auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <app-book-modal-section class="book-modal-confirmed__status-label">
      <app-status-label [statusTitle]="'Confirmed'" [statusClass]="'success--green'"></app-status-label>
    </app-book-modal-section>
    <header class="book-modal-confirmed__header book-modal__header book-modal__section">
      <p class="auth-modal__heading">{{ bookModalConfirmedRequestData?.gigTitle }}</p>
    </header>
    <form class="book-modal-confirmed__form auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm()">
      <app-book-modal-section [sectionTitle]="avatarGroupTitle">
        <app-avatar-group [avatarGroupData]="avatarGroupData" [isClickable]="true"></app-avatar-group>
      </app-book-modal-section>
      <hr />

      <!--       TODO (Jovana): Make pipe for this format date-->
      <app-book-modal-section
        *ngIf="bookModalConfirmedRequestData?.bookedServiceType"
        [sectionTitle]="bookingServiceTypeTitle"
        [sectionText]="bookModalConfirmedRequestData?.bookedServiceType | titlecase"
      >
      </app-book-modal-section>

      <hr *ngIf="bookModalConfirmedRequestData?.bookedServiceType" />

      <app-book-modal-section
        *ngIf="!bookModalConfirmedRequestData?.instant"
        [sectionTitle]="bookingDatesTitle"
        [sectionText]="bookModalConfirmedRequestData?.dates[0] | date: 'EEEE, MMMM d'"
      >
      </app-book-modal-section>

      <app-book-modal-section *ngIf="bookModalConfirmedRequestData?.instant" [sectionTitle]="bookingDatesTitle">
        <app-date-time-range-zone-label
          [startDate]="bookModalConfirmedRequestData?.startDate"
          [endDate]="bookModalConfirmedRequestData?.endDate"
        >
        </app-date-time-range-zone-label>
        <app-calendar-select *ngIf="!openedFromWebView" (emitCalendar)="emitCalendar($event)"></app-calendar-select>
      </app-book-modal-section>

      <hr *ngIf="bookModalConfirmedRequestData?.instant" />

      <app-book-modal-section *ngIf="bookModalConfirmedRequestData?.instant" [sectionTitle]="videoMeetingLinkTitle">
        <div class="book-modal-confirmed__video-link-container flex row align-center">
          <label class="book-modal-confirmed__video-link-text">
            {{ videoMeetingLink }}
          </label>
          <img
            class="book-modal-confirmed__copy copy-to-clipboard"
            src="assets/images/icons/copy.svg"
            alt="Copy to clipboard"
            attr.data-clipboard-text="{{ videoMeetingLink }}"
          />
        </div>
        <a class="book-modal-confirmed__video-link-btn" href="{{ videoMeetingLink }}" target="_blank" rel="noreferrer">
          <app-button [buttonLabel]="'JOIN SESSION'"></app-button>
        </a>
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="payoutTitle">
        <app-price-total
          [price]="priceTotal"
          (priceTotalInfoClicked)="openPayoutDetails()"
          [totalTitle]="'Total'"
        ></app-price-total>
      </app-book-modal-section>

      <hr />

      <app-book-modal-section
        [sectionTitle]="bookingIdNumberTitle"
        [sectionText]="bookModalConfirmedRequestData?.assignedId"
      >
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="manageBookingTitle">
        <app-book-modal-clickable-label
          *ngIf="bookModalConfirmedRequestData?.instant && !isSeller"
          [labelText]="rescheduleTitle"
          (labelClicked)="openReschedulingFlow()"
        ></app-book-modal-clickable-label>
        <br *ngIf="bookModalConfirmedRequestData?.instant && !isSeller" />

        <app-book-modal-clickable-label
          [labelText]="contactSupportTitle"
          (labelClicked)="openContactSupport()"
        ></app-book-modal-clickable-label>
      </app-book-modal-section>
    </form>
  </div>
</app-jammcard-modal>
