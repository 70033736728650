import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeLabelComponent } from './date-time-label.component';

@NgModule({
  declarations: [DateTimeLabelComponent],
  exports: [DateTimeLabelComponent],
  imports: [CommonModule],
})
export class DateTimeLabelModule {}
