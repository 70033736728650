import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataComponent } from './no-data.component';
import { NoDataImgModule } from '../../atoms/no-data-img/no-data-img.module';
import { NoDataLabelModule } from '../../atoms/no-data-label/no-data-label.module';

@NgModule({
  declarations: [NoDataComponent],
  imports: [CommonModule, NoDataImgModule, NoDataLabelModule],
  exports: [NoDataComponent],
})
export class NoDataModule {}
