import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MetaService {
  constructor(private metaService: Meta) {}

  public addTag(tag: MetaDefinition, forceCreation: boolean = false): HTMLMetaElement | null {
    return this.metaService.addTag(tag, forceCreation);
  }

  public addTags(tags: MetaDefinition[], forceCreation: boolean = false): HTMLMetaElement[] {
    return this.metaService.addTags(tags, forceCreation);
  }

  public getTag(attrSelector: string): HTMLMetaElement | null {
    return this.metaService.getTag(attrSelector);
  }

  public updateTag(tag: MetaDefinition): HTMLMetaElement | null {
    return this.metaService.updateTag(tag);
  }
}
