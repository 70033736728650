import { Component, Input, OnInit } from '@angular/core';
import { Message } from '@twilio/conversations';

@Component({
  selector: 'app-messenger-opportunity-card',
  templateUrl: './messenger-opportunity-card.component.html',
  styleUrls: ['./messenger-opportunity-card.component.scss'],
})
export class MessengerOpportunityCardComponent implements OnInit {
  @Input() message: Message | any;

  constructor() {}

  ngOnInit(): void {}
}
