import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './header.component';
import { LoginButtonModule } from '../../atoms/login-button/login-button.module';
import { MoreDropdownMenuModule } from '../more-dropdown-menu/more-dropdown-menu.module';
import { NotificationBadgeModule } from '../../atoms/notification-badge/notification-badge.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    LoginButtonModule,
    MoreDropdownMenuModule,
    NgbDropdownModule,
    RouterModule,
    TranslateModule,
    NotificationBadgeModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
