import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SkillFeatured } from '../../../models';

interface SkillFeaturedCustom extends SkillFeatured {
  selected: boolean;
}

@Component({
  selector: 'app-skills-filter',
  templateUrl: './skills-filter.component.html',
  styleUrls: ['./skills-filter.component.scss'],
})
export class SkillsFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() availableSkills: SkillFeatured[];
  @Input() expandable = true;
  @Input() showError = false;
  @Input() skillsToFilterBy$: Subject<SkillFeatured[]>;
  @Input() label?: string = null;
  @Input() showActiveLabel?: boolean = false;
  @Output() skillChanged = new EventEmitter<SkillFeatured[]>();
  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;
  @ViewChild('selectableSkillsContainer') selectableSkillsContainer: ElementRef<HTMLElement>;
  @ViewChild('selectableSkillsExpandableWrapper') selectableSkillsExpandableWrapper: ElementRef<HTMLElement>;

  public readonly selectableSkillsTresholdHeight = 142;
  public selectedSkills: SkillFeatured[] = [];
  public skillsForDisplay: SkillFeaturedCustom[];
  public skillsExpanded: boolean = false;
  public showSeeMore: boolean;
  public labelTranslated: string = 'Skills';

  public searchTerm$ = new Subject<string>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.skillsForDisplay = this.mapToSkillsForDisplay(this.availableSkills);

    this.skillsToFilterBy$?.subscribe((skillsToFilterBy) => {
      // NOTE: Clear the search field when reassigning skills for display
      if (this.searchField) {
        this.searchField.nativeElement.value = '';
      }
      this.selectedSkills = skillsToFilterBy.slice();
      this.skillsForDisplay = this.mapToSkillsForDisplay(this.availableSkills);
    });

    if (this.label)
      this.translateService.get(this.label ?? 'SEARCH.SKILLS').subscribe((res) => {
        this.labelTranslated = res;
      });

    this.searchTerm$
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => prev.trim() === curr.trim()),
      )
      .subscribe((searchTerm) => {
        let filteredAvailableSkills = this.availableSkills.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase().trim()),
        );

        this.skillsForDisplay = this.mapToSkillsForDisplay(filteredAvailableSkills);
        // NOTE: To have the updated clientHeight after skills changed we have to wait for
        // the rerender first.
        setTimeout(() => {
          this.showSeeMore =
            this.selectableSkillsContainer.nativeElement.clientHeight > this.selectableSkillsTresholdHeight;
        }, 0);
      });
  }

  ngAfterViewInit(): void {
    this.selectableSkillsExpandableWrapper.nativeElement.style.maxHeight = `${this.selectableSkillsTresholdHeight}px`;
    // NOTE: For non-expandable variation, manually call the contentShown() to initialize see more UI
    if (!this.expandable) {
      setTimeout(() => this.contentShown(), 0);
    }
  }

  ngOnDestroy(): void {
    this.searchTerm$.unsubscribe();
    this.skillsToFilterBy$?.unsubscribe();
  }

  public contentShown(): void {
    this.showSeeMore = this.selectableSkillsContainer.nativeElement.clientHeight > this.selectableSkillsTresholdHeight;
    if (this.showSeeMore && !this.skillsExpanded) {
      this.selectableSkillsExpandableWrapper.nativeElement.style.maxHeight = '';
      this.selectableSkillsExpandableWrapper.nativeElement.style.height = `${this.selectableSkillsTresholdHeight}px`;
    }
  }

  public toggleFullSelectableSkills() {
    this.skillsExpanded = !this.skillsExpanded;
    if (this.skillsExpanded) {
      this.selectableSkillsExpandableWrapper.nativeElement.style.height = `${this.selectableSkillsContainer.nativeElement.clientHeight}px`;
    } else {
      this.selectableSkillsExpandableWrapper.nativeElement.style.height = `${this.selectableSkillsTresholdHeight}px`;
    }
  }

  public search(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
  }

  public removeSelectedSkill(skill: SkillFeatured): void {
    this.skillToggle({ ...skill, selected: true });
  }

  public skillToggle(skill: SkillFeaturedCustom): void {
    let skillIndex = this.skillsForDisplay.findIndex((item) => item.id === skill.id);
    if (skill.selected) {
      let selectedSkillIndex = this.selectedSkills.findIndex((item) => item.id === skill.id);

      // NOTE: If a skill is selected it should be inside this.selectedSkills, sanity check
      if (selectedSkillIndex !== -1) {
        this.selectedSkills.splice(selectedSkillIndex, 1);
        this.skillsForDisplay[skillIndex].selected = false;
      }
    } else {
      this.selectedSkills.push(skill);
      this.skillsForDisplay[skillIndex].selected = true;
      this.searchTerm$.next('');
    }

    if (!this.expandable) {
      this.skillChanged.emit(this.selectedSkills);
    }
  }

  private mapToSkillsForDisplay(skills: SkillFeatured[]): SkillFeaturedCustom[] {
    return skills.map((item) => ({
      ...item,
      selected: this.selectedSkills.findIndex((selectedItem) => selectedItem.id === item.id) !== -1 ? true : false,
    }));
  }

  // NOTE: Universally named methods used by parent to get and reset the selected filter values
  public getFilterOutput(): SkillFeatured[] {
    return this.selectedSkills;
  }

  public resetFilter(): void {
    this.selectedSkills = [];
    this.skillsForDisplay = this.mapToSkillsForDisplay(this.availableSkills);
  }
}
