<app-mentorship-modal class="truncated-profile">
  <app-seller-profile-card
    [proTruncatedProfile]="proTruncatedProfile"
    [hideBookButton]="hideBookButton"
    [hasAvailability]="hasAvailability"
    [isSubscribed]="isSubscribed"
    (closeModal)="closeModal()"
  ></app-seller-profile-card>

  <!-- This image is temporary added. Image is hidden and it is used for thumbnail img for social sharing, due to meta img disfuncion. -->
  <img [src]="mentorsThumbnail" alt="mentorsThumbnail" class="mentorsThumbnail" />

  <div class="truncated-profile__rounded-corners-wrapper">
    <div class="truncated-profile__content-container">
      <app-about-mentorship-card
        *ngIf="serviceForBooking?.description"
        [text]="serviceForBooking?.description"
        title="{{ 'BOOK.ABOUT_MENTORSHIP' | translate }}"
      >
      </app-about-mentorship-card>

      <div class="truncated-section-divider" *ngIf="proTruncatedProfile?.medias"></div>

      <app-truncated-media
        *ngIf="proTruncatedProfile?.medias?.medias?.length"
        [media]="proTruncatedProfile?.medias"
      ></app-truncated-media>

      <div class="truncated-section-divider" *ngIf="serviceForBooking?.skills"></div>

      <app-skills-card
        *ngIf="serviceForBooking?.skills"
        [skills]="serviceForBooking?.skills"
        title="{{ 'HOME.KNOWN_FOR' | translate }}"
      ></app-skills-card>

      <div class="truncated-section-divider" *ngIf="proTruncatedProfile?.experiences?.length"></div>

      <app-experience
        *ngIf="proTruncatedProfile?.experiences?.length"
        [experiences]="proTruncatedProfile?.experiences"
      ></app-experience>
    </div>
  </div>
</app-mentorship-modal>
