import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { MentorshipModalModule } from '../mentorship-modal/mentorship-modal.module';
import { SellerProfileCardModule } from '../../molecules/seller-profile-card/seller-profile-card.module';
import { UnsubscribeModalComponent } from './unsubscribe-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UnsubscribeModalComponent],
  imports: [CommonModule, ButtonModule, MentorshipModalModule, SellerProfileCardModule, TranslateModule],
  exports: [UnsubscribeModalComponent],
})
export class UnsubscribeModalModule {}
