import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeNotificationComponent } from './change-notification.component';
import { ToggleModule } from '../../atoms/toggle/toggle.module';

@NgModule({
  declarations: [ChangeNotificationComponent],
  exports: [ChangeNotificationComponent],
  imports: [CommonModule, ToggleModule],
})
export class ChangeNotificationModule {}
