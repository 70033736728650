import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { MoreDropdownMenuComponent } from './more-dropdown-menu.component';

@NgModule({
  declarations: [MoreDropdownMenuComponent],
  imports: [CommonModule, NgbDropdownModule, RouterModule, TranslateModule],
  exports: [MoreDropdownMenuComponent],
})
export class MoreDropdownMenuModule {}
