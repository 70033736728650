import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationSettingsItems } from '../../../models';

@Component({
  selector: 'app-notification-settings-items',
  templateUrl: './notification-settings-items.component.html',
  styleUrls: ['./notification-settings-items.component.scss'],
})
export class NotificationSettingsItemsComponent {
  @Input() settingsItems: NotificationSettingsItems[];
  @Output() emitSwitchedState: EventEmitter<NotificationSettingsItems> = new EventEmitter(null);

  constructor() {}

  public onChangeState(event: boolean, item: NotificationSettingsItems): void {
    item.active = event;
    this.emitSwitchedState.emit(item);
  }
}
