import { Injectable, Inject } from '@angular/core';

import { LocalStorage } from '../utils/local-storage';

@Injectable({
  providedIn: 'root',
})

//TODO(Mladen): Finish the implementation. Optional: return observable
export class LocalStorageService {
  constructor(@Inject(LocalStorage) private localStorage: Storage) {}

  public add(key: string, value: any): void {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  public get(key: string): any {
    try {
      return JSON.parse(this.localStorage.getItem(key));
    } catch {
      return this.localStorage.getItem(key);
    }
  }

  public delete(key: string): void {
    this.localStorage.removeItem(key);
  }

  public clear(): void {
    this.localStorage.clear();
  }
}
