import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationText'
})
export class DurationTextPipe implements PipeTransform {

  transform(duration: number): string {
    switch(duration) {
      case 15:
        return 'QUARTER HOUR';
      case 30:
        return 'HALF HOUR';
      case 60: 
        return 'HOUR';
      default: 
        return '';
    }
  }

}
