import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { ChangePasswordComponent } from './change-password.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [ButtonModule, CommonModule, FormsModule, PipesModule, ReactiveFormsModule, TranslateModule],
  exports: [ChangePasswordComponent],
})
export class ChangePasswordModule {}
