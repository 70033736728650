import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AuthActions } from './action-types';
import { concatMap, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService, ProfileService } from '../../services';
import { ProfileActions } from '../profile/action-types';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() => this.actions$.pipe(ofType(AuthActions.login)), { dispatch: false });

  public logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        concatMap(() => this.authService.logout()),
      ),
    { dispatch: false },
  );

  public loginSuccess$ = createEffect(() => {
    let isManualLogin = false;

    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      concatMap(({ isManual }) => {
        isManualLogin = isManual;
        return this.profileService.getUserShort();
      }),
      map((res) => res.body),
      tap((user) => {
        // NOTE: handle login from root landing page, redirect to full profile but only if user logged in manually
        if (this.router.url === '/' && isManualLogin) this.router.navigate([`${user.username}`]);
      }),
      map((user) => AuthActions.userShortLoaded({ user })),
    );
  });

  public userShortReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.basicProfileInfoUpdateSuccess, AuthActions.reloadUserShort),
      concatMap(() => this.profileService.getUserShort()),
      map((res) => res.body),
      map((user) => AuthActions.userShortReloaded({ user })),
    ),
  );

  public authUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authUpdate),
      map(({ user }) => this.authService.setAuthTokens(user)),
      map(() => AuthActions.authUpdateSuccess()),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthenticationService,
    private profileService: ProfileService,
  ) {}
}
