import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDetailsComponent } from './payment-details.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PaymentDetailsComponent],
  imports: [CommonModule, TranslateModule],
  exports: [PaymentDetailsComponent],
})
export class PaymentDetailsModule {}
