<div class="availability-slot" *ngIf="slot">
  <button class="availability-slot__unclicked" *ngIf="!isClicked" (click)="onClick()">
    {{ slot.startDateTime }}
  </button>
  <div *ngIf="isClicked" class="availability-slot--clicked" appClickOutside (clickOutside)="onClickOutside()">
    <button disabled class="availability-slot__label">
      {{ slot.startDateTime }}
    </button>
    <app-button
      class="availability-slot__confirm"
      buttonLabel="{{ 'FAST_BOOKING.SELECT' | translate }}"
      [rounded]="true"
      (click)="onConfirm()"
    ></app-button>
  </div>
</div>
