import { Component, Input, OnInit } from '@angular/core';
import { CHANGE_NOTIFICATIONS } from '../../../utils';

@Component({
  selector: 'app-change-notifications-section',
  templateUrl: './change-notifications-section.component.html',
  styleUrls: ['./change-notifications-section.component.scss'],
})
export class ChangeNotificationsSectionComponent implements OnInit {
  @Input() sectionTitle: any;
  @Input() sectionSubtitle: any;

  public emailLabel = CHANGE_NOTIFICATIONS.EMAIL;
  public pushNotificationsLabel = CHANGE_NOTIFICATIONS.PUSH_NOTIFICATIONS;

  constructor() {}

  ngOnInit(): void {}
}
