import { Component, Input, OnInit } from '@angular/core';

import { BookingQuickInfoCardInfo } from '../../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstallAppModalComponent } from '../install-app-modal/install-app-modal.component';

@Component({
  selector: 'app-booking-quick-info-card',
  templateUrl: './booking-quick-info-card.component.html',
  styleUrls: ['./booking-quick-info-card.component.scss'],
})
export class BookingQuickInfoCardComponent implements OnInit {
  @Input() buttonLabel;
  @Input() cardContent: BookingQuickInfoCardInfo;
  @Input() statusClass;
  @Input() statusTitle;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  public openBooking(): void {
    this.modalService.open(InstallAppModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });
  }
}
