import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingQuickInfoCardComponent } from './booking-quick-info-card.component';
import { BookingStatusLabelModule } from '../../atoms/booking-status-label/booking-status-label.module';
import { CardButtonModule } from '../../atoms/card-button/card-button.module';

@NgModule({
  declarations: [BookingQuickInfoCardComponent],
  imports: [CommonModule, BookingStatusLabelModule, CardButtonModule],
  exports: [BookingQuickInfoCardComponent],
})
export class BookingQuickInfoCardModule {}
