import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentorship-modal',
  templateUrl: './mentorship-modal.component.html',
  styleUrls: ['./mentorship-modal.component.scss'],
})
export class MentorshipModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  constructor() {}

  ngOnInit(): void {}
}
