<div class="request-sent-card flex row">
  <div class="request-sent-card__content flex column">
    <app-status-label
      [statusClass]="'success--green'"
      [statusTitle]="requestQuickCardData?.requestStatusLabel || requestStatusLabel"
    ></app-status-label>
    <label class="request-sent-card__title">{{ requestQuickCardData?.gigTitle || gigTitle }}</label>
    <div class="request-sent-card__pro">
      <label>{{ requestQuickCardData?.proName || proName }}</label>
      <label *ngIf="requestQuickCardData?.city">&nbsp;·&nbsp;</label>
      <label *ngIf="requestQuickCardData?.city">{{ requestQuickCardData?.city }}</label>
    </div>
    <label class="request-sent-card__date flex align-center">{{
      requestQuickCardData?.bookingDate || bookingDate | date: 'longDate'
    }}</label>
  </div>
  <div class="request-sent-card__avatar-area flex column justify-start">
    <div
      *ngIf="requestQuickCardData?.profileImage || profileImage"
      class="request-sent-card__avatar"
      style="background-image: url({{ requestQuickCardData?.profileImage || profileImage }});"
    ></div>
  </div>
</div>
