<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div class="auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">Set up your Payout Method</p>
    </header>
    <form class="book-modal-add-payout__form auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm()">
      <app-book-modal-section [sectionTitle]="'Payout Method'">
        <app-payout-method-info></app-payout-method-info>
      </app-book-modal-section>

      <div>
        <hr />
        <div class="book-modal__send-btn-container">
          <app-button
            id="bookModalButton"
            [buttonDisabled]="!bookForm.valid"
            [buttonLabel]="buttonLabel"
            [buttonType]="'submit'"
          >
          </app-button>
        </div>
      </div>
    </form>
  </div>
</app-jammcard-modal>
