<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="auth-modal__install-info-content">
    <div class="auth-modal__input-section">
      <div class="edit-profile-form__control">
        <p class="edit-profile-form__label">{{ 'PROFILE.EDIT.NAME.TITLE' | translate }}</p>
        <input
          class="edit-profile-form__input"
          name="name"
          formControlName="name"
          [ngClass]="{ 'link-sent': linkSent }"
          type="text"
          required
          [placeholder]="labels?.NAME?.DESCRIPTION"
        />
      </div>

      <div *ngIf="name?.invalid && (name?.dirty || !name?.pristine)">
        <p *ngIf="name?.errors.required" class="help-block">{{ 'PROFILE.EDIT.NAME.VALIDATOR.REQUIRED' | translate }}</p>
        <p *ngIf="name?.errors.maxlength" class="help-block">
          {{ 'PROFILE.EDIT.NAME.VALIDATOR.MAX_LENGTH' | translate }}
        </p>
        <p *ngIf="name?.errors.minlength" class="help-block">
          {{ 'PROFILE.EDIT.NAME.VALIDATOR.MIN_LENGTH' | translate }}
        </p>
      </div>

      <div class="auth-modal__headline edit-profile-form__control">
        <p class="edit-profile-form__label">{{ 'PROFILE.EDIT.HEADLINE.TITLE' | translate }}</p>
        <textarea
          class="edit-profile-form__textarea"
          name="headline"
          formControlName="headline"
          [ngClass]="{ 'link-sent': linkSent }"
          rows="1"
          cols="20"
          [placeholder]="labels?.HEADLINE?.DESCRIPTION"
        ></textarea>
      </div>
      <div *ngIf="headline?.invalid && (headline?.dirty || !headline?.pristine)">
        <p *ngIf="headline?.errors?.maxlength" class="help-block">
          {{ 'PROFILE.EDIT.HEADLINE.VALIDATOR.MAX_LENGTH' | translate }}
        </p>
      </div>

      <div class="auth-modal__website edit-profile-form__control">
        <p class="edit-profile-form__label">{{ 'PROFILE.EDIT.WEBSITE.TITLE' | translate }}</p>
        <input
          class="edit-profile-form__input"
          name="website"
          formControlName="website"
          [ngClass]="{ 'link-sent': linkSent }"
          type="text"
          [placeholder]="labels?.WEBSITE?.DESCRIPTION"
        />
      </div>
      <div *ngIf="website?.invalid && (website?.dirty || !website?.pristine)">
        <p *ngIf="website?.errors.maxlength" class="help-block">
          {{ 'PROFILE.EDIT.WEBSITE.VALIDATOR.MAX_LENGTH' | translate }}
        </p>
      </div>

      <div class="auth-modal__gender edit-profile-form__control">
        <p class="edit-profile-form__label">{{ 'PROFILE.EDIT.GENDER.TITLE' | translate }}</p>
        <!-- <select class="edit-profile-form__select" (change)="changeGender($event)" formControlName="gender">
          <option value="" disabled>Enter your gender</option>
          <option *ngFor="let gender of genders" [ngValue]="gender">{{ gender | genderCase }}</option>
        </select> -->
        <ng-select
          (change)="changeGender($event)"
          [searchable]="false"
          [placeholder]="labels?.GENDER?.DESCRIPTION"
          formControlName="gender"
        >
          <ng-option *ngFor="let gender of genders" [value]="gender">{{ gender | genderCase }} </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
</form>
<app-button (click)="submit()" [buttonLabel]="'save'" [buttonDisabled]="disableSubmit"></app-button>
