import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingRating } from '../../../models';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-rate-experience-group',
  templateUrl: './rate-experience-group.component.html',
  styleUrls: ['./rate-experience-group.component.scss'],
})
export class RateExperienceGroupComponent implements OnInit {
  @Input() name: string;
  @Input() starNumber: number;
  @Output() starNumberChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  changeStarNumber(): void {
    this.starNumberChange.emit(this.starNumber);
  }
}
