import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessengerMobileNavComponent } from './messenger-mobile-nav.component';

@NgModule({
  declarations: [MessengerMobileNavComponent],
  imports: [CommonModule],
  exports: [MessengerMobileNavComponent],
})
export class MessengerMobileNavModule {}
