import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
})
export class EditModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  constructor() {}

  ngOnInit(): void {}
}
