<div class="settings-tab-content__container flex column">
  <div *ngIf="notificationsList" class="settings-tab-content__card">
    <!--       The following line was added for the new design, delete if code needs to be reverted to old design-->
    <p class="change-notifications__title">{{ changeNotificationsTitle }}</p>
    <app-change-notification
      [changeNotificationLabel]="newFollower"
      [changeNotificationChecked]="notificationsList?.following"
      (changeNotificationValue)="setNotificationValue('following', $event)"
    ></app-change-notification>
    <app-change-notification
      [changeNotificationLabel]="newMessage"
      [changeNotificationChecked]="notificationsList?.message"
      (changeNotificationValue)="setNotificationValue('message', $event)"
    ></app-change-notification>
    <app-change-notification
      [changeNotificationLabel]="newComment"
      [changeNotificationChecked]="notificationsList?.commented"
      (changeNotificationValue)="setNotificationValue('commented', $event)"
    ></app-change-notification>
    <app-change-notification
      [changeNotificationLabel]="newTag"
      [changeNotificationChecked]="notificationsList?.tagged"
      (changeNotificationValue)="setNotificationValue('tagged', $event)"
    ></app-change-notification>
    <app-change-notification
      [changeNotificationLabel]="newUpvote"
      [changeNotificationChecked]="notificationsList?.upvoted"
      (changeNotificationValue)="setNotificationValue('upvoted', $event)"
    ></app-change-notification>

    <!--      The following part of code was used for initial design that might be used in the future -->

    <!--      <app-change-notifications-section-->
    <!--        [sectionTitle]="messagesTitle"-->
    <!--        [sectionSubtitle]="messagesSubtitle"-->
    <!--      ></app-change-notifications-section>-->
    <!--      <app-change-notifications-section-->
    <!--        [sectionTitle]="bookingStatusUpdatesTitle"-->
    <!--        [sectionSubtitle]="bookingStatusUpdatesSubtitle"-->
    <!--      ></app-change-notifications-section>-->
    <!--      <app-change-notifications-section-->
    <!--        [sectionTitle]="remindersTitle"-->
    <!--        [sectionSubtitle]="remindersSubtitle"-->
    <!--      ></app-change-notifications-section>-->
    <!--      <app-change-notifications-section-->
    <!--        [sectionTitle]="generalAnnouncementsTitle"-->
    <!--        [sectionSubtitle]="generalAnnouncementsSubtitle"-->
    <!--      ></app-change-notifications-section>-->

    <!--      The part of code before this line was used for initial design that might be used in the future -->
  </div>

  <div class="settings-tab-content__button-container flex row justify-end">
    <app-button
      id="changeNotificationsSettingsButton"
      [buttonLabel]="buttonLabel"
      [buttonType]="'submit'"
      (clicked)="saveNotificationsSettings()"
    >
    </app-button>
  </div>
</div>
