import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BasicProfile, BookingRole } from '../../../models';
import { BookingService, ProfileService, ToastService } from '../../../services';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BookModalCompleteComponent } from './book-modal-complete.component';
import { BOOKING_STATUS_RESPONSE } from '../../../pages/bookings/bookings.config';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';
import { BookingActions } from '../../../store/booking/action-types';
import { getBookingFromUrl } from '../../../utils/operators';

@Component({
  template: '',
})
export class BookModalCompleteEntryComponent implements OnInit, OnDestroy {
  public modalInstance: any;
  public bookModalData: any;
  public modal$: Subscription;
  public sellerInfo$: Subscription;
  public router$: Subscription;

  constructor(
    public bookingService: BookingService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private toastService: ToastService,
  ) {}

  openModal(booking: BookingCurrent, user: BasicProfile): void {
    const isSeller = booking.role === BookingRole.SELLER;
    const isRated = booking.booking.rated;
    this.modalInstance = this.modalService.open(BookModalCompleteComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = { booking, user, isSeller, isRated };

    this.modalInstance.result.then(
      () => this.router.navigate(['bookings']),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH ? this.router.navigate(['bookings']) : null;
      },
    );
    this.store.dispatch(BookingActions.seenBookingById({ booking: booking.booking }));
  }

  ngOnInit() {
    const bookingIdRouteParam = this.route.snapshot.params.bookingId;

    this.modal$ = this.store
      .select(selectCurrentBooking)
      .pipe(
        take(1),
        mergeMap((booking) => getBookingFromUrl(booking, bookingIdRouteParam, this.store)),
      )
      .subscribe((booking) => {
        if (
          bookingIdRouteParam !== booking?.booking?.id.toString() ||
          booking?.booking?.status !== BOOKING_STATUS_RESPONSE.COMPLETED
        ) {
          this.showMessage('Action not allowed for selected booking!');
          this.router.navigate(['bookings']);
        } else {
          const profileId =
            booking?.role === BookingRole.SELLER
              ? booking?.booking?.booker?.profileId
              : booking?.booking?.bookee?.profileId;
          this.profileService.getBasicProfile(profileId).subscribe((user) => this.openModal(booking, user.body));
        }
      });
  }

  ngOnDestroy() {
    this.activeModal.close();
    this.modal$.unsubscribe();
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
