import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalClickableLabelComponent } from './book-modal-clickable-label.component';

@NgModule({
  declarations: [BookModalClickableLabelComponent],
  exports: [BookModalClickableLabelComponent],
  imports: [CommonModule],
})
export class BookModalClickableLabelModule {}
