import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoModalThanksForContactingComponent } from './info-modal-thanks-for-contacting.component';
import { InfoModalModule } from '../../templates/info-modal/info-modal.module';

@NgModule({
  declarations: [InfoModalThanksForContactingComponent],
  imports: [CommonModule, InfoModalModule],
  exports: [InfoModalThanksForContactingComponent],
})
export class InfoModalThanksForContactingModule {}
