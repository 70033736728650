<div class="jammboard-gig-main-card-details__container data-input-modal">
  <div class="jamboard-gig-main-card-details__header data-input-modal__header">
    <button class="data-input-modal__header-close reset-button-styles" type="button" (click)="closeModal()">
      <img src="assets/images/icons/close-black.svg" />
    </button>
    <h4 class="data-input-modal__header-title">{{ 'JAMMBOARD.GIG' | translate }}</h4>
  </div>
  <div class="jammboard-gig-main-card-details__content data-input-modal__content">
    <app-jammboard-gig-main-card [gigData]="opportunity" [isInlineCard]="true"></app-jammboard-gig-main-card>
    <div class="jammboard-gig-main-card-details__details">
      <h5 class="jammboard-gig-main-card-details__details-title">{{ 'JAMMBOARD.DETAILS' | translate }}</h5>
      <p class="jammboard-gig-main-card-details__details-text">{{ opportunity?.description }}</p>
    </div>
    <div
      *ngIf="!isCurrentUserGigOwner && opportunity.status === opportunityStatusActive && isUserLoggedIn"
      class="jammboard-gig-main-card-details__show-interest"
    >
      <h5 class="jammboard-gig-main-card-details__show-interest-title">
        {{ 'JAMMBOARD.SHOW_INTEREST' | translate }}
      </h5>
      <textarea class="jammboard-gig-main-card-details__show-interest-text" [(ngModel)]="text"></textarea>
    </div>
    <div
      *ngIf="!isCurrentUserGigOwner && opportunity.status === opportunityStatusActive"
      class="jammboard-gig-main-card-details__button-wrapper"
    >
      <app-button
        *ngIf="isUserLoggedIn"
        class="jammboard-gig-main-card-details__button"
        buttonLabel="{{ 'JAMMBOARD.SEND_MESSAGE' | translate }}"
        [rounded]="true"
        [buttonDisabled]="text === ''"
        (click)="sendMessage(text)"
      ></app-button>
      <button
        *ngIf="isUserLoggedIn"
        class="jammboard-gig-main-card-details__share-button reset-button-styles copy-to-clipboard"
        title="Copy gig link"
      >
        <img src="assets/images/icons/share-link.svg" alt="Share Gig" />
      </button>
      <app-button
        *ngIf="!isUserLoggedIn"
        class="jammboard-gig-main-card-details__button"
        buttonLabel="{{ 'LOGIN.LOG_IN' | translate }}"
        [rounded]="true"
        (click)="showLoginModal()"
      ></app-button>
    </div>
    <div
      *ngIf="isCurrentUserGigOwner && opportunity.status === opportunityStatusActive"
      class="jammboard-gig-main-card-details__button-wrapper"
    >
      <app-button buttonLabel="{{ 'JAMMBOARD.EDIT' | translate }}" [rounded]="true" (click)="onEdit()"></app-button>
      <button
        *ngIf="isUserLoggedIn"
        class="jammboard-gig-main-card-details__share-button reset-button-styles copy-to-clipboard"
        title="Copy gig link"
      >
        <img src="assets/images/icons/share-link.svg" alt="Share Gig" />
      </button>
    </div>
    <div
      *ngIf="isCurrentUserGigOwner && opportunity.status === opportunityStatusFilled"
      class="jammboard-gig-main-card-details__button-wrapper"
    >
      <app-button
        buttonLabel="{{ 'JAMMBOARD.MARK_AS_NOT_FILLED' | translate }}"
        [rounded]="true"
        (click)="onMarkAsNotFilled()"
      ></app-button>
    </div>
  </div>
</div>
