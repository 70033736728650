<div class="change-notification-section">
  <header>
    <p class="change-notification-section__title">{{ sectionTitle }}</p>
    <p class="change-notification-section__subtitle">{{ sectionSubtitle }}</p>
  </header>
  <section>
    <app-change-notification [changeNotificationLabel]="emailLabel"></app-change-notification>
    <hr />
    <app-change-notification [changeNotificationLabel]="pushNotificationsLabel"></app-change-notification>
    <hr />
  </section>
</div>
