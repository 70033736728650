import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginButtonComponent } from './login-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginButtonComponent],
  imports: [CommonModule, TranslateModule],
  exports: [LoginButtonComponent],
})
export class LoginButtonModule {}
