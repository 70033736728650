import { Component, Input } from '@angular/core';

import { RateOld } from '../../../models';

@Component({
  selector: 'app-rates-accordion-card',
  templateUrl: './rates-accordion-card.component.html',
  styleUrls: ['./rates-accordion-card.component.scss'],
})
export class RatesAccordionCardComponent {
  @Input() ratesItem: RateOld;

  constructor() {}
}
