<section class="pro-card__container flex row">
  <a routerLink="/{{ proCardInfo?.username }}" rel="noreferrer" target="_blank">
    <div class="pro-card__image-container flex justify-center align-center">
      <div
        *ngIf="proCardInfo?.profileImage?.thumbnailMedium"
        class="pro-card__image"
        style="background-image: url({{ proCardInfo?.profileImage?.thumbnailMedium }});"
      ></div>
      <div *ngIf="!proCardInfo?.profileImage?.thumbnailMedium" class="pro-card__image no-avatar-image"></div>
    </div>
  </a>
  <div class="pro-card__info-container flex column">
    <div>
      <div class="pro-card__name-container flex row align-center">
        <span class="pro-card__name">
          {{ proCardInfo?.name }}
        </span>
        <img
          *ngIf="proCardInfo?.verified"
          class="featured-card__mark"
          alt="Verified checkmark"
          src="assets/images/icons/verified3x.png"
        />
      </div>
      <label class="pro-card__headline">{{ proCardInfo?.headline }}</label>
    </div>
    <div class="pro-card__buttons-container flex row align-center">
      <a class="pro-card__button-profile" routerLink="/{{ proCardInfo?.username }}" rel="noreferrer" target="_blank">
        <app-button [inverted]="true" [buttonLabel]="buttonLabelProfile"></app-button>
      </a>
    </div>
  </div>
</section>
