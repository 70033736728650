import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaModalComponent } from './media-modal.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [MediaModalComponent],
  imports: [CommonModule, PipesModule],
  exports: [MediaModalComponent],
})
export class MediaModalModule {}
