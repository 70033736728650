import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-messenger-input-group',
  templateUrl: './messenger-input-group.component.html',
  styleUrls: ['./messenger-input-group.component.scss'],
})
export class MessengerInputGroupComponent implements OnInit {
  @Output('focus') focus = new EventEmitter<any>();
  @Output('blur') blur = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public inputFocus(): void {
    this.focus.emit();
  }

  public inputBlur(): void {
    this.blur.emit();
  }
}
