import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalPendingRequestComponent } from './book-modal-pending-request.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { ModalModule } from '../../templates/modal/modal.module';
import { AvatarGroupModule } from '../../molecules/avatar-group/avatar-group.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { PaymentDetailsModule } from '../../molecules/payment-details/payment-details.module';
import { BookModalPendingRequestEntryComponent } from './book-modal-pending-request-entry.component';

@NgModule({
  declarations: [BookModalPendingRequestComponent, BookModalPendingRequestEntryComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ModalModule,
    AvatarGroupModule,
    TranslateModule,
    FormsModule,
    BookModalSectionModule,
    PaymentDetailsModule,
  ],
  exports: [BookModalPendingRequestComponent, BookModalPendingRequestEntryComponent],
})
export class BookModalPendingRequestModule {}
