import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';

import { Observable, of } from 'rxjs';

@Injectable()
export class BrowserstateInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const transferKey = makeStateKey(request.url);
    const storedResponse = this.transferState.get<any>(transferKey, null);

    if (storedResponse) {
      // NOTE: Remove after using once so the next potential call on the browser side does not use this stale data
      this.transferState.remove(transferKey);
      const response = new HttpResponse({ body: storedResponse, status: 200 });
      return of(response);
    }

    return next.handle(request);
  }
}
