import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-switch',
  templateUrl: './button-switch.component.html',
  styleUrls: ['./button-switch.component.scss'],
})
export class ButtonSwitchComponent {
  @Input() isChecked: boolean = false;
  @Output() changeState: EventEmitter<boolean> = new EventEmitter(false);

  public checkboxID: number;

  constructor() {
    this.checkboxID = Math.random() * 100;
  }

  public onCheck(event: any): void {
    this.isChecked = event.target.checked;
    this.changeState.emit(this.isChecked);
  }
}
