import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ASSETS_PATH, EVENT_NAME_PREFIX, PLACEHOLDER_IMAGE_URL } from '../../../utils';
import { InfoModalSuccessComponent } from '../../organisms/info-modal-success/info-modal-success.component';
import { InfoService } from '../../../services';
import { TruncatedProfile } from '../../../models';

@Component({
  selector: 'app-seller-profile-card',
  templateUrl: './seller-profile-card.component.html',
  styleUrls: ['./seller-profile-card.component.scss'],
})
export class SellerProfileCardComponent implements OnInit {
  @Input() noButtons = false;
  @Input() hideBookButton = false;
  // TODO(Jovana): Remove proId and proUsername from store, models, etc. (it is already contained in TruncatedProfile model)
  @Input() proId?: number;
  @Input() proTruncatedProfile: TruncatedProfile;
  @Input() proUsername?: string;
  @Input() hasAvailability?: boolean;
  @Input() isSubscribed?: boolean;

  @Output() closeModal = new EventEmitter<any>();

  public coverImageForDisplay: string;
  public profileImageForDisplay: string;
  public readonly verifiedCheckmark = ASSETS_PATH.VERIFIED_CHECKMARK;
  public readonly closeIcon = ASSETS_PATH.CLOSE_WHITE_ICON;
  public readonly jammcardLogo = ASSETS_PATH.JAMMCARD_MENTORS_LOGO_WHITE;
  public rateLabel = '1 on 1 video mentorship';
  public modalInstance: any;
  public buttonShouldStick: boolean = false;
  public isMobile: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private infoService: InfoService,
    public modalService: NgbModal,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.profileImageForDisplay =
      this.proTruncatedProfile?.profileImage?.thumbnailSmall || PLACEHOLDER_IMAGE_URL.NO_AVATAR;
    this.coverImageForDisplay = this.proTruncatedProfile?.coverImage?.thumbnailLarge || PLACEHOLDER_IMAGE_URL.NO_COVER;
    this.isMobile = this.deviceService.isMobile();
  }

  public onBookClicked(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'book-button',
      profileId: this.proTruncatedProfile.profileId,
      mentorName: this.proTruncatedProfile.name,
    });
    // Initiate Fast Booking Flow
    // Using router.navigate with relativeTo set to activatedRoute does not work as expected, navigates to root/availability
    // this.router.navigate(['availability'], { relativeTo: this.activatedRoute });
    const newUrl = `${this.currentUrlWithoutQueryParams()}/availability`;
    this.router.navigateByUrl(newUrl);
  }

  public onNotifyMeClicked(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'waitlist-button',
      profileId: this.proTruncatedProfile.profileId,
      mentorName: this.proTruncatedProfile.name,
    });
    const newUrl = `${this.currentUrlWithoutQueryParams()}/notify-me`;
    this.router.navigateByUrl(newUrl);
  }

  // Leave Waitlist button is removed from truncated profile, logic will be temporary commented but not removed due to possibility to change it back
  // public onUnsubscribeClicked(): void {
  //   const leaveWaitlistId = this.proTruncatedProfile.profileId;
  //   this.router.navigate(['/mentors'], { queryParams: { leaveWaitlistId: leaveWaitlistId } });
  // }

  public onCloseClicked(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'sellerprofile-card-close',
    });
    this.closeModal.emit();
  }

  // Duplicated code, refactor
  public openSuccessModal(): void {
    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    // TODO (Jovana): Add translations
    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: `You are on the list!`,
        description: `You’ll be amongst the first to know when ${this.proTruncatedProfile.name} drops time slots `,
        buttonLabel: 'DONE',
        backgroundImageClass: 'success',
        redirectTo: '/mentors',
      },
    };
  }

  public topVisible(event): void {
    if (!this.buttonShouldStick) {
      this.buttonShouldStick = !event.isVisible;
    }
  }

  public bottomVisible(event): void {
    if (this.buttonShouldStick && event.isVisible) {
      this.buttonShouldStick = false;
    }
  }

  private currentUrlWithoutQueryParams(): string {
    // The sponsored link (FB adds for expamle) links to truncated profile but with some added querry params so when adding further navigation
    // we need to remove the querry params first and than append the url fragment
    const urlTree = this.router.parseUrl(this.router.routerState.snapshot.url);
    urlTree.queryParams = {};
    return urlTree.toString();
  }
}
