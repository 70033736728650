import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationSettingsMenuComponent } from './notification-settings-menu.component';

@NgModule({
  declarations: [NotificationSettingsMenuComponent],
  imports: [CommonModule],
  exports: [NotificationSettingsMenuComponent],
})
export class NotificationSettingsMenuModule {}
