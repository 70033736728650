import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-messenger-label',
  templateUrl: './messenger-label.component.html',
  styleUrls: ['./messenger-label.component.scss'],
})
export class MessengerLabelComponent implements OnInit {
  @Input() dateTime: Date;
  @Input() bookingStatusLabel?: string;

  constructor() {}

  ngOnInit(): void {}
}
