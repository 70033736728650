import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallAppModalComponent } from './install-app-modal.component';
import { InfoModalModule } from '../../templates/info-modal/info-modal.module';
import { AppsButtonsGroupModule } from '../../molecules/apps-buttons-group/apps-buttons-group.module';

@NgModule({
  declarations: [InstallAppModalComponent],
  imports: [CommonModule, InfoModalModule, AppsButtonsGroupModule],
  exports: [InstallAppModalComponent],
})
export class InstallAppModalModule {}
