import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequestQuickCardComponent } from './request-quick-card.component';
import { BookingStatusLabelModule } from '../../atoms/booking-status-label/booking-status-label.module';

@NgModule({
  declarations: [RequestQuickCardComponent],
  imports: [CommonModule, BookingStatusLabelModule],
  exports: [RequestQuickCardComponent],
})
export class RequestQuickCardModule {}
