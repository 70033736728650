import { createAction, props } from '@ngrx/store';
import { CurrentUserShort, User } from '../../models';
interface loginData {
  id: number;
  email: string;
}

export const login = createAction('[Auth] User Login Attempt', props<{ user: User }>());
export const loginSuccess = createAction('[Auth] User Logged In Successfully', props<{ isManual?: boolean }>());
export const reloadUserShort = createAction('[Auth] User Short Reload');
export const userShortLoaded = createAction(
  '[Auth Effect] Loaded Logged In User Short Info',
  props<{ user: CurrentUserShort }>(),
);
export const userShortReloaded = createAction(
  '[Auth Effect] Reloaded User Short Info',
  props<{ user: CurrentUserShort }>(),
);
export const register = createAction('[Auth] User Register', props<{ user: User }>());
export const logout = createAction('[Auth] User Logged Out');
export const authUpdate = createAction('[Auth] Auth tokens update', props<{ user: User }>());
export const authUpdateSuccess = createAction('[Auth] Auth tokens update success');
