<div class="more__dropdown-container" ngbDropdown placement="bottom-right">
  <button class="more__button reset-button-styles" id="moreDropdown" ngbDropdownToggle>
    <img src="assets/images/icons/menu.svg" />
  </button>
  <div class="more__dropdown" ngbDropdownMenu aria-labelledby="moreDropdown">
    <div class="dropdown__title uppercase">{{ 'HEADER.MORE_JAMMCARD' | translate }}</div>
    <a class="dropdown__link" routerLink="/mentors" ngbDropdownItem>
      {{ 'HEADER.MENTORS' | translate }}
    </a>
    <a class="dropdown__link" href="{{ url.JAMMCARD_COM }}" target="_blank" rel="noreferrer" ngbDropdownItem>
      {{ 'HEADER.JAMMCARD_WEBSITE' | translate }}
    </a>
    <a class="dropdown__link" href="{{ url.ABOUT }}" target="_blank" rel="noreferrer" ngbDropdownItem>
      {{ 'HEADER.ABOUT' | translate }}
    </a>
    <a class="dropdown__link" href="{{ url.JAMMJAM }}" target="_blank" rel="noreferrer" ngbDropdownItem>
      {{ 'HEADER.JAMMJAM' | translate }}
    </a>
    <a class="dropdown__link" href="{{ url.SAMPLES }}" target="_blank" rel="noreferrer" ngbDropdownItem>
      {{ 'HEADER.SAMPLES' | translate }}
    </a>
    <a class="dropdown__link" href="{{ url.BLOG }}" target="_blank" rel="noreferrer" ngbDropdownItem>
      {{ 'HEADER.BLOG' | translate }}
    </a>
    <a class="dropdown__link" routerLink="/faq" ngbDropdownItem>
      {{ 'HEADER.FAQ' | translate }}
    </a>
    <a class="dropdown__link" routerLink="/tos" ngbDropdownItem>
      {{ 'HEADER.TERMS_OF_SERVICE' | translate }}
    </a>
  </div>
</div>
