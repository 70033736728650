import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerImageComponent } from './messenger-image.component';

@NgModule({
  declarations: [MessengerImageComponent],
  imports: [CommonModule],
  exports: [MessengerImageComponent],
})
export class MessengerImageModule {}
