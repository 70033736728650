import { Component, Inject, Input, OnInit } from '@angular/core';
import { ProCardDTO } from '../../../models';

@Component({
  selector: 'app-pro-cards-list',
  templateUrl: './pro-cards-list.component.html',
  styleUrls: ['./pro-cards-list.component.scss'],
})
export class ProCardsListComponent implements OnInit {
  @Input() proCardsList: ProCardDTO[];

  constructor() {}

  ngOnInit(): void {}
}
