<div class="notification-settings-tab-group" #notificationSettingsTabGroup *ngIf="notificationSettings$ | async">
  <div
    class="notification-settings-tab flex"
    *ngFor="let notificationSetting of notificationSettings$ | async; let i = index"
    [class.notification-settings-tab--active]="notificationSetting?.notificationType === activeTab?.notificationType"
    #notificationSettingsTab
  >
    <div class="notification-settings-tab-selector"></div>
    <button class="notification-settings-tab-button flex" (click)="selectTab(notificationSetting)">
      <img
        src="assets/images/icons/notification-settings-{{ notificationSetting?.notificationType.toLowerCase() }}.svg"
        alt="{{ notificationSettings?.notificationType }}"
        class="notification-settings-tab-button__icon"
      />
      {{ 'NOTIFICATION_SETTINGS.' + notificationSetting?.notificationType + '_NOTIFICATION' | translate }}
    </button>
  </div>
</div>
