<app-date-time-label [dateTime]="messageGroup?.messages[0].dateCreated"></app-date-time-label>
<section class="my-message-group__container flex row justify-end">
  <div class="flex column align-end">
    <ng-container *ngIf="!messageGroup?.opportunity; else opportunityMessage">
      <div *ngFor="let message of messageGroup?.messages">
        <app-my-message
          *ngIf="message.type === 'text'; else messageMedia"
          [messageText]="message.body"
        ></app-my-message>
        <ng-template #messageMedia>
          <app-my-image [media]="message.media"></app-my-image>
        </ng-template>
        <div *ngIf="message.index === lastSeenMessageIndex" class="my-message-group__seen-label">Seen</div>
      </div>
    </ng-container>
    <ng-template #opportunityMessage>
      <app-messenger-opportunity-card [message]="messageGroup?.messages[0]"></app-messenger-opportunity-card>
    </ng-template>
  </div>
  <app-message-avatar class="message-avatar__container flex column" [avatarImage]="avatarImage | profileImage">
  </app-message-avatar>
</section>
