import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmAndPayBooking, PaymentCard, PaymentMethodType, TruncatedProfile } from '../../../models';
import {
  BookingService,
  InfoService,
  LocalStorageService,
  PaymentService,
  ProfileService,
  ToastService,
} from '../../../services';
import {
  AFFILIATE_CAMPAIGN_DATA,
  constructURLAccordingToSourceType,
  EVENT_NAME_PREFIX,
  scrollToBottom,
  SERVICE_BOOKING_GUEST,
} from '../../../utils';
import { PAYMENT_METHOD_ID_PLACEHOLDER } from '../../../pages/bookings/bookings.config';
import { FastBookingData, AvailabilitySlotLocked } from '../../../models/rates-and-services';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';

@Component({
  selector: 'app-book-modal-fast-checkout',
  templateUrl: './book-modal-fast-checkout.component.html',
  styleUrls: ['./book-modal-fast-checkout.component.scss'],
})
export class BookModalFastCheckoutComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public cardsData: PaymentCard[];
  public fastBookingData: FastBookingData;
  public lockedAvailabilitySlots: AvailabilitySlotLocked;
  public newPaymentMethodCreated: boolean = false;
  public newPaymentMethodId: string;
  public paymentDetailsTitle: string;
  public rateId: number;
  public truncatedProfile: TruncatedProfile;
  public openedFromWebView: boolean;

  public fromParent: {
    truncatedProfile: TruncatedProfile;
    lockedAvailabilitySlots: AvailabilitySlotLocked;
    rateId: number;
    backUrl: string;
    openedFromWebView?: boolean;
  };

  public fetchingCardData: boolean = false;
  public modalInstance: any;
  public processingConfirmation: boolean;
  public selectedPaymentMethodId: string;
  public selectedPaymentMethodType: PaymentMethodType;

  public buttonLabel: string;
  public paymentLabel: string;

  public addNewPaymentMethod$ = new Subject<string>();
  public cardsData$: BehaviorSubject<PaymentCard[]> = new BehaviorSubject(null);
  public preselectCard$: BehaviorSubject<string> = new BehaviorSubject(null);
  public start3DSecure$ = new Subject<any>();

  public bookingStardDate: number;
  public bookingEndDate: number;
  public assignedId: string;

  constructor(
    private activeModal: NgbActiveModal,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private infoService: InfoService,
    private localStorageService: LocalStorageService,
    private paymentService: PaymentService,
    private profileService: ProfileService,
    private translate: TranslateService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.processingConfirmation = false;
    this.translate.get('BOOK').subscribe((res: any) => {
      this.buttonLabel = res.CONFIRM_AND_PAY;
      this.paymentLabel = res.PAYMENT;
      this.paymentDetailsTitle = res.MENTORSHIP;
    });
    this.formatData();
  }

  public scrollContainerToBottom(myScrollContainer: Element): void {
    scrollToBottom(myScrollContainer);
  }

  public formatData(): void {
    this.truncatedProfile = this.fromParent.truncatedProfile;
    this.lockedAvailabilitySlots = this.fromParent.lockedAvailabilitySlots;
    this.rateId = this.fromParent.rateId;
    this.openedFromWebView = this.fromParent.openedFromWebView;
    this.getCardsInfo();
  }

  private getCardsInfo(): any {
    this.fetchingCardData = true;
    this.paymentService.getPaymentCards().subscribe((cardsRes) => {
      this.cardsData = cardsRes.body?.cards;
      this.cardsData$.next(this.cardsData);
      this.fetchingCardData = false;
    });
  }

  public formatDataForConfirmation(paymentMethodId: string): any {
    const data = {
      bookingDetails: {
        availabilitySlotsIds: this.lockedAvailabilitySlots.availabilitySlots.map((slot) => {
          return slot.id;
        }),
        rateId: this.rateId,
      },
      paymentDetails: {
        paymentMethodId: paymentMethodId || this.selectedPaymentMethodId,
      },
    };

    const affiliateCampaignData = this.localStorageService.get(AFFILIATE_CAMPAIGN_DATA);
    if (!!affiliateCampaignData) {
      return {
        ...data,
        affiliateCampaign: {
          cid: affiliateCampaignData.cid,
        },
      };
    } else return data;
  }

  public closeModal(event): any {
    /* Check if feature is opened from WebView. 
-     If it is, modal close route should have extra query params to indicate WebView exit */

    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: 'Are you sure you want to cancel the booking?',
        buttonExitLabel: 'YES',
        buttonStayLabel: 'NO',
        backgroundImageClass: 'success',
        exitMentorship: true,
        openedFromWebView: this.openedFromWebView,
      },
    };
    this.modalInstance.result.then((schouldCancel) => {
      if (schouldCancel) {
        this.profileService.unlockPendingSlots();
        return this.activeModal.close();
      }
    });
  }

  public submitForm(): any {
    this.processingConfirmation = true;
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, { element: 'confirm-and-pay' });

    if (this.selectedPaymentMethodId === PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD) {
      this.addNewPaymentMethod$.next(PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD);
    } else if (this.selectedPaymentMethodId === PAYMENT_METHOD_ID_PLACEHOLDER.NEW_BANK_ACCOUNT) {
      /* The booking confirmation with a new bank account, we are currently not implementing this possibility */
    } else {
      /* Confirmation of the booking with an existing payment method */
      this.confirmBooking({
        paymentMethodId: this.selectedPaymentMethodId,
        paymentMethodType: this.selectedPaymentMethodType,
      });
    }
    this.localStorageService.delete(SERVICE_BOOKING_GUEST);
  }

  public onPaymentMethodChanged({
    paymentMethodId,
    paymentMethodType,
  }: {
    paymentMethodId: string;
    paymentMethodType: PaymentMethodType;
  }): void {
    this.selectedPaymentMethodId = paymentMethodId;
    this.selectedPaymentMethodType = paymentMethodType;
  }

  public onProcessingFinished(e: any): void {
    this.processingConfirmation = false;

    if (e?.refetchCards && this.newPaymentMethodCreated) {
      // refetch all cards and select the newly added one - this should also hide the add new card form
      this.preselectCard$.next(this.newPaymentMethodId);
      this.getCardsInfo();
      this.newPaymentMethodCreated = false;
    }
  }

  public confirmWithNewPaymentMethod({ paymentMethodId, paymentMethodType }: ConfirmAndPayBooking): void {
    this.newPaymentMethodCreated = true;
    this.newPaymentMethodId = paymentMethodId;
    this.confirmBooking({ paymentMethodId, paymentMethodType });
  }

  private confirmBooking({ paymentMethodId, paymentMethodType }: ConfirmAndPayBooking): void {
    const fastBookingData = this.formatDataForConfirmation(paymentMethodId);
    this.bookingService.confirmFastBooking({ ...fastBookingData }).subscribe((res: any) => {
      if (res.status === 201) {
        if (res.body.paymentIntent.success) {
          // The booking is confirmed successfully
          this.bookingStardDate = res.body.booking.startDate;
          this.bookingEndDate = res.body.booking.endDate;
          this.assignedId = res.body.booking.assignedId;
          this.showSuccessModal();
        } else if (res.body.paymentIntent.requires_action) {
          // 3D Secure flow should be initiated
          this.start3DSecure$.next({
            clientSecret: res.body.paymentIntent.payment_intent_client_secret,
          });
        }
      }
    }, this.confirmationErrorHandler);
  }

  public on3DSecureSuccess({ paymentMethodId, paymentMethodType, paymentIntentId }: ConfirmAndPayBooking): void {
    const fastBookingData = this.formatDataForConfirmation(paymentMethodId);
    this.bookingService.confirmFastBookingAfter3DSecure(paymentIntentId, fastBookingData).subscribe((res: any) => {
      if (res.status === 201) {
        if (res.body.paymentIntent.success) {
          // The booking is confirmed successfully
          this.bookingStardDate = res.body.booking.startDate;
          this.bookingEndDate = res.body.booking.endDate;
          this.assignedId = res.body.booking.assignedId;
          this.showSuccessModal();
        }
      }
    }, this.confirmationErrorHandler);
  }

  private showSuccessModal(): void {
    /* Check if feature is opened from WebView. 
-     If it is, modal close route should have extra query params to indicate WebView exit */

    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    // TODO (Jovana): Add translations
    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: `Your session is booked!`,
        description: `A link to the video chat has been sent to your email and is also in your booking tab. Make sure to be on time and ready for your Mentor!`,
        buttonLabel: 'DONE',
        backgroundImageClass: 'success',
        redirectTo: constructURLAccordingToSourceType(
          '/bookings?tab=upcoming',
          this.fromParent.openedFromWebView,
          true,
        ),
        assignedId: this.assignedId,
        bookingStardDate: this.bookingStardDate,
        bookingEndDate: this.bookingEndDate,
        calendar: true,
        openedFromWebView: this.openedFromWebView,
      },
    };
  }

  private confirmationErrorHandler = (error: any) => {
    // Note: Some known edge-cases that we handle here:
    // - if one of or all time slots are deleted by the Mentor - status 400 is returned
    // - if the time slot is no longer available for locking/booking (locked/confirmed by another buyer) - status 403 is returned
    // - if the rate is removed by the Seller - status 412 is returned
    // TODO (Milan): Should everything else be handled with 'Something went wrong!' message?
    this.translate.get('TOAST_MESSAGE').subscribe((res) => {
      let errorMessage = res.SOMETHING_WENT_WRONG;
      let errorClass = 'black';

      if (error.status === 400) {
        errorMessage = res.SEEMS_THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE;
        errorClass = 'error';
      } else if (error.status === 403) {
        errorMessage = res.SEEMS_THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE;
        errorClass = 'error';
      } else if (error.status === 412) {
        errorMessage = res.SEEMS_THE_SELECTED_RATE_IS_NO_LONGER_AVAILABLE;
        errorClass = 'error';
      }
      this.toastService.showMessage(errorMessage, errorClass);
    });

    if (this.newPaymentMethodCreated) {
      // refetch all cards and select the newly added one - this should also hide the add new card form
      this.preselectCard$.next(this.newPaymentMethodId);
      this.getCardsInfo();
      this.newPaymentMethodCreated = false;
    }

    this.processingConfirmation = false;
  };
}
