import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.component.html',
})
export class MediaModalComponent implements OnInit {
  public mediaIndex: number;
  public mediaUrl: string;
  public mediaCaption: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    // @ts-ignore
    this.mediaIndex = this.fromParent.mediaIndex ?? null;
    // @ts-ignore
    this.mediaUrl = this.fromParent.mediaUrl ?? '';
    // @ts-ignore
    this.mediaCaption = this.fromParent.mediaCaption ?? '';
  }
}
