import { Component, OnInit, Input } from '@angular/core';

import { from, Observable } from 'rxjs';
import { Media } from '@twilio/conversations';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { map } from 'rxjs/operators';

import { formatSingleGalleryImage, GalleryImage } from '../../../utils';

@Component({
  selector: 'app-others-image',
  templateUrl: './others-image.component.html',
  styleUrls: ['./others-image.component.scss'],
})
export class OthersImageComponent implements OnInit {
  @Input() media: Media;
  public image: Observable<string> | any;
  public images: GalleryImage[] = [];
  public options: NgxGalleryOptions[] = [];
  public isLoading: boolean = true;
  constructor() {}

  ngOnInit(): void {
    this.image = from(this.media.getContentTemporaryUrl())
      .pipe(map((image) => formatSingleGalleryImage(image)))
      .subscribe((images) => {
        this.isLoading = false;
        this.images = [...images];
      });

    this.options = [
      {
        closeIcon: 'close-gallery',
        imageArrows: false,
        previewArrows: false,
        thumbnailsArrows: false,
        thumbnails: false,
      },
    ];
  }
}
