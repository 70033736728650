import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subscription, Subject } from 'rxjs';

import { NO_DATA, TWILIO } from '../../../utils';
import { BookingService, ToastService, TwilioService } from '../../../services';
import { BookingQuickInfoCardInfo, BookingByStatus } from '../../../models';
import { BUTTON_LABEL_QUICK_INFO_MAP, STATUS_QUICK_INFO_MAP } from '../../../pages/bookings/bookings.config';
import { IConversationParticipant, IMessageGroup } from '../messenger-content/messenger-content.config';

// NOTE: After migrating from Twilio Programable Chat to the Twilio Conversations library
// a 'channel' became a 'conversation' and a 'member' became a 'participant',
// however the component names and the class names remained the same as before the migration
// and are still including the words 'channel' and 'member'
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() messageGroups$: Subject<IMessageGroup[]>;
  @Input() loggedUserId: string;
  @Input() loggedUserUsername: string;
  @Input() loggedUserProfileImage: any;
  @Input() newMessage: boolean;
  @Input() conversationUniqueName$: Subject<string>;
  @Input() otherParticipant$: Subject<IConversationParticipant>;

  @Output() bottomReached: EventEmitter<any> = new EventEmitter<any>();
  @Output() topReached: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasBookingQuickInfo: EventEmitter<any> = new EventEmitter<any>();

  public activeConversationLastSeenMessageIndex: number = null;
  public clientConnected: boolean;
  public conversationUniqueName: string;
  public inputFieldIsFocused: boolean;
  public messageGroups: IMessageGroup[];
  public noDataSubtitle: string;
  public noDataTitle: string;
  public otherParticipant: IConversationParticipant;

  private bottomReachedSubscription$: Subscription;
  private bottomReached$ = new Subject<any>();
  private topReachedSubscription$: Subscription;
  private topReached$ = new Subject<any>();
  private subscription$ = new Subscription();

  private chatCont: any;

  public systemMessageIdentity: string = TWILIO.SYSTEM_MESSAGE_IDENTITY;

  public bookingQuickInfoData: BookingQuickInfoCardInfo;
  public buttonLabelMap: any;
  public statusInfoMap: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private bookingService: BookingService,
    private toastService: ToastService,
    private twilioService: TwilioService,
  ) {}

  ngOnInit(): void {
    this.buttonLabelMap = BUTTON_LABEL_QUICK_INFO_MAP;
    this.statusInfoMap = STATUS_QUICK_INFO_MAP;
    this.noDataSubtitle = NO_DATA.SUBTITLE.MESSENGER;
    this.noDataTitle = NO_DATA.TITLE.MESSENGER;
    this.chatCont = this.document.getElementById('chatMessages');

    // TODO (Milan): When the client is not connected (e.g. the Twilio token is getting updated after some time in idle state)
    // the chat container will temporarily show 'no-data' even if a conversation was previously open. A better UI would be to show some kind of
    // loader. This should be checked with the designer.
    this.subscription$.add(
      this.twilioService.clientConnected$.subscribe((clientConnected) => {
        this.clientConnected = clientConnected;
      }),
    );

    this.subscription$.add(
      this.otherParticipant$.subscribe((otherParticipant) => {
        this.otherParticipant = otherParticipant;

        if (this.otherParticipant) {
          this.getChatBookings(this.otherParticipant.profileId);
        }
      }),
    );

    this.subscription$.add(
      this.conversationUniqueName$.subscribe((uniqueName) => {
        this.conversationUniqueName = uniqueName;

        if (this.conversationUniqueName) {
          if (!this.topReachedSubscription$) {
            this.topReachedSubscription$ = this.topReached$.subscribe(() => {
              this.topReached.emit();
            });
          } else {
            this.topReachedSubscription$.unsubscribe();
            this.topReachedSubscription$ = this.topReached$.subscribe(() => {
              this.topReached.emit();
            });
          }

          if (!this.bottomReachedSubscription$) {
            this.bottomReachedSubscription$ = this.bottomReached$.subscribe(() => {
              this.bottomReached.emit();
            });
          } else {
            this.bottomReachedSubscription$.unsubscribe();
            this.bottomReachedSubscription$ = this.bottomReached$.subscribe(() => {
              this.bottomReached.emit();
            });
          }
        }
      }),
    );

    this.subscription$.add(
      this.messageGroups$.subscribe((groups) => {
        this.messageGroups = groups;
        this.twilioService.activeConversationPreviousScroll = this.chatCont.scrollTop;

        setTimeout(() => {
          if (!this.newMessage) {
            if (this.chatCont.scrollTop !== this.twilioService.activeConversationPreviousScroll) {
              this.chatCont.scrollTop = this.twilioService.activeConversationPreviousScroll;
            }
          } else {
            let lastMessageGroupIdentity = this.messageGroups[this.messageGroups.length - 1].identity;

            if (this.twilioService.activeConversationPreviousScroll === 0) {
              this.bottomReached$.next();
            } else if (lastMessageGroupIdentity === this.loggedUserId) {
              // NOTE: Manually scroll to bottom if the user scolled up and sends a message
              this.chatCont.scrollTop = 0;
              this.twilioService.activeConversationPreviousScroll = this.chatCont.scrollTop;
              this.bottomReached$.next();
            }
          }
        }, 0);
      }),
    );

    this.subscription$.add(
      this.twilioService.activeConversationLastSeenMessageIndex$.subscribe((index) => {
        this.activeConversationLastSeenMessageIndex = index;
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.bottomReachedSubscription$) {
      this.bottomReachedSubscription$.unsubscribe();
    }

    if (this.topReachedSubscription$) {
      this.topReachedSubscription$.unsubscribe();
    }

    this.subscription$.unsubscribe();
  }

  public getChatBookings(profileId: number): any {
    if (profileId) {
      this.bookingService.getBookingsForChat(profileId).subscribe(
        (chatBookings) => {
          if (chatBookings.body.length) {
            const filteredBookings = this.filterPastBookings(chatBookings.body);
            if (filteredBookings.length) {
              const bookingWithHighestDate = this.getElementWithHighestDate(filteredBookings);
              this.bookingQuickInfoData = this.mapToBookingsQuickInfoData(bookingWithHighestDate);
              this.hasBookingQuickInfo.emit(event);
            } else {
              this.bookingQuickInfoData = null;
            }
          } else {
            this.bookingQuickInfoData = null;
          }
        },
        (error) => {
          this.toastService.showMessage('Something went wrong');
        },
      );
    }
  }

  public filterPastBookings(array: BookingByStatus[]): BookingByStatus[] {
    return array.filter((e) => {
      return BUTTON_LABEL_QUICK_INFO_MAP[e.status] !== undefined && STATUS_QUICK_INFO_MAP[e.status] !== undefined;
    });
  }

  private mapToBookingsQuickInfoData(booking: BookingByStatus): BookingQuickInfoCardInfo {
    // NOTE: Scale down to only the needed properties
    return {
      bookee: booking.bookee,
      booker: booking.booker,
      gigTitle: booking.gigTitle,
      id: booking.id,
      price: booking.price,
      totalPrice: booking.totalPrice,
      status: booking.status,
    };
  }

  public getElementWithHighestDate(array: BookingByStatus[]): BookingByStatus {
    return array.reduce((a, b) => {
      return a.dates[0] < b.dates[0] ? a : b;
    });
  }

  public topVisible($event): void {
    const { isVisible } = $event;

    if (isVisible) {
      this.topReached$.next();
    }
  }

  public bottomVisible($event): void {
    const { isVisible } = $event;

    if (isVisible) {
      this.bottomReached$.next();
    }
  }

  public inputFocus(): void {
    this.inputFieldIsFocused = true;
  }

  public inputBlur(): void {
    this.inputFieldIsFocused = false;
  }
}
