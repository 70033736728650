import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateTimeLabelModule } from '../../atoms/date-time-label/date-time-label.module';
import { MessengerOpportunityCardComponent } from './messenger-opportunity-card.component';
import { MessageAvatarModule } from '../../atoms/message-avatar/message-avatar.module';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [MessengerOpportunityCardComponent],
  imports: [CommonModule, DateTimeLabelModule, MessageAvatarModule, PipesModule],
  exports: [MessengerOpportunityCardComponent],
})
export class MessengerOpportunityCardModule {}
