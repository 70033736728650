import { Component, OnInit } from '@angular/core';
import { TwilioService } from '../../../services';

@Component({
  selector: 'app-add-picture-button',
  templateUrl: './add-picture-button.component.html',
  styleUrls: ['./add-picture-button.component.scss'],
})
export class AddPictureButtonComponent implements OnInit {
  constructor(private twilioService: TwilioService) {}

  ngOnInit(): void {}

  public onImagesSelected(event): void {
    const images = [...(<File[]>event.target.files)];
    let formDataArray = [];
    images.forEach(() => (formDataArray = [...formDataArray, new FormData()]));
    formDataArray.forEach((formData, i) => formData.append('image', images[i], images[i].name));
    formDataArray.forEach((formData) => this.twilioService.sendMessage(formData));
  }
}
