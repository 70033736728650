import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProCardsListComponent } from './pro-cards-list.component';
import { ProCardModule } from '../../molecules/pro-card/pro-card.module';

@NgModule({
  declarations: [ProCardsListComponent],
  exports: [ProCardsListComponent],
  imports: [CommonModule, ProCardModule],
})
export class ProCardsListModule {}
