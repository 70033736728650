import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SERVICE_URL } from '../utils';
import { TEMP_STORAGE_API_KEY } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TempStorageService {
  constructor(private http: HttpClient) {}

  public storeData(data: any): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.USER}auth/temporary-storage`,
      { ...data },
      {
        headers: {
          'client-api-key': TEMP_STORAGE_API_KEY,
        },
        observe: 'response',
      },
    );
  }

  public fetchData(uuid: string): Observable<any> {
    return this.http.get(`${SERVICE_URL.USER}auth/temporary-storage/${uuid}`, {
      headers: {
        'client-api-key': TEMP_STORAGE_API_KEY,
      },
      observe: 'response',
    });
  }
}
