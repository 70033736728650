<div class="see-more flex column">
    <input type="checkbox" id="see-more-checkbox" [(ngModel)]="isOpened">
    <p 
        class="see-more__text" 
        [ngClass]="{ 'see-more__text--margin': this.shouldShowButton }"
        [ngStyle]="{ 'line-height': fontLineHeight + 'px'}" >
        {{ text }}
    </p>
    <div *ngIf="shouldShowButton" class="see-more-button__container flex"> 
        <label 
            *ngIf="!isOpened" 
            for="see-more-checkbox" 
            role="button" 
            class="see-more-button__more see-more-button uppercase">
            see more
        </label>
        <label 
            *ngIf="isOpened" 
            for="see-more-checkbox" 
            role="button" 
            class="see-more-button__less see-more-button uppercase">
            see less
        </label>
    </div>
</div>
