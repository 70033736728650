import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsContentModule } from '../../organisms/settings-content/settings-content.module';
import { UserPageComponent } from './user-page.component';

@NgModule({
  declarations: [UserPageComponent],
  imports: [CommonModule, SettingsContentModule],
  exports: [UserPageComponent],
})
export class UserPageModule {}
