import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { AuthenticationService, InfoService, LocalStorageService, ToastService } from '../../../services';
import { AuthModalData, ServiceType } from '../../../models';
import {
  AUTH_CONTROLLER,
  AUTH_PASSWORD_LENGTH,
  EVENT_NAME_PREFIX,
  RESET_PASS,
  SERVICE_BOOKING_GUEST,
  USER_EMAIL,
  reloadPage,
} from '../../../utils';
import { AppState } from '../../../store/app.reducers';
import { AuthActions } from '../../../store/auth/action-types';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
})
export class LoginModalComponent implements OnInit {
  @Input() linkSent: any;
  @Input() loginModalData: AuthModalData;
  @Input() buttonLabel: string;
  @Output() changeModal: EventEmitter<AuthModalData> = new EventEmitter(null);
  @Output() modalType: EventEmitter<string> = new EventEmitter(null);

  public loginForm: FormGroup;
  public loginRequestSending: boolean;
  public passwordDisplayed: boolean;
  public resetPasswordClicked: boolean;
  public signupModalData: AuthModalData;

  constructor(
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private infoService: InfoService,
    private formBuilder: FormBuilder,
    private localStorage: LocalStorageService,
    public modalService: NgbModal,
    private router: Router,
    private store: Store<AppState>,
    private toastService: ToastService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        },
      ],
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(AUTH_PASSWORD_LENGTH)],
        },
      ],
    });

    this.passwordDisplayed = false;
    if (this.localStorage.get(USER_EMAIL)) {
      this.email.setValue(this.localStorage.get(USER_EMAIL));
    }
  }

  public get email(): FormControl {
    return this.loginForm.get('email') as FormControl;
  }

  public get password(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  submitForm(form: NgForm): any {
    this.loginRequestSending = true;

    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'log-in-button',
      email: this.email.value,
    });

    this.authenticationService.login(this.email.value, this.password.value, this.loginModalData?.storageUUID).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.loginRequestSending = false;
          //TODO(Mladen): Handle the whole auth through ngrx.
          //this.store.dispatch(login(response.body));
          this.store.dispatch(AuthActions.loginSuccess({ isManual: true }));
          this.activeModal.close();
          if (this.localStorage.get(RESET_PASS) && this.localStorage.get(RESET_PASS).email === this.email.value) {
            this.router.navigate(['settings'], { queryParams: { tab: 'password' } });
          } else if (this.loginModalData.normalModal && !this.loginModalData?.book) {
            reloadPage(this.router);
          } else if (this.loginModalData?.book) {
            let url = null;
            if (this.localStorage.get(SERVICE_BOOKING_GUEST)?.serviceType === ServiceType.MENTORSHIP) {
              this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
              url = '?prepopulate=true';
            } else {
              url = this.router.url.includes('/book') ? '' : '/book';
            }
            reloadPage(this.router, url);
          }
        }
      },
      (error) => {
        this.loginRequestSending = false;
        if (error.status === 401) {
          this.password.setErrors({ invalid: true });
        } else if (error.status === 422) {
          // Go through all validators of inputs and mark dirty every input that is empty
          Object.keys(this.loginForm.value).forEach((validator) => {
            if (!this[validator].value) {
              this[validator].markAsDirty();
            }
          });
          if (this.email.value.length && error.error?.fieldErrors.some((err) => err.errorType === 'invalidEmail')) {
            this.email.markAsDirty();
            this.email.setErrors({ email: true });
          } else if (
            this.email.value &&
            error.error?.fieldErrors.some((err) => err.errorType === 'emailAlreadyExists')
          ) {
            this.password.markAsDirty();
            this.email.setErrors({ notUnique: true });
          } else if (
            this.password.value &&
            error.error?.fieldErrors.some((err) => err.errorType === 'passwordLengthNotSatisfied')
          ) {
            this.password.markAsDirty();
            this.password.setErrors({ minlength: true });
          }
          if (error.error?.fieldErrors.some((err) => err.errorType === 'invalidEmail')) {
            this.email.markAsDirty();
            this.email.setErrors({ email: true });
          } else if (error.error?.fieldErrors.some((err) => err.errorType === 'passwordLengthNotSatisfied')) {
            this.password.markAsDirty();
            this.password.setErrors({ minlength: true });
          }
        } else if (error.status === 403 && error.error?.generalError.message === 'userHasNotBeenVerified') {
          this.toastService.showMessage(
            'This account has not been verified. Please check your email, verify account, then come back.',
          );
        } else if (error.status === 404) {
          this.email.setErrors({ notUnique: true });
        } else {
          this.toastService.showMessage('Something went wrong');
        }
      },
    );
  }

  openSignupModal(): void {
    this.signupModalData = this.loginModalData;
    this.changeModal.emit(this.signupModalData);
    this.modalType.emit(AUTH_CONTROLLER.SIGNUP);
  }

  toggleDisplayPassword(): any {
    this.passwordDisplayed = !this.passwordDisplayed;
  }

  public resetPassword(): void {
    this.resetPasswordClicked = true;
  }

  public backToLogin(): void {
    this.resetPasswordClicked = false;
    // Reset password field when getting back to login
    this.password.reset();
  }
}
