import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { AvailabilitySlotComponent } from './availability-slot.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { ClickOutsideModule } from '../../../utils/directives/click-outside/click-outside.module';

@NgModule({
  declarations: [AvailabilitySlotComponent],
  imports: [CommonModule, ButtonModule, ClickOutsideModule, TranslateModule],
  exports: [AvailabilitySlotComponent],
})
export class AvailabilitySlotModule {}
