import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Actions, ofType } from '@ngrx/effects';
import { of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isNil } from 'lodash-es';

import { AppState } from '../../../store/app.reducers';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';
import { AuthenticationService, BookingService, LocalStorageService, ToastService } from '../../../services';
import { selectFastBookingProTruncatedProfile } from '../../../store/fast-booking/fast-booking.selectors';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';
import { ASSETS_PATH, MetaService, MODAL_DISMISS_REASON, replaceTags, TitleService } from '../../../utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TruncatedProfileModalComponent } from './truncated-profile-modal.component';
import { TruncatedProfile, ServiceProvision, ServiceType, AffiliateCampaign } from '../../../models';

@Component({
  template: '',
})
export class TruncatedProfileModalEntryComponent implements OnInit, OnDestroy {
  private modalInstance: any;
  private subscription$ = new Subscription();
  private navigateBackUrl: string;

  constructor(
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document,
    private metaService: MetaService,
    private modalService: NgbModal,
    private router: Router,
    private store: Store<AppState>,
    private titleService: TitleService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.navigateBackUrl = this.router.url.split('/featured')[0];

    // NOTE: This could be either profileId or username. In the case of username
    // we want to take out the case sensitivity so convert to lower case
    const routeId = (this.activatedRoute.snapshot.params.id as string).toLowerCase();

    this.store.dispatch(FastBookingActions.loadTruncatedProfile({ id: routeId }));

    this.subscription$.add(
      this.actions$.pipe(ofType(FastBookingActions.truncatedProfileLoadingFailed)).subscribe(() => {
        // TODO (Milan): Relative redirection acts unpredictable after adding /mentors landing page so absolute redirection is used
        // this will be made dynamic with booking via profile feature
        this.router.navigate([this.navigateBackUrl]);
      }),
    );

    this.subscription$.add(
      this.store
        .select(selectFastBookingProTruncatedProfile)
        .pipe(
          filter(
            (proTruncatedProfile) =>
              !isNil(proTruncatedProfile) &&
              (routeId === proTruncatedProfile?.username.toLowerCase() ||
                routeId === proTruncatedProfile?.profileId.toString()),
          ),
          take(1),
          switchMap((proTruncatedProfile) => {
            const servicesProvision = proTruncatedProfile?.servicesProvision;
            // TODO (Milan): Currently this is hardcoded for Mentorship service, eventually should be flexible
            const serviceForBooking = servicesProvision?.find((service) => service.type === ServiceType.MENTORSHIP);

            if (!serviceForBooking) {
              return this.translateService.get('TOAST_MESSAGE').pipe(
                tap((res) => {
                  this.toastService.showMessage(res.SOMETHING_WENT_WRONG);
                  // TODO (Milan): Relative redirection acts unpredictable after adding /mentors landing page so absolute redirection is used
                  // this will be made dynamic with booking via profile feature
                  this.router.navigate([this.navigateBackUrl]);
                }),
                switchMap(() => of({ proTruncatedProfile: null, serviceForBooking: null })),
              );
            } else {
              return of({ proTruncatedProfile, serviceForBooking });
            }
          }),
          switchMap(({ proTruncatedProfile, serviceForBooking }) => {
            if (this.authenticationService.isLoggedIn()) {
              return this.store.select(selectCurrentUserShort).pipe(
                filter((currentUser) => !isNil(currentUser)),
                take(1),
                switchMap((currentUser) => {
                  const hideBookButton = proTruncatedProfile?.profileId === currentUser.id;
                  return of({ proTruncatedProfile, serviceForBooking, hideBookButton });
                }),
              );
            } else return of({ proTruncatedProfile, serviceForBooking, hideBookButton: false });
          }),
        )
        .subscribe(({ proTruncatedProfile, serviceForBooking, hideBookButton }) => {
          if (proTruncatedProfile) {
            // TODO (Milan): Move meta tags setup into a functon, also it's not clear yet what the client wants exactly for this
            const title = proTruncatedProfile?.name;
            const description = 'Jammcard Mentors';
            this.titleService.setTitle(title);

            this.metaService.updateTag({ name: 'description', content: description });

            this.metaService.updateTag({
              name: 'og:url',
              content: this.document.location.protocol + this.document.location.origin,
            });
            this.metaService.updateTag({ name: 'og:title', content: title });
            this.metaService.updateTag({ name: 'og:description', content: description });
            // Use Mentors Landing image
            this.metaService.updateTag({
              name: 'og:image',
              content: proTruncatedProfile?.profileImage?.thumbnailMedium || ASSETS_PATH.MENTORS_THUMBNAIL,
            });
            this.metaService.updateTag({ name: 'twitter:title', content: title });
            this.metaService.updateTag({ name: 'twitter:description', content: description });
            this.metaService.updateTag({
              name: 'twitter:image',
              content: proTruncatedProfile?.profileImage?.thumbnailMedium || ASSETS_PATH.MENTORS_THUMBNAIL,
            });
            this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
            // end of meta setup

            this.openModal(proTruncatedProfile, serviceForBooking, hideBookButton);
          }
        }),
    );
  }

  // TODO(Jovana): Update and universalise tagging logic
  mapTruncatedData(truncatedData: TruncatedProfile): any {
    const truncatedDataTagged = cloneDeep(truncatedData);

    if (truncatedDataTagged.experiences.length > 0) {
      truncatedDataTagged.experiences.forEach((exp) => Object.assign(exp, { replacedExp: [] }));
      truncatedDataTagged.experiences.map((experience) => {
        experience.replacedExp = replaceTags(
          experience.descriptionWithTags,
          experience.descriptionTagList,
          experience.description,
        );
      });
    }
    return truncatedDataTagged;
  }

  openModal(proTruncatedProfile: TruncatedProfile, serviceForBooking: ServiceProvision, hideBookButton: boolean): void {
    this.modalInstance = this.modalService.open(TruncatedProfileModalComponent, {
      windowClass: 'mentorship-modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = {
      proTruncatedProfile: this.mapTruncatedData(proTruncatedProfile),
      serviceForBooking,
      hideBookButton,
    };
    this.modalInstance.result.then(
      () => {
        this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
        this.store.dispatch(FastBookingActions.clearFastBooking());
        // TODO (Milan): Relative redirection acts unpredictable after adding /mentors landing page so absolute redirection is used
        // this will be made dynamic with booking via profile feature
        this.router.navigate([this.navigateBackUrl]);
      },
      (reason: string) => {
        if (reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH) {
          this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
          this.store.dispatch(FastBookingActions.clearFastBooking());
          // TODO (Milan): Relative redirection acts unpredictable after adding /mentors landing page so absolute redirection is used
          // this will be made dynamic with booking via profile feature
          this.router.navigate([this.navigateBackUrl]);
        } else {
          return null;
        }
      },
    );
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
