import { Message } from '@twilio/conversations';

import { IImage } from '../../../models';

export const MEDIA_PREVIEW_PLACEHOLDER = 'Sent a photo';

export const MESSAGE_PREVIEW_MAX_LENGTH = 35;

export const MESSAGES_TO_LOAD_IN_BATCH = 20;

export interface ConversationSelectedEventData {
  conversationUniqueName: string;
  participantFullName: string;
}

export interface ParticipantAttributes {
  fullName: string;
  profileImage: string;
  profileId: number;
}

export interface IConversation {
  conversationSid: string;
  uniqueName: string;
  lastUpdateTime: number;
  lastMessage: string;
  hasUnreadMessages: boolean;
  otherParticipant: IConversationParticipant;
}

export interface IConversationParticipant {
  profileId: number;
  name: string;
  profileImage: IImage;
  username: string;
  lastReadMessageIndex: number;
}

export interface IMessengerNotificationResponse {
  unreadConversationsCount: number;
}

export interface IMessageGroup {
  identity: string;
  messages: Message[];
  opportunity?: boolean;
}
