import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodTabComponent } from './payment-method-tab.component';

@NgModule({
  declarations: [PaymentMethodTabComponent],
  imports: [CommonModule],
  exports: [PaymentMethodTabComponent],
})
export class PaymentMethodTabModule {}
