import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import {
  BOOKING_STATUS_RESPONSE,
  PAYMENT_METHOD_ID_PLACEHOLDER,
  STATUS_TITLE,
} from '../../../pages/bookings/bookings.config';
import { BookingByStatus, BasicProfile, ConfirmAndPayBooking, PaymentCard, PaymentMethodType } from '../../../models';
import { BookModalData } from '../book-modal/book-modal.component';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BookingService, PaymentService, ToastService } from '../../../services';
import { createBookModalData, createAvatarGroupData, PLACEHOLDER_IMAGE_URL, scrollToBottom } from '../../../utils';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';
import { IRequestQuickCardData } from '../../molecules/request-quick-card/request-quick-card.component';
import { deleteCurrentBooking, loadPendingBookings } from '../../../store/booking/booking.actions';

@Component({
  selector: 'app-book-modal-pay-to-confirm',
  templateUrl: './book-modal-pay-to-confirm.component.html',
  styleUrls: ['./book-modal-pay-to-confirm.component.scss'],
})
export class BookModalPayToConfirmComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public avatarGroupData: IAvatarGroupData;
  public booking: BookingCurrent;
  public bookModalPendingRequestData: BookingByStatus;
  public bookModalData: BookModalData;
  public cardsData: PaymentCard[];
  public cardsData$: BehaviorSubject<PaymentCard[]> = new BehaviorSubject(null);
  public fetchingCardData: boolean = false;
  public modalInstance: any;
  public newPaymentMethodCreated: boolean = false;
  public newPaymentMethodId: string;
  public processingConfirmation: boolean;
  public requestQuickCardData: IRequestQuickCardData;
  public selectedPaymentMethodId: string;
  public selectedPaymentMethodType: PaymentMethodType;
  public seller: BasicProfile;
  public fromParent: { booking: BookingCurrent; cardsData: PaymentCard[]; seller: BasicProfile };

  public buttonLabel: string;
  public paymentLabel: string;

  public addNewPaymentMethod$ = new Subject<string>();
  public preselectCard$: BehaviorSubject<string> = new BehaviorSubject(null);
  public start3DSecure$ = new Subject<any>();

  constructor(
    private activeModal: NgbActiveModal,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private paymentService: PaymentService,
    public translate: TranslateService,
    private toastService: ToastService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.processingConfirmation = false;
    this.translate.get('BOOK').subscribe((res: any) => {
      this.buttonLabel = res.CONFIRM_AND_PAY;
      this.paymentLabel = res.PAYMENT;
    });
    this.formatData();
  }

  public scrollContainerToBottom(myScrollContainer: Element): void {
    scrollToBottom(myScrollContainer);
  }

  //TODO(Mladen): Make Appropriate Classes for these object
  public formatData(): void {
    this.booking = this.fromParent.booking;
    this.cardsData = this.fromParent.cardsData;
    this.seller = this.fromParent.seller;
    const { bookee } = this.booking.booking;
    const { booker } = this.booking.booking;
    const { booking } = this.booking;

    this.cardsData$.next(this.cardsData);

    this.avatarGroupData = createAvatarGroupData(bookee);
    this.bookModalData = createBookModalData(this.seller);
    this.bookModalPendingRequestData = { ...booking };

    this.requestQuickCardData = {
      bookingDate: booking.dates[0],
      gigTitle: booking.gigTitle,
      loggedUserName: booker.name,
      profileImage: bookee.profileImage ? bookee.profileImage.url : PLACEHOLDER_IMAGE_URL.NO_AVATAR,
      proName: bookee.name,
      requestStatusLabel: STATUS_TITLE[booking.status],
      city: this.bookModalData.proCity,
    };
  }

  public closeModal(event): any {
    this.activeModal.close();
  }

  public submitForm(): any {
    this.processingConfirmation = true;
    if (this.selectedPaymentMethodId === PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD) {
      this.addNewPaymentMethod$.next(PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD);
    } else if (this.selectedPaymentMethodId === PAYMENT_METHOD_ID_PLACEHOLDER.NEW_BANK_ACCOUNT) {
      /* The booking confirmation with a new bank account, we are currently not implementing this possibility */
    } else {
      /* Confirmation of the booking with an existing payment method */
      this.confirmBooking({
        paymentMethodId: this.selectedPaymentMethodId,
        paymentMethodType: this.selectedPaymentMethodType,
      });
    }
  }

  private refetchCards(): void {
    this.fetchingCardData = true;
    this.paymentService.getPaymentCards().subscribe((cardsRes) => {
      this.cardsData = cardsRes.body?.cards;
      this.cardsData$.next(this.cardsData);
      this.fetchingCardData = false;
    });
  }

  public onPaymentMethodChanged({
    paymentMethodId,
    paymentMethodType,
  }: {
    paymentMethodId: string;
    paymentMethodType: PaymentMethodType;
  }): void {
    this.selectedPaymentMethodId = paymentMethodId;
    this.selectedPaymentMethodType = paymentMethodType;
  }

  public onProcessingFinished(e: any): void {
    this.processingConfirmation = false;

    if (e?.refetchCards && this.newPaymentMethodCreated) {
      // refetch all cards and select the newly added one - this should also hide the add new card form
      this.preselectCard$.next(this.newPaymentMethodId);
      this.refetchCards();
      this.newPaymentMethodCreated = false;
    }
  }

  public confirmWithNewPaymentMethod({ paymentMethodId, paymentMethodType }: ConfirmAndPayBooking): void {
    this.newPaymentMethodCreated = true;
    this.newPaymentMethodId = paymentMethodId;
    this.confirmBooking({ paymentMethodId, paymentMethodType });
  }

  private confirmBooking({ paymentMethodId, paymentMethodType }: ConfirmAndPayBooking): void {
    this.bookingService
      .confirmBooking(this.booking.booking.id, { paymentMethodId, paymentMethodType })
      .subscribe((res: any) => {
        if (res.status === 200) {
          if (res.body.paymentIntent.success && res.body.booking.status === BOOKING_STATUS_RESPONSE.CONFIRMED) {
            // The booking is confirmed successfully
            this.store.dispatch(loadPendingBookings());
            this.store.dispatch(deleteCurrentBooking());

            this.showSuccessModal();
          } else if (res.body.paymentIntent.requires_action) {
            // 3D Secure flow should be initiated
            this.start3DSecure$.next({ clientSecret: res.body.paymentIntent.payment_intent_client_secret });
          }
        }
      }, this.confirmationErrorHandler);
  }

  public on3DSecureSuccess({ paymentMethodId, paymentMethodType, paymentIntentId }: ConfirmAndPayBooking): void {
    this.bookingService
      .confirmBooking(this.booking.booking.id, { paymentMethodId, paymentMethodType, paymentIntentId })
      .subscribe((res: any) => {
        if (res.status === 200) {
          if (res.body.paymentIntent.success && res.body.booking.status === BOOKING_STATUS_RESPONSE.CONFIRMED) {
            // The booking is confirmed successfully
            this.store.dispatch(loadPendingBookings());
            this.store.dispatch(deleteCurrentBooking());

            this.showSuccessModal();
          } else {
          }
        }
      }, this.confirmationErrorHandler);
  }

  private confirmationErrorHandler = (error: any) => {
    this.showMessage('Something went wrong!');

    if (this.newPaymentMethodCreated) {
      // refetch all cards and select the newly added one - this should also hide the add new card form
      this.preselectCard$.next(this.newPaymentMethodId);
      this.refetchCards();
      this.newPaymentMethodCreated = false;
    }

    this.processingConfirmation = false;
  };

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }

  private showSuccessModal(): void {
    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: `Your booking with ${this.seller.name} is confirmed!`,
        description: `We've emailed you and ${this.seller.name} additional confirmation. You're all set!`,
        buttonLabel: 'DONE',
        backgroundImageClass: 'success',
        redirectTo: 'bookings',
      },
    };
  }
}
