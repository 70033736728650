import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGigMain, GigStatus } from '../../../models';
import { InfoModalSuccessComponent } from '../../organisms/info-modal-success/info-modal-success.component';
import { JammboardService, ToastService } from '../../../services';
import { ManageGigModalComponent } from '../../organisms/manage-gig-modal/manage-gig-modal.component';
import { MODAL_DISMISS_REASON, MY_OPPORTUNITIES_STATUS } from '../../../utils';

// NOTE: Exract const if reusing
const DUMMY_GIG_DATA = {
  title: '-------------------------',
  description: '-------------------------------------------------------------------',
  price: '$999.99',
  status: GigStatus.ACTIVE,
  id: null,
  featured: null,
  createdAt: null,
  date: new Date().getTime(),
  shareHash: null,
  shareUrl: null,
  cityWithCoordinatesDTO: { city: 'Los Angeles', lat: null, lon: null },
  skills: [
    { id: null, name: '-----' },
    { id: null, name: '-----' },
    { id: null, name: '-----' },
  ],
};

@Component({
  selector: 'app-jammboard-gig-main-card',
  templateUrl: './jammboard-gig-main-card.component.html',
  styleUrls: ['./jammboard-gig-main-card.component.scss'],
})
export class JammboardGigMainCardComponent implements OnInit {
  @Input() isDummyGig = false;
  @Input() gigData: IGigMain;
  @Input() showSubMenu: boolean;
  @Input() isInlineCard: boolean = false;
  @Output() emitReloadGigs: EventEmitter<boolean> = new EventEmitter(false);

  public isOpenSubMenu: boolean = false;
  public readonly calendarIcon = 'assets/images/icons/calendar-new.svg';
  public readonly locationIcon = 'assets/images/icons/location.svg';
  public readonly calendarGreyIcon = 'assets/images/icons/calendar-new-grey.svg';
  public readonly locationGreyIcon = 'assets/images/icons/location-grey.svg';
  public readonly opportunityStatusActive = MY_OPPORTUNITIES_STATUS.POSTED;
  public readonly opportunityStatusFilled = MY_OPPORTUNITIES_STATUS.FILLED;

  private modalInstance: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private jammboardService: JammboardService,
    private modalService: NgbModal,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    if (this.isDummyGig) {
      this.gigData = DUMMY_GIG_DATA;
    }
  }

  public onClickOutside(): void {
    if (this.isOpenSubMenu) this.isOpenSubMenu = false;
  }

  public onCardClick(): void {
    if (!this.isInlineCard && !this.isDummyGig) {
      //NOTE: The shareUrl is the full web url from which only the part concerning the
      // specific gig is needed for inside app navigation.
      // Extract if reusing this logic for managing url.
      const insideAppRoute = this.gigData.shareUrl.split('jammcard.com/jobs/')[1];

      this.router.navigate([insideAppRoute], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      });
    }
  }

  public onOwnerClick(ownerName: string): void {
    if (!this.isDummyGig) {
      this.router.navigateByUrl(`${ownerName}`);
    }
  }

  public onSubMenuClick(event: any, isOpenSubMenu: boolean): void {
    event.stopPropagation();
    // NOTE: a document element click manually dispatched for clickOutside directive to close
    // other open subMenus when a new one is opened
    this.document.dispatchEvent(new Event('click'));
    this.isOpenSubMenu = !isOpenSubMenu;
  }

  public onEditOpp(event: any): void {
    event.stopPropagation();
    this.jammboardService.getAllAvailableSkills().subscribe(
      (res) => {
        const availableSkills = res.body.slice();

        const modalInstanceRef = this.modalService.open(ManageGigModalComponent, {
          windowClass: 'data-input-modal-window',
          backdrop: 'static',
        });

        modalInstanceRef.componentInstance.fromParent = {
          availableSkills,
          manageGigForm: {
            title: this.gigData.title || null,
            skills: this.gigData.skills || null,
            description: this.gigData.description || null,
            price: this.gigData.price || null,
            date: this.gigData.date ? new Date(this.gigData.date) : null,
          },
          cityWithCoordinatesDTO: this.gigData.cityWithCoordinatesDTO,
          shouldEditOpp: true,
          gigId: this.gigData.id,
        };

        modalInstanceRef.result.then(
          () => {
            this.isOpenSubMenu = false;
          },
          (reason) => {
            this.isOpenSubMenu = false;
            if (reason === MODAL_DISMISS_REASON.ROUTE_SWITCH) {
              this.emitReloadGigs.emit(true);
              return null;
            }
          },
        );
      },
      () => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  public onMarkAsFilledOpp(event: any, filled: boolean): void {
    event.stopPropagation();
    this.isOpenSubMenu = false;
    this.jammboardService.markOpportunity(this.gigData.id, filled).subscribe(
      (res) => {
        this.emitReloadGigs.emit(true);
        let markMsg = '';
        markMsg = filled
          ? 'Your gig was successfuly marked as filled'
          : 'Your gig was successfuly marked as not filled';
        this.toastService.show(markMsg, {
          autohide: true,
          classname: 'success',
        });
      },
      () => {
        this.toastService.showMessage('Something went wrong');
      },
    );
  }

  public onDeleteOpp(event: any): void {
    event.stopPropagation();

    this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = {
      modalData: {
        title: 'Are you sure you want to delete this gig?',
        buttonExitLabel: 'YES',
        buttonStayLabel: 'NO',
        backgroundImageClass: 'success',
        exitMentorship: true,
      },
    };
    this.modalInstance.result.then((shouldDelete) => {
      if (shouldDelete) {
        this.jammboardService.deleteOpportunity(this.gigData.id).subscribe(
          (res) => {
            this.emitReloadGigs.emit(true);
            this.toastService.show('Your gig was successfuly deleted', {
              autohide: true,
              classname: 'error',
            });
          },
          () => {
            this.toastService.showMessage('Something went wrong');
          },
        );
      }
    });
  }
}
