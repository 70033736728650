import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMessageComponent } from './my-message.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [MyMessageComponent],
  exports: [MyMessageComponent],
  imports: [CommonModule, PipesModule],
})
export class MyMessageModule {}
