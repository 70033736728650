import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';
import { concatMap, filter, tap } from 'rxjs/operators';
import { selectProfileBasicById } from '../../../store/profile/profile.selectors';
import { BasicProfile, Gender } from '../../../models';
import { isNil } from 'lodash';
import { Actions, ofType } from '@ngrx/effects';
import { ProfileActions } from '../../../store/profile/action-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services';

@Component({
  selector: 'app-edit-profile-form',
  templateUrl: './edit-profile-form.component.html',
  styleUrls: ['./edit-profile-form.component.scss'],
})
export class EditProfileFormComponent implements OnInit, OnDestroy {
  @Input() profile: BasicProfile;
  public disableSubmit: boolean;
  public form: FormGroup;
  public subscription: Subscription = new Subscription();
  public labels;
  public user$: Observable<BasicProfile>;
  public genders = [Gender.MALE, Gender.FEMALE, Gender.NON_BINARY];
  public linkSent: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private store: Store,
    private actions: Actions,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    this.buildForm();
    this.setFormValues(this.profile);
    this.getLabels();
    this.handleSubmit();
  }

  private setFormValues(user: BasicProfile): void {
    this.name.setValue(user.name, { onlySelf: true });
    this.headline.setValue(user.headline, { onlySelf: true });
    this.website.setValue(user.website, { onlySelf: true });
    this.gender.setValue(user.gender, { onlySelf: true });
  }

  private getLabels(): void {
    this.subscription.add(
      this.translate.get('PROFILE').subscribe((res) => {
        this.labels = res['EDIT'];
      }),
    );
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: [
        '',
        {
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(40)],
          updateOn: 'change',
        },
      ],
      headline: ['', { validators: [Validators.maxLength(130)], updateOn: 'change' }],
      website: ['', { validators: [Validators.maxLength(120)], updateOn: 'change' }],
      gender: [''],
    });
  }

  private handleSubmit() {
    this.subscription.add(
      this.actions.pipe(ofType(ProfileActions.basicProfileInfoUpdateFail)).subscribe(() => {
        this.disableSubmit = false;
        this.toastService?.showMessage(this.labels['SAVE_FAILED']);
      }),
    );
    this.subscription.add(
      this.actions
        .pipe(
          ofType(ProfileActions.basicProfileInfoUpdateSuccess),
          tap(() => this.toastService?.showMessage(this.labels['SAVE_SUCCESS'])),
        )
        .subscribe(() => this.modalService.dismissAll()),
    );
  }

  public get name(): FormControl {
    return this.form?.get('name') as FormControl;
  }

  public get headline(): FormControl {
    return this.form?.get('headline') as FormControl;
  }

  public get website(): FormControl {
    return this.form?.get('website') as FormControl;
  }

  public get gender(): FormControl {
    return this.form?.get('gender') as FormControl;
  }

  public submit(): void {
    if (this.form.valid) {
      const basicInfoDTO = { ...this.form.value, location: this.profile.location };
      this.disableSubmit = true;
      this.store.dispatch(ProfileActions.basicProfileInfoUpdate({ basicInfoDTO }));
    }
  }

  public changeGender(newGender: Gender): void {
    this.gender.setValue(newGender, {
      onlySelf: true,
    });
  }

  public formatWebsiteUrl(url: string): string {
    return url.includes('http://') || url.includes('https://') ? url : `http://${url}`;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
