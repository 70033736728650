import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { EditProfileModalBasicComponent } from './edit-profile-modal-basic.component';
import { BasicProfile, CurrentUserShort } from '../../../models';
import { ProfileService, ToastService } from '../../../services';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectProfileBasicById } from '../../../store/profile/profile.selectors';
import { selectCurrentUserShort } from '../../../store/auth/auth.selectors';

@Component({
  template: '',
})
export class EditProfileModalBasicEntryComponent implements OnInit, OnDestroy {
  public bookModalData: any;
  private currentUserShort$: Observable<CurrentUserShort>;
  private currentUserShortSubscription$: Subscription;
  public modal$: Subscription;
  public modalInstance: any;
  public router$: Observable<any>;

  constructor(
    private modalService: NgbModal,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit() {
    this.currentUserShort$ = this.store.pipe(select(selectCurrentUserShort));
    this.currentUserShortSubscription$ = this.currentUserShort$.subscribe((currentUserShort) => {
      if (currentUserShort) {
        const currentUserShortId = currentUserShort.id;
        this.modal$ = this.store.select(selectProfileBasicById, { id: currentUserShortId }).subscribe((profile) => {
          if (profile && profile.profileId === currentUserShortId) {
            this.openModal(profile);
          } else {
            // NOTE(Jovana): For implementation purposes
            // this.showMessage('Action not allowed!');
            // this.router.navigate(['../'], {relativeTo: this.route});
          }
        });
      }
    });
  }

  openModal(profile: BasicProfile): void {
    this.modalInstance = this.modalService.open(EditProfileModalBasicComponent, {
      windowClass: 'edit-modal-window',
      backdrop: 'static',
    });

    // NOTE(Jovana): For implementation purposes
    this.modalInstance.componentInstance.fromParent = { profile };

    this.modalInstance.result.then(
      () => this.router.navigate(['../'], { relativeTo: this.route }),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH
          ? this.router.navigate(['../'], { relativeTo: this.route })
          : null;
      },
    );
  }

  // NOTE(Jovana): For implementation purposes
  /*  private showMessage(message: string): void {
      this.toastService.show(message, {
        delay: delayTime,
        autohide: true,
        classname: 'black',
      });
    }*/

  ngOnDestroy() {
    this.currentUserShortSubscription$?.unsubscribe();
    this.modal$?.unsubscribe();
  }
}
