export const isDateInPast = (year, month, day): boolean => {
  const date = new Date(year, month, day).getTime();
  const today = new Date().setHours(0, 0, 0, 0);
  return date < today;
};

export const isPastMonth = (date: Date): boolean => {
  const today = new Date();
  if (today.getFullYear() > date.getFullYear()) return true;
  if (today.getFullYear() < date.getFullYear()) return false;
  return today.getMonth() > date.getMonth();
};

export const isDateInFuture = (year, month, day, futureDays): boolean => {
  const date = new Date(year, month, day).getTime();
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + futureDays);
  return date >= futureDate.getTime();
};

export const isTodayAfternoon = (year, month, day, disableToday?): boolean => {
  return isToday(year, month, day) && disableToday;
};

export const isToday = (year, month, day): boolean => {
  const date = new Date(year, month, day).getTime();
  const today = new Date().setHours(0, 0, 0, 0);
  return date === today;
};

export const areTheSameDay = (date1, date2): boolean => {
  return (
    date1?.getFullYear() === date2?.getFullYear() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getDate() === date2?.getDate()
  );
};

export const isSelectedDate = (year, month, day, bookingDate, isAvailabilityDatepicker): any => {
  if (!day || !bookingDate) {
    return false;
  }
  if (!isAvailabilityDatepicker) {
    // This is needed logic for normal Booking flow
    const date = new Date(year, month, day, 19, 59);
    return date.getTime() === bookingDate.getTime();
  } else if (isAvailabilityDatepicker) {
    const date = new Date(year, month, day);
    return areTheSameDay(date, bookingDate);
  }
};

export const isAvailableDate = (year, month, day, availableDates): any => {
  if (!day) {
    return false;
  }
  const dateToCheckDateString = new Date(year, month, day).toDateString();
  return availableDates.some((availableDate) => {
    return new Date(+availableDate.startDateTime).toDateString() === dateToCheckDateString;
  });
};

export const daysRemainingInMonth = (date: Date): number => {
  const dayNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const dayToday = date.getDate();
  return 1 + dayNextMonth - dayToday;
};

export const isDateWithinNumberOfDaysInFuture = (date: Date, numberOfDays: number): boolean => {
  const todayDate = new Date();
  const todayFromZero = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
  const milisecondsPerDay = 24 * 60 * 60 * 1000;

  return (date.getTime() - todayFromZero.getTime()) / milisecondsPerDay < numberOfDays;
};

export const getFirstDayOfNextMonthForDate = (date: Date): Date => {
  const monthForReference = date.getMonth();
  const yearForReference = date.getFullYear();

  let nextMonth = null;
  let yearOfNextMonth = null;

  if (monthForReference === 11) {
    nextMonth = 0;
    yearOfNextMonth = yearForReference + 1;
  } else {
    nextMonth = monthForReference + 1;
    yearOfNextMonth = yearForReference;
  }

  return new Date(yearOfNextMonth, nextMonth, 1);
};
