import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, fromEvent, Observable, of, Subscription } from 'rxjs';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

import { NavigationContentDirective } from '../../../utils/directives/navigation-content/navigation-content.directive';
import { NavStateChange } from '../../organisms/settings-content/settings-content.component';
import { switchMap } from 'rxjs/operators';

export const NAV_STATES = {
  MOBILE_INITIAL: 'mobileInitial',
  MOBILE_SELECTED: 'mobileSelected',
  DESKTOP: 'desktop',
};

export const IPAD_WIDTH_START = 768;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterContentInit, OnDestroy {
  /* Define links here before using them, also the routeList input should be consisted of some or all of the
  defined links */
  links = new Map([
    ['password', 'Change Password'],
    ['payment-and-payout', 'Payment & Payout'],
    ['notification-settings', 'Notification Settings'],
    ['posted', 'POSTED'],
    ['filled', 'FILLED'],
    ['deleted', 'DELETED'],
  ]);

  @Input() routeList: string[];
  @Input() goBack$: BehaviorSubject<boolean>;
  @Input() handleNotifications$: BehaviorSubject<NavStateChange>;
  @Output() stateChange: EventEmitter<NavStateChange> = new EventEmitter();

  @ContentChildren(NavigationContentDirective)
  public contentList: QueryList<NavigationContentDirective>;

  @ViewChild('nav', { static: false }) navigation: NgbNav;
  private resizeObservable$: Observable<Event> = fromEvent(window, 'resize');
  private subscription$: Subscription;

  public contentList$: BehaviorSubject<QueryList<NavigationContentDirective>> = new BehaviorSubject(new QueryList());
  public hideNavTabs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hideNavContent$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public activeId: string = '';

  private isMobileLayout: boolean;
  private navState: string;
  private currentPage: string;

  @HostListener('window:resize', ['$event'])
  getWindowWidth(event?) {
    return window.innerWidth;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.currentPage = this.activatedRoute.snapshot.parent.url[0].path;
    this.isMobileLayout = this.getWindowWidth() < IPAD_WIDTH_START;

    if (this.isMobileLayout) {
      this.navState = NAV_STATES.MOBILE_INITIAL;
      this.hideNavContent$.next(true);
      this.stateChange.emit({ state: this.navState, tabName: null });
    } else {
      this.navState = NAV_STATES.DESKTOP;
      this.hideNavContent$.next(false);
    }

    this.subscription$ = this.resizeObservable$.subscribe((event) => {
      this.setState(event);
    });

    this.goBack$.subscribe((shouldGoBack) => {
      if (shouldGoBack) {
        this.hideNavTabs$.next(false);
        this.hideNavContent$.next(true);
        this.navState = NAV_STATES.MOBILE_INITIAL;
      }
    });

    this.activatedRoute?.queryParams
      ?.pipe(switchMap((params, index) => of({ params, index })))
      ?.subscribe(({ params, index }) => {
        // this.isMobileLayout has to be recalculated because the browser could be resized after initialization
        this.isMobileLayout = this.getWindowWidth() < IPAD_WIDTH_START;
        this.activeId = params.tab || this.routeList[0];
        if (this.isMobileLayout && !!params.tab) {
          if (index === 0) {
            // NOTE: To be able to navigate, the navigation and tabs need to be already initialized
            // which happens with first stateChange.emit called outside of this subscribe. If queryParams are subscribed
            // in ngAfterViewInit the whole feature does not work so setTimeout is used.
            setTimeout(() => {
              this.openTab(this.activeId);
            }, 0);
          }
        } else if (this.isMobileLayout) {
          // this means (this.isMobileLayout && !params.tab)
          // hide tab content
          this.goBack$.next(true);
          this.stateChange.emit({ state: this.navState, tabName: null });
        } else if (!params.tab) {
          // this means (!this.isMobileLayout && !params.tab)
          // The remaining case, which is (!this.isMobileLayout && !!params.tab) is when a tab is clicked
          // and that is already handled with openTab() method
          this.navigateToActiveTab(this.activeId);
        }
      });
  }

  ngAfterContentInit() {
    this.contentList$.next(this.contentList);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public openTab(tabName: string): void {
    this.activeId = tabName;
    this.navigateToActiveTab(tabName);
    this.navigation.select(tabName);
    if (this.navState === NAV_STATES.MOBILE_INITIAL) {
      this.navState = NAV_STATES.MOBILE_SELECTED;
      this.hideNavTabs$.next(true);
      this.hideNavContent$.next(false);
      this.stateChange.emit({ state: this.navState, tabName: this.links.get(this.activeId) });
    }
  }

  private navigateToActiveTab(tabName: string): void {
    this.handleNotifications$.subscribe((state) => {
      this.router.navigate([this.currentPage], { queryParams: { tab: tabName } });
      this.stateChange.emit({ state: this.navState, tabName: this.links.get(this.activeId) });
    });
  }

  private setState(event: Event): void {
    //Mobile view and states
    if ((event.currentTarget as Window).innerWidth < IPAD_WIDTH_START) {
      if (this.navState === NAV_STATES.DESKTOP) {
        this.navState = NAV_STATES.MOBILE_SELECTED;
      }

      this.navState === NAV_STATES.MOBILE_SELECTED || this.navState === NAV_STATES.DESKTOP
        ? this.hideNavTabs$.next(true)
        : this.hideNavTabs$.next(false);

      if (this.navState === NAV_STATES.MOBILE_SELECTED || this.navState === NAV_STATES.DESKTOP) {
        this.navState = NAV_STATES.MOBILE_SELECTED;
        this.stateChange.emit({ state: this.navState, tabName: this.links.get(this.activeId) });
      }
    } else {
      this.navState = NAV_STATES.DESKTOP;
      this.hideNavTabs$.next(false);
      this.hideNavContent$.next(false);
      this.stateChange.emit({ state: this.navState, tabName: null });
    }
  }
}
