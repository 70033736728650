<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div class="book-modal-pay-to-confirm__content auth-modal__info book-modal__info flex column" #scrollMe>
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">Pay to confirm your booking with {{ bookModalData?.proName }}</p>
    </header>
    <form class="auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm()">
      <app-book-modal-section>
        <app-request-quick-card [requestQuickCardData]="requestQuickCardData"></app-request-quick-card>
      </app-book-modal-section>

      <app-book-modal-section [sectionTitle]="paymentLabel">
        <app-payment-details
          [bookingPrice]="bookModalPendingRequestData?.price"
          [serviceFeePrice]="bookModalPendingRequestData?.buyersFee"
          [isSeller]="false"
        ></app-payment-details>
      </app-book-modal-section>

      <hr />

      <app-add-payment-card
        (confirmWithNewPaymentMethod)="confirmWithNewPaymentMethod($event)"
        (paymentMethodChanged)="onPaymentMethodChanged($event)"
        (processingConfirmationFinished)="onProcessingFinished($event)"
        (threeDSecureSuccess)="on3DSecureSuccess($event)"
        [addNewPaymentMethod$]="addNewPaymentMethod$"
        [cards]="cardsData"
        [cards$]="cardsData$"
        [preselectCard]="preselectCard$ | async"
        [start3DSecure$]="start3DSecure$"
        (scrollToBottom)="scrollContainerToBottom(scrollMe)"
      ></app-add-payment-card>

      <section class="book-modal-pay-to-confirm__disclaimer book-modal__disclaimer book-modal__section">
        {{ 'BOOK.I_AGREE_TO_THE' | translate
        }}<a
          class="auth-modal__disclaimer--link"
          href="https://site.jammcard.com/jammcard-terms-of-service/"
          rel="noreferrer"
          target="_blank"
        >
          {{ 'BOOK.CANCELLATION_POLICY' | translate }}</a
        >,
        <a
          class="auth-modal__disclaimer--link"
          href="https://site.jammcard.com/jammcard-terms-of-service/"
          rel="noreferrer"
          target="_blank"
          >{{ 'BOOK.REFUND_POLICY' | translate }}
        </a>
        {{ 'BOOK.AND_THE_JAMMCARD_BOOKINGS' | translate }}
        <a
          class="auth-modal__disclaimer--link"
          href="https://site.jammcard.com/jammcard-terms-of-service/"
          rel="noreferrer"
          target="_blank"
        >
          {{ 'BOOK.TERMS_OF_SERVICE' | translate }}.&nbsp;
        </a>
        {{ 'BOOK.I_ALSO_AGREE_TO_PAY_THE_TOTAL_AMOUNT' | translate }}
      </section>

      <div class="book-modal__send-btn-container">
        <app-button
          id="bookModalButton"
          [buttonDisabled]="!bookForm.valid || !selectedPaymentMethodId || processingConfirmation || fetchingCardData"
          [buttonLabel]="buttonLabel"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
    </form>
  </div>
</app-jammcard-modal>
