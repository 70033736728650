export interface IImage {
  id: number;
  mediaId: number;
  thumbnailLarge: string;
  thumbnailMedium: string;
  thumbnailSmall: string;
  url: string;
}
export interface IIdName {
  id: number;
  name: string;
}

export interface IIdNameImage extends IIdName {
  image: string;
}
