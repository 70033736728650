<section class="payment-details__container flex column justify-center">
  <div class="payment-details__section flex align-center justify-content-between">
    <label class="payment-details__label">{{ paymentDetailsTitle || ('BOOK.BOOKING_OFFER' | translate) }}</label>
    <label class="payment-details__label">${{ bookingPrice | number: '1.2-2' }}</label>
  </div>
  <div class="payment-details__section align-center flex justify-content-between">
    <label class="payment-details__label">{{ 'BOOK.SERVICE_FEE' | translate }}</label>
    <label class="payment-details__label"
      ><span *ngIf="isSeller">-</span>${{ serviceFeePrice | number: '1.2-2' }}</label
    >
  </div>
  <div class="payment-details__section payment-details__total align-center flex justify-content-between">
    <label class="payment-details__label">{{ 'BOOK.TOTAL' | translate }}</label>
    <label class="payment-details__label">${{ totalPrice | number: '1.2-2' }}</label>
  </div>
</section>
