import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-messenger-mobile-nav',
  templateUrl: './messenger-mobile-nav.component.html',
  styleUrls: ['./messenger-mobile-nav.component.scss'],
})
export class MessengerMobileNavComponent implements OnInit {
  @Input() conversationParticipantName: string;
  @Output() backToConversationsList: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  public navButtonClick(): void {
    this.backToConversationsList.emit();
  }
}
