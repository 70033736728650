<app-booking-quick-info-card
  *ngIf="bookingQuickInfoData"
  class="booking-quick-info-card"
  [ngClass]="{ 'hide-booking-info': inputFieldIsFocused }"
  [cardContent]="bookingQuickInfoData"
  [buttonLabel]="
    buttonLabelMap[bookingQuickInfoData.status][bookingQuickInfoData.bookee.profileId | bookingRole: loggedUserId]
  "
  [statusClass]="
    this.statusInfoMap[bookingQuickInfoData.status][bookingQuickInfoData.bookee.profileId | bookingRole: loggedUserId]
      .class
  "
  [statusTitle]="
    this.statusInfoMap[bookingQuickInfoData.status][bookingQuickInfoData.bookee.profileId | bookingRole: loggedUserId]
      .title
  "
></app-booking-quick-info-card>
<section class="chat flex column">
  <div id="chatMessages" class="chat__messages">
    <div
      *ngIf="!conversationUniqueName || !clientConnected"
      class="chat__no-data-wrapper flex justify-center align-center"
    >
      <app-no-data [noDataTitle]="noDataTitle"></app-no-data>
    </div>
    <div
      *ngIf="messageGroups && otherParticipant && conversationUniqueName && clientConnected"
      class="chat__messages-inner-container"
    >
      <div visibilityObserver (visible)="topVisible($event)" [debounceTime]="500" class="chat__top-mark"></div>
      <div *ngFor="let group of messageGroups; index as i">
        <app-messenger-label
          *ngIf="group.identity === systemMessageIdentity"
          [dateTime]="group.messages[0].dateCreated"
          [bookingStatusLabel]="group.messages[0].body | formatSystemMessage"
        ></app-messenger-label>
        <app-my-message-group
          *ngIf="group.identity === loggedUserId"
          [avatarImage]="loggedUserProfileImage"
          [messageGroup]="group"
          [lastSeenMessageIndex]="activeConversationLastSeenMessageIndex"
        ></app-my-message-group>
        <app-others-message-group
          *ngIf="group.identity !== loggedUserId && group.identity !== systemMessageIdentity"
          [avatarImage]="otherParticipant.profileImage.thumbnailSmall"
          [messageGroup]="group"
          [username]="otherParticipant.username"
        ></app-others-message-group>
      </div>
      <div visibilityObserver (visible)="bottomVisible($event)" [debounceTime]="500" class="chat__bottom-mark"></div>
    </div>
  </div>
  <app-messenger-input-group
    *ngIf="conversationUniqueName"
    class="messenger-input-group"
    (focus)="inputFocus()"
    (blur)="inputBlur()"
  ></app-messenger-input-group>
</section>
