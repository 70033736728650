<section class="media section" *ngIf="userProfile?.length">
  <h2 class="section__heading uppercase flex row">{{ 'PROFILE.MEDIA' | translate }}</h2>
  <div class="media-container">
    <div *ngFor="let mediaItem of userProfile; index as i" class="media__card">
      <div class="media">
        <div
          class="media-frame"
          style="outline: none"
          (click)="setActiveIframe(i, getIframeSrc(mediaItem.resourceUri, mediaItem.baseType), mediaItem.caption)"
        >
          <iframe
            style="pointer-events: none"
            id="{{ i }}"
            width="100%"
            height="315"
            [src]="getIframeSrc(mediaItem.resourceUri, mediaItem.baseType) | safe: 'resourceUrl'"
            [title]="formatItemCaption(mediaItem.caption)"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>

        <div class="media__info">
          <h3 class="media__title">
            {{ formatItemCaption(mediaItem.caption) }}
          </h3>
          <p class="media__desc" *ngIf="!mediaItem.descriptionTagList">
            {{ formatItemDescription(mediaItem.description) }}
          </p>
          <p class="media__desc tag" *ngIf="mediaItem.descriptionTagList" [innerHTML]="mediaItem.replacedMedia"></p>
          <p class="media__meta flex row align-center">
            <span class="media__length-container flex row align-center">
              <span class="media__length dib vas">{{ mediaItem.length }}</span>
            </span>
            <span class="media__likes flex row align-center">
              <img class="icon icon--like dib vas" src="../../../assets/images/icons/heart-l.png" alt="Heart" />
              <span class="media__count dib vas">{{ mediaItem.voteCount }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</section>
