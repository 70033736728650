import { Pipe, PipeTransform } from '@angular/core';

const PROFILE_ITEMS_NUMBER = {
  desktop: {
    media: 6,
    biography: 500,
    eventDates: 8,
    experiences: 6,
    education: 3,
    skills: 25,
    genres: 25,
    groupedGears: 12,
  },
  tablet: {
    media: 4,
    biography: 500,
    eventDates: 6,
    experiences: 6,
    education: 3,
    skills: 30,
    genres: 30,
    groupedGears: 8,
  },
};
@Pipe({
  name: 'compare',
})
export class Compare implements PipeTransform {
  transform(sectionCount: number, screenType: string, sectionName: string): boolean {
    return sectionCount > PROFILE_ITEMS_NUMBER[screenType][sectionName];
  }
}
