import { Component, OnInit } from '@angular/core';

import { URL } from '../../../utils';

@Component({
  selector: 'app-more-dropdown-menu',
  templateUrl: './more-dropdown-menu.component.html',
  styleUrls: ['./more-dropdown-menu.component.scss'],
})
export class MoreDropdownMenuComponent implements OnInit {
  public readonly url = URL;

  constructor() {}

  ngOnInit(): void {}
}
