import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../../store/app.reducers';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';
import {
  AVAILABILITY_MODAL_ENTERED_FROM,
  constructURLAccordingToSourceType,
  MOBILE_WEB_VIEW,
  MODAL_DISMISS_REASON,
} from '../../../utils';
import { selectFastBookingProTruncatedProfile } from '../../../store/fast-booking/fast-booking.selectors';
import { FastBookingRouterData, ServiceProvision, ServiceType, TruncatedProfile } from '../../../models';
import { LocalStorageService, ToastService } from '../../../services';
import { NotifyMeModalComponent } from './notify-me-modal.component';

@Component({
  template: '',
})
export class NotifyMeModalEntryComponent implements OnInit, OnDestroy {
  private subscription$ = new Subscription();
  private componentEnteredFrom: string;
  private navigateBackUrl: string;

  private openedFromWebView: boolean;

  constructor(
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    // Note that this can be id or (most often) username
    let routeId: string;
    this.subscription$.add(
      this.activatedRoute.data.subscribe((data: FastBookingRouterData) => {
        this.openedFromWebView = !!this.localStorageService.get(MOBILE_WEB_VIEW);

        this.componentEnteredFrom = data.modalEnteredFrom;

        if (this.componentEnteredFrom === AVAILABILITY_MODAL_ENTERED_FROM.PROFILE) {
          routeId = this.activatedRoute.parent.snapshot.params.id;
          this.navigateBackUrl = routeId;
          this.store.dispatch(FastBookingActions.loadTruncatedProfile({ id: routeId }));
        } else {
          this.navigateBackUrl = this.router.url.split('/featured')[0];
          routeId = this.activatedRoute.snapshot.params.id;
          this.store.dispatch(FastBookingActions.loadTruncatedProfile({ id: routeId }));
        }
      }),
    );

    this.subscription$.add(
      this.actions$.pipe(ofType(FastBookingActions.truncatedProfileLoadingFailed)).subscribe(() => {
        /* Check if feature is opened from WebView. 
        If it is, modal close route should have extra query params to indicate WebView exit */
        this.router.navigateByUrl(constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView));
      }),
    );

    this.subscription$.add(
      this.store
        .select(selectFastBookingProTruncatedProfile)
        .pipe(
          filter(
            (proTruncatedProfile) =>
              !isNil(proTruncatedProfile) &&
              // NOTE: username is case sensitive so convert to lower case when comparing values!
              (routeId.toLowerCase() === proTruncatedProfile?.username.toLowerCase() ||
                routeId === proTruncatedProfile?.profileId.toString()),
          ),
          take(1),
          switchMap((proTruncatedProfile) => {
            const servicesProvision = proTruncatedProfile?.servicesProvision;
            // TODO (Milan): Currently this is hardcoded for Mentorship service, eventually should be flexible
            const serviceForBooking = servicesProvision?.find((service) => service.type === ServiceType.MENTORSHIP);

            if (!serviceForBooking) {
              return this.translateService.get('TOAST_MESSAGE').pipe(
                tap((res) => {
                  this.toastService.showMessage(res.SOMETHING_WENT_WRONG);
                  /* Check if feature is opened from WebView. 
-                   If it is, modal close route should have extra query params to indicate WebView exit */
                  this.router.navigateByUrl(
                    constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
                  );
                }),
                switchMap(() => of({ proTruncatedProfile: null, serviceForBooking: null })),
              );
            } else {
              return of({ proTruncatedProfile, serviceForBooking });
            }
          }),
        )
        .subscribe(({ proTruncatedProfile, serviceForBooking }) => {
          if (proTruncatedProfile) {
            this.openModal(proTruncatedProfile, serviceForBooking, this.navigateBackUrl);
          }
        }),
    );
  }

  private openModal(
    proTruncatedProfile: TruncatedProfile,
    serviceForBooking: ServiceProvision,
    navigateBackUrl: string,
  ): void {
    this.modalService.dismissAll(MODAL_DISMISS_REASON.FAST_BOOKING_CHECKOUT);
    const modalInstance = this.modalService.open(NotifyMeModalComponent, {
      windowClass: 'mentorship-modal-window',
      backdrop: 'static',
    });

    modalInstance.componentInstance.fromParent = {
      proTruncatedProfile,
      serviceForBooking,
      navigateBackUrl,
    };
    modalInstance.result.then(
      () => {
        this.store.dispatch(FastBookingActions.clearFastBooking());
        /* Check if feature is opened from WebView. 
-         If it is, modal close route should have extra query params to indicate WebView exit */
        return this.router.navigateByUrl(
          constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
        );
      },
      (reason: string) => {
        if (
          reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH &&
          reason !== MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK &&
          reason !== MODAL_DISMISS_REASON.FAST_BOOKING_CHECKOUT
        ) {
          this.store.dispatch(FastBookingActions.clearFastBooking());
          this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
          /* Check if feature is opened from WebView. 
-           If it is, modal close route should have extra query params to indicate WebView exit */
          return this.router.navigateByUrl(
            constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
          );
        } else {
          return null;
        }
      },
    );
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
