import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { SERVICE_URL } from '../utils';
import { SERVICE_PAGINATION_MAX } from '../app/app.config';
import { BasicProfile, BasicProfileDTO, ServiceProvisionListDTO } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private authService: AuthenticationService, private http: HttpClient) {}

  public getUserShort(): Observable<any> {
    return this.http.get(`${SERVICE_URL.PROFILE}api/profiles/?short=true`, {
      observe: 'response',
    });
  }

  public getBasicProfile(id: number): Observable<HttpResponse<BasicProfile>> {
    return this.http.get<BasicProfile>(`${SERVICE_URL.PROFILE}auth/profiles/v4/basic/${id}`, {
      observe: 'response',
    });
  }

  public getExtendedProfile(id: number): Observable<any> {
    return this.http.get(`${SERVICE_URL.PROFILE}auth/profiles/v4/extended/${id}`, {
      observe: 'response',
    });
  }

  public updateBasicProfile(updateProfileRequestDTO: BasicProfileDTO): Observable<any> {
    return this.http.put(`${SERVICE_URL.PROFILE}api/profiles`, updateProfileRequestDTO);
  }

  public getGallery(proId: number): Observable<any> {
    return this.http.get(`${SERVICE_URL.MEDIA}auth/media/gallery/${proId}`, {
      observe: 'response',
    });
  }

  public getAllLandingPageComponents(alias: string, params?: HttpParams): Observable<any> {
    return this.http.get(`${SERVICE_URL.PROFILE}auth/landing-pages/${alias}/components`, {
      params,
      observe: 'response',
    });
  }

  public getMemberListByHash(hash: string): Observable<any> {
    return this.http.get(`${SERVICE_URL.PROFILE}api/auth/marketplace/private-member-list/${hash}`, {
      observe: 'response',
    });
  }

  public getMediaProfile(id: number): Observable<any> {
    return this.http.get(`${SERVICE_URL.MEDIA}auth/media/v4/media-profile/${id}?size=${SERVICE_PAGINATION_MAX}`, {
      observe: 'response',
    });
  }

  public getTruncatedProfile(id: number | string): Observable<any> {
    let epType = this.authService.isLoggedIn() ? 'api' : 'auth';

    return this.http.get(`${SERVICE_URL.PROFILE}${epType}/profiles/v4/truncated/${id}`, { observe: 'response' });
  }

  public addImage(type: string, image: string): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.MEDIA}api/media/${type}`,
      {
        image,
      },
      {
        observe: 'response',
      },
    );
  }

  // NOTE: It was agreed that FE handles cases for guest/logged in user
  // public end-points start with 'auth' and protected ones with 'api'
  public getAvailableDays(startTime: number, days: number, rateId: number, serviceId: number): Observable<any> {
    let epType = this.authService.isLoggedIn() ? 'api' : 'auth';

    return this.http.get(
      `${SERVICE_URL.PROFILE}${epType}/services-provision/${serviceId}/service-provision-rate-settings/${rateId}/available-days/booking?startDateTime=${startTime}&days=${days}`,
      { observe: 'response' },
    );
  }

  // NOTE: It was agreed that FE handles cases for guest/logged in user
  // public end-points start with 'auth' and protected ones with 'api'
  public getAvailableSlots(startTime: number, days: number = 1, serviceProvisionId: number): Observable<any> {
    let epType = this.authService.isLoggedIn() ? 'api' : 'auth';

    return this.http.get(
      `${SERVICE_URL.PROFILE}${epType}/services-provision/${serviceProvisionId}/availability-slots?startDateTime=${startTime}&days=${days}`,
      { observe: 'response' },
    );
  }

  public lockAvailableSlots(availabilitySlotsIds: number[], rateId: number): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.PROFILE}api/availability-slots/lock`,
      { availabilitySlotsIds, rateId },
      { observe: 'response' },
    );
  }

  public unlockPendingSlots(): void {
    // NOTE: We are not reacting to potential errors so subscribe is called here directly
    this.http.post(`${SERVICE_URL.PROFILE}api/availability-slots/unlock`, {}).subscribe();
  }

  public getSellerServicesProvision(profileId: number): Observable<HttpResponse<ServiceProvisionListDTO>> {
    let epType = this.authService.isLoggedIn() ? 'api' : 'auth';
    return this.http.get<ServiceProvisionListDTO>(
      `${SERVICE_URL.PROFILE}${epType}/profiles/${profileId}/services-provision`,
      { observe: 'response' },
    );
  }

  public getRescheduleData(bookingId: number): Observable<any> {
    return this.http.get(`${SERVICE_URL.PROFILE}api/availability-slots/reschedule/booking/${bookingId}`, {
      observe: 'response',
    });
  }

  // NOTE: It was agreed that FE handles cases for guest/logged in user
  // public end-points start with 'auth' and protected ones with 'api'
  // Subscribe to specific unavailable service provision (GUEST USER or LOGGED USER)
  public notifyMe(serviceProvisionId: number, email?: string): Observable<any> {
    const epType = this.authService.isLoggedIn() ? 'api' : 'auth';
    if (epType === 'api') {
      return this.http.post<ServiceProvisionListDTO>(
        `${SERVICE_URL.PROFILE}${epType}/profiles/services-provision/${serviceProvisionId}/subscribe`,
        {},
        { observe: 'response' },
      );
    } else if (epType === 'auth') {
      return this.http.post<ServiceProvisionListDTO>(
        `${SERVICE_URL.PROFILE}${epType}/profiles/services-provision/${serviceProvisionId}/subscribe`,
        { email },
        { observe: 'response' },
      );
    }
  }

  public checkSubscription(serviceProvisionId: number): Observable<any> {
    return this.http.get<ServiceProvisionListDTO>(
      `${SERVICE_URL.PROFILE}api/profiles/services-provision/${serviceProvisionId}/check-subscription`,
      { observe: 'response' },
    );
  }

  public getSubscription(subscriptionId: number): Observable<any> {
    return this.http.get(`${SERVICE_URL.PROFILE}auth/profiles/services-provision/subscriptions/${subscriptionId}`, {
      observe: 'response',
    });
  }

  public unsubscribeBuyer(serviceProvisionId: number, email?: string): Observable<any> {
    if (this.authService.isLoggedIn()) {
      return this.http.delete(
        `${SERVICE_URL.PROFILE}api/profiles/services-provision/${serviceProvisionId}/unsubscribe`,
        { observe: 'response' },
      );
    } else {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response' as 'response',
        body: {
          email,
        },
      };
      return this.http.delete(
        `${SERVICE_URL.PROFILE}auth/profiles/services-provision/${serviceProvisionId}/unsubscribe`,
        options,
      );
    }
  }

  public opportunityShowInterest(id: number, message: string): Observable<any> {
    return this.http.post(`${SERVICE_URL.PROFILE}api/opportunities/${id}/apply`, { message }, { observe: 'response' });
  }
}
