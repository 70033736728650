import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { switchMap, map, take, mergeMap } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { BasicProfile, BookingRole, PaymentCard } from '../../../models';
import { BookingService, PaymentService, ProfileService, ToastService } from '../../../services';
import { BookModalPayToConfirmComponent } from './book-modal-pay-to-confirm.component';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BOOKING_STATUS_RESPONSE } from '../../../pages/bookings/bookings.config';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';
import { BookingActions } from '../../../store/booking/action-types';
import { getBookingFromUrl } from '../../../utils/operators';

@Component({
  template: '',
})
export class BookModalPayToConfirmEntryComponent implements OnInit, OnDestroy {
  public modalInstance: any;
  public bookModalData: any;
  public modal$: Subscription;
  public sellerInfo$: Subscription;
  public router$: Subscription;

  constructor(
    public bookingService: BookingService,
    private modalService: NgbModal,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private profileService: ProfileService,
    private toastService: ToastService,
  ) {}

  openModal(booking: BookingCurrent, cardsData: PaymentCard[], seller: BasicProfile): void {
    this.modalInstance = this.modalService.open(BookModalPayToConfirmComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = { booking, cardsData, seller };

    this.store.dispatch(BookingActions.seenBookingById({ booking: booking.booking }));

    this.modalInstance.result.then(
      () => this.router.navigate(['bookings']),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH ? this.router.navigate(['bookings']) : null;
      },
    );
  }

  ngOnInit() {
    const bookingIdRouteParam = this.route.snapshot.params.bookingId;

    this.modal$ = this.store
      .select(selectCurrentBooking)
      .pipe(
        take(1),
        mergeMap((booking) => getBookingFromUrl(booking, bookingIdRouteParam, this.store)),
        switchMap((booking) => {
          if (isNil(booking.role) || isNil(booking.booking)) {
            return of({ booking: null, seller: null });
          }

          return this.profileService
            .getBasicProfile(booking.booking.bookee.profileId)
            .pipe(map((seller) => ({ booking, seller: seller.body })));
        }),
        switchMap(({ booking, seller }) => {
          if (!booking) {
            return of({ booking: null, cardsData: null, seller: null });
          }

          return this.paymentService
            .getPaymentCards()
            .pipe(map((cardsRes) => ({ booking, cardsData: cardsRes.body?.cards, seller })));
        }),
      )
      .subscribe(
        ({ booking, cardsData, seller }) => {
          if (
            bookingIdRouteParam !== booking.booking.id.toString() ||
            booking.booking.status !== BOOKING_STATUS_RESPONSE.ACCEPTED ||
            booking.role !== BookingRole.BUYER
          ) {
            this.showMessage('Action not allowed for selected booking!');
            this.router.navigate(['bookings']);
          } else {
            this.openModal(booking, cardsData, seller);
          }
        },
        (error) => {
          this.showMessage('Something went wrong!');
        },
      );
  }

  ngOnDestroy() {
    this.modal$.unsubscribe();
    //this.router$.unsubscribe();
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
