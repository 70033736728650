import { Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { PaymentCardView } from '../../../models';

@Component({
  selector: 'app-view-payment-card',
  templateUrl: './view-payment-card.component.html',
  styleUrls: ['./view-payment-card.component.scss'],
})
export class ViewPaymentCardComponent implements OnInit {
  @Input() cardInfo$: BehaviorSubject<PaymentCardView> = new BehaviorSubject(undefined);

  constructor() {}

  ngOnInit(): void {}
}
