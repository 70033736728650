<section class="rate-experience-group__container">
  <p *ngIf="name" class="rate-experience-group__title">Rate your experience with {{ name }}</p>
  <div class="flex row">
    <ng-template #t let-fill="fill">
      <span class="star" [class.full]="fill === 100"> </span>
    </ng-template>
    <ngb-rating [(rate)]="starNumber" [starTemplate]="t" [readonly]="false" [max]="5" (rateChange)="changeStarNumber()">
    </ngb-rating>
  </div>
</section>
