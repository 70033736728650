import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService, LocalStorageService, ToastService } from '../../../services';
import { REQUEST_SENT_BACKGROUND_URL, RESET_PASS } from '../../../utils';

import { RequestSentModalComponent } from '../../organisms/request-sent-modal/request-sent-modal.component';
import { IRequestSentModalData } from '../../organisms/request-sent-modal/request-sent.config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Output() backToLoginClicked = new EventEmitter<any>();

  public buttonLabel = 'Reset password';
  public modalInstance: any;
  public requestSentModalData: IRequestSentModalData;
  public resetPasswordForm: FormGroup;
  public resetRequestSending: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private toastService: ToastService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.translate.get('RESET_PASSWORD').subscribe((res) => {
      this.buttonLabel = res.RESET_PASSWORD;

      const emailInboxDesc = res.HI_SOMEBODY_HAS_REQUEST;
      const emailInboxSubt = res.PASSWORD_RESET;
      const resetPassButtonLabel = res.BACK_TO_LOGIN;
      const resetPassDescription = '';
      const resetPassTitle = res.AN_EMAIL_HAS_BEEN_SENT_WITH_YOUR_TEMPORARY_PASSWORD;

      this.requestSentModalData = {
        emailInboxDescription: emailInboxDesc,
        emailInboxSubtitle: emailInboxSubt,
        requestSentBackgroundUrl: REQUEST_SENT_BACKGROUND_URL,
        requestSentButtonAction: this.backToLogin,
        requestSentButtonLabel: resetPassButtonLabel,
        requestSentDescription: resetPassDescription,
        requestSentTitle: resetPassTitle,
      };
    });

    this.resetPasswordForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'change',
        },
      ],
    });
  }

  public get email(): FormControl {
    return this.resetPasswordForm.get('email') as FormControl;
  }

  onSubmit(form: any): any {
    this.resetRequestSending = true;
    this.authenticationService.requestNewPassword(this.email.value).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.resetRequestSending = false;
          this.localStorage.add(RESET_PASS, { email: this.email.value });
          this.activeModal.close();
          this.modalInstance = this.modalService.open(RequestSentModalComponent, {
            windowClass: 'modal-window',
            backdrop: 'static',
          });
          const requestSentModalData = this.requestSentModalData;
          this.modalInstance.componentInstance.fromParent = {
            requestSentModalData,
          };
        }
      },
      (error) => {
        this.resetRequestSending = false;
        if (error.status === 404) {
          this.email.setErrors({ notFound: true });
        } else if (error.status === 422 || error.error.fieldErrors[0].errorType === 'invalidEmail') {
          this.email.markAsDirty();
          this.email.setErrors({ email: true });
        } else {
          this.toastService.showMessage('Something went wrong');
        }
      },
    );
  }

  public backToLogin(event): void {
    this.backToLoginClicked.emit();
  }
}
