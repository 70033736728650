import { NgModule } from '@angular/core';

import { BookingRolePipe } from './booking-role/booking-role.pipe';
import { Compare } from './compare/compare.pipe';
import { MessagePreviewPipe } from './message-preview/message-preview.pipe';
import { ProfileImagePipe } from './profile-image/profile-image.pipe';
import { SafePipe } from './safe/safe.pipe';
import { SsrTranslatePipe } from './ssr-translate/ssr-translate.pipe';
import { FormatEducationOngoingPipe } from './format-education-ongoing/format-education-ongoing.pipe';
import { FormatOpportunityMessagePipe } from './format-opportunity-message/format-opportunity-message.pipe';
import { FormatOpportunityMessengerTitlePipe } from './format-opportunity-messenger-title/format-opportunity-messenger-title.pipe';
import { FormatSystemMessagePipe } from './format-system-message/format-system-message.pipe';
import { FormatTextWithLinksPipe } from './format-text-with-links/format-text-with-links.pipe';
import { TimeagoShortPipe } from './timeago-short/timeago-short.pipe';
import { ExtendedEmptyPipe } from './extended-empty/extended-empty.pipe';
import { GenderCasePipe } from './gender-case/gender-case.pipe';
import { DurationShortPipe } from './duration-short.pipe';
import { DatepickerClassPipe } from './datepicker-class/datepicker-class.pipe';
import { DatePastDisabledPipe } from './date-past-disabled/date-past-disabled.pipe';
import { DurationTextPipe } from './duration-text/duration-text.pipe';
import { HideCardInfoPipe } from './hide-card-info/hide-card-info.pipe';
import { SecurityCodePipe } from './security-code/security-code.pipe';
import { SearchSortCasePipe } from './search-sort-case/search-sort-case.pipe';
import { ActiveFilterPipe } from './active-filter/active-filter.pipe';
import { SearchFilterLabelPipe } from './search-filter-label/search-filter-label.pipe';
import { CastNumberPipe } from './cast-number/cast-number.pipe';

@NgModule({
  declarations: [
    SafePipe,
    SsrTranslatePipe,
    Compare,
    BookingRolePipe,
    MessagePreviewPipe,
    ProfileImagePipe,
    FormatEducationOngoingPipe,
    FormatOpportunityMessagePipe,
    FormatOpportunityMessengerTitlePipe,
    FormatSystemMessagePipe,
    FormatTextWithLinksPipe,
    TimeagoShortPipe,
    ExtendedEmptyPipe,
    GenderCasePipe,
    DurationShortPipe,
    DatepickerClassPipe,
    DatePastDisabledPipe,
    DurationTextPipe,
    HideCardInfoPipe,
    SecurityCodePipe,
    SearchSortCasePipe,
    ActiveFilterPipe,
    SearchFilterLabelPipe,
    CastNumberPipe,
  ],
  exports: [
    SafePipe,
    SsrTranslatePipe,
    Compare,
    BookingRolePipe,
    MessagePreviewPipe,
    ProfileImagePipe,
    FormatOpportunityMessagePipe,
    FormatOpportunityMessengerTitlePipe,
    FormatSystemMessagePipe,
    FormatTextWithLinksPipe,
    TimeagoShortPipe,
    FormatEducationOngoingPipe,
    ExtendedEmptyPipe,
    GenderCasePipe,
    DurationShortPipe,
    DatepickerClassPipe,
    DatePastDisabledPipe,
    DurationTextPipe,
    HideCardInfoPipe,
    SecurityCodePipe,
    SearchSortCasePipe,
    ActiveFilterPipe,
    SearchFilterLabelPipe,
    CastNumberPipe,
  ],
})
export class PipesModule {}
