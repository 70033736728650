import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationBadgeComponent } from './notification-badge.component';

@NgModule({
  declarations: [NotificationBadgeComponent],
  exports: [NotificationBadgeComponent],
  imports: [CommonModule],
})
export class NotificationBadgeModule {}
