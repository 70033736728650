import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ChangePasswordModule } from '../change-password/change-password.module';
import { ChangeNotificationsModule } from '../change-notifications/change-notifications.module';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { SettingsContentComponent } from './settings-content.component';
import { PaymentMethodTabGroupModule } from '../../molecules/payment-method-tab-group/payment-method-tab-group.module';
import { PaymentAndPayoutModule } from '../payment-and-payout/payment-and-payout.module';
import { NavigationModule } from '../../molecules/navigation/navigation.module';
import { NavigationContentModule } from '../../../utils/directives/navigation-content/navigation-content.module';
import { NotificationSettingsModule } from '../notification-settings/notification-settings.module';

@NgModule({
  declarations: [SettingsContentComponent],
  imports: [
    CommonModule,
    ChangePasswordModule,
    NgbModule,
    ChangeNotificationsModule,
    PipesModule,
    RouterModule,
    TranslateModule,
    PaymentAndPayoutModule,
    NavigationModule,
    NavigationContentModule,
    NotificationSettingsModule,
  ],
  exports: [SettingsContentComponent],
})
export class SettingsContentModule {}
