import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BookModalData } from '../book-modal/book-modal.component';
import { BasicProfile, BookingByStatus, BookingModal } from '../../../models';
import { IRequestQuickCardData } from '../../molecules/request-quick-card/request-quick-card.component';
import { ITransactionCardData } from '../../molecules/transaction-card/transaction-card.component';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { createAvatarGroupData, createBookModalData } from '../../../utils';
import { STATUS_TITLE } from '../../../pages/bookings/bookings.config';

@Component({
  selector: 'app-book-modal-payout-details',
  templateUrl: './book-modal-payout-details.component.html',
  styleUrls: ['./book-modal-payout-details.component.scss'],
})
export class BookModalPayoutDetailsComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public bookModalPendingRequestData: BookingByStatus;
  public bookModalData: BookModalData;
  public fromParent: BookingModal;
  public avatarGroupData: IAvatarGroupData;
  public requestQuickCardData: IRequestQuickCardData;
  public transactionCardData: ITransactionCardData;
  public user: BasicProfile;
  public type: string = '';
  public title: string = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.bookModalPendingRequestData = { ...this.fromParent.booking.booking };
    this.user = { ...this.fromParent.user };
    this.type = this.fromParent.booking.booking.bookedServiceType;
    this.formatTypeData();
    this.bookModalData = createBookModalData(this.user);
    this.avatarGroupData = createAvatarGroupData(this.user);

    // TODO (Jovana): Universalize this

    this.requestQuickCardData = {
      bookingDate: this.bookModalPendingRequestData.dates[0],
      gigTitle: this.bookModalPendingRequestData.gigTitle,
      loggedUserName: this.bookModalData.loggedUserName,
      profileImage: this.bookModalData.profileImage,
      proName: this.bookModalData.proName,
      requestStatusLabel: STATUS_TITLE[this.bookModalPendingRequestData.status],
      city: this.bookModalData.proCity,
    };

    if (this.fromParent.isSeller) {
      this.transactionCardData.priceTotal =
        this.bookModalPendingRequestData.price - this.bookModalPendingRequestData.sellersFee;
    } else {
      this.transactionCardData.priceTotal =
        this.bookModalPendingRequestData.price + this.bookModalPendingRequestData.buyersFee;
    }
  }

  //TODO: Use translate service
  private formatTypeData(): void {
    if (this.type === 'MENTORSHIP') {
      this.title = 'Payment Details';
      this.transactionCardData = { ...this.bookModalPendingRequestData, type: 'Payment' };
    } else {
      this.title = 'Payout Details';
      this.transactionCardData = { ...this.bookModalPendingRequestData, type: 'Payout' };
    }
  }

  closeModal(event): any {
    this.activeModal.close();
  }
}
