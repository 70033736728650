import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rates-accordion',
  templateUrl: './rates-accordion.component.html',
  styleUrls: ['./rates-accordion.component.scss'],
})
export class RatesAccordionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
