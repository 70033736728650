import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutMentorshipCardComponent } from './about-mentorship-card.component';
import { SeeMoreTextModule } from '../see-more-text/see-more-text.module';



@NgModule({
  declarations: [AboutMentorshipCardComponent],
  imports: [
    CommonModule,
    SeeMoreTextModule
  ],
  exports: [AboutMentorshipCardComponent]
})
export class AboutMentorshipCardModule { }
