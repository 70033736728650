import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeFilter',
})
export class ActiveFilterPipe implements PipeTransform {
  transform(amount: number): string {
    if (amount <= 0) return '';
    if (amount === 1) return `Active Filter`;
    if (amount > 1) return `${amount} Active Filters`;
  }
}
