import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { PAYMENT_METHOD_ID_PLACEHOLDER } from '../../../pages/bookings/bookings.config';

@Component({
  selector: 'app-payment-methods-modal-add-card',
  templateUrl: './payment-methods-modal-add-card.component.html',
  styleUrls: ['./payment-methods-modal-add-card.component.scss'],
})
export class PaymentMethodsModalAddCardComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public addNewPaymentMethod$ = new Subject<string>();
  public buttonLabel = 'Save';
  public processingConfirmation: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  public closeModal(event?): void {
    this.activeModal.close();
  }

  public submitCard(): void {
    this.processingConfirmation = true;
    this.addNewPaymentMethod$.next(PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD);
  }

  public processingConfirmationFinished(): void {
    this.processingConfirmation = false;
  }

  public confirmWithNewPaymentMethod(event): void {
    this.activeModal.close('paymentMethodConfirmed');
  }
}
