import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { BasicProfile, BookingRole } from '../../../models';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BookModalPayoutDetailsComponent } from './book-modal-payout-details.component';
import { BOOKING_STATUS_RESPONSE } from '../../../pages/bookings/bookings.config';
import { ProfileService, ToastService } from '../../../services';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';
import { getBookingFromUrl } from '../../../utils/operators';

@Component({
  template: '',
})
export class BookModalPayoutDetailsEntryComponent implements OnInit, OnDestroy {
  public modalInstance: NgbModalRef;
  public bookModalData: any;
  public modal$: Subscription;
  public sellerInfo$: Subscription;
  public router$: Subscription;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private toastService: ToastService,
  ) {}

  openModal(booking: BookingCurrent, user: BasicProfile): void {
    const isSeller = booking.role === BookingRole.SELLER;
    this.modalInstance = this.modalService.open(BookModalPayoutDetailsComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = { booking, user, isSeller };

    this.modalInstance.result.then(
      () => this.router.navigate(['bookings']),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH ? this.router.navigate(['bookings']) : null;
      },
    );
  }

  ngOnInit() {
    const bookingIdRouteParam = this.route.snapshot.params.bookingId;

    this.modal$ = this.store
      .select(selectCurrentBooking)
      .pipe(
        take(1),
        mergeMap((booking) => getBookingFromUrl(booking, bookingIdRouteParam, this.store)),
      )
      .subscribe((booking) => {
        if (
          bookingIdRouteParam !== booking.booking.id.toString() ||
          (booking.booking.status !== BOOKING_STATUS_RESPONSE.CONFIRMED &&
            booking.booking.status !== BOOKING_STATUS_RESPONSE.COMPLETED &&
            booking.booking.status !== BOOKING_STATUS_RESPONSE.PROCESSING_PAYMENT)
        ) {
          this.showMessage('Action not allowed for selected booking!');
          this.router.navigate(['bookings']);
        } else {
          const profileId =
            booking.role === BookingRole.SELLER ? booking.booking.booker.profileId : booking.booking.bookee.profileId;
          this.profileService.getBasicProfile(profileId).subscribe((user) => this.openModal(booking, user.body));
        }
      });
  }

  ngOnDestroy() {
    this.modal$.unsubscribe();
    //this.router$.unsubscribe();
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
