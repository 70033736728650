<section class="experience section" *ngIf="experiences?.length">
  <h2 class="section__heading uppercase flex row">{{ 'PROFILE.EXPERIENCE' | translate }}</h2>
  <div class="experience__container flex column" [ngClass]="{ expand: experiences }">
    <section *ngIf="experiences?.length">
      <div class="experience__live flex column">
        <span
          *ngFor="let experience of experiences; index as i"
          id="{{ experience.id }}"
          class="experience__card section__text flex column align-start justify-center"
        >
          <app-experience-card [experience]="experience"></app-experience-card>
        </span>
      </div>
    </section>
  </div>
  <ng-content></ng-content>
</section>
