import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveFiltersModule } from '../../atoms/active-filters/active-filters.module';

import { SkillsFilterComponent } from './skills-filter.component';

@NgModule({
  declarations: [SkillsFilterComponent],
  imports: [CommonModule, NgbAccordionModule, TranslateModule, ActiveFiltersModule],
  exports: [SkillsFilterComponent],
})
export class SkillsFilterModule {}
