import { Component, Input, OnInit } from '@angular/core';
import { Rate } from '../../../models';

@Component({
  selector: 'app-rate-label',
  templateUrl: './rate-label.component.html',
  styleUrls: ['./rate-label.component.scss'],
})
export class RateLabelComponent implements OnInit {
  @Input() rates?: Rate[];
  @Input() featuredList?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
