import { Pipe, PipeTransform } from '@angular/core';

import { MESSAGE_PREVIEW_MAX_LENGTH } from '../../../components/organisms/messenger-content/messenger-content.config';

@Pipe({
  name: 'messagePreview',
})
export class MessagePreviewPipe implements PipeTransform {
  constructor() {}
  public transform(message: string): string {
    if (message) {
      if (message.length > MESSAGE_PREVIEW_MAX_LENGTH) {
        return message.slice(0, MESSAGE_PREVIEW_MAX_LENGTH - 1) + '...';
      }

      return message;
    }

    return '-';
  }
}
