<div class="add-payment-card__content flex column">
  <div *ngIf="!viewOnly" class="add-payment-card__title book-modal__section">PAYMENT METHOD</div>
  <div *ngIf="!viewOnly" class="add-payment-card__radio-group">
    <div *ngFor="let card of cards" class="add-payment-card__radio-button-container">
      <div class="book-modal__section">
        <label class="add-payment-card__custom-radio-label">
          {{ (card.cardName | titlecase) + ' ending in ' + card.last4 }}
          <input
            type="radio"
            class="add-payment-card__radio-button"
            (change)="onRadioChange($event, card)"
            name="payment"
            [checked]="card.cardId === preselectCard"
          />
          <span class="add-payment-card__radio-checkmark"></span>
        </label>
      </div>
    </div>
    <div class="book-modal__section add-payment-card__radio-button-container">
      <label class="add-payment-card__custom-radio-label">
        {{ 'Add new payment method' }}
        <input
          type="radio"
          class="add-payment-card__radio-button"
          (change)="onRadioChange($event, null)"
          name="payment"
          [checked]="addNewPaymentMethodSelected"
        />
        <span class="add-payment-card__radio-checkmark"></span>
      </label>
    </div>
  </div>
  <div class="add-payment-card__form" [ngClass]="{ show: addNewPaymentMethodSelected }">
    <app-add-payment-card-form
      [addNewPaymentMethod$]="addNewPaymentMethod$"
      [clearForm$]="clearCardForm$"
      [start3DSecure$]="start3DSecure$"
      (confirmWithNewPaymentMethod)="handleConfirmWithNewPaymentMethod($event)"
      (threeDSecureSuccess)="handleThreeDSecureSuccess($event)"
      (processingConfirmationFinished)="handleProcessingConfirmationFinished($event)"
    >
    </app-add-payment-card-form>
  </div>
</div>
