import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedProfile } from '../../../models';

@Pipe({
  name: 'extendedEmpty',
})
export class ExtendedEmptyPipe implements PipeTransform {
  transform(extendedInfo: ExtendedProfile): boolean {
    return !Boolean(
      extendedInfo?.education.length ||
        extendedInfo?.eventDates.length ||
        extendedInfo?.experiences.length ||
        extendedInfo?.genres.length ||
        extendedInfo?.groupedGears.length ||
        extendedInfo?.servicesProvision.length ||
        extendedInfo?.skills.length,
    );
  }
}
