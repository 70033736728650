import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RateLabelComponent } from './rate-label.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';

@NgModule({
  declarations: [RateLabelComponent],
  exports: [RateLabelComponent],
  imports: [CommonModule, PipesModule],
})
export class RateLabelModule {}
