import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { loadStripe } from '@stripe/stripe-js';
import { Subject, Subscription } from 'rxjs';

import { PaymentService, ToastService } from '../../../services';

import { PaymentMethodType } from '../../../models';
import { STRIPE } from '../../../environments/environment';
import { PAYMENT_METHOD_ID_PLACEHOLDER } from '../../../pages/bookings/bookings.config';

@Component({
  selector: 'app-add-payment-card-form',
  templateUrl: './add-payment-card-form.component.html',
  styleUrls: ['./add-payment-card-form.component.scss'],
})
export class AddPaymentCardFormComponent implements OnInit, OnDestroy {
  @Input() addNewPaymentMethod$: Subject<string>;
  @Input() clearForm$: Subject<boolean>;
  @Input() start3DSecure$: Subject<any>;

  @Input() addNewPaymentMethodSelected: boolean = false;
  @Input() addCardOnlyBehavior: boolean = false;

  @Output() confirmWithNewPaymentMethod = new EventEmitter<any>();
  @Output() processingConfirmationFinished = new EventEmitter<any>();
  @Output() threeDSecureSuccess = new EventEmitter<any>();

  public cardNumberError: string;
  public cardExpDateError: string;
  public cardCvcError: string;

  private addNewPaymentMethodSubscription$: Subscription;
  private clearFormSubscription$: Subscription;
  private start3DSecureSubscription$: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private paymentService: PaymentService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    loadStripe(STRIPE.PUBLISHABLE_KEY).then((stripe) => {
      const style = {
        base: {
          fontFamily: 'Lato-Regular, sans-serif',
          fontSize: '18px',
          color: '#282727',
        },
      };
      const elements = stripe.elements();
      const cardNumber = elements.create('cardNumber', { style });
      const cardExpiry = elements.create('cardExpiry', { style });
      const cardCvc = elements.create('cardCvc', { style });
      const postalCode = <HTMLInputElement>this.document.getElementById('cardPostalCode');

      cardNumber.mount('#cardNumber');
      cardExpiry.mount('#cardExpiry');
      cardCvc.mount('#cardCvc');

      cardNumber.on('change', (event) => {
        if (event.error) {
          this.cardNumberError = event.error.message;
        } else {
          this.cardNumberError = null;
        }
      });

      cardExpiry.on('change', (event) => {
        if (event.error) {
          this.cardExpDateError = event.error.message;
        } else {
          this.cardExpDateError = null;
        }
      });

      cardCvc.on('change', (event) => {
        if (event.error) {
          this.cardCvcError = event.error.message;
        } else {
          this.cardCvcError = null;
        }
      });

      this.addNewPaymentMethodSubscription$ = this.addNewPaymentMethod$?.subscribe((res) => {
        if (res === PAYMENT_METHOD_ID_PLACEHOLDER.NEW_CARD) {
          stripe
            .createPaymentMethod({
              type: 'card',
              card: cardNumber,
              billing_details: {
                address: {
                  postal_code: postalCode.value,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                /* Checked with mobile team, all errors are handled with 'Something went wrong' */
                this.showError('Something went wrong!');
                this.processingConfirmationFinished.emit();
              } else {
                this.paymentService.addPaymentCard(result.paymentMethod.id).subscribe(
                  (response: HttpResponse<any>) => {
                    if (response.status === 201) {
                      // the card was successfully created and can be used for payment
                      this.confirmWithNewPaymentMethod.emit({
                        paymentMethodId: response.body.cardId,
                        paymentMethodType: PaymentMethodType.CARD,
                      });
                    }
                  },
                  (error) => {
                    this.showError('Something went wrong!');
                    this.processingConfirmationFinished.emit();
                  },
                );
              }
            });
        }
      });

      this.start3DSecureSubscription$ = this.start3DSecure$?.subscribe(({ clientSecret, bookingId }) => {
        stripe
          .handleCardAction(clientSecret)
          .then((res) => {
            if (res.paymentIntent) {
              this.threeDSecureSuccess.emit({
                paymentIntentId: res.paymentIntent.id,
                paymentMethodId: res.paymentIntent.payment_method,
                paymentMethodType: PaymentMethodType.CARD,
                bookingId: bookingId,
              });
            } else {
              this.showError('Something went wrong!');
              this.processingConfirmationFinished.emit({ refetchCards: true });
            }
          })
          .catch((err) => {
            this.showError('Something went wrong!');
            this.processingConfirmationFinished.emit({ refetchCards: true });
          });
      });

      this.clearFormSubscription$ = this.clearForm$.subscribe(() => {
        cardNumber.clear();
        cardExpiry.clear();
        cardCvc.clear();
        postalCode.value = '';
      });
    });
  }

  ngOnDestroy(): void {
    this.addNewPaymentMethodSubscription$?.unsubscribe();
    this.clearFormSubscription$?.unsubscribe();
    this.start3DSecureSubscription$?.unsubscribe();
  }

  private showError(error: string): void {
    this.toastService.showMessage(error);
  }
}
