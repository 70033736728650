<div class="view-payment-card flex column">
  <div class="view-payment-card__section view-payment-card__number">
    <label class="view-payment-card__label">
      CARD NUMBER
      <div id="cardNumber" class="view-payment-card__info"></div>
    </label>
    <div class="add-payment-card__error" [ngClass]="{ show: cardNumberError }">{{ cardNumberError }}</div>
  </div>
  <div class="view-payment-card__section view-payment-card__section--flex flex">
    <div class="view-payment-card__expiry">
      <label class="view-payment-card__label">
        EXP. DATE
        <div id="cardExpiry" class="view-payment-card__info"></div>
      </label>
      <div class="add-payment-card__error" [ngClass]="{ show: cardExpDateError }">{{ cardExpDateError }}</div>
    </div>
    <div class="view-payment-card__cvc">
      <label class="view-payment-card__label">
        CVC
        <div id="cardCvc" class="view-payment-card__info"></div>
      </label>
      <div class="add-payment-card__error" [ngClass]="{ show: cardCvcError }">{{ cardCvcError }}</div>
    </div>
  </div>
  <div class="view-payment-card__section view-payment-card__zip">
    <label class="view-payment-card__label">
      ZIP CODE
      <input id="cardPostalCode" class="view-payment-card__info" type="text" maxlength="10" />
    </label>
  </div>
</div>
