import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { JammboardService, ToastService } from '../../../services';
import { IsBrowserService, MODAL_DISMISS_REASON } from '../../../utils';
import { JammboardGigMainCardDetailsComponent } from './jammboard-gig-main-card-details.component';

import { AppState } from '../../../store/app.reducers';
import { reloadUserShort } from '../../../store/auth/auth.actions';
import { IGigMain, JammboardRouterData } from '../../../models';

@Component({
  template: '',
})
export class JammboardGigMainCardDetailsEntryComponent implements OnInit {
  private navigateBackUrl: string;
  private modalInstance: any;
  private opportunity: IGigMain;

  constructor(
    private activatedRoute: ActivatedRoute,
    private isBrowserService: IsBrowserService,
    private jammboardService: JammboardService,
    private modalService: NgbModal,
    private router: Router,
    private store: Store<AppState>,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    if (this.isBrowserService.isBrowser()) {
      this.activatedRoute.data.subscribe((data: JammboardRouterData) => {
        this.navigateBackUrl = data.modalEnteredFrom;
      });
      const id = this.activatedRoute.snapshot.params.id;
      this.jammboardService.getSingleOpportunity(id).subscribe(
        (res) => {
          this.opportunity = res.body;
          this.openModal(this.opportunity);
          if (this.opportunity?.jammboardActivatedByShare) {
            // NOTE: The user opened a shared link and triggered
            // enabling of the full Jammboard feature
            this.store.dispatch(reloadUserShort());
          }
        },
        (error) => {
          let errorMessage = error.status === 403 ? 'Gig not available!' : 'Something went wrong';
          this.toastService.showMessage(errorMessage);
          this.router.navigate([this.navigateBackUrl], { queryParamsHandling: 'merge' });
        },
      );
    }
  }

  private openModal(opportunity: IGigMain): void {
    this.modalInstance = this.modalService.open(JammboardGigMainCardDetailsComponent, {
      windowClass: 'data-input-modal-window',
      backdrop: 'static',
    });

    this.modalInstance.componentInstance.fromParent = {
      opportunity,
    };

    this.modalInstance.result.then(
      () => {
        this.router.navigate([this.navigateBackUrl], { queryParamsHandling: 'merge' });
      },
      (reason) => {
        if (reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH && reason !== MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK) {
          this.router.navigate([this.navigateBackUrl], { queryParamsHandling: 'merge' });
        } else {
          return null;
        }
      },
    );
  }
}
