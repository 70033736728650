import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-label',
  templateUrl: './no-data-label.component.html',
  styleUrls: ['./no-data-label.component.scss'],
})
export class NoDataLabelComponent implements OnInit {
  @Input() noDataTitle;
  @Input() noDataSubtitle;

  constructor() {}

  ngOnInit(): void {}
}
