<app-mentorship-modal>
  <app-seller-profile-card
    class="display"
    [proTruncatedProfile]="proTruncatedProfile"
    (closeModal)="closeModal()"
    [noButtons]="true"
  ></app-seller-profile-card>

  <div class="fast-booking-availability__container">
    <img
      class="auth-modal__close book-modal-fast-checkout__close hide"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal()"
    />

    <div class="fast-booking-availability__components">
      <div class="flex column">
        <h3 class="fast-booking-availability__title">
          {{ 'FAST_BOOKING.WHEN_DO_YOU_WANT_TO_BOOK_A_SESSION' | translate }}
          <span>{{ proTruncatedProfile.name }}?</span>
        </h3>
        <div class="fast-booking-availability__rate-and-calendar">
          <app-rate-select-pill
            [rates]="serviceForBooking?.rates"
            [preselectRateId]="preselect?.rate?.id"
            [rescheduleRate]="rescheduleRate"
            (rateSelected)="changeDuration($event)"
          ></app-rate-select-pill>
          <p *ngIf="selectedRate?.amount" class="fast-booking-availability__selected-price">
            ${{ selectedRate?.amount }}&nbsp;/&nbsp;{{ selectedRate?.rateSetting?.timeDurationAmount }}&nbsp;min
          </p>
          <app-availability-datepicker
            [enabled$]="calendarEnabled$"
            [availableDates$]="availabilityDays$"
            [preselect]="preselect?.calendar"
          ></app-availability-datepicker>
        </div>
      </div>

      <div class="fast-booking-availability__slots-container">
        <div class="fast-booking-availability_slots-wrapper">
          <app-availability-slot-list
            [slots]="availabilitySlots$"
            [rate]="selectedRateDurationAmount$"
            [preselectSlotIds]="preselect?.slots"
            [isRescheduleRoute]="!!rescheduleRate"
            [profileName]="proTruncatedProfile?.name"
            [openedFromWebView]="openedFromWebView"
          ></app-availability-slot-list>
        </div>
        <div class="fast-booking-availability_join-waitlist" [hidden]="isFromReschedule">
          <p
            class="fast-booking-availability-join-waitlist__title"
            [ngClass]="{ 'fast-booking-availability-join-waitlist__title--subscribed': isViewerSubscribed }"
          >
            {{ 'FAST_BOOKING.JOIN_WAITLIST' | translate }}
          </p>
          <app-button
            class="fast-booking-availability__notify-button"
            buttonLabel="{{ 'HOME.JOIN_WAITLIST' | translate }}"
            [rounded]="true"
            [inverted]="true"
            [buttonGrayDisabled]="isViewerSubscribed"
            (clicked)="onNotifyMeClicked()"
            triggers="hover"
            placement="bottom"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</app-mentorship-modal>
