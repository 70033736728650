import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationSettingsTabGroupComponent } from './notification-settings-tab-group.component';

@NgModule({
  declarations: [NotificationSettingsTabGroupComponent],
  imports: [CommonModule, TranslateModule],
  exports: [NotificationSettingsTabGroupComponent],
})
export class NotificationSettingsTabGroupModule {}
