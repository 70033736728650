import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeeMoreTextComponent } from './see-more-text.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SeeMoreTextComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[SeeMoreTextComponent]
})
export class SeeMoreTextModule { }
