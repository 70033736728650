import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { RouterStateUrl } from './router/custom-router.serializer';
import { makeStateKey } from '@angular/platform-browser';
import { authReducer, AuthState } from './auth/reducers/auth.reducers';
import { messengerReducer, MessengerState } from './messenger/reducers/messenger.reducers';
import { notificationReducer, NotificationsState } from './notifications/reducers/notifications.reducers';
import { fastBookingReducer, FastBookingState } from './fast-booking/reducers/fast-booking.reducers';
import { AuthActions } from './auth/action-types';
export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  auth: AuthState;
  notifications: NotificationsState;
  fastBooking: FastBookingState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: authReducer,
  notifications: notificationReducer,
  fastBooking: fastBookingReducer,
};

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State, action: any) {
    if (action.type === AuthActions.logout.type) {
      state = {} as State;
    }
    return reducer(state, action);
  };
}

export const NGRX_STATE = makeStateKey('NGRX_STATE');
export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [stateSetter, clearStateMetaReducer]
  : [clearStateMetaReducer];
