import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceTotalComponent } from './price-total.component';

@NgModule({
  declarations: [PriceTotalComponent],
  imports: [CommonModule],
  exports: [PriceTotalComponent],
})
export class PriceTotalModule {}
