<section class="apps-buttons-group__container flex row">
  <a href="https://itunes.apple.com/us/app/jammcard/id879279559?mt=8" target="_blank" rel="noreferrer">
    <img
      class="store-btn app-store-button"
      src="assets/images/logos/app-store.svg"
      alt="Download on App Store Button"
      title="Download on App Store Button" /></a
  ><a href="https://play.google.com/store/apps/details?id=com.jammcard&amp;hl=en_CA" target="_blank" rel="noreferrer">
    <img
      class="store-btn"
      src="assets/images/logos/google-play.svg"
      alt="Download on Google Play Store Button"
      title="Download on Google Play Button"
  /></a>
</section>
