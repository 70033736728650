import { createAction, props } from '@ngrx/store';

import {
  AvailableDay,
  AvailabilitySlot,
  Rate,
  TruncatedProfile,
  AvailabilitySlotLocked,
  ServiceProvisionListDTO,
} from '../../models';
import { ISubscribed } from './reducers/fast-booking.reducers';

export const loadTruncatedProfile = createAction('[Fast Booking] Load Truncated Profile', props<{ id: string }>());
export const truncatedProfileLoaded = createAction(
  '[Fast Booking Effect] Truncated Profile Loaded',
  props<{ id: number | string; proProfile: TruncatedProfile }>(),
);
export const truncatedProfileLoadingFailed = createAction('[Fast Booking Effect] Truncated Profile Loading Failed');

export const clearFastBooking = createAction('[Fast Booking] Clear State');
export const setFastBookingTruncatedProfile = createAction(
  '[Fast Booking] Pro Truncated Profile Set',
  props<{ proProfile: TruncatedProfile }>(),
);
export const setFastBookingProUsername = createAction('[Fast Booking] Pro Username Set', props<{ username: string }>());

export const loadAvailableDays = createAction(
  '[Fast Booking] Load Available Days',
  props<{ startTime: number; endTime?: number; days: number; month: number; rate: Rate; serviceId?: number }>(),
);

export const availableDaysLoaded = createAction(
  '[Fast Booking Effect] Available Days Loaded',
  props<{ availableDays: AvailableDay[]; rateId: number; month: number }>(),
);

export const availableDaysLoadingFailed = createAction(
  '[Fast Booking Effect] Available Days Loading Failed',
  props<{ availableDays: AvailableDay[]; rateId: number; month: number }>(),
);

export const calendarMonthSelected = createAction(
  '[Fast Booking] Calendar Month Selected',
  props<{ startTime: number; endTime?: number; days: number; month: number }>(),
);

export const calendarDaySelected = createAction(
  '[Fast Booking] Calendar Day Selected',
  props<{ startTime: number; endTime: number }>(),
);

export const loadAvailableSlots = createAction(
  '[Fast Booking] Load Available Slots',
  props<{ startTime: number; endTime: number; serviceProvisionId: number }>(),
);

export const availableSlotsLoaded = createAction(
  '[Fast Booking Effect] Available Slots Loaded',
  props<{ availableSlots: AvailabilitySlot[] }>(),
);

export const availableSlotsLoadingFailed = createAction(
  '[Fast Booking Effect] Available Slots Loading Failed',
  props<{ availableSlots: AvailabilitySlot[] }>(),
);

export const availabilitySlotsInitiateLock = createAction(
  '[Fast Booking] Availability Slots Initiate Lock',
  props<{ availabilitySlotsIds: number[]; openedFromWebView?: boolean }>(),
);

export const lockAvailabilitySlots = createAction(
  '[Fast Booking] Lock Availability Slots',
  props<{
    availabilitySlotsIds: number[];
    rateId: number;
    day: { startTime: number; endTime: number };
    backUrl?: string;
    openedFromWebView?: boolean;
  }>(),
);

export const availabilitySlotsLocked = createAction(
  '[Fast Booking Effect] Availability Slots Locked',
  props<{ lockedSlots: AvailabilitySlotLocked; rateId: number; backUrl?: string; openedFromWebView?: boolean }>(),
);

export const availabilitySlotsLockingFailed = createAction('[Fast Booking Effect] Availability Slots Locking Failed');

export const clearAvailabilityInfo = createAction('[Fast Booking] Clear Availability Info');

export const clearAvailabilitySelectedDay = createAction('[Fast Booking] Clear Availability Selected Day');
