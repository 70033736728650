import { Component, Input, OnInit } from '@angular/core';

export interface IRequestQuickCardData {
  bookingDate: any;
  gigTitle: string;
  loggedUserName: string;
  profileImage: any;
  proName: string;
  requestStatusLabel: string;
  city?: string;
}

@Component({
  selector: 'app-request-quick-card',
  templateUrl: './request-quick-card.component.html',
  styleUrls: ['./request-quick-card.component.scss'],
})
export class RequestQuickCardComponent implements OnInit {
  @Input() requestQuickCardData: IRequestQuickCardData;
  @Input() bookingDate: any;
  @Input() gigTitle: string;
  @Input() loggedUserName: string;
  @Input() profileImage: any;
  @Input() proName: string;
  @Input() requestStatusLabel: string;

  constructor() {}

  ngOnInit(): void {}
}
