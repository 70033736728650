import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AvailableDay } from '../../../models';
import {
  BOOKING_DAYS_IN_ADVANCE_CONSTRAINT,
  getFirstDayOfNextMonthForDate,
  EVENT_NAME_PREFIX,
  isDateWithinNumberOfDaysInFuture,
} from '../../../utils';
import { InfoService, ToastService } from '../../../services';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnDestroy {
  @Input() bookingDate: Date;
  @Input() isOpen: boolean;
  @Input() disablePastMonths?: boolean = false;
  @Input() noFutureLimit: boolean = false;

  @Input() isAvailabilityDatepicker?: boolean;
  @Input() isBookModalDatepicker?: boolean;
  @Input() enabled$?: Observable<boolean>;
  @Input() availableDates$?: Observable<AvailableDay[]>;
  @Input() preselect: { startTime: number; endTime: number };

  @Output() dateChange = new EventEmitter<Date>();
  @Output() monthChange = new EventEmitter<Date>();
  @Output() toggleDatepicker = new EventEmitter<void>();

  public todayDate: Date;
  public year: number;
  public month: number;
  public currentMonth: number | string;
  public dayMonth: number;
  public day: number;
  public weekday: number | string;
  public dates: any[];
  public options = {
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    maxFutureDaysAllowed: 80,
  };
  public disableToday: boolean;
  public availableDates: any;
  public calendarIsEnabled = false;
  public showHoverState: boolean = false;

  private availabilityDatepickerInitInProgress: boolean = false;
  private errorMsgSomethingWentWrong: string;
  private errorMsgNoFreeSlots: string;
  private errorMsgDayNoLongerAvailable: string;
  private firstDayOfMonthToBeSelected: Date;
  private subscription$ = new Subscription();

  constructor(
    private infoService: InfoService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    this.disableToday = false;
    this.todayDate = new Date();
    this.showHoverState = this.deviceService.isDesktop();

    this.translateService.get('TOAST_MESSAGE').subscribe((res) => {
      this.errorMsgSomethingWentWrong = res.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN;
      this.errorMsgNoFreeSlots = res.LOOKS_LIKE_SELLER_HAS_NO_FREE_SLOTS;
      this.errorMsgDayNoLongerAvailable = res.THE_DAY_YOU_SELECTED_IS_NO_LONGER_AVAILABLE;
    });

    if (this.isAvailabilityDatepicker) {
      // TODO (Milan): Maybe organize this better
      if (this.preselect) {
        const preselectDate = new Date(this.preselect.startTime);
        this.firstDayOfMonthToBeSelected = new Date(preselectDate.getFullYear(), preselectDate.getMonth(), 1);
        this.year = preselectDate.getFullYear();
        this.month = preselectDate.getMonth();
        this.dayMonth = preselectDate.getDate();
        this.day = preselectDate.getDay();
      } else {
        this.firstDayOfMonthToBeSelected = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
        this.year = this.firstDayOfMonthToBeSelected.getFullYear();
        this.month = this.firstDayOfMonthToBeSelected.getMonth();
        this.dayMonth = this.firstDayOfMonthToBeSelected.getDate();
        this.day = this.firstDayOfMonthToBeSelected.getDay();
      }

      // Setting the flag on first emission of 'true' for enabled$
      this.subscription$.add(
        this.enabled$
          .pipe(
            filter((value) => value === true),
            take(1),
          )
          .subscribe(() => {
            this.availabilityDatepickerInitInProgress = true;
          }),
      );

      this.subscription$.add(
        this.availableDates$.subscribe((availableDates) => {
          this.availableDates = availableDates;
          if (!this.availableDates) {
            this.toastService.showMessage(this.errorMsgSomethingWentWrong);
          } else {
            const monthHasAvailableDays = availableDates?.length > 0;

            // availabilityDatepickerInitInProgress is a flag marking the initial load of the availability modal, it is used
            // for triggering the prepopulating logic
            if (this.availabilityDatepickerInitInProgress) {
              if (!monthHasAvailableDays && !this.preselect) {
                this.firstDayOfMonthToBeSelected = getFirstDayOfNextMonthForDate(this.firstDayOfMonthToBeSelected);
                if (
                  isDateWithinNumberOfDaysInFuture(this.firstDayOfMonthToBeSelected, BOOKING_DAYS_IN_ADVANCE_CONSTRAINT)
                ) {
                  // If start of the next month is still within 90 days from today, fetch the next month availability
                  this.monthChange.emit(this.firstDayOfMonthToBeSelected);
                } else {
                  // this.toastService.showMessage(this.errorMsgNoFreeSlots);
                  this.availabilityDatepickerInitInProgress = false;
                  this.initializeDatepicker(this.year, this.month, this.dayMonth, this.day);
                }
              } else {
                this.availabilityDatepickerInitInProgress = false;

                if (this.preselect) {
                  const dateIndex = availableDates.findIndex(
                    (date) => parseInt(date.startDateTime) === this.preselect.startTime,
                  );

                  if (dateIndex === -1) {
                    this.toastService.showMessage(this.errorMsgDayNoLongerAvailable);
                  } else {
                    this.pickDate(new Event(null), this.day, this.dayMonth);
                  }
                } else {
                  const firstAvailableDay = new Date(availableDates[0].startDateTime);
                  this.year = firstAvailableDay.getFullYear();
                  this.month = firstAvailableDay.getMonth();
                  this.dayMonth = firstAvailableDay.getDate();
                  this.day = firstAvailableDay.getDay();
                  this.pickDate(new Event(null), this.day, this.dayMonth);
                }

                this.initializeDatepicker(this.year, this.month, this.dayMonth, this.day);
              }
            } else {
              this.initializeDatepicker(this.year, this.month, this.dayMonth, this.day);
            }
          }
        }),
      );

      this.subscription$.add(
        this.enabled$.subscribe((enabled) => {
          this.calendarIsEnabled = enabled;
          if (this.calendarIsEnabled) {
            this.monthChange.emit(this.firstDayOfMonthToBeSelected);
          }
        }),
      );
    } else if (!this.isAvailabilityDatepicker) {
      this.initializeDatepicker();

      if ((this.todayDate.getHours() === 18 && this.todayDate.getMinutes() >= 59) || this.todayDate.getHours() > 18) {
        this.disableToday = true;
      }
    }
  }

  noOp(e): void {
    e.stopPropagation();
    e.preventDefault();
  }

  calculateDate(year, month, day, dayweek): string | number {
    if (year == null) {
      year = false;
    }
    if (month == null) {
      month = false;
    }
    if (day == null) {
      day = false;
    }
    if (dayweek == null) {
      dayweek = false;
    }
    this.year = year || this.todayDate.getFullYear();
    this.month = this.todayDate.getMonth();
    if (month !== false) {
      this.month = month;
    }

    if (this.options && this.options.monthNames) {
      this.currentMonth = this.options.monthNames[this.month];
      this.dayMonth = day || this.todayDate.getDate();
      const dayWeek = this.todayDate.getDay();
      this.weekday = this.options.weekDays[dayWeek];
      if (dayweek !== false) {
        return (this.weekday = this.options.weekDays[dayweek]);
      }
    }
  }

  initializeDatepicker(year?, month?, day?, dayweek?): void {
    this.calculateDate(year, month, day, dayweek);

    const firstDayOfMonth = new Date(this.year, this.month, 1);
    const firstDayOfWeek = firstDayOfMonth.getDay();

    const numberOfDays = new Date(this.year, this.month + 1, 0).getDate();
    this.dates = [];
    let count = 1;
    for (let i = 0; i < 6; i++) {
      this.dates[i] = new Array(7);
      for (let j = 0; j < 7; j++) {
        if (count <= numberOfDays && (i > 0 || j >= firstDayOfWeek)) {
          this.dates[i][j] = count++;
        }
      }
      if (count > numberOfDays) {
        break;
      }
    }
  }

  changeMonth(e, value): void {
    e.stopPropagation();
    e.preventDefault();
    let dayweek;
    this.month += value;
    if (this.month < 0) {
      this.month = 11;
      this.year--;
    } else if (this.month > 11) {
      this.month = 0;
      this.year++;
    }
    // TODO (Milan): dayweek seems to have no use, it is passed into initializeDatepicker but
    // it is only used to set some local variables as it seems, not used for any calculation or display.
    // Should be double checked and removed.
    // *Daylight saving time warning* creating a date with params for 31. of april: new Date(2022, 3, 31) will create
    // the object referencing to 1st of May!!So not as expected!
    dayweek = new Date(this.year, this.month, this.dayMonth);
    this.day = dayweek.getDay();
    if (!this.isAvailabilityDatepicker) {
      this.initializeDatepicker(this.year, this.month, this.dayMonth, this.day);
    } else if (this.isAvailabilityDatepicker) {
      this.firstDayOfMonthToBeSelected = new Date(this.year, this.month, 1);
      this.monthChange.emit(this.firstDayOfMonthToBeSelected);
    }
  }

  pickDate(e, index, day): any {
    e.stopPropagation();
    e.preventDefault();
    if (!day) {
      return;
    }

    this.dayMonth = day;
    this.weekday = this.options.weekDays[index];
    this.bookingDate = new Date(this.year, this.month, day);
    this.bookingDate.setHours(19);
    this.bookingDate.setMinutes(59);

    if (this.isAvailabilityDatepicker) {
      // Preselecting also triggers pickDate but we can distinguish real user clicks by event type property
      if (e?.type === 'click') {
        this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.date.click`, {
          element: `availability-date-select`,
          bookingDate: this.bookingDate,
        });
      }
      // TODO (Milan): should be resolved cleaner, 'originalIndex' property is added which represents the index in the non-filtered array where all days
      // are present (available and non-available) as we get in BE response
      this.dateChange.emit(this.availableDates.find((date) => date.originalIndex === day));
    } else {
      this.dateChange.emit(this.bookingDate);
    }

    this.toggleDatepicker.emit();
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
