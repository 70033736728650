import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { BookingService } from '../../../services';
import { BookingByStatus, BookingModal } from '../../../models';
import { BookModalData } from '../book-modal/book-modal.component';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { createBookModalData, createAvatarGroupData } from '../../../utils';

@Component({
  selector: 'app-book-modal-complete',
  templateUrl: './book-modal-complete.component.html',
  styleUrls: ['./book-modal-complete.component.scss'],
})
export class BookModalCompleteComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public bookModalPendingRequestData: BookingByStatus;
  public bookModalData: BookModalData;
  public avatarGroupData: IAvatarGroupData;
  public buttonLabel: string;
  public isSellerModal: boolean;

  public fromParent: BookingModal;
  public bookingTitlePlaceholder: string;
  public messagePlaceholder: string;
  public menteeTitle: string;
  public mentorTitle: string;
  public bookingIdNumberTitle: string;
  public serviceProvisionType: string;
  public gigTitleTitle: string;
  public bookerTitle: string;
  public bookingDatesTitle: string;
  public payoutTitle: string;
  public bookingMusicProTitle: string;
  public avatarGroupTitle: string;
  public reviewTitle: string;
  public rateYourExperienceTitle: string;
  public contactSupportTitle: string;
  public manageBookingTitle: string;

  public paymentClicked: boolean;

  public reviewed: boolean;

  public user: any;
  public starNumber: number;

  public priceTotal: number;

  constructor(
    public activeModal: NgbActiveModal,
    public bookingService: BookingService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
  ) {
    // initial value
    this.paymentClicked = false;
  }

  ngOnInit(): void {
    this.translate.get('BOOK').subscribe((res: any) => {
      this.bookingTitlePlaceholder = res.REMOTE_RECORDING_FOR_MY_PROJECT;
      this.messagePlaceholder = res.INTRODUCE_YOURSELF;
      this.bookingIdNumberTitle = res.BOOKING_ID_NUMBER;
      this.serviceProvisionType = res.BOOKING_TYPE;
      this.gigTitleTitle = res.GIG_TITLE;
      this.bookerTitle = res.BOOKER;
      this.bookingDatesTitle = res.BOOKING_DATE;
      this.payoutTitle = res.PAYOUT;
      this.bookingMusicProTitle = res.BOOKING_MUSIC_PRO;
      this.reviewTitle = res.REVIEW;
      this.rateYourExperienceTitle = res.RATE_YOUR_EXPERIENCE;
      this.contactSupportTitle = res.CONTACT_SUPPORT;
      this.manageBookingTitle = res.MANAGE_BOOKING;
      this.menteeTitle = res.MENTOR;
      this.mentorTitle = res.MENTEE;

      // @ts-ignore
      this.isSellerModal = this.fromParent.isSeller;
      this.payoutTitle = this.isSellerModal ? res.PAYOUT : res.PAYMENT;
    });

    this.bookModalPendingRequestData = { ...this.fromParent.booking.booking };

    this.avatarGroupTitle = this.getAvatarGroupTitle();

    if (this.isSellerModal) {
      this.reviewed = !!(this.fromParent?.isRated && this.bookModalPendingRequestData.bookingRating?.rateBooker);
      this.starNumber = this.bookModalPendingRequestData?.bookingRating?.rateBooker;
    } else {
      this.reviewed = !!(this.fromParent?.isRated && this.bookModalPendingRequestData.bookingRating?.rateBooked);
      this.starNumber = this.bookModalPendingRequestData?.bookingRating?.rateBooked;
    }

    const user = { ...this.fromParent.user };
    this.user = user;

    this.bookModalData = createBookModalData(user);
    this.avatarGroupData = createAvatarGroupData(user);

    if (this.isSellerModal) {
      this.priceTotal = this.bookModalPendingRequestData.price - this.bookModalPendingRequestData.sellersFee;
    } else {
      this.priceTotal = this.bookModalPendingRequestData.price + this.bookModalPendingRequestData.buyersFee;
    }
  }

  /* TODO (Jovana): Extract this function, it is repeated in book-modal-confirmed component*/
  getAvatarGroupTitle(): string {
    if (this.bookModalPendingRequestData?.instant) {
      return this.isSellerModal ? this.mentorTitle : this.menteeTitle;
    } else {
      return this.isSellerModal ? this.bookerTitle : this.bookingMusicProTitle;
    }
  }

  public closeModal(event): any {
    this.activeModal.close();
  }

  public submitForm(): any {
    // This is mock function, real action should be put here
    this.closeModal(undefined);
  }

  public openPayoutDetails(): void {
    this.router.navigate([`/bookings/${this.bookModalPendingRequestData.id}/payout-details`]);
    // this.paymentClicked = true;
  }

  openRatingModal(): any {
    this.router.navigate([`/bookings/${this.bookModalPendingRequestData.id}/completed/rate`]);
  }

  public openContactSupport(): void {
    // Does not redirect where expected when using relativeTo parameter
    // this.router.navigate(['support'], { relativeTo: this.route });
    this.router.navigate([`bookings/${this.bookModalPendingRequestData.id}/completed/support`]);
  }
}
