<section class="channels-list__content flex column">
  <div class="channels-list__title flex">Messages</div>
  <div class="channels-list__search">
    <span class="search-icon"></span>
    <input
      class="channels-list__input-field"
      #searchField
      id="search-field"
      (input)="search(searchField.value)"
      [placeholder]="'Search Messages'"
      [disabled]="!clientConnected"
    />
    <img
      *ngIf="searchTerm$ | async"
      class="clear-icon"
      alt="Clear"
      src="assets/images/icons/close-black.png"
      (click)="searchField.value = ''; search(searchField.value)"
    />
  </div>
  <div *ngIf="clientConnected && conversationsData; else conversationsLoading" class="channels-list__scroll-wrapper">
    <div class="channels-list__inner-wrapper">
      <app-channel-card
        *ngFor="let conversationData of conversationsData"
        [isUnread]="conversationData.hasUnreadMessages"
        [isLoaded]="true"
        [conversationData]="conversationData"
        [isConversationSelected]="selectedConversation[conversationData.uniqueName]"
        [lastMessage]="conversationData.lastMessage"
        [participant]="conversationData.otherParticipant"
        [updatedDate]="conversationData.lastUpdateTime"
        (click)="selectConversation(conversationData.uniqueName)"
      ></app-channel-card>
      <div
        visibilityObserver
        (visible)="bottomReached($event)"
        [debounceTime]="500"
        class="channels-list__bottom-mark"
      ></div>
    </div>
  </div>
  <ng-template #conversationsLoading>
    <div *ngIf="conversationsData?.length === 0"></div>
    <div *ngIf="conversationsData?.length !== 0">
      <app-channel-card *ngFor="let mockData of isLoadingArray" [isLoaded]="false"></app-channel-card>
    </div>
  </ng-template>
</section>
