import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationContentDirective } from './navigation-content.directive';

@NgModule({
  declarations: [NavigationContentDirective],
  imports: [CommonModule],
  exports: [NavigationContentDirective],
})
export class NavigationContentModule {}
