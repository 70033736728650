<app-mentorship-modal>
  <div class="mentorship-session-rating-modal__container truncated-profile__content-container">
    <img class="mentorship-session-rating-modal__close" [src]="closeIcon" alt="Close icon" (click)="closeModal(true)" />
    <img class="mentorship-session-rating-modal__logo" [src]="jammcardMentorsLogo" alt="Jammcard logo" />
    <h3 class="mentorship-session-rating-modal__title">
      {{ 'BOOK.CONGRATS_ON_COMPLETING_YOUR_MENTORSHIP' | translate }}
    </h3>
    <p class="mentorship-session-rating-modal__text">{{ 'BOOK.PLEASE_LEAVE_REVIEW_ON_YOUR_SESSION' | translate }}</p>
    <p class="mentorship-session-rating-modal__text">{{ 'BOOK.THE_MORE_DETAILED_THE_BETTER' | translate }}</p>
    <div class="mentorship-session-rating-modal__star-wrapper">
      <app-rate-experience-group [(starNumber)]="starNumber"></app-rate-experience-group>
    </div>
    <form class="mentorship-session-rating-modal__form" #reviewForm="ngForm" (ngSubmit)="submitForm()">
      <textarea
        class="mentorship-session-rating-modal__input auth-modal__input"
        type="text"
        name="reviewInput"
        [placeholder]="textareaPlaceholder"
        #reviewInputRef="ngModel"
        [(ngModel)]="reviewInput"
        [maxlength]="reviewMaxLength"
      ></textarea>
      <div class="mentorship-session-rating-modal__error-container">
        <p *ngIf="reviewInput?.length === reviewMaxLength" class="help-block">
          {{ 'BOOK.REVIEW_CANNOT_BE_LONGER_THAN_255_CHARACTERS' | translate }}
        </p>
      </div>

      <div class="mentorship-session-rating-modal__button-wrapper">
        <app-button
          id="reviewFormButton"
          [buttonDisabled]="!reviewForm.valid || !starNumber"
          buttonLabel="{{ 'BOOK.DONE' | translate }}"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
    </form>
  </div>
</app-mentorship-modal>
