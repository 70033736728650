import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageAvatarComponent } from './message-avatar.component';

@NgModule({
  declarations: [MessageAvatarComponent],
  exports: [MessageAvatarComponent],
  imports: [CommonModule],
})
export class MessageAvatarModule {}
