import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalAddPayoutComponent } from './book-modal-add-payout.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { PayoutMethodInfoModule } from '../../molecules/payout-method-info/payout-method-info.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { FormsModule } from '@angular/forms';
import { BookModalAddPayoutEntryComponent } from './book-modal-add-payout-entry.component';

@NgModule({
  declarations: [BookModalAddPayoutComponent, BookModalAddPayoutEntryComponent],
  imports: [CommonModule, ModalModule, BookModalSectionModule, PayoutMethodInfoModule, ButtonModule, FormsModule],
  exports: [BookModalAddPayoutComponent, BookModalAddPayoutEntryComponent],
})
export class BookModalAddPayoutModule {}
