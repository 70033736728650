import { Component, OnInit, ViewChild, OnDestroy, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { BookingSentModalComponent } from '../booking-sent-modal/booking-sent-modal.component';
import { AuthenticationService, BookingService, LocalStorageService, ToastService } from '../../../services';
import {
  AUTH_CONTROLLER,
  BOOKING_GUEST,
  MODAL_DISMISS_REASON,
  USER_EMAIL,
  PROFILE_USERNAME,
  getNoBookProfileUrl,
  USER_NAME,
} from '../../../utils';
import { RateOld } from '../../../models';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { AuthControllerModalComponent } from '../../templates/auth-controller-modal/auth-controller-modal.component';

const messagePlaceholder = 'Introduce yourself and add any additional details about the booking here.';

export interface BookModalData {
  isBookRoute: boolean;
  loggedUserName: string;
  modalImage: string;
  proCity: string;
  proId: number;
  proName: string;
  proVerified: boolean;
  profileImage: string;
  ratesAndServices: RateOld[];
}

export interface BookingInfo {
  dates: [number];
  date: Date;
  gigTitle: string;
  serviceProvisionType: string;
  message: string;
  price: number;
  sellerProfileId: number;
}

export interface BookingInstructionsEmailData {
  bookeeProfileId: number;
  email: string;
  fullName: string;
  message?: string;
}

export interface RateForDisplay {
  item: RateOld;
  type: string;
}

@Component({
  selector: 'app-book-modal',
  templateUrl: './book-modal.component.html',
  styleUrls: ['./book-modal.component.scss'],
})
export class BookModalComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'host-fill-height';
  @ViewChild('bookForm') bookForm: any;

  public avatarGroupData: IAvatarGroupData;

  public bookModalData: BookModalData;
  public bookingInfo: BookingInfo = {
    dates: [null],
    date: null,
    gigTitle: '',
    serviceProvisionType: '',
    message: '',
    price: null,
    sellerProfileId: null,
  };
  public bookingInstructionsEmailData: BookingInstructionsEmailData = {
    bookeeProfileId: null,
    email: null,
    fullName: null,
  };
  public bookingRequestSending: boolean;
  public buttonLabel: string;
  public date: NgbDateStruct;
  public dateSelected: boolean;
  public modalInstance: any;
  public pickedDate: number;
  /*TODO (Milan): Variables for rates feature, waiting for client feedback
  public rateIsSelected: boolean;
  private rateItemSelected: Rate;
  private rateTypeSelected: string;
  public ratesAndServices: Rate[];
  public ratesForDisplay: RateForDisplay[];
  */
  public isOpen: boolean;
  public dateOptions: any;
  public fromParent: any;

  public bookingTitlePlaceholder: string;
  public messagePlaceholder: string;

  public bookingTypeLive: string = 'Live';
  public bookingTypeRecording: string = 'Recording';

  constructor(
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    public bookingService: BookingService,
    private localStorageService: LocalStorageService,
    public modalService: NgbModal,
    private router: Router,
    private toastService: ToastService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.translate.get('BOOK').subscribe((res: any) => {
      this.buttonLabel = res.REQUEST_TO_BOOK;
      this.bookingTitlePlaceholder = res.REMOTE_RECORDING_FOR_MY_PROJECT;
      this.messagePlaceholder = res.INTRODUCE_YOURSELF;
    });

    this.dateSelected = false;
    this.isOpen = false;
    this.messagePlaceholder = messagePlaceholder;

    this.bookingInfo = this.prepoulateForm();
    this.setBookingInfo();

    // TODO (Jovana): Refactor this code
    const guestBooking: BookingInfo = this.getGuestBookingFromStorage();
    this.dateSelected =
      Boolean(this.localStorageService?.get(BOOKING_GUEST)?.date) &&
      guestBooking.sellerProfileId === this.fromParent.bookModalData.proId;
    /* TODO (Milan): For rates feature, waiting for client feedback
    this.setRatesForDisplay();
    */

    this.dateSelected = Boolean(this.localStorageService?.get(BOOKING_GUEST)?.date);
  }

  ngOnDestroy(): void {
    // NOTE(Mladen): A hack for removing querry params without refreshing the state / triggering a page reload
    // const url = this.router
    //   .createUrlTree([], {
    //     queryParams: { book: null },
    //     relativeTo: this.activatedRoute,
    //   })
    //   .toString();
    // this.location.go(url);
  }

  private prepoulateForm(): BookingInfo {
    const guestBooking: BookingInfo = this.getGuestBookingFromStorage();
    if (guestBooking) {
      guestBooking.date = new Date(guestBooking.date);
    }

    /* TODO (Milan): For rates feature, waiting for client feedback,
       the gigTitle in return object below should then pass this.gigTitle instead of an empty string
    this.setUpGigAndPrice();
    */

    return guestBooking && guestBooking.sellerProfileId === this.fromParent.bookModalData.proId
      ? guestBooking
      : {
          dates: [null],
          date: null,
          gigTitle: '',
          serviceProvisionType: '',
          message: '',
          price: null,
          sellerProfileId: null,
        };
  }

  private setBookingInfo(): void {
    this.bookModalData = this.fromParent.bookModalData;
    this.avatarGroupData = { ...this.bookModalData };
    this.bookingInstructionsEmailData = {
      bookeeProfileId: this.bookModalData.proId,
      email: this.localStorageService.get(USER_EMAIL),
      fullName: this.bookModalData.loggedUserName || this.localStorageService.get(USER_NAME),
    };
    this.bookingInfo.sellerProfileId = this.bookModalData?.proId;
    this.bookingInfo.date = this.setDefaultBookingDate();

    /* TODO (Milan): This is for rates feature, waiting for client feedback
    this.ratesAndServices = this.bookModalData.ratesAndServices;

    if (this.bookModalData.isBookRoute) {
      this.rateIsSelected = true;
    }
    */
  }

  public setDefaultBookingDate(): Date {
    const today = new Date();
    return this.bookingInfo.date && this.isBookDateValid(this.bookingInfo.date, today)
      ? this.bookingInfo.date
      : new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2, 19, 59);
  }

  private isBookDateValid(date: Date, today: Date): boolean {
    return date >= today;
  }

  /* TODO (Milan): For rates feature, waiting for client feedback
  private setUpGigAndPrice(): any {
    if (this.rateItemSelected) {
      this.gigTitle = `${toTitleCase(this.rateItemSelected.serviceName)} with ${this.bookModalData.proName}`;
      this.price = this.rateItemSelected.serviceRate;
    } else {
      this.price = null;
      this.gigTitle =
        this.rateTypeSelected && this.rateTypeSelected !== OTHER_RATE_TYPE
          ? `${toTitleCase(this.rateTypeSelected)} with ${this.bookModalData.proName}`
          : '';
    }
  }


  private setRatesForDisplay(): void {
    let ratesOfTypeOther: RateForDisplay[];

    ratesOfTypeOther = this.ratesAndServices
      .filter((rate) => rate.serviceType === OTHER_RATE_TYPE)
      .map((rateItem) => {
        return {
          item: rateItem,
          type: OTHER_RATE_TYPE,
        };
      });

    // The desired order of rates for displaying them is achieved here
    this.ratesForDisplay = [
      {
        item: this.ratesAndServices.find((rate) => rate.serviceType === CONSULTATION_RATE_TYPE),
        type: CONSULTATION_RATE_TYPE,
      },
      {
        item: this.ratesAndServices.find((rate) => rate.serviceType === LESSONS_RATE_TYPE),
        type: LESSONS_RATE_TYPE,
      },
      {
        item: this.ratesAndServices.find((rate) => rate.serviceType === RECORDING_RATE_TYPE),
        type: RECORDING_RATE_TYPE,
      },
      {
        item: this.ratesAndServices.find((rate) => rate.serviceType === PERFORMANCE_RATE_TYPE),
        type: PERFORMANCE_RATE_TYPE,
      },
      ...ratesOfTypeOther,
      {
        item: undefined,
        type: OTHER_RATE_TYPE,
      },
    ];
  }
  */

  public scrollElementIntoView(elementId): void {
    const parent = document.getElementById(elementId);
    if (parent.scrollTop < parent.scrollHeight) {
      parent.scrollTop = parent.scrollHeight;
    }
  }

  public closeModal(event?): any {
    this.activeModal.close();
  }

  private getGuestBookingFromStorage(): BookingInfo {
    return this.localStorageService.get(BOOKING_GUEST);
  }

  public get Date(): Date {
    return new Date(this.bookingInfo.date);
  }

  public submitForm(bookingInfo: BookingInfo): void {
    this.bookingInfo.dates[0] = new Date(bookingInfo.date).valueOf();
    const bookingInfoFormatted = this.formatBookingInfo(this.bookingInfo);
    const isLoggedIn = this.authenticationService.isLoggedIn();

    isLoggedIn ? this.sendBookingRequest(bookingInfoFormatted) : this.openRegisterModal(this.bookingInfo);
  }

  private sendBookingRequest(booking: any): void {
    this.bookingRequestSending = true;
    this.bookingService.sendBookingRequest(booking).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.bookingRequestSending = false;
          this.localStorageService.delete(BOOKING_GUEST);
          if (Object.values(this.bookingInstructionsEmailData).some((prop) => !prop)) {
            this.setBookingInfo();
          }
          const bookingInstructionsEmailData = this.bookingInstructionsEmailData;
          this.bookingService.requestInstructionsEmail(bookingInstructionsEmailData).subscribe();

          this.modalInstance = this.modalService.open(BookingSentModalComponent, {
            windowClass: 'modal-window',
            backdrop: 'static',
          });
          const bookingDate = this.bookingInfo.dates[0];
          const gigTitle = this.bookingInfo.gigTitle;
          const loggedUserName = this.bookModalData?.loggedUserName;
          const proName = this.bookModalData?.proName;
          const profileImage = this.bookModalData?.profileImage;
          const redirectToUrl = getNoBookProfileUrl(this.router.url);
          this.modalInstance.componentInstance.fromParent = {
            bookingDate,
            loggedUserName,
            gigTitle,
            proName,
            profileImage,
            redirectToUrl,
          };
        }
      },
      (error) => {
        this.bookingRequestSending = false;
        if (error.status === 401) {
          this.toastService.showMessage('Something went wrong');
        } else if (error.status === 404) {
          this.toastService.showMessage('Something went wrong');
        } else {
          this.toastService.showMessage('Something went wrong');
        }
      },
    );
  }

  private openRegisterModal(booking: any): void {
    this.activeModal.dismiss(MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK);

    if (this.getGuestBookingFromStorage()) {
      this.localStorageService.delete(BOOKING_GUEST);
      this.localStorageService.add(BOOKING_GUEST, booking);
    } else {
      this.localStorageService.add(BOOKING_GUEST, booking);
    }
    this.localStorageService.add(PROFILE_USERNAME, this.bookModalData?.proId);

    const modalInstance = this.modalService.open(AuthControllerModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    const signupModalData = {
      modalImage: this.bookModalData.modalImage,
      proName: this.bookModalData.proName,
      book: true,
    };

    modalInstance.componentInstance.fromParent = {
      modalType: AUTH_CONTROLLER.SIGNUP,
      authControllerModalData: signupModalData,
    };
  }

  private formatBookingInfo(bookingInfo: BookingInfo): any {
    const bookingInfoFormatted = { ...bookingInfo };
    delete bookingInfoFormatted.date;
    return bookingInfoFormatted;
  }

  public validatePrice(value): any {
    if (value > 900000) {
      this.bookForm.controls.bookingPrice.setErrors({ invalid: true });
    }
    if (value <= 0) {
      this.bookForm.controls.bookingPrice.setErrors({ invalid: true });
    }
    if (!value) {
      this.bookForm.controls.bookingPrice.setErrors({ invalid: true, required: true });
    }
  }

  public toggleDatepicker(): void {
    this.isOpen = !this.isOpen;
    // Hide Message placeholder when Datepicker is open, display when closed
    this.isOpen ? (this.messagePlaceholder = '') : (this.messagePlaceholder = messagePlaceholder);

    document.getElementById('bookModalDateTitleSeparator').scrollIntoView(true);
  }

  public closeDatepicker(): void {
    this.isOpen = false;
    // Show Message placeholder text when Datepicker is closed
    this.messagePlaceholder = messagePlaceholder;
  }

  public changeDate(date): void {
    this.bookingInfo.date = date;
    this.dateSelected = true;
  }

  /* TODO (Milan): Put back in when client feedback is provided
  public onRateSelected(rate: RateForDisplay): void {
    this.rateItemSelected = rate.item;
    this.rateTypeSelected = rate.type;
    this.bookingInfo = this.prepoulateForm();
    this.setBookingInfo();
    this.rateIsSelected = true;
  }
  */
}
