import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'securityCode',
})
export class SecurityCodePipe implements PipeTransform {
  transform(value: number): string {
    if (value === 4) {
      return '••••';
    }
    return '•••';
  }
}
