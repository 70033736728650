import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '../../atoms/button/button.module';
import { RequestSentModalModule } from '../../organisms/request-sent-modal/request-sent-modal.module';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RequestSentModalModule,
  ],
  exports: [ResetPasswordComponent],
})
export class ResetPasswordModule {}
