import { Component, Input, OnInit } from '@angular/core';
import { BookingRating } from '../../../models';

@Component({
  selector: 'app-rated-experience-group',
  templateUrl: './rated-experience-group.component.html',
  styleUrls: ['./rated-experience-group.component.scss'],
})
export class RatedExperienceGroupComponent implements OnInit {
  @Input() name: string;
  @Input() starNumber: number;

  public starEmptyNumber: number;
  public starFullNumber: number;
  public starEmptyNumberArray: Array<any>;
  public starFullNumberArray: Array<any>;

  constructor() {}

  ngOnInit(): void {
    this.starFullNumber = this.starNumber;
    this.starEmptyNumber = 5 - this.starNumber;
    this.starEmptyNumberArray = new Array<any>(this.starEmptyNumber);
    this.starFullNumberArray = new Array<any>(this.starFullNumber);
  }
}
