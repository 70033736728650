import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-change-notification',
  templateUrl: './change-notification.component.html',
  styleUrls: ['./change-notification.component.scss'],
})
export class ChangeNotificationComponent implements OnInit {
  @Input() changeNotificationLabel: string;
  @Input() changeNotificationChecked: boolean;

  @Output() changeNotificationValue = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  emitChangeNotificationValue(e): void {
    this.changeNotificationValue.emit(e);
  }
}
