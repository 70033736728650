import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataImgComponent } from './no-data-img.component';

@NgModule({
  declarations: [NoDataImgComponent],
  exports: [NoDataImgComponent],
  imports: [CommonModule],
})
export class NoDataImgModule {}
