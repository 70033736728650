import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';

import {
  createLocalTracks,
  connect,
  Room,
  LocalAudioTrack,
  LocalVideoTrack,
  createLocalVideoTrack,
} from 'twilio-video';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { LocalStorageService, ToastService } from '../services';
import { VIDEO_CALL_SELECTED_AUDIO_DEVICE_ID, VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID, SERVICE_URL } from '../utils';
import { VideoSettingsModalComponent } from '../components/organisms/video-settings-modal/video-settings-modal.component';
import { VIDEO_ERRORS } from '../pages/video-call/video-call.config';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  public localAudioTrack: LocalAudioTrack;
  public localVideoTrack: LocalVideoTrack;
  public localVideoDimensions: {
    width: number;
    height: number;
  };
  public remoteVideoDimensions: {
    width: number;
    height: number;
  };
  public room: Room;

  public audioOutputChanged$ = new Subject<any>();
  public cameraFacingMode$ = new ReplaySubject<string>(1);
  // Video room component will get initiated after these flags were already set and emitted so we need ReplaySubject
  public localAudioMuted$ = new ReplaySubject<boolean>(1);
  public localVideoDisabled$ = new ReplaySubject<boolean>(1);
  public videoTrackRepublish$ = new Subject<boolean>();

  private localAudioMuted = false;
  private localVideoDisabled = false;
  private videoQualityConstraints: any;
  private videoWasPreviouslyDisabled: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
  ) {}

  public checkRoomStatus(bookingId: number): Observable<HttpResponse<any>> {
    return this.http.get(`${SERVICE_URL.MESSAGING}api/video/join-video-room/${bookingId}`, { observe: 'response' });
  }

  public async connectToRoom(twilioToken: string, roomName: string): Promise<void | Room> {
    if (this.localAudioTrack && this.localVideoTrack) {
      const videoConnectOptions = this.deviceService.isMobile()
        ? { height: 480, frameRate: 24, width: 640 }
        : { height: 1080, frameRate: 24, width: 1920 };

      // TODO (Milan): Check for changing room if it causes problems. Connecting to a new room should overwrite the old one, but
      // should be double checked if something undesired happens in that case.
      return connect(twilioToken, {
        name: roomName,
        tracks: [this.localAudioTrack, this.localVideoTrack],
        video: videoConnectOptions,
        // The order of these codecs is important for interaction between Chrome/Firefox and Safari.
        // If set in a different order or not set at all Safari browser cannot get video sent from a participant
        // using Chrome or Mozila
        // preferredVideoCodecs: ['H264', 'VP8'],
        // trying without set codecs ^ TODO (Milan): check back to see should it be set or not
      })
        .then((room) => {
          this.room = room;
          const isMobile = this.deviceService.isMobile();
          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', this.disconnectFromRoomAndRemoveTracks);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', this.disconnectFromRoomAndRemoveTracks);
          }

          room.once('disconnected', (room, error) => {
            // Reset the room only after all other `disconnected` listeners have been called.
            // setTimeout(() => setRoom(null));
            this.toastService.showMessage('You have left the session.', 'notification');
            window.removeEventListener('beforeunload', this.disconnectFromRoomAndRemoveTracks);

            if (isMobile) {
              window.removeEventListener('pagehide', this.disconnectFromRoomAndRemoveTracks);
            }

            if (error) {
              this.handleVideoSessionError(error);
            }
          });

          return room;
        })
        .catch((error) => {
          this.room = null;
          this.handleVideoSessionError(error);
        });
    } else {
      return;
    }
  }

  public async createLocalParticipantTracks(): Promise<boolean> {
    const selectedVideoDeviceId = this.localStorageService.get(VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID);
    const selectedAudioDeviceId = this.localStorageService.get(VIDEO_CALL_SELECTED_AUDIO_DEVICE_ID);
    this.videoQualityConstraints = this.deviceService.isMobile()
      ? {
          height: 480,
          frameRate: 24,
          width: 640,
        }
      : {
          height: 1080,
          frameRate: 24,
          width: 1920,
        };
    const localTracksConstraints = {
      // TODO (Milan): Try the video constraints to use better resolution
      video: {
        ...this.videoQualityConstraints,
        ...(!!selectedVideoDeviceId && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio: {
        ...(!!selectedAudioDeviceId && { deviceId: { exact: selectedAudioDeviceId } }),
      },
    };

    return createLocalTracks(localTracksConstraints)
      .then((tracks) => {
        if (tracks) {
          this.localAudioTrack = tracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
          this.localVideoTrack = tracks.find((track) => track.kind === 'video') as LocalVideoTrack;
          this.localVideoTrack.on('started', this.setCameraFacing);
          this.localAudioMuted$.next(this.localAudioMuted);
          this.localVideoDisabled$.next(this.localVideoDisabled);

          if (!selectedVideoDeviceId) {
            this.localStorageService.add(
              VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID,
              this.localVideoTrack?.mediaStreamTrack.getSettings().deviceId,
            );
          }
          return true;
        } else {
          // TODO (Milan): See how to handle this case
          this.localAudioMuted = true;
          this.localVideoDisabled = true;
          this.localAudioMuted$.next(this.localAudioMuted);
          this.localVideoDisabled$.next(this.localVideoDisabled);
          return false;
        }
      })
      .catch((e) => {
        this.handleVideoSessionError(e);
        this.localAudioMuted = true;
        this.localVideoDisabled = true;
        this.localAudioMuted$.next(this.localAudioMuted);
        this.localVideoDisabled$.next(this.localVideoDisabled);
        return false;
      });
  }

  private setCameraFacing = (localVideoTrack: LocalVideoTrack) => {
    this.cameraFacingMode$.next(localVideoTrack.mediaStreamTrack?.getSettings().facingMode);
  };

  public toggleAudio(disable = false, outsideRoomToggle = false): void {
    if (!this.localAudioTrack) return;

    if (outsideRoomToggle) {
      if (!this.localAudioMuted || disable) {
        this.localAudioTrack.disable();
        this.localAudioMuted = true;
      } else {
        this.localAudioTrack.enable();
        this.localAudioMuted = false;
      }
    } else {
      if (!this.localAudioMuted || disable) {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
        this.localAudioMuted = true;
      } else {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });
        this.localAudioMuted = false;
      }
    }

    this.localAudioMuted$.next(this.localAudioMuted);
  }

  // NOTE (Milan): toggleVideo with stopping and unpublishing and then republishing the track
  // the first tried method was enabling and disabling the track but it caused a crash in Safari
  public async toggleVideo(disable = false, outsideRoomToggle = false): Promise<void> {
    if (!this.localVideoTrack) return;

    if (outsideRoomToggle) {
      if (!this.localVideoDisabled || disable) {
        this.localVideoTrack.stop();
        this.localVideoDisabled = true;
      } else {
        this.localVideoTrack = await this.createLocalVideoTrackWrapper();
        this.localVideoDisabled = false;
      }
    } else {
      if (!this.localVideoDisabled || disable) {
        this.localVideoTrack.stop();
        this.room.localParticipant.unpublishTrack(this.localVideoTrack);
        this.localVideoDisabled = true;
      } else {
        this.localVideoTrack = await this.createLocalVideoTrackWrapper();
        await this.room.localParticipant.publishTrack(this.localVideoTrack);
        this.localVideoDisabled = false;
      }
    }

    this.videoTrackRepublish$.next(true);
    this.localVideoDisabled$.next(this.localVideoDisabled);
  }

  public openSettings(selectedAudioOutputDeviceId: string = null, outsideRoomSettings = false): void {
    const modalRef = this.modalService.open(VideoSettingsModalComponent, {
      windowClass: 'modal-window video-settings-modal-window',
      backdrop: 'static',
    });

    modalRef.componentInstance.fromParent = {
      sinkId: selectedAudioOutputDeviceId,
      outsideRoomSettings,
    };
    modalRef.componentInstance?.audioOutputChanged.subscribe((e) => {
      this.audioOutputChanged$.next(e);
    });
    modalRef.componentInstance?.disableAudio.subscribe((e) => {
      this.toggleAudio(e.disable, e.outisdeRoomTrigger);
    });
    modalRef.componentInstance?.disableVideo.subscribe((e) => {
      this.toggleVideo(e.disable, e.outisdeRoomTrigger);
    });
  }

  public removeLocalAudioTrack(): void {
    if (this.localAudioTrack) {
      this.localAudioTrack.stop();
      this.localAudioTrack = null;
    }
  }

  public removeLocalVideoTrack(): void {
    if (this.localVideoTrack) {
      this.localVideoTrack.stop();
      this.localVideoTrack.off('started', this.setCameraFacing);
      this.localVideoTrack = null;
    }
  }

  private disconnectFromRoomAndRemoveTracks = () => {
    this.room.disconnect();
    this.removeLocalAudioTrack();
    this.removeLocalVideoTrack();
  };

  private async createLocalVideoTrackWrapper(): Promise<LocalVideoTrack> {
    const selectedVideoDeviceId = this.localStorageService.get(VIDEO_CALL_SELECTED_VIDEO_DEVICE_ID);

    return createLocalVideoTrack({
      ...this.videoQualityConstraints,
      ...(!!selectedVideoDeviceId && { deviceId: { exact: selectedVideoDeviceId } }),
    });
  }

  public handleVideoSessionError(error): void {
    let errorToShow: string;
    let toastClassToUse = 'error';
    if (error?.code && VIDEO_ERRORS?.[error?.code]) {
      errorToShow = VIDEO_ERRORS[error.code];
    } else if (error?.name && VIDEO_ERRORS?.[error?.name]) {
      errorToShow = VIDEO_ERRORS[error.name];
    } else if (error?.message === 'Room completed') {
      errorToShow = VIDEO_ERRORS.RoomCompleted;
      toastClassToUse = 'notification';
    } else {
      errorToShow = VIDEO_ERRORS.GeneralError;
    }
    // Note: pass the last parameter for autohide - false if an error is shown meaning do not autohide the toast, otherwise autohide is true
    this.toastService.showMessage(errorToShow, toastClassToUse, toastClassToUse !== 'error');

    // TODO (Milan): left while testing, remove after all goes well
    console.log('twilio error name: ', error?.name);
    console.log('twilio error message: ', error?.message);
  }

  public addMobileDeviceBackgroundHandling(): void {
    if (this.deviceService.isMobile()) {
      this.document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    }
  }

  private visibilityChangeHandler = async () => {
    if (this.document.visibilityState === 'hidden') {
      // The app has been backgrounded. So, stop and unpublish LocalVideoTrack if it isn't already stopped
      this.videoWasPreviouslyDisabled = this.localVideoDisabled;
      this.toggleVideo(true);
    } else {
      // The app has been foregrounded, So, create and publish a new LocalVideoTrack if the video was previously enabled
      if (!this.videoWasPreviouslyDisabled && this.localVideoDisabled) {
        this.toggleVideo();
        this.videoWasPreviouslyDisabled = null;
      }
    }
  };

  public removeMobileDeviceBackgroundHandling(): void {
    this.document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
  }
}
