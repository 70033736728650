import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TruncatedProfile } from '../../../models';

@Component({
  selector: 'app-unsubscribe-modal',
  templateUrl: './unsubscribe-modal.component.html',
  styleUrls: ['./unsubscribe-modal.component.scss'],
})
export class UnsubscribeModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public proTruncatedProfile: TruncatedProfile;
  public email: string;
  public serviceProvisionId: number;

  private fromParent: {
    proTruncatedProfile: TruncatedProfile;
    email: string;
    serviceProvisionId: number;
  };

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.proTruncatedProfile = this.fromParent.proTruncatedProfile;
    this.email = this.fromParent.email;
    this.serviceProvisionId = this.fromParent.serviceProvisionId;
  }

  public closeModal(flag: boolean): void {
    this.activeModal.close(flag);
  }
}
