<div class="booking-services-modal modal-fill-screen flex column" *ngIf="bookingServices && bookingServices.length > 0">
  <div class="booking-services__header">
    <h6 class="booking-services__header-title">{{ 'BOOK.BOOK' | translate }}</h6>
    <img class="booking-services__header-close" [src]="closeIcon" alt="Close icon" (click)="onCloseClicked()" />
  </div>
  <div class="booking-services">
    <div class="booking-services__card" *ngFor="let service of bookingServices">
      <h5 class="booking-services__card-title">{{ service?.type | translate }}</h5>
      <app-rate-label class="booking-services__card-rates" [rates]="service?.rates"></app-rate-label>
      <p class="booking-services__card-description">{{ service?.description | translate }}</p>
      <div *ngIf="service?.type !== mentorshipTitle">
        <app-button
          class="booking-services__card-button"
          buttonLabel="{{ service?.buttonLabel | translate }}"
          [rounded]="true"
          (click)="onConfirm(service)"
        ></app-button>
      </div>
      <div *ngIf="service?.type === mentorshipTitle">
        <app-button
          *ngIf="service.hasAvailability"
          class="booking-services__card-button"
          buttonLabel="{{ service?.buttonLabel | translate }}"
          [rounded]="true"
          (click)="onConfirm(service)"
        ></app-button>
        <app-button
          *ngIf="!service.hasAvailability"
          class="booking-services__card-button"
          buttonLabel="{{ 'HOME.JOIN_WAITLIST' | translate }}"
          [rounded]="true"
          (click)="onNotifyMeClicked()"
          [buttonGrayDisabled]="service.isViewerSubscribed"
        ></app-button>
      </div>
    </div>
  </div>
</div>
