import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../../store/app.reducers';
import { FastBookingAvailabilityModalComponent } from './fast-booking-availability-modal.component';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';
import {
  AVAILABILITY_MODAL_ENTERED_FROM,
  constructURLAccordingToSourceType,
  MOBILE_WEB_VIEW,
  MODAL_DISMISS_REASON,
} from '../../../utils';
import { selectFastBookingProTruncatedProfile } from '../../../store/fast-booking/fast-booking.selectors';
import { FastBookingRouterData, Rate, ServiceProvision, ServiceType, TruncatedProfile } from '../../../models';
import { LocalStorageService, ProfileService, ToastService } from '../../../services';

@Component({
  template: '',
})
export class FastBookingAvailabilityModalEntryComponent implements OnInit, OnDestroy {
  private subscription$ = new Subscription();
  private componentEnteredFrom: string;
  private navigateBackUrl: string;
  public isRescheduleRoute: boolean;
  public rescheduleRate: Rate;
  public routeId: string;
  private openedFromWebView: boolean;

  constructor(
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    public profileService: ProfileService,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.subscription$.add(
      this.activatedRoute.data.subscribe((data: FastBookingRouterData) => {
        /* Check if feature is opened from WebView. 
-         If it is, modal close route should have extra query params to indicate WebView exit */
        this.openedFromWebView = !!this.localStorageService.get(MOBILE_WEB_VIEW);

        this.componentEnteredFrom = data.modalEnteredFrom;
        if (this.componentEnteredFrom === AVAILABILITY_MODAL_ENTERED_FROM.PROFILE) {
          this.subscription$.add(
            this.activatedRoute.parent.params.subscribe((params) => {
              this.routeId = params.id;
              this.navigateBackUrl = this.routeId;
              this.store.dispatch(FastBookingActions.loadTruncatedProfile({ id: this.routeId }));
              this.selectDataForModal();
            }),
          );
        } else if (this.componentEnteredFrom === AVAILABILITY_MODAL_ENTERED_FROM.RESCHEDULE) {
          this.isRescheduleRoute = true;
          this.navigateBackUrl = `/${AVAILABILITY_MODAL_ENTERED_FROM.BOOKINGS}`;
          this.subscription$.add(
            this.activatedRoute.queryParams.subscribe((params) => {
              const bookingId = params.bookingId;
              this.profileService.getRescheduleData(bookingId).subscribe(
                (response) => {
                  this.rescheduleRate = {
                    serviceProvisionId: response.body.serviceProvisionId,
                    rateSetting: response.body.serviceProvisionRateSetting,
                    serviceProvisionOwner: response.body.serviceProvisionOwner,
                  };
                  // Reschedule flow will always set username as queryParams but here we store it inside routeId local variable
                  this.routeId = this.activatedRoute.snapshot.queryParams.username;
                  this.store.dispatch(FastBookingActions.loadTruncatedProfile({ id: this.routeId }));
                  this.selectDataForModal();
                },
                (error) => {
                  this.showMessage('Something went wrong!');
                  /* Check if feature is opened from WebView. 
-                   If it is, modal close route should have extra query params to indicate WebView exit */
                  this.router.navigateByUrl(
                    constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
                  );
                },
              );
            }),
          );
        } else {
          this.navigateBackUrl = this.router.url.split('/featured')[0];
          this.routeId = this.activatedRoute.snapshot.params.id;
          this.store.dispatch(FastBookingActions.loadTruncatedProfile({ id: this.routeId }));
          this.selectDataForModal();
        }
      }),
    );

    this.subscription$.add(
      this.actions$.pipe(ofType(FastBookingActions.truncatedProfileLoadingFailed)).subscribe(() => {
        /* Check if feature is opened from WebView. 
-         If it is, modal close route should have extra query params to indicate WebView exit */
        this.router.navigateByUrl(constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView));
      }),
    );
  }

  private selectDataForModal(): any {
    this.subscription$.add(
      this.store
        .select(selectFastBookingProTruncatedProfile)
        .pipe(
          filter(
            (proTruncatedProfile) =>
              !isNil(proTruncatedProfile) &&
              // NOTE: username is case sensitive, convert to lower case before comparison!
              (this.routeId.toLowerCase() === proTruncatedProfile?.username.toLowerCase() ||
                this.routeId === proTruncatedProfile?.profileId.toString()),
          ),
          take(1),
          switchMap((proTruncatedProfile) => {
            const servicesProvision = proTruncatedProfile?.servicesProvision;
            // TODO (Milan): Currently this is hardcoded for Mentorship service, eventually should be flexible.
            const serviceForBooking = servicesProvision?.find((service) => service.type === ServiceType.MENTORSHIP);

            if (!serviceForBooking && !this.isRescheduleRoute) {
              return this.translateService.get('TOAST_MESSAGE').pipe(
                tap((res) => {
                  this.toastService.showMessage(res.SOMETHING_WENT_WRONG);
                  /* Check if feature is opened from WebView. 
-                   If it is, modal close route should have extra query params to indicate WebView exit */
                  this.router.navigateByUrl(
                    constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
                  );
                }),
                switchMap(() => of({ proTruncatedProfile: null, serviceForBooking: null })),
              );
            } else {
              return of({ proTruncatedProfile, serviceForBooking });
            }
          }),
        )
        .subscribe(({ proTruncatedProfile, serviceForBooking }) => {
          if (proTruncatedProfile) {
            this.openModal(proTruncatedProfile, serviceForBooking, this.navigateBackUrl, this.rescheduleRate);
          }
        }),
    );
  }

  private openModal(
    proTruncatedProfile: TruncatedProfile,
    serviceForBooking: ServiceProvision,
    navigateBackUrl: string,
    rescheduleRate?: Rate,
  ): void {
    const modalInstance = this.modalService.open(FastBookingAvailabilityModalComponent, {
      windowClass: 'mentorship-modal-window',
      backdrop: 'static',
    });

    modalInstance.componentInstance.fromParent = {
      proTruncatedProfile,
      serviceForBooking,
      navigateBackUrl,
      rescheduleRate,
      openedFromWebView: this.openedFromWebView,
    };
    modalInstance.result.then(
      () => {
        // check this
        this.store.dispatch(FastBookingActions.clearFastBooking());
        this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
        /* Check if feature is opened from WebView. 
-         If it is, modal close route should have extra query params to indicate WebView exit */
        return this.router.navigateByUrl(
          constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
        );
      },
      (reason: string) => {
        if (
          reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH &&
          reason !== MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK &&
          reason !== MODAL_DISMISS_REASON.FAST_BOOKING_CHECKOUT
        ) {
          this.store.dispatch(FastBookingActions.clearFastBooking());
          this.store.dispatch(FastBookingActions.clearAvailabilityInfo());
          /* Check if feature is opened from WebView. 
-           If it is, modal close route should have extra query params to indicate WebView exit */
          return this.router.navigateByUrl(
            constructURLAccordingToSourceType(this.navigateBackUrl, this.openedFromWebView),
          );
        } else {
          return null;
        }
      },
    );
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
