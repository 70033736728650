import { IImage } from './common';
export interface User {
  roles?: string[];
  refresh_token: string;
  access_token: string;
}

export interface UserLogin {
  email: string;
  password: string;
}

export interface CurrentUserShort {
  city: string;
  disabled: boolean;
  disabledMessenger: boolean;
  email: string;
  featured: boolean;
  followedByYou: boolean;
  hasSeenBookingTutorial: boolean;
  hasStripeAccount: boolean;
  id: number;
  jammboardEnabled: boolean;
  name: string;
  profileImage: IImage;
  profileType: string;
  userId: number;
  username: string;
  verified: boolean;
}
