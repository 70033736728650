<div class="modal-header-card__container flex align-center">
  <img
    class="modal-header-card__close"
    src="../../../assets/images/icons/close-black.png"
    alt="Close icon"
    (click)="onCloseClicked()"
  />
  <div class="modal-header-card__title">
    {{ title }}
  </div>
</div>
