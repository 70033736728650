import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { PaymentService, ToastService } from '../../../services';

import { PaymentCard, PaymentCardView } from '../../../models/booking';

import { NavStateChange } from '../settings-content/settings-content.component';
import { PaymentMethodsModalAddCardComponent } from '../payment-methods-modal-add-card/payment-methods-modal-add-card.component';
import { PAYMENT_TYPES, SECURITY_CODE_TYPES } from '../../../utils';
import { IPAD_WIDTH_START } from '../../molecules/navigation/navigation.component';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {
  paymentMethodTabGroupVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  paymentDetails$: BehaviorSubject<PaymentCardView> = new BehaviorSubject(null);
  paymentCards$: BehaviorSubject<PaymentCard[]> = new BehaviorSubject(null);

  addPaymentMethodLabel: string;
  deleteLabel: string;
  processingConfirmation: boolean;
  setAsDefaultLabel: string;

  initiallyLanded: boolean = true;
  isDefaultSet: boolean = false;
  paymentCards: PaymentCard[];
  selectedCardId: string;
  selectedCard: PaymentCard;

  modalInstance: NgbModalRef;

  subscription$ = new Subscription();

  @Output() stateChange: EventEmitter<NavStateChange> = new EventEmitter();
  @Input() stateChange$: BehaviorSubject<NavStateChange>;

  @HostListener('window:resize', ['$event'])
  getWindowWidth(event?) {
    return window.innerWidth;
  }

  constructor(
    private paymentService: PaymentService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private deviceService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    this.setTranslatedText();
    this.getCards();
  }

  public getCards(): void {
    this.processingConfirmation = true;
    this.paymentService.getPaymentCards().subscribe(
      (response) => {
        if (response.status === 200) {
          this.setData(response.body.cards);
          this.processingConfirmation = false;
        }
      },
      (error) => {
        this.showMessage('Something went wrong.');
        this.processingConfirmation = false;
      },
    );
  }

  public showPaymentDetails(card: PaymentCard): void {
    if (!!card) {
      this.paymentDetails$.next(this.formatCardDetails(card));
      this.selectedCardId = card?.cardId;
      this.selectedCard = card;
      if (this.deviceService.isMobile()) {
        this.scrollToBottom();
      }
      this.initiallyLanded = false;
    } else {
      this.selectedCardId = null;
      this.selectedCard = null;
      this.paymentDetails$.next(null);
    }
  }

  public formatCardDetails(card: PaymentCard): PaymentCardView {
    return <PaymentCardView>{
      ...card,
      expYear: parseInt(card.expYear.toString().substr(2, 2)),
      securityCodeLabel:
        card.cardName === PAYMENT_TYPES.AMERICAN_EXPRESS ? SECURITY_CODE_TYPES.CSC : SECURITY_CODE_TYPES.CVC,
      securityCodeLength: card.cardName === PAYMENT_TYPES.AMERICAN_EXPRESS ? 4 : 3,
    };
  }

  public isDefaultCardSet(cards: PaymentCard[]): boolean {
    return cards.some((card) => card.default);
  }

  public setData(cards: PaymentCard[]): void {
    this.paymentCards$.next(cards);
    this.paymentCards = cards;
    this.isDefaultSet = this.isDefaultCardSet(cards);

    if (this.getWindowWidth() >= IPAD_WIDTH_START) {
      cards.length && this.isDefaultSet
        ? this.showPaymentDetails(cards.find((card) => card.default))
        : this.showPaymentDetails(cards[0]);
    }
  }

  public setCardAsDefault(): void {
    this.processingConfirmation = true;
    this.paymentService.setCardAsDefault(this.selectedCardId).subscribe(
      (res) => {
        if (res.status === 200) {
          this.getCards();
        }
      },
      (error) => {
        this.showMessage('Something went wrong.');
        this.processingConfirmation = false;
      },
    );
  }

  public deleteCard(): void {
    this.processingConfirmation = true;
    this.paymentService.deletePaymentCard(this.selectedCardId).subscribe(
      (res) => {
        if (res.status === 200) {
          this.getCards();
        }
      },
      (error) => {
        this.showMessage('Something went wrong.');
        this.processingConfirmation = false;
      },
    );
  }

  public addPaymentMethod(): void {
    this.openModal();
  }

  public openModal(): void {
    this.modalInstance = this.modalService.open(PaymentMethodsModalAddCardComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    this.modalInstance.result.then((res) => {
      if (res === 'paymentMethodConfirmed') {
        this.getCards();
      }
    });
  }

  private setTranslatedText(): void {
    this.translate.get('PAYMENT_AND_PAYOUT').subscribe((res) => {
      this.deleteLabel = res.DELETE;
      this.setAsDefaultLabel = res.SET_AS_DEFAULT;
      this.addPaymentMethodLabel = res.ADD_PAYMENT_METHOD;
    });
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }

  private scrollToBottom(): void {
    try {
      setTimeout(() => {
        const scrollAmount = document.body.scrollHeight;
        window.scrollTo({ behavior: 'smooth', top: scrollAmount });
      }, 0);
    } catch (err) {}
  }
}
