import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
  @Input() changeNotificationChecked: boolean;

  @Output() changeNotificationValue = new EventEmitter<any>();

  public toggleValue: boolean;

  constructor() {}

  ngOnInit(): void {
    this.toggleValue = this.changeNotificationChecked;
  }

  emitToggleValue(): void {
    this.toggleValue = !this.toggleValue;
    this.changeNotificationValue.emit(this.toggleValue);
  }
}
