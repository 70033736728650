import { IRequestSentModalData, RequestSentModalType } from '../request-sent-modal/request-sent.config';

export const REQUEST_SENT_VERIFICATION_MODAL: IRequestSentModalData = {
  emailInboxDescription: 'Welcome to Jammcard! In order to get started...',
  emailInboxSubtitle: 'Registration Confirmation',
  requestSentBackgroundUrl: '',
  requestSentButtonAction: '',
  requestSentButtonLabel: 'Back to Log in',
  requestSentDescription: '',
  requestSentTitle: 'Almost done!',
  type: RequestSentModalType.EMAIL,
  backButtonLabel: 'Resend Verification Email',
};
