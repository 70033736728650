<ng-container *ngIf="contentList$ | async">
  <section class="user-page-content__navigation" [ngClass]="{ 'hide-show': hideNavTabs$ | async }">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeId">
      <li *ngFor="let content of contentList$ | async; let i = index" [ngbNavItem]="routeList[i]">
        <a (click)="openTab(routeList[i])" ngbNavLink routerLink="." [queryParams]="{ tab: routeList[i] }">
          {{ links.get(routeList[i]) | titlecase | translate }}
        </a>
        <ng-template ngbNavContent>
          <div [ngTemplateOutlet]="content.template"></div>
        </ng-template>
      </li>
    </ul>
  </section>
  <section
    class="user-page-content__content"
    [ngClass]="{
      'hide-show': hideNavContent$ | async
    }"
  >
    <div [ngbNavOutlet]="nav"></div>
    <router-outlet></router-outlet>
  </section>
</ng-container>
