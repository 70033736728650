import { Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ROUTE_TYPE_VALIDATOR, USER } from './constants';
import { BasicProfile } from '../models';
import { IAvatarGroupData } from '../components/molecules/avatar-group/avatar-group.component';
import { BookModalData } from '../components/organisms/book-modal/book-modal.component';
export interface GalleryImage {
  small: string;
  medium: string;
  big: string;
}

export function replaceTags(text, arr, fallback): any {
  const arrayOfUserTagged = arr;
  let counter = 0;
  const tagMarker = '<@#%>';
  const link = '/';

  if (arrayOfUserTagged) {
    const stringArr = text.split(' ');
    const resultArr = stringArr.map((item) => {
      if (item.includes(tagMarker)) {
        const curr = counter++;

        let prefixValue = '';
        let suffixValue = '';

        if (typeof item === 'string' && item.length > 5) {
          const indexOfTagMarker = item.indexOf(tagMarker);

          prefixValue = indexOfTagMarker > 0 ? item.slice(0, indexOfTagMarker - 1) : '';
          suffixValue = item.slice(indexOfTagMarker + tagMarker.length);
        }

        const href = link + (arrayOfUserTagged[curr].username || arrayOfUserTagged[curr].id);
        const name = arrayOfUserTagged[curr].name;
        const disabled = arrayOfUserTagged[curr].disabled;

        if (name !== null && disabled !== true) {
          return prefixValue + '<a href="' + href + '" target="_blank" rel="noreferrer">' + name + '</a>' + suffixValue;
        } else {
          return name + '';
        }
      } else {
        return item;
      }
    });
    return resultArr.join(' ');
  }
}

export function isCardNumberEven(sectionName): any {
  return this.userProfile[sectionName].length % 2 === 0;
}

export function toggleValue(value: boolean): boolean {
  return !value;
}

export function checkIfTheSameString(firstVal: string, secondVal: string): boolean {
  return firstVal === secondVal;
}

export function isQuerySearchable(queryString): boolean {
  return queryString ? queryString.length > 2 : false;
}

export async function reloadPage(router: Router, url: string = ''): Promise<boolean> {
  const targetUrl = router.url + url;
  await router.navigateByUrl('/mentors', { skipLocationChange: true });
  return router.navigateByUrl(targetUrl);
}

export function userHasRequiredRole(route: ActivatedRouteSnapshot, localStorageUser: any): boolean {
  if (localStorageUser?.roles && route?.data?.roles) {
    return localStorageUser.roles.some((role: string) => route.data.roles.includes(role));
  }

  return false;
}

export function toTitleCase(str) {
  return str ? str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()) : '';
}

export const filterObjectByValue = (obj: object, filter: string, filterValue: any) =>
  Object.keys(obj).reduce(
    (acc, val) =>
      obj[val][filter] === filterValue
        ? acc
        : {
            ...acc,
            [val]: obj[val],
          },
    {},
  );
export function formatSingleGalleryImage(imageUrl: string): GalleryImage[] {
  return [{ small: imageUrl, medium: imageUrl, big: imageUrl }];
}

export const createAvatarGroupData = (user: Partial<BasicProfile>): IAvatarGroupData => ({
  id: user?.profileId,
  username: user?.username,
  proCity: user?.city,
  proName: user?.name,
  proVerified: user?.verified ?? false,
  profileImage: user?.profileImage?.url ?? '',
});

export const createBookModalData = (user: Partial<BasicProfile>): BookModalData => ({
  isBookRoute: false,
  loggedUserName: null,
  modalImage: user?.coverImage?.url ?? '',
  proCity: user?.city ?? '',
  proId: user?.profileId,
  proName: user?.name,
  proVerified: user?.verified ?? false,
  profileImage: user?.profileImage?.url ?? '',
  ratesAndServices: null,
});

export const getNoBookProfileUrl = (url: string) => url.slice(0, -'/book'.length);

export const getIframeSrc = (videoId: string, service: string) =>
  service === 'YOUTUBE'
    ? `https://www.youtube.com/embed/${videoId}`
    : `https://w.soundcloud.com/player/?url=${videoId}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true`;

export const isRouteStringOrNumber = (param: any) =>
  isNaN(param) ? 'string' : ROUTE_TYPE_VALIDATOR.test(param) ? 'string' : 'number';

//NOTE(Mladen): Converts dateInt to 'hours:minutes' string representation
//              Example: 1624978800000 ==> '5:00 PM'
export const formatDateIntToHours = (date: number): string =>
  new Date(date).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: '2-digit' });

export const scrollToBottom = (container: Element): void => {
  try {
    setTimeout(() => {
      if (container) {
        const scrollAmount = container.scrollHeight;
        container.scrollTo({ behavior: 'smooth', top: scrollAmount });
      }
    }, 0);
  } catch (err) {}
};

export const constructURLAccordingToSourceType = (
  navigateBackUrl: string,
  isWebViewOpen: boolean,
  isCompleted: boolean = false,
): string => {
  const queryString = isCompleted ? 'closeWebView=true&isCompletedSuccessfully=true' : 'closeWebView=true';
  return isWebViewOpen
    ? navigateBackUrl.includes('?')
      ? `${navigateBackUrl}&${queryString}`
      : `${navigateBackUrl}?${queryString}`
    : navigateBackUrl;
};

export const isEmptyValue = (value: any): boolean =>
  value === undefined ||
  value === null ||
  Number.isNaN(value) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
