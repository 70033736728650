import { Injectable, TemplateRef } from '@angular/core';

import { delayTime } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}): void {
    this.toasts.push({
      textOrTpl,
      ...options,
      classname: `base ${options?.classname} ${options?.autohide ? 'autohide' : ''}`,
    });
  }

  remove(toast): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  // TODO (Milan): 'black' classname left for existing code calling the showMessage.
  // After updating the style of toast messages all existing code using it should be updated
  // throughout the app
  showMessage(message: string | TemplateRef<any>, classname = 'notification', autohide = true): void {
    this.show(message, {
      delay: delayTime,
      autohide,
      classname: `base ${classname} ${autohide ? 'autohide' : ''}`,
    });
  }
}
