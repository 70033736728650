import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  public add(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public get(key: string): any {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch {
      return sessionStorage.getItem(key);
    }
  }

  public delete(key: string): void {
    sessionStorage.removeItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}
