<div class="location-filter__container search__accordion-override-style">
  <ng-container *ngIf="expandable; else nonExpandable">
    <ngb-accordion [destroyOnHide]="false" #acc="ngbAccordion">
      <ngb-panel id="skillsPanel">
        <ng-template ngbPanelTitle>
          <h6>{{ 'SEARCH.LOCATION' | translate }}</h6>
          <div class="accordion__show-more">
            <app-active-filters *ngIf="showActiveLabel" [count]="selectedLocation?.name"></app-active-filters>
            <img *ngIf="!acc.isExpanded('skillsPanel')" src="assets/images/icons/faq-down.svg" alt="Arrow Down" />
            <img *ngIf="acc.isExpanded('skillsPanel')" src="assets/images/icons/faq-up.svg" alt="Arrow Up" />
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngTemplateOutlet="locationFilterContent"></ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>
  <ng-template #nonExpandable>
    <ng-container *ngTemplateOutlet="locationFilterContent"></ng-container>
  </ng-template>
</div>
<ng-template #locationFilterContent>
  <div class="card-body-content">
    <div class="main-search__input-wrapper">
      <img src="assets/images/icons/search-black.svg" />
      <input #locationSearchField placeholder="Search" (input)="searchLocation()" />
    </div>
    <div class="location-filter__selected-location flex">
      <div *ngIf="selectedLocation?.name" class="filter-section__pill">
        <!-- TODO (Milan): Not sure yet if the close button will stay or not  -->
        <button class="reset-button-styles" type="button">
          <img src="assets/images/icons/close-black.svg" (click)="setSelectedLocation()" />
        </button>
        <span>{{ selectedLocation.name }}</span>
      </div>
    </div>
    <div class="location-filter__suggestions-wrapper">
      <!-- NOTE: Making a loader container so the loader itself can be put in a reusable component in the future -->
      <div *ngIf="loadingInProgress" class="location-filter__loader-container">
        <div class="loader-dots">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
      <div class="location-filter__suggestions-content">
        <button
          *ngIf="!loadingInProgress"
          class="location-filter__suggestion reset-button-styles"
          type="button"
          (click)="getCurrentLocation()"
        >
          <img src="assets/images/icons/current-location.svg" />
          <span>{{ 'SEARCH.CURRENT_LOCATION' | translate }}</span>
        </button>
        <div *ngIf="showInitialSuggestions && !loadingInProgress">
          <button
            *ngFor="let suggestion of initialLocations"
            class="location-filter__suggestion reset-button-styles"
            type="button"
            (click)="setSelectedLocation(suggestion)"
          >
            <img src="assets/images/icons/location-arrow.svg" />
            <span>{{ suggestion.name }}</span>
          </button>
        </div>
        <div *ngIf="!showInitialSuggestions && !loadingInProgress">
          <ng-container *ngIf="googleLocationPredictions?.length; else noResults">
            <button
              *ngFor="let prediction of googleLocationPredictions"
              class="location-filter__suggestion reset-button-styles"
              type="button"
              (click)="googleSuggestionClick(prediction)"
            >
              <img src="assets/images/icons/location-arrow.svg" />
              <span>{{ prediction.description }}</span>
            </button>
          </ng-container>
          <ng-template #noResults>
            <div class="location-filter__no-results">{{ 'SEARCH.NO_RESULTS' | translate }}</div>
          </ng-template>
        </div>
      </div>
      <!-- NOTE: This is a Google requirement when not displaying a Google Map along with the Autocomplete functionality
           Specific styles are also required -->
      <div class="location-filter__provided-by-google">
        <span>powered by</span>
        <img src="assets/images/logos/google-logo.png" />
      </div>
    </div>
    <hr />
  </div>
</ng-template>
