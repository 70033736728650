import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { BookingInfo } from '../components/organisms/book-modal/book-modal.component';

@Injectable()
export class BookingEntityService extends EntityCollectionServiceBase<BookingInfo> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Booking', serviceElementsFactory);
  }
}
