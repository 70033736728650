import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Calendar {
  width?: string;
  text: string;
  value: string;
  active: true;
}

@Component({
  selector: 'app-calendar-select',
  templateUrl: './calendar-select.component.html',
  styleUrls: ['./calendar-select.component.scss'],
})
export class CalendarSelectComponent implements OnInit {
  @Input() showCollapse: boolean = false;
  @Output() emitCalendar = new EventEmitter<string>();

  public isCollapsed: boolean = false;
  public calendarItems: Calendar[] = [
    { width: '22.5px', text: 'BOOK.CALENDAR_GOOGLE', value: 'GOOGLE', active: true },
    { width: '23px', text: 'BOOK.CALENDAR_APPLE', value: 'APPLE', active: true },
    { width: '22px', text: 'BOOK.CALENDAR_YAHOO', value: 'YAHOO', active: true },
    { width: '21px', text: 'BOOK.CALENDAR_OFFICE_365', value: 'OFFICE_365', active: true },
    { width: '22px', text: 'BOOK.CALENDAR_OUTLOOK', value: 'OUTLOOK', active: true },
  ];
  public calendarData: Calendar = {
    value: '',
    text: 'Add to my calendar',
    active: true,
  };
  public isToggled: boolean;

  constructor() {}

  ngOnInit(): void {}

  public checkDropdown(toggle: boolean): void {
    this.isToggled = toggle;
  }

  public onCalendar(calendar: Calendar): void {
    this.calendarData.active = true;
    this.calendarData.text = calendar.text;
    this.emitCalendar.emit(calendar.value);
    this.calendarData.value = `-${calendar.value}`;
  }
}
