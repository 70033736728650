import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CalendarSelectComponent } from './calendar-select.component';

@NgModule({
  declarations: [CalendarSelectComponent],
  exports: [CalendarSelectComponent],
  imports: [CommonModule, NgbCollapseModule, NgbDropdownModule, TranslateModule],
})
export class CalendarSelectModule {}
