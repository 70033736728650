<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="rates-accordion flex align-center">
        <!-- TODO (Milan) finish up this component when get feedback on how to proceed -->
        <div class="rates-accordion__header">Not sure what to offer? See Pro Rates</div>
        <button id="toggleRatesAccordion" ngbPanelToggle class="rates-accordion__collapse">
          <img ngbPanelToggle src="../../../assets/images/icons/close-gold.svg" alt="Close icon" />
        </button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
      officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
      moon tempor,
    </ng-template>
  </ngb-panel>
</ngb-accordion>
