import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SERVICE_URL } from '../utils';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private http: HttpClient) {}

  // The logic for adding of new cards was changed on BE and we adjusted our logic on FE. To not change the contract from the previous
  // implemetnation the request param is still called cardToken but we are actually sending a paymentMethodId
  public addPaymentCard(cardToken: string): Observable<any> {
    return this.http.post(`${SERVICE_URL.BOOKING}api/payment/cards`, { cardToken }, { observe: 'response' });
  }

  public getPaymentCards(): Observable<any> {
    return this.http.get(`${SERVICE_URL.BOOKING}api/payment/cards`, { observe: 'response' });
  }

  // The return value is intentionally left without { observe: 'response' } param
  public checkStripeAccount(): Observable<any> {
    return this.http.get(`${SERVICE_URL.BOOKING}api/payment/account_record`);
  }

  public optinSeller(authorizationCode: string): Observable<any> {
    return this.http.post(
      `${SERVICE_URL.BOOKING}api/payment/optin-seller`,
      { authorizationCode },
      { observe: 'response' },
    );
  }

  public deletePaymentCard(cardId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
      body: {
        cardId,
      },
    };
    return this.http.delete(`${SERVICE_URL.BOOKING}api/payment/cards`, options);
  }

  public setCardAsDefault(cardToken: string): Observable<any> {
    return this.http.patch(
      `${SERVICE_URL.BOOKING}api/payment/default-payment-method`,
      { cardToken },
      { observe: 'response' },
    );
  }
}
