import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatEducationOngoing',
})
export class FormatEducationOngoingPipe implements PipeTransform {
  transform(value: any, ongoing: boolean, finishYear: string | number): string | number {
    return ongoing ? 'Present' : finishYear;
  }
}
