import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { EditProfileModalBasicComponent } from './edit-profile-modal-basic.component';
import { EditProfileModalBasicEntryComponent } from './edit-profile-modal-basic-entry.component';
import { InfoModalModule } from '../../templates/info-modal/info-modal.module';
import { ImageUploadCardModule } from '../../molecules/image-upload-card/image-upload-card.module';
import { EditModalModule } from '../../templates/edit-modal/edit-modal.module';
import { ModalHeaderCardModule } from '../../molecules/modal-header-card/modal-header-card.module';
import { EditProfileFormModule } from '../edit-profile-form/edit-profile-form.module';

@NgModule({
  declarations: [EditProfileModalBasicComponent, EditProfileModalBasicEntryComponent],
  imports: [
    CommonModule,
    ImageUploadCardModule,
    InfoModalModule,
    EditModalModule,
    EditProfileFormModule,
    ModalHeaderCardModule,
    TranslateModule,
  ],
  exports: [EditProfileModalBasicComponent, EditProfileModalBasicEntryComponent],
})
export class EditProfileModalBasicModule {}
