<header class="auth-modal__header">
  <p *ngIf="signupModalData?.normalModal" class="auth-modal__heading">
    {{ 'SIGNUP.SIGNUP_TO_JAMMCARD' | translate }}
  </p>
  <p *ngIf="!signupModalData?.normalModal" class="auth-modal__heading">
    {{ 'SIGNUP.SIGNUP_TO_BOOK' | translate }} {{ signupModalData?.proName }}!
  </p>
</header>
<form class="auth-modal__form flex column" [formGroup]="signupForm" (ngSubmit)="submitForm(signupForm)">
  <!--      Prevent Browser from setting initial values in input elements of the form -->
  <div style="display: none">
    <input type="text" readonly tabindex="-1" />
    <input type="password" readonly tabindex="-1" />
  </div>
  <section>
    <div class="auth-modal__text-section">{{ 'SIGNUP.JOIN_JAMMCARD' | translate }}</div>
    <div class="auth-modal__text-section">
      {{ 'SIGNUP.ALREADY_HAVE_ACCOUNT' | translate }}
      <span class="marked" (click)="openLoginModal()">{{ 'SIGNUP.LOG_IN' | translate }}</span>
    </div>
    <div class="auth-modal__install-info-content">
      <div class="auth-modal__input-section">
        <div class="auth-modal__name">
          <input
            id="signupModalInputName"
            class="auth-modal__input form-control"
            type="text"
            name="fullName"
            formControlName="fullName"
            required
          />
          <span class="floating-label">{{ 'SIGNUP.FULL_NAME' | translate }}</span>
        </div>

        <div *ngIf="fullName.invalid && (fullName.dirty || !fullName.pristine)">
          <p *ngIf="fullName.errors.required" class="help-block">{{ 'SIGNUP.NAME_REQUIRED' | translate }}</p>
          <p *ngIf="fullName.errors.fullName" class="help-block">
            {{ 'SIGNUP.PLEASE_ENTER_VALID_FULL_NAME' | translate }}
          </p>
        </div>

        <div class="auth-modal__email">
          <input
            id="signupModalInputEmail"
            class="auth-modal__input form-control"
            [ngClass]="{ 'link-sent': linkSent }"
            type="text"
            name="email"
            formControlName="email"
            required
            autocomplete="off"
          />
          <span class="floating-label">{{ 'SIGNUP.EMAIL_ADDRESS' | translate }}</span>
        </div>

        <div *ngIf="email.invalid && (email.dirty || !email.pristine)">
          <p *ngIf="email.errors.required" class="help-block">{{ 'SIGNUP.EMAIL_REQUIRED' | translate }}</p>
          <p *ngIf="email.errors.email" class="help-block">{{ 'SIGNUP.PLEASE_ENTER_VALID_EMAIL' | translate }}</p>
          <p *ngIf="email.errors.notUnique" class="help-block">
            {{ 'SIGNUP.ACCOUNT_WITH_THIS_EMAIL_EXISTS' | translate }}
          </p>
        </div>

        <div class="auth-modal__email">
          <input
            id="signupModalInputPassword"
            class="auth-modal__input form-control"
            [ngClass]="{ 'link-sent': linkSent }"
            type="password"
            name="password"
            formControlName="password"
            [type]="passwordDisplayed ? 'text' : 'password'"
            required
            autocomplete="new-password"
          />
          <span class="floating-label">{{ 'SIGNUP.PASSWORD' | translate }}</span>
          <img
            *ngIf="!passwordDisplayed"
            class="eye-closed"
            src="../../../assets/images/icons/eye-closed.svg"
            alt="Closed eye"
            (click)="toggleDisplayPassword()"
          />
          <img
            *ngIf="passwordDisplayed"
            class="eye-opened"
            src="../../../assets/images/icons/eye-open.svg"
            alt="Opened eye"
            (click)="toggleDisplayPassword()"
          />
        </div>

        <div *ngIf="password.invalid && (password.dirty || !password.pristine)">
          <p *ngIf="password.errors.required" class="help-block">{{ 'SIGNUP.PASSWORD_REQUIRED' | translate }}</p>
          <p *ngIf="password.errors.minlength" class="help-block">
            {{ 'SIGNUP.PASSWORD_MUST_BE_LONGER' | translate }}
          </p>
        </div>

        <div class="auth-modal__send-btn-container">
          <app-button
            id="signupModalButton"
            [ngClass]="{ 'link-sent': linkSent }"
            [buttonDisabled]="signupRequestSending"
            [buttonLabel]="buttonLabel"
            [buttonType]="'submit'"
          >
          </app-button>
        </div>

        <div class="auth-modal__disclaimer">
          {{ 'SIGNUP.BY_CLICKING_SIGNUP_YOU_AGREE_TO' | translate }}
          <a
            href="https://site.jammcard.com/jammcard-terms-of-service/"
            target="_blank"
            rel="noreferrer"
            class="auth-modal__disclaimer--link"
            >{{ "SIGNUP.JAMMCARD'S_TERMS&CONDITIONS" | translate }}</a
          >
          {{ 'SIGNUP.AND' | translate }}
          <a
            href="https://site.jammcard.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            class="auth-modal__disclaimer--link"
            >{{ 'SIGNUP.PRIVACY_POLICY' | translate }}</a
          >.
        </div>
      </div>
    </div>
  </section>
</form>
