import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.scss'],
})
export class NotificationBadgeComponent implements OnInit {
  @Input() notificationNumber: number;

  constructor() {}

  ngOnInit(): void {}
}
