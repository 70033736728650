<div class="cookie-consent__container" [ngClass]="{ disappear: disappear }">
  <div class="cookie-consent__copy">
    <h3 class="cookie-consent__title">Jammcard uses cookies.</h3>
    <p class="cookie-consent__text">We use cookies to make the Jammcard experience better for you.</p>
  </div>
  <div class="flex align-center">
    <button class="cookie-consent__button" (click)="onAcceptClick()">OK, got it</button>
    <!-- <app-button [roundedInverted]="true" [rounded]="true" buttonLabel="Decline" (clicked)="onDeclineClick()">
    </app-button> -->
  </div>
</div>
