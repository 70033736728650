<app-jammcard-modal (closed)="closeModal($event)" [modalImage]="bookModalData?.modalImage">
  <div class="auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <header class="book-modal__header book-modal__section">
      <p class="auth-modal__heading">Contact support</p>
    </header>
    <form
      class="book-modal-contact-support__form auth-modal__form flex column justify-content-between"
      #bookForm="ngForm"
      (ngSubmit)="submitForm()"
    >
      <div>
        <app-book-modal-section *ngIf="showBookingIdSection" [sectionTitle]="'Booking ID number'">
          <div *ngIf="bookingId; else elseBlock">
            <div class="book-modal-contact-support__booking-id">{{ bookingId }}</div>
          </div>
          <ng-template #elseBlock>
            <input
              class="book-modal-contact-support__input auth-modal__input form-control uppercase"
              type="text"
              placeholder="Enter Booking ID #"
              name="bookingIdInput"
              #bookingIdRef="ngModel"
              [(ngModel)]="bookingIdInput"
              required
            />
            <p *ngIf="bookingIdRef.invalid && (bookingIdRef.dirty || !bookingIdRef.pristine)" class="help-block">
              This field is required
            </p>
          </ng-template>
        </app-book-modal-section>

        <hr *ngIf="bookingId" />

        <br />

        <app-book-modal-section [sectionTitle]="'Email Address'">
          <input
            class="book-modal-contact-support__input auth-modal__input form-control"
            type="email"
            placeholder="Enter your email address"
            name="emailInput"
            #emailInputRef="ngModel"
            [(ngModel)]="emailInput"
            required
            email
          />
          <div *ngIf="emailInputRef.invalid && (emailInputRef.dirty || !emailInputRef.pristine)">
            <p *ngIf="emailInput.length === 0" class="help-block">This field is required</p>
            <p *ngIf="emailInput.length > 0" class="help-block">Please enter a valid email</p>
          </div>
        </app-book-modal-section>

        <br />

        <app-book-modal-section [sectionTitle]="'message'">
          <textarea
            class="book-modal-contact-support__input book-modal__input-text auth-modal__input"
            type="text"
            name="messageInput"
            [placeholder]="'How can we help you?'"
            autosize
            [rows]="1"
            #messageInputRef="ngModel"
            [(ngModel)]="messageInput"
            required
          ></textarea>
          <p *ngIf="messageInputRef.invalid && (messageInputRef.dirty || !messageInputRef.pristine)" class="help-block">
            This field is required
          </p>
        </app-book-modal-section>
      </div>
      <div class="book-modal__send-btn-container">
        <app-button
          [buttonDisabled]="!bookForm.valid || processingSending"
          [buttonLabel]="'SEND MESSAGE'"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
    </form>
  </div>
</app-jammcard-modal>
