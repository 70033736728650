import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthModalData } from '../../../models';
import { IRequestSentModalData, RequestSentModalType, RequestSentModalButtonType } from './request-sent.config';
import { AuthenticationService, ToastService } from '../../../services';
import { getNoBookProfileUrl, AUTH_CONTROLLER, reloadPage } from '../../../utils';
import { AuthControllerModalComponent } from '../../templates/auth-controller-modal/auth-controller-modal.component';

@Component({
  selector: 'app-request-sent-modal',
  templateUrl: './request-sent-modal.component.html',
  styleUrls: ['../booking-sent-modal/booking-sent-modal.component.scss', './request-sent-modal.component.scss'],
})
export class RequestSentModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public loginModalData: AuthModalData;
  public requestSentModalData: IRequestSentModalData;
  public type: RequestSentModalType = RequestSentModalType.EMAIL;
  public backButtonType: RequestSentModalButtonType = RequestSentModalButtonType.LOGIN;

  public book: boolean;
  public proId: string;
  public proProfileId: number;
  public proProfileUsername: string;
  public signupEmail: string;
  public webApp: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private authService: AuthenticationService,
    public route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.loginModalData = {
      normalModal: true,
    };
  }

  ngOnInit(): void {
    this.webApp = true;

    // @ts-ignore
    this.requestSentModalData = this.fromParent.requestSentModalData;
    this.proProfileId = this.requestSentModalData.proProfileId;
    this.proProfileUsername = this.requestSentModalData.proProfileUsername;
    this.signupEmail = this.requestSentModalData.email;
    this.book = this.requestSentModalData.book;
    this.type = this.requestSentModalData.type ?? RequestSentModalType.EMAIL;
    this.backButtonType = this.requestSentModalData.backButtonType ?? RequestSentModalButtonType.LOGIN;
  }

  closeModal(event): any {
    const url = this.router.url;
    if (url.includes('/book') && !url.includes('/book-mentorship')) this.router.navigate([getNoBookProfileUrl(url)]);
    if (url.includes('/featured') || url.includes('/book-mentorship')) reloadPage(this.router);
    this.activeModal.close();
  }

  public openLoginModal(event): void {
    this.activeModal.close();
    const modalInstance = this.modalService.open(AuthControllerModalComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });
    const loginModalData = this.loginModalData;
    modalInstance.componentInstance.fromParent = {
      modalType: AUTH_CONTROLLER.LOGIN,
      authControllerModalData: loginModalData,
    };
  }

  public resendVerificationEmail(): void {
    const resendVerificationEmailBody = {
      email: this.signupEmail,
      web: true,
      book: this.book,
      proProfileId: this.proProfileId,
      proProfileUsername: this.proProfileUsername,
    };
    this.authService.resendVerificationEmail(resendVerificationEmailBody).subscribe((res: any) => {
      this.toastService.showMessage('Email verification resent successfully.');
    });
  }
}
