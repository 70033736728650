import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPaymentCardFormComponent } from './add-payment-card-form.component';

@NgModule({
  declarations: [AddPaymentCardFormComponent],
  imports: [CommonModule],
  exports: [AddPaymentCardFormComponent],
})
export class AddPaymentCardFormModule {}
