import { CurrentUserShort } from './user';
import { SkillFeatured } from './rates-and-services';

export enum GigStatus {
  ACTIVE = 'ACTIVE',
  FILLED = 'FILLED',
  DELETED = 'DELETED',
}

export interface ICityWithCoordinates {
  city?: string;
  lat: number;
  lon: number;
}

export interface IGoogleLocation {
  name: string;
  latitude: number;
  longitude: number;
}

export interface IGigCreateDTO {
  title: string;
  description: string;
  skillIds: number[];
  price?: string;
  cityWithCoordinatesDTO?: ICityWithCoordinates;
  date?: number;
}

export interface IGigMain {
  id: number;
  createdAt: number;
  date: number;
  title: string;
  description: string;
  featured: boolean;
  price: string;
  shareHash: string;
  shareUrl: string;
  skills: SkillFeatured[];
  status: GigStatus;
  postedBy?: CurrentUserShort;
  cityWithCoordinatesDTO: ICityWithCoordinates;
  jammboardActivatedByShare?: boolean;
}
