export interface NotificationsList {
  message: boolean;
  following: boolean;
  upvoted: boolean;
  commented: boolean;
  tagged: boolean;
  custom: boolean;
  invites: boolean;
}

export interface NotificationSettings {
  notificationType: string;
  settings: NotificationGroupSettings[];
}

export interface NotificationGroupSettings {
  title: string;
  description: string;
  groups: NotificationSettingsGroup[];
  items: NotificationSettingsItems[];
}

export interface NotificationSettingsGroup {
  title: string;
  description: string;
  items: NotificationSettingsItems[];
}

export interface NotificationSettingsItems {
  id: number;
  title: string;
  active: boolean;
}
