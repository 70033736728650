import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExperienceComponent } from './experience.component';
import { ExperienceCardModule } from '../../molecules/experience-card/experience-card.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ExperienceComponent],
  imports: [CommonModule, ExperienceCardModule, TranslateModule],
  exports: [ExperienceComponent],
})
export class ExperienceModule {}
