import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerInputComponent } from './messenger-input.component';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MessengerInputComponent],
  imports: [CommonModule, AutosizeModule, FormsModule],
  exports: [MessengerInputComponent],
})
export class MessengerInputModule {}
