<section class="truncated-media" *ngIf="media?.medias?.length">
  <div class="truncated-media__highlight" (click)="openModal(mediaHighlight)">
    <iframe
      style="pointer-events: none"
      width="100%"
      height="300"
      [src]="mediaHighlight | safe: 'resourceUrl'"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
  <div class="truncated-media__others">
    <div
      *ngFor="let media of mediaOthers; let i = index"
      (click)="openModal(media)"
      class="truncated-media__others-media"
    >
      <iframe
        style="pointer-events: none"
        width="100%"
        height="100"
        [src]="media | safe: 'resourceUrl'"
        frameborder="0"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
  <div class="swiper-container" [swiper]="swiperConfig">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let media of mediaAll; let i = index" (click)="openModal(media.url)">
        <iframe
          style="pointer-events: none"
          width="100%"
          height="185px"
          [src]="media.url | safe: 'resourceUrl'"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</section>
