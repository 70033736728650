import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ASSETS_PATH } from '../../../utils';
import { BookingService, ToastService } from '../../../services';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';
import { REVIEW_MAX_LENGTH } from '../../../pages/video-call/video-call.config';

@Component({
  selector: 'app-mentorship-session-rating-modal',
  templateUrl: './mentorship-session-rating-modal.component.html',
  styleUrls: ['./mentorship-session-rating-modal.component.scss'],
})
export class MentorshipSessionRatingModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public fromParent: any;
  public reviewInput: string;
  public starNumber: number;
  public textareaPlaceholder: string;
  public readonly closeIcon = ASSETS_PATH.CLOSE_BLACK_ICON;
  public readonly jammcardMentorsLogo = ASSETS_PATH.LOGO_MENTORS;
  public readonly reviewMaxLength = REVIEW_MAX_LENGTH;

  private bookingId: number;
  private participantIsMentor: boolean;
  private redirectTo: string;
  private remoteParticipantUsername: string;
  private somethingWentWrongMessage: string;
  private successModalTitle: string;

  constructor(
    private activeModal: NgbActiveModal,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.bookingId = this.fromParent.bookingId;
    this.participantIsMentor = this.fromParent.participantIsMentor;
    this.remoteParticipantUsername = this.fromParent?.remoteParticipantUsername;
    this.redirectTo = this.fromParent?.redirectTo || '/mentors';

    this.translateService.get(['BOOK', 'TOAST_MESSAGE']).subscribe((res) => {
      this.textareaPlaceholder = this.participantIsMentor
        ? res.BOOK.PLEASE_LEAVE_REVIEW_ON_WORK_WITH_MENTEE
        : res.BOOK.PLEASE_LEAVE_REVIEW_ON_WORK_WITH_MENTOR;
      this.somethingWentWrongMessage = res.TOAST_MESSAGE.SOMETHING_WENT_WRONG;
      this.successModalTitle = res.BOOK.THANK_YOU_FOR_YOUR_FEEDBACK;
    });
  }

  public closeModal(redirect = false): void {
    this.activeModal.close();

    if (redirect) {
      this.router.navigateByUrl(this.redirectTo);
    }
  }

  public submitForm(): void {
    const bookingRatingResults = {
      bookingExperienceRating: this.starNumber,
      feedback: this.reviewInput,
      partnerRating: this.starNumber,
    };
    this.bookingService.rateBooking(this.bookingId, { ...bookingRatingResults }).subscribe(
      () => {
        this.closeModal();
        const modalInstance = this.modalService.open(InfoModalSuccessComponent, {
          windowClass: 'modal-window',
          backdrop: 'static',
        });

        // NOTE: Button labels passed here are the translation property names and not the actual labels that will be shown
        const modalData = this.participantIsMentor
          ? {
              title: this.successModalTitle,
              description: null,
              buttonLabel: 'GO_TO_MY_BOOKINGS',
              backgroundImageClass: 'success',
              redirectTo: '/bookings',
            }
          : {
              title: this.successModalTitle,
              description: null,
              buttonLabelsArr: ['BROWSE_MENTORS', 'BOOK_AGAIN'],
              backgroundImageClass: 'success',
              redirectTo: '/bookings',
              redirectToArr: ['/mentors', `/mentors/featured/${this.remoteParticipantUsername}`],
            };

        modalInstance.componentInstance.fromParent = {
          modalData,
        };
      },
      (err) => {
        this.toastService.showMessage(this.somethingWentWrongMessage);
      },
    );
  }
}
