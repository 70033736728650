import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SkillsCardComponent } from './skills-card.component';

@NgModule({
  declarations: [SkillsCardComponent],
  imports: [CommonModule],
  exports: [SkillsCardComponent],
})
export class SkillsCardModule {}
