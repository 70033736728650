import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/operators';

import { AuthenticationService, LocalStorageService } from '../services';
import { AuthActions } from '../store/auth/action-types';
import { REFRESH_TOKEN_INITIATED } from '../../src/utils';
import { User } from '../models';
import { USER } from './constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private localRefreshTokenRequestInProgress$ = new BehaviorSubject(false);
  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private user: User;

  constructor(
    private action$: Actions,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.action$.pipe(ofType(AuthActions.authUpdateSuccess)).subscribe(() => {
      /* Listen to User updated action and put new user into this.user */
      this.user = this.authenticationService.getAuthTokens();
    });

    window.addEventListener('storage', this.storageEventListener);
  }

  private storageEventListener = (e: StorageEvent) => {
    if (e.key === REFRESH_TOKEN_INITIATED) {
      if (e.newValue === 'false') {
        this.user = this.authenticationService.getAuthTokens();
        this.tokenRefreshedSource.next();
      }
    }

    /* NOTE(Mladen):
     StorageEvent.key === null is triggered only when .clear() method is called on localStorage.
     StorageEvent.key === USER is set upon logging in.
     The following logic reloads non-focused tabs on login and logout. 
     To discern login from updating of storage tokens we check for e.oldValue to be null.
    */
    if (!e.key || (e.key === USER && !e.oldValue)) {
      // NOTE: We don't want the reload to terminate pending refresh-token call, that's why a local flag is used
      // to follow the state of this EP call. reloadPage() function can not be used here because the router is not recognized
      this.localRefreshTokenRequestInProgress$
        .pipe(
          filter((inProgress) => !inProgress),
          take(1),
        )
        .subscribe(() => {
          window.location.reload();
        });
    }
  };

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.user = this.authenticationService.getAuthTokens();
    return next.handle(request).pipe(
      catchError((error) => {
        return this.handleError(error, request, next);
      }),
    );
  }

  handleError(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (error.status === 401 && error.error.error === 'tokenExpired' && this.user) {
      return this.refreshToken(request).pipe(
        switchMap(() => {
          const newRequest = this.addAuthHeader(request);
          return next.handle(newRequest);
        }),
      );
    } else if (
      error.status === 401 &&
      error.error.error === 'Full authentication is required to access this resource'
    ) {
      // TODO (Milan): Not sure why clearing of localStorage was put here, it makes problems for reloading of
      // multiple tabs at the same time
      // this.authenticationService.removeUserInfoFromStorage();
    } else if ([401, 403].indexOf(error.status) !== -1) {
      // TODO (Jovana): Add universal logic for handling BE error responses for all EPs
    }
    return throwError(error);
  }

  refreshToken(
    request: HttpRequest<any>,
    justContinue?: boolean,
    waitForNewToken?: boolean,
  ): Observable<HttpEvent<any>> {
    // Just retry the call with current tokens from local storage
    if (justContinue) {
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }

    if (this.localStorageService.get(REFRESH_TOKEN_INITIATED) || waitForNewToken) {
      // Refreshing in progress, don't make any refreshToken EP call until new token is gotten
      return new Observable((observer) => {
        this.tokenRefreshed$.pipe(take(1)).subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.localStorageService.add(REFRESH_TOKEN_INITIATED, true);
      if (this.user) {
        const refreshTokenPayload = {
          accessToken: this.user.access_token,
          refreshToken: this.user.refresh_token,
        };
        this.localRefreshTokenRequestInProgress$.next(true);
        return this.authenticationService
          .refreshToken(refreshTokenPayload.accessToken, refreshTokenPayload.refreshToken)
          .pipe(
            tap((newUser) => {
              this.store.dispatch(AuthActions.authUpdate({ user: newUser }));
              this.localStorageService.add(REFRESH_TOKEN_INITIATED, false);
              this.localRefreshTokenRequestInProgress$.next(false);
              this.tokenRefreshedSource.next();
            }),
            // NOTE: Even though a flag is set in localStorage when a refreshing of token is initiated by one tab,
            // when multiple tabs trigger the logic at the same moment, none sees the flag as 'true' since it is currently
            // being set by all of the tabs and thus all of them call the refresh-token EP.
            // Probably depending on wheather these calls are triggered miliseconds apart or at the EXACT same moment
            // sometimes 'tokenIntegrityViolation' message will be returned and sometimes 'invalidAuthorizationDetails'
            catchError((error) => {
              // TODO (Milan): The simultaneous reload of multiple tabs problem still persists. From analyzing
              // so far the conclusion is that this.user does not update on time and old token values are used
              // for comparison even though new ones are fetched and put into localStorage.
              // A way should be found to update the local values properly, a delay seems to exist.
              // If we use localStorage values here for comparison and correctlly retry the EP call that triggered the
              // refreshing of the token still the retry will happen with previous access token!
              // const localStorageTokens = this.authenticationService.getAuthTokens();

              if (error.status === 401 && error.error.generalError?.message === 'tokenIntegrityViolation') {
                // Do not reset flags here because we don't want this flow to be interrupted
                // and we want to consider the refresh-token in progress. Just retry the refresh-token after 1s.
                return new Observable((observer) => {
                  observer.next();
                  observer.complete();
                }).pipe(
                  delay(1000),
                  switchMap(() => {
                    this.localStorageService.add(REFRESH_TOKEN_INITIATED, false);
                    this.localRefreshTokenRequestInProgress$.next(false);
                    return this.refreshToken(request);
                  }),
                );
              }
              // NOTE: This error message is returned when the token pair that the app is trying to refresh has already
              // been refreshed and thus deleted from the DB.
              // One of the scenarios is having multiple tabs and parallel refresh-token calls all being sent
              // with the same token pair from localStorage that they want to refresh but one succeeds and thus the old
              // pair gets deleted from the DB and all the other ones will get this error message.

              // NOTE: If the token got refreshed by another tab in the meantime, new tokens will be in localStorage and this.user should
              // be updated
              else if (
                error.status === 401 &&
                error.error.generalError?.message === 'invalidAuthorizationDetails' &&
                // TODO (Milan): Related to updating of this.user, here not the latest value is used
                // when multiple calls happen from multiple tabs and are miliseconds apart and thus
                // the condition does not reflect the current state of local storage tokens
                this.user &&
                refreshTokenPayload.accessToken !== this.user?.access_token &&
                refreshTokenPayload.refreshToken !== this.user?.refresh_token
              ) {
                this.localStorageService.add(REFRESH_TOKEN_INITIATED, false);
                this.localRefreshTokenRequestInProgress$.next(false);
                return this.refreshToken(request, true);
              } else {
                // In other cases log out the user
                this.authenticationService.removeUserInfoFromStorage();
                this.localStorageService.add(REFRESH_TOKEN_INITIATED, false);
                this.localRefreshTokenRequestInProgress$.next(false);
                return throwError(error);
              }
            }),
          );
      }
    }
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (this.user && this.user.access_token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.user.access_token}`,
        },
      });
    }
    return request;
  }
}
