import { Component, Input, OnInit } from '@angular/core';
import { BookingPaymentDetails } from '../../../models';

export interface ITransactionCardData {
  gigTitle?: string;
  priceTotal?: number;
  date?: number | Date;
  paymentDetails?: BookingPaymentDetails;
  type?: string;
}

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.scss'],
})
export class TransactionCardComponent implements OnInit {
  @Input() transactionCardData: ITransactionCardData;
  public type: string = '';
  public totalTitle: string = '';

  constructor() {}

  ngOnInit(): void {
    this.format();
  }

  private format(): void {
    const data = { ...this.transactionCardData };
    this.type = data.type;
    this.totalTitle = `Total ${this.type}`;
  }
}
