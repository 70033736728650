import { Pipe, PipeTransform } from '@angular/core';
import { toTitleCase } from '../../helper-functions';

@Pipe({
  name: 'genderCase',
})
/**
 * Converts BE Gender Enum Value to Display Ready.
 * @param {string} gender - 'NON_BINARY'
 * @return {string} 'Non Binary'
 */
export class GenderCasePipe implements PipeTransform {
  transform(gender: string): string {
    return toTitleCase(gender.replace(/_/gi, ' ').toLowerCase());
  }
}
