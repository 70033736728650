<div
  class="datepicker"
  *ngIf="isOpen"
  [ngClass]="{ 'availability-datepicker': isAvailabilityDatepicker, 'book-modal-datepicker': isBookModalDatepicker }"
  (click)="noOp($event)"
>
  <div class="datepicker-table">
    <div class="month">
      <div class="month-nav">
        <button
          class="prev-month"
          [disabled]="disablePastMonths | datePastDisabled: year:month"
          (click)="changeMonth($event, -1)"
        >
          <img class="change-month" src="../../../assets/images/icons/arrow-left-new.svg" alt="Arrow left" />
        </button>
        <h4 class="month">{{ currentMonth }} {{ year }}</h4>
        <button class="next-month" (click)="changeMonth($event, 1)">
          <img
            class="change-month arrow-right"
            src="../../../assets/images/icons/arrow-left-new.svg"
            alt="Arrow right"
          />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th *ngFor="let day of options.weekDays">{{ day[0] }}</th>
          </tr>
        </thead>
        <tbody *ngIf="!isAvailabilityDatepicker || availableDates">
          <tr *ngFor="let week of dates; index as i">
            <td
              *ngFor="let day of week; index as j"
              [ngClass]="
                day
                  | datepickerClass
                    : month
                    : year
                    : isAvailabilityDatepicker
                    : calendarIsEnabled
                    : disableToday
                    : bookingDate
                    : availableDates
                    : noFutureLimit
              "
            >
              <div class="inner-date" [ngClass]="{ 'show-hover': showHoverState }" (click)="pickDate($event, j, day)">
                {{ day }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
