import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-img',
  templateUrl: './no-data-img.component.html',
  styleUrls: ['./no-data-img.component.scss'],
})
export class NoDataImgComponent implements OnInit {
  public noDataImageUrl: string;

  constructor() {}

  ngOnInit(): void {
    this.noDataImageUrl = '../../assets/images/components/no-data.svg';
  }
}
