import { Component, HostBinding, OnInit } from '@angular/core';
import { IInfoModalData } from '../../templates/info-modal/info-modal.config';

@Component({
  selector: 'app-install-app-modal',
  templateUrl: './install-app-modal.component.html',
  styleUrls: ['./install-app-modal.component.scss'],
})
export class InstallAppModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public infoModalData: IInfoModalData;

  constructor() {}

  ngOnInit(): void {
    this.infoModalData = {
      backgroundImageClass: 'warning',
      title: 'Please install our mobile app to use this feature',
    };
  }
}
