import { Component, OnInit, Input } from '@angular/core';

import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { map } from 'rxjs/operators';
import { Media } from '@twilio/conversations';
import { Observable, from } from 'rxjs';

import { formatSingleGalleryImage, GalleryImage } from '../../../utils';

@Component({
  selector: 'app-my-image',
  templateUrl: './my-image.component.html',
  styleUrls: ['./my-image.component.scss'],
})
export class MyImageComponent implements OnInit {
  @Input() media: Media;
  public image: Observable<string> | any;
  public images: GalleryImage[] = [];
  public options: NgxGalleryOptions[] = [];
  public isLoading: boolean = true;
  constructor() {}

  ngOnInit(): void {
    this.image = from(this.media.getContentTemporaryUrl())
      .pipe(map((image) => formatSingleGalleryImage(image)))
      .subscribe((images) => {
        this.isLoading = false;
        this.images = [...images];
      });

    this.options = [
      {
        closeIcon: 'close-gallery',
        imageArrows: false,
        previewArrows: false,
        thumbnailsArrows: false,
        thumbnails: false,
      },
    ];
  }
}
