<div class="skills-filter__container search__accordion-override-style">
  <ng-container *ngIf="expandable; else nonExpandable">
    <ngb-accordion [destroyOnHide]="false" #acc="ngbAccordion" (shown)="contentShown()">
      <ngb-panel id="skillsPanel">
        <ng-template ngbPanelTitle>
          <h6>{{ labelTranslated }}</h6>
          <div class="accordion__show-more">
            <app-active-filters *ngIf="showActiveLabel" [count]="selectedSkills.length"></app-active-filters>
            <img *ngIf="!acc.isExpanded('skillsPanel')" src="assets/images/icons/faq-down.svg" alt="Arrow Down" />
            <img *ngIf="acc.isExpanded('skillsPanel')" src="assets/images/icons/faq-up.svg" alt="Arrow Up" />
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngTemplateOutlet="skillsFilterContent"> </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>
  <ng-template #nonExpandable>
    <ng-container *ngTemplateOutlet="skillsFilterContent"> </ng-container>
  </ng-template>
</div>
<ng-template #skillsFilterContent>
  <div class="card-body-content">
    <div class="main-search__input-wrapper">
      <img src="assets/images/icons/search-black.svg" />
      <input #searchField placeholder="Search" [value]="searchTerm$ | async" (input)="search(searchField.value)" />
    </div>
    <p class="help-block" *ngIf="showError">{{ 'SEARCH.MINIMUM_SKILL_REQUIRED' | translate: { number: '1' } }}</p>
    <div class="skills-filter__selected-skills">
      <div *ngFor="let selectedSkill of selectedSkills" class="filter-section__pill pill-selected">
        <!-- TODO (Milan): Not sure yet if the close button will stay or not  -->
        <button class="reset-button-styles" type="button">
          <img src="assets/images/icons/close-black.svg" (click)="removeSelectedSkill(selectedSkill)" />
        </button>
        <span>{{ selectedSkill.name }}</span>
      </div>
    </div>
    <hr />
    <div
      #selectableSkillsExpandableWrapper
      class="skills-filter__selectable-skills-wrapper"
      [ngClass]="{ expanded: skillsExpanded }"
    >
      <div #selectableSkillsContainer class="skills-filter__selectable-skills">
        <div *ngIf="!skillsForDisplay?.length">{{ 'SEARCH.NO_MATCHING_SKILLS' | translate }}</div>
        <button
          *ngFor="let skill of skillsForDisplay"
          class="filter-section__pill reset-button-styles"
          type="button"
          [ngClass]="{ 'pill-selected': skill.selected }"
          (click)="skillToggle(skill)"
        >
          {{ skill.name }}
        </button>
      </div>
    </div>
    <div class="skills-filter__see-more-wrapper">
      <button
        *ngIf="showSeeMore"
        class="skills-filter__see-more-button reset-button-styles"
        type="button"
        (click)="toggleFullSelectableSkills()"
      >
        <span *ngIf="!skillsExpanded">{{ 'SEARCH.SEE_MORE' | translate }}</span>
        <span *ngIf="skillsExpanded">{{ 'SEARCH.SEE_LESS' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
