import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { ASSETS_PATH, META_CONTENT, MetaService, TitleService } from '../../utils';
import { Translation } from '../../models/translation';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public buttonLabel: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private metaService: MetaService,
    private titleService: TitleService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.document.documentElement.style.height = '100%';
    this.document.body.style.height = '100%';
    this.document.getElementById('root-div').style.height = '100%';

    this.translate.get('HOME.ENTER_SITE').subscribe((res: string) => {
      this.buttonLabel = res;
    });
    const title = 'Jammcard | Hire The Best Music Professionals';
    const description = META_CONTENT.GENERAL_DESCRIPTION;
    this.titleService.setTitle(title);
    this.metaService.addTag({ name: 'description', content: description });

    // TODO (Milan) the url sent should be checked if it comes out right when this page is shared
    this.metaService.addTag({
      name: 'og:url',
      content: this.document.location.protocol + this.document.location.origin,
    });
    this.metaService.addTag({ name: 'og:title', content: title });
    this.metaService.addTag({ name: 'og:description', content: description });
    this.metaService.addTag({
      name: 'og:image',
      content: ASSETS_PATH.LANDING_BACKGROUND,
    });
    this.metaService.addTag({ name: 'twitter:title', content: title });
    this.metaService.addTag({ name: 'twitter:description', content: description });
    this.metaService.addTag({
      name: 'twitter:image',
      content: ASSETS_PATH.LANDING_BACKGROUND,
    });
    this.metaService.addTag({ name: 'twitter:card', content: 'summary_large_image' });
  }

  ngOnDestroy() {
    this.document.documentElement.style.height = '';
    this.document.body.style.height = '';
    this.document.getElementById('root-div').style.height = '';
  }

  onClick(event): void {}
}
