import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookModalRatingComponent } from './book-modal-rating.component';
import { ModalModule } from '../../templates/modal/modal.module';
import { BookModalSectionModule } from '../../molecules/book-modal-section/book-modal-section.module';
import { RequestQuickCardModule } from '../../molecules/request-quick-card/request-quick-card.module';
import { PaymentDetailsModule } from '../../molecules/payment-details/payment-details.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RateExperienceGroupModule } from '../../molecules/rate-experience-group/rate-experience-group.module';
import { BookModalRatingEntryComponent } from './book-modal-rating-entry.component';

@NgModule({
  declarations: [BookModalRatingComponent, BookModalRatingEntryComponent],
  imports: [
    CommonModule,
    ModalModule,
    BookModalSectionModule,
    RequestQuickCardModule,
    PaymentDetailsModule,
    ButtonModule,
    FormsModule,
    TranslateModule,
    RateExperienceGroupModule,
  ],
  exports: [BookModalRatingComponent, BookModalRatingEntryComponent],
})
export class BookModalRatingModule {}
