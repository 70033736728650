<div class="info-modal flex column">
  <section class="info-modal__header flex column justify-center align-center">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <app-email-inbox-card
      [emailInboxDescription]="requestSentModalData.emailInboxDescription"
      [emailInboxSubtitle]="requestSentModalData.emailInboxSubtitle"
      [type]="this.type"
    ></app-email-inbox-card>
  </section>

  <section class="info-modal__content request-sent-modal__content flex column align-center justify-center">
    <header class="info-modal__heading flex justify-center">
      <p class="info-modal__title">
        {{ requestSentModalData.requestSentTitle }}
      </p>
    </header>
    <p *ngIf="requestSentModalData.requestSentDescription.length" class="info-modal__description">
      {{ requestSentModalData.requestSentDescription }}
    </p>
    <div [ngSwitch]="this.backButtonType">
      <div *ngSwitchCase="'login'" class="info-modal__btn-container auth-modal__send-btn-container">
        <app-button
          id="resetPassBackToLoginButton"
          (clicked)="openLoginModal($event)"
          [buttonDisabled]="false"
          [buttonLabel]="requestSentModalData.requestSentButtonLabel"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
      <div *ngSwitchCase="'verification'" class="info-modal__multiple-buttons flex row justify-center align-center">
        <app-button
          class="info-modal__btn-container request-sent-modal__btn"
          id="signupVerificationBackToLoginButton"
          (clicked)="openLoginModal($event)"
          [buttonDisabled]="false"
          [buttonLabel]="requestSentModalData.requestSentButtonLabel"
          [buttonType]="'submit'"
          [inverted]="true"
        >
        </app-button>
        <app-button
          id="signupResendVerificationEmailButton"
          class="info-modal__btn-container"
          (clicked)="this.resendVerificationEmail()"
          [buttonDisabled]="false"
          [buttonLabel]="requestSentModalData.backButtonLabel"
          [buttonType]="'submit'"
        >
        </app-button>
      </div>
    </div>
  </section>
</div>
