<app-jammcard-modal [modalImage]="bookModalData?.modalImage" [buttonLabel]="buttonLabel">
  <div class="auth-modal__info book-modal__info flex column">
    <img
      class="auth-modal__close"
      src="../../../assets/images/icons/close-black.png"
      alt="Close icon"
      (click)="closeModal(undefined)"
    />
    <app-book-modal-section class="book-modal-complete__status-label">
      <app-status-label [statusTitle]="'Complete'" [statusClass]="'success--green'"></app-status-label>
    </app-book-modal-section>
    <header class="book-modal-complete__header book-modal__header book-modal__section">
      <p class="auth-modal__heading">{{ bookModalPendingRequestData?.gigTitle }}</p>
    </header>
    <form class="book-modal-complete__form auth-modal__form flex column" #bookForm="ngForm" (ngSubmit)="submitForm()">
      <app-book-modal-section
        [sectionTitle]="bookingIdNumberTitle"
        [sectionText]="bookModalPendingRequestData?.assignedId"
      >
      </app-book-modal-section>

      <hr />

      <app-book-modal-section
        *ngIf="bookModalPendingRequestData?.bookedServiceType"
        [sectionTitle]="serviceProvisionType"
        [sectionText]="bookModalPendingRequestData?.bookedServiceType | titlecase"
      >
      </app-book-modal-section>

      <hr *ngIf="bookModalPendingRequestData?.bookedServiceType" />

      <app-book-modal-section *ngIf="!reviewed" [sectionTitle]="reviewTitle">
        <app-book-modal-clickable-label
          [labelText]="rateYourExperienceTitle"
          (labelClicked)="openRatingModal()"
        ></app-book-modal-clickable-label>
      </app-book-modal-section>

      <app-book-modal-section *ngIf="reviewed" [sectionTitle]="reviewTitle">
        <app-rated-experience-group
          [name]="bookModalData.proName"
          [starNumber]="starNumber"
        ></app-rated-experience-group>
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="avatarGroupTitle">
        <app-avatar-group [avatarGroupData]="avatarGroupData" [isClickable]="true"></app-avatar-group
      ></app-book-modal-section>

      <hr />

      <app-book-modal-section
        [sectionTitle]="bookingDatesTitle"
        [sectionText]="bookModalPendingRequestData?.dates[0] | date: 'EEEE, MMMM d'"
      >
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="payoutTitle">
        <app-price-total
          [price]="priceTotal"
          (priceTotalInfoClicked)="openPayoutDetails()"
          [totalTitle]="'Total'"
        ></app-price-total>
      </app-book-modal-section>

      <hr />

      <app-book-modal-section [sectionTitle]="manageBookingTitle">
        <app-book-modal-clickable-label
          [labelText]="contactSupportTitle"
          (labelClicked)="openContactSupport()"
        ></app-book-modal-clickable-label>
      </app-book-modal-section>
    </form>
  </div>
</app-jammcard-modal>
