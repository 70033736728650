<section class="transaction-card__container flex column justify-start">
  <div class="transaction-card__header">
    <div class="transaction-card__section flex row justify-content-between align-center width-100">
      <label
        ><strong>{{ transactionCardData?.gigTitle }}</strong
        >&nbsp;{{ type }}</label
      >
      <label>${{ transactionCardData?.priceTotal | number: '1.2-2' }}</label>
    </div>
    <div class="transaction-card__section flex row justify-content-between align-center width-100">
      <label
        >{{ transactionCardData?.paymentDetails?.payoutDate | date: 'mediumDate' }}&nbsp;·&nbsp;{{
          transactionCardData?.paymentDetails?.payoutDate | date: 'h:mm a'
        }}</label
      >
    </div>
    <div class="transaction-card__method flex row justify-content-between align-center width-100">
      <label>{{ transactionCardData?.paymentDetails?.paymentMethodType | titlecase }}</label>
    </div>
  </div>
  <app-price-total [price]="transactionCardData?.priceTotal" [totalTitle]="totalTitle"></app-price-total>
</section>
