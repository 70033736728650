import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditModalComponent } from './edit-modal.component';

@NgModule({
  declarations: [EditModalComponent],
  exports: [EditModalComponent],
  imports: [CommonModule],
})
export class EditModalModule {}
