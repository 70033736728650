<div class="manage-gig-modal data-input-modal">
  <div class="manage-gig-modal__header data-input-modal__header">
    <button class="data-input-modal__header-close reset-button-styles" type="button" (click)="closeModal()">
      <img src="assets/images/icons/close-black.svg" />
    </button>
    <h4 class="data-input-modal__header-title">
      {{ shouldEditOpp ? ('JAMMBOARD.EDIT_A_GIG' | translate) : ('JAMMBOARD.POST_A_GIG' | translate) }}
    </h4>
    <p>{{ 'JAMMBOARD.MARKED_FIELDS_ARE_REQUIRED' | translate: { symbol: '*' } }}</p>
  </div>
  <div class="manage-gig-modal__content data-input-modal__content">
    <form class="flex column" [formGroup]="manageGigForm" (ngSubmit)="onSubmit()">
      <span class="manage-gig-modal__error-scroll-to" *ngIf="title.invalid && title.touched"></span>
      <h6 class="manage-gig-modal__field-title">{{ 'JAMMBOARD.TITLE' | translate: { symbol: '*' } }}</h6>
      <input
        class="book-modal__input-text"
        formControlName="title"
        placeholder="{{ 'JAMMBOARD.DRUMMER_FOR_US_ARENA_TOUR' | translate: { idEst: 'i.e.' } }}"
      />
      <hr />
      <p class="help-block" *ngIf="title.errors?.required && title.touched">
        {{ 'JAMMBOARD.TITLE_IS_REQUIRED' | translate }}
      </p>
      <p class="help-block" *ngIf="title.errors?.maxlength && title.dirty">
        {{ 'JAMMBOARD.TITLE_CANNOT_BE_LONGER_THAN' | translate: { number: '1024' } }}
      </p>
      <span class="manage-gig-modal__error-scroll-to" *ngIf="skills.invalid && skills.touched"></span>
      <h6 class="manage-gig-modal__field-title">{{ 'JAMMBOARD.SKILLS_NEEDED' | translate: { symbol: '*' } }}</h6>
      <app-skills-filter
        [availableSkills]="availableSkills"
        [skillsToFilterBy$]="skillsToFilterBy$"
        [expandable]="false"
        [showError]="skills.invalid && skills.touched"
        (skillChanged)="skillsChanged($event)"
      ></app-skills-filter>
      <hr />
      <span class="manage-gig-modal__error-scroll-to" *ngIf="description.invalid && description.touched"></span>
      <h6 class="manage-gig-modal__field-title">{{ 'JAMMBOARD.DESCRIPTION' | translate: { symbol: '*' } }}</h6>
      <input
        class="book-modal__input-text"
        formControlName="description"
        placeholder="{{ 'JAMMBOARD.ADD_ANY_OTHER_DETAILS' | translate }}"
      />
      <hr />
      <p class="help-block" *ngIf="description.errors?.required && description.touched">
        {{ 'JAMMBOARD.DESCRIPTION_IS_REQUIRED' | translate }}
      </p>
      <p class="help-block" *ngIf="description.errors?.maxlength && description.dirty">
        {{ 'JAMMBOARD.DESCRIPTION_CANNOT_BE_LONGER_THAN' | translate: { number: '3000' } }}
      </p>
      <h6 class="manage-gig-modal__field-title">{{ 'JAMMBOARD.LOCATION' | translate }}</h6>
      <app-location-filter [expandable]="false" [locationToFilterBy$]="locationToFilterBy$"></app-location-filter>
      <h6 class="manage-gig-modal__field-title">{{ 'JAMMBOARD.COMPENSATION' | translate }}</h6>
      <div class="manage-gig-modal__price-input-wrapper">
        <input
          class="book-modal__input-text"
          formControlName="price"
          placeholder="{{ 'JAMMBOARD.PER_DAY' | translate: { idEst: 'i.e.', price: '$400' } }}"
        />
      </div>
      <p class="help-block" *ngIf="price.invalid && price.dirty">
        {{ 'JAMMBOARD.PRICE_CANNOT_BE_LONGER_THAN' | translate: { number: '100' } }}
      </p>
      <hr id="dateSectionSeparator" />
      <h6 class="manage-gig-modal__field-title">{{ 'JAMMBOARD.DATE' | translate }}</h6>
      <div class="flex row">
        <div
          class="manage-gig-modal__date-input-wrapper flex row"
          (click)="toggleDatepicker()"
          appClickOutside
          (clickOutside)="closeDatepicker()"
        >
          <input
            class="book-modal__input-text"
            formControlName="date"
            placeholder="{{ 'JAMMBOARD.CLICK_TO_SELECT_DATE' | translate }}"
            [value]="manageGigForm.get('date').value | date: 'MM/dd/yyyy'"
            readonly
          />
          <button
            class="manage-gig-modal__date-clear-button reset-button-styles"
            *ngIf="manageGigForm.get('date').value"
            type="button"
            (click)="resetDate()"
          >
            <img src="assets/images/icons/close-black.svg" />
          </button>
          <app-datepicker
            [isOpen]="datepickerIsOpen"
            [bookingDate]="manageGigForm.value.date"
            [noFutureLimit]="true"
            [isBookModalDatepicker]="true"
            (toggleDatepicker)="toggleDatepicker()"
            (dateChange)="changeDate($event)"
          ></app-datepicker>
        </div>
      </div>
      <p class="help-block" *ngIf="date.invalid">
        {{ 'JAMMBOARD.DATE_CANNOT_BE_IN_PAST' | translate }}
      </p>
      <hr />
      <div class="manage-gig-modal__footer">
        <app-button
          buttonLabel="{{ postOppButtonLabel | translate }}"
          buttonType="submit"
          [rounded]="true"
        ></app-button>
        <ng-container *ngIf="shouldEditOpp">
          <app-button
            buttonLabel="{{ 'JAMMBOARD.MARK_AS_FILLED' | translate }}"
            buttonType="button"
            [inverted]="true"
            [darkBorder]="true"
            [rounded]="true"
            (clicked)="onMarkAsFilled()"
          ></app-button>
          <app-button
            buttonLabel="{{ 'JAMMBOARD.DELETE_GIG' | translate }}"
            buttonType="button"
            red="true"
            [rounded]="true"
            (clicked)="onDelete()"
          ></app-button>
        </ng-container>
      </div>
    </form>
  </div>
</div>
