import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationShort',
})
export class DurationShortPipe implements PipeTransform {
  transform(duration: unknown, ...args: unknown[]): unknown {
    if (duration === 'MINUTES') {
      return duration.slice(0, 3);
    }
  }
}
