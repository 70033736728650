<div class="rate-select-pill__container">
  <div *ngIf="displayAsPill && !rescheduleRate" class="rate-select-pill__pill-display flex">
    <ng-container *ngIf="rates?.length === 2">
      <div
        class="rate-select-pill__left-end"
        (click)="onClick(rates[0])"
        [ngClass]="{ selected: selectedRate.id === rates[0].id }"
      >
        {{ rates[0].rateSetting.timeDurationAmount }} {{ shortTimeUnitDisplay[rates[0].rateSetting.timeDurationUnit] }}
      </div>
      <div
        class="rate-select-pill__right-end"
        (click)="onClick(rates[1])"
        [ngClass]="{ selected: selectedRate.id === rates[1].id }"
      >
        {{ rates[1].rateSetting.timeDurationAmount }} {{ shortTimeUnitDisplay[rates[1].rateSetting.timeDurationUnit] }}
      </div>
    </ng-container>
    <ng-container *ngIf="rates?.length === 3">
      <div
        class="rate-select-pill__left-end"
        (click)="onClick(rates[0])"
        [ngClass]="{ selected: selectedRate.id === rates[0].id }"
      >
        {{ rates[0].rateSetting.timeDurationAmount }} {{ shortTimeUnitDisplay[rates[0].rateSetting.timeDurationUnit] }}
      </div>
      <div
        class="rate-select-pill__middle"
        (click)="onClick(rates[1])"
        [ngClass]="{ selected: selectedRate.id === rates[1].id }"
      >
        {{ rates[1].rateSetting.timeDurationAmount }} {{ shortTimeUnitDisplay[rates[1].rateSetting.timeDurationUnit] }}
      </div>
      <div
        class="rate-select-pill__right-end"
        (click)="onClick(rates[2])"
        [ngClass]="{ selected: selectedRate.id === rates[2].id }"
      >
        {{ rates[2].rateSetting.timeDurationAmount }} {{ shortTimeUnitDisplay[rates[2].rateSetting.timeDurationUnit] }}
      </div>
    </ng-container>
  </div>
  <!-- <div *ngIf="!displayAsPill || rescheduleRate" class="rate-select-pill__non-pill-display">
    <p *ngIf="!rescheduleRate" class="rate-select-pill__single-rate">
      {{ sessionDurationLongDisplay[rates[0].rateSetting.timeDurationUnit][rates[0].rateSetting.timeDurationAmount] }}
    </p>
    <p *ngIf="rescheduleRate" class="rate-select-pill__single-rate">
      {{
        sessionDurationLongDisplay[rescheduleRate.rateSetting.timeDurationUnit][
          rescheduleRate.rateSetting.timeDurationAmount
        ]
      }}
    </p>
  </div> -->
</div>
