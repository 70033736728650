import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifyMeModalComponent } from './notify-me-modal.component';
import { NotifyMeModalEntryComponent } from './notify-me-modal-entry.component';
import { MentorshipModalModule } from '../../templates/mentorship-modal/mentorship-modal.module';
import { SellerProfileCardModule } from '../../molecules/seller-profile-card/seller-profile-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../../atoms/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NotifyMeModalComponent, NotifyMeModalEntryComponent],
  imports: [
    CommonModule,
    MentorshipModalModule,
    SellerProfileCardModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
  ],
  exports: [NotifyMeModalEntryComponent],
})
export class NotifyMeModalModule {}
