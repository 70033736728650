import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { AvailabilitySlotListComponent } from './availability-slot-list.component';
import { AvailabilitySlotModule } from '../../molecules/availability-slot/availability-slot.module';

@NgModule({
  declarations: [AvailabilitySlotListComponent],
  imports: [CommonModule, AvailabilitySlotModule, TranslateModule],
  exports: [AvailabilitySlotListComponent],
})
export class AvailabilitySlotListModule {}
