import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { isNil } from 'lodash-es';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../../store/app.reducers';
import { AvailableDay, AvailabilitySlot, Rate, ServiceProvision, ServiceType, TruncatedProfile } from '../../../models';
import {
  AuthenticationService,
  InfoService,
  LocalStorageService,
  ProfileService,
  TempStorageService,
  ToastService,
} from '../../../services';
import { FastBookingActions } from '../../../store/fast-booking/fast-booking.action-types';
import {
  AUTH_CONTROLLER,
  constructURLAccordingToSourceType,
  EVENT_NAME_PREFIX,
  MODAL_DISMISS_REASON,
  PLACEHOLDER_IMAGE_URL,
  SERVICE_BOOKING_GUEST,
} from '../../../utils';
import {
  selectAvailabilityDaysForRateAndMonth,
  selectAvailabilitySlotsForSelectedDay,
  selectLockedSlots,
} from '../../../store/fast-booking/fast-booking.selectors';
import { AuthControllerModalComponent } from '../../templates/auth-controller-modal/auth-controller-modal.component';

@Component({
  selector: 'app-fast-booking-availability-modal',
  templateUrl: './fast-booking-availability-modal.component.html',
  styleUrls: ['./fast-booking-availability-modal.component.scss'],
})
export class FastBookingAvailabilityModalComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'host-fill-height';

  public availabilityDays$ = new Subject<AvailableDay[]>();
  public availabilitySlots$ = new Subject<AvailabilitySlot[]>();
  // On initial load, first two values of this flag get emitted before the calendar is initiated and subscribed to it so ReplaySubject is needed
  // to store these initial two values for use in child components
  public calendarEnabled$ = new ReplaySubject<boolean>(2);
  // TODO (Milan): Check for how this obj should be initialized
  public preselect: {
    rate: any;
    calendar: any;
    slots: any;
  } = { rate: null, calendar: null, slots: null };
  public proTruncatedProfile: TruncatedProfile;
  public selectedDay: { startTime: number; endTime: number } = null;
  public selectedRate: Rate = null;
  public selectedRateDurationAmount$ = new ReplaySubject<number | string>(1);
  public serviceForBooking: ServiceProvision;
  public navigateBackUrl: string;
  public rescheduleRate: Rate;
  public isViewerSubscribed: boolean;
  public isFromReschedule: boolean;
  public openedFromWebView: boolean;

  private fromParent: {
    proTruncatedProfile: TruncatedProfile;
    serviceForBooking: ServiceProvision;
    navigateBackUrl: string;
    rescheduleRate?: Rate;
    openedFromWebView?: boolean;
  };
  private subscription$ = new Subscription();

  constructor(
    private actions$: Actions,
    private activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private infoService: InfoService,
    private localStorageService: LocalStorageService,
    public profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private tempStorageService: TempStorageService,
    public toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.subscription$.add(
      this.route.queryParams.subscribe((params) => {
        if (params?.prepopulate) {
          const prepopulateObj = this.localStorageService.get(SERVICE_BOOKING_GUEST);

          // TODO (Milan): The format of these values should probably be adjusted to be used in the components in the most efficiant way
          this.preselect.rate = prepopulateObj?.rate || null;
          this.preselect.calendar = prepopulateObj?.day || null;
          this.preselect.slots = prepopulateObj?.slotIds || null;
        }
      }),
    );

    this.navigateBackUrl = this.fromParent.navigateBackUrl;
    this.proTruncatedProfile = this.fromParent.proTruncatedProfile;
    this.serviceForBooking = this.fromParent.serviceForBooking;
    this.rescheduleRate = this.fromParent.rescheduleRate;
    this.openedFromWebView = this.fromParent.openedFromWebView;

    this.router.url.includes('reschedule') ? (this.isFromReschedule = true) : (this.isFromReschedule = false);
    this.isViewerSubscribed = this.proTruncatedProfile?.servicesProvision[0]?.isViewerSubscribed;

    this.subscription$.add(
      this.actions$
        .pipe(
          ofType(FastBookingActions.calendarMonthSelected),
          tap((action) => {
            // TODO (Milan): Rate should already be set when this code gets executed, check for behavior
            this.store.dispatch(
              FastBookingActions.loadAvailableDays({
                startTime: action.startTime,
                days: action.days,
                month: action.month,
                rate: this.selectedRate,
                serviceId: this.serviceForBooking?.id,
              }),
            );
          }),
          switchMap((action, index) => {
            // Reset preselect object on second emission as it should only be taken into consideration on the first one.
            // In this way, resetting will propagate into child components also
            if (index === 1) {
              this.preselect = { rate: null, calendar: null, slots: null };
            }
            // TODO (Milan): Check if this take(1) does not cancel the source observable, it is needed here because every time a different
            // month/rate will be selected and the previous one should be unsubscribed
            return this.store.select(selectAvailabilityDaysForRateAndMonth(this.selectedRate, action.month)).pipe(
              filter((availableDays) => !isNil(availableDays)),
              take(1),
            );
          }),
        )
        .subscribe((availableDays: AvailableDay[]) => {
          // If an error occurs while fetching of available days, here we will get and emitt null so the calendar component is notified that an error ocurred
          // TODO (Milan): It was needed to have the index of the day in the original non-filtered array, this should be handled more cleanly through the flow all
          // the way to datepicker component
          availableDays = availableDays
            .map(
              (day, index) =>
                (day = {
                  ...day,
                  originalIndex: new Date(availableDays[index].startDateTime).getDate(),
                }),
            )
            .filter((day) => day.available);
          this.availabilityDays$.next(availableDays);
        }),
    );

    this.subscription$.add(
      this.actions$.pipe(ofType(FastBookingActions.calendarDaySelected)).subscribe((action) => {
        const serviceProvisionId = this.rescheduleRate?.serviceProvisionId || this.serviceForBooking.id;
        this.selectedDay = { startTime: action.startTime, endTime: action.endTime };
        this.store.dispatch(
          FastBookingActions.loadAvailableSlots({
            startTime: action.startTime,
            endTime: action.endTime,
            serviceProvisionId,
          }),
        );
      }),
    );

    this.subscription$.add(
      this.actions$
        .pipe(
          ofType(FastBookingActions.availableSlotsLoaded),
          switchMap((action) =>
            this.store.select(selectAvailabilitySlotsForSelectedDay).pipe(
              filter((availabilitySlots) => !isNil(availabilitySlots)),
              map((availabilitySlots) => {
                if (this.rescheduleRate?.serviceProvisionOwner) {
                  return availabilitySlots.filter((slot) => slot.status === 'FREE');
                } else {
                  return availabilitySlots;
                }
              }),
              take(1),
            ),
          ),
        )
        .subscribe((availabilitySlots) => {
          this.availabilitySlots$.next(availabilitySlots);
        }),
    );

    this.subscription$.add(
      this.actions$.pipe(ofType(FastBookingActions.availabilitySlotsInitiateLock)).subscribe((action) => {
        if (this.authenticationService.isLoggedIn()) {
          /* Check if feature is opened from WebView. 
-           If it is, modal close route should have extra query params to indicate WebView exit */
          this.store.dispatch(
            FastBookingActions.lockAvailabilitySlots({
              availabilitySlotsIds: action.availabilitySlotsIds,
              backUrl: constructURLAccordingToSourceType(this.navigateBackUrl, action.openedFromWebView),
              rateId: this.selectedRate.id,
              day: this.selectedDay,
              openedFromWebView: action.openedFromWebView,
            }),
          );
        } else {
          this.openRegisterModal(action.availabilitySlotsIds);
        }
      }),
    );

    this.subscription$.add(
      this.store
        .select(selectLockedSlots)
        .pipe(filter((lockedSlots) => !isNil(lockedSlots)))
        .subscribe((lockedSlots) => {
          // TODO: Slots are successfully locked and confirm & pay modal should be opened
          // this.router.navigate(['confirm'], { relativeTo: this.activatedRoute })
        }),
    );
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public changeDuration(e: Rate): void {
    this.selectedRate = e;
    this.selectedRateDurationAmount$.next(parseInt(this.selectedRate.rateSetting.timeDurationAmount.toString()));
    // First disable and then enable the calendar that now starts it's initiation sequence
    this.calendarEnabled$.next(false);
    this.calendarEnabled$.next(true);
  }

  public onNotifyMeClicked(): void {
    this.infoService.trackEvent(`${EVENT_NAME_PREFIX.WEB_UI}.button.click`, {
      element: 'waitlist-button',
      profileId: this.proTruncatedProfile.profileId,
      mentorName: this.proTruncatedProfile.name,
    });
    const newUrl = `${this.currentUrlWithoutQueryParams()}/notify-me`;
    this.router.navigateByUrl(newUrl);
  }

  private currentUrlWithoutQueryParams(): string {
    // The sponsored link (FB adds for expamle) links to truncated profile but with some added querry params so when adding further navigation
    // we need to remove the querry params first and than append the url fragment
    let urlTree = this.router.parseUrl(this.router.routerState.snapshot.url);
    urlTree.queryParams = {};
    return urlTree.toString().replace('/availability', '').replace('/book-mentorship', '');
  }

  private openRegisterModal(slotIds: number[]): void {
    // store the selected guest data into temp storage
    this.tempStorageService
      .storeData({
        serviceType: ServiceType.MENTORSHIP,
        rate: this.selectedRate,
        day: this.selectedDay,
        slotIds,
      })
      .subscribe((res) => {
        const storageUUID = res.body?.uuid || null;

        this.activeModal.dismiss(MODAL_DISMISS_REASON.SIGN_UP_FROM_BOOK);

        const modalInstance = this.modalService.open(AuthControllerModalComponent, {
          windowClass: 'modal-window',
          backdrop: 'static',
        });

        const signupModalData = {
          modalImage: this.proTruncatedProfile.coverImage?.url || PLACEHOLDER_IMAGE_URL.NO_COVER,
          proName: this.proTruncatedProfile.name,
          book: true,
          storageUUID,
          serviceType: ServiceType.MENTORSHIP,
        };

        modalInstance.componentInstance.fromParent = {
          modalType: AUTH_CONTROLLER.SIGNUP,
          authControllerModalData: signupModalData,
        };
      });
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
