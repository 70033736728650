import { createAction, props } from '@ngrx/store';
import { BasicProfile } from '../../models';

export const loadProfilePage = createAction('[Profile] Entered A New Profile Page');
export const loadBasicProfileInfo = createAction('[Profile] Load Basic Profile Info');
export const loadBasicProfileInfoById = createAction(
  '[Profile] Load Basic Profile Info By ID',
  props<{ id: number }>(),
);

export const basicProfileInfoLoaded = createAction(
  '[Profile Effect] Loaded Basic Profile Info',
  props<{ basicInfo: BasicProfile }>(),
);
export const basicProfileInfoLoadedFromCache = createAction('[Profile Effect] Loaded Basic Profile Info From Cache');
export const basicProfileInfoDoesntExist = createAction('[Profile Effect] Basic Profile Info doesnt exist');

export const basicProfileInfoUpdate = createAction(
  '[Profile] Basic Profile Info Update Initiated',
  props<{ basicInfoDTO }>(),
);
export const basicProfileInfoUpdateSuccess = createAction(
  '[Profile Effect] Basic Profile Info Update Success',
  props<{ basicInfoDTO }>(),
);
export const basicProfileInfoUpdateLoaded = createAction(
  '[Profile Effect] Basic Profile Info Reloaded',
  props<{ basicInfo: BasicProfile }>(),
);
export const basicProfileInfoUpdateFail = createAction(
  '[Profile Effect] Basic Profile Info Update Fail',
  props<{ error }>(),
);
export const coverImageUpdated = createAction('[Profile] Cover Image Updated');

export const loadExtendedProfileInfo = createAction('[Profile] Load Extended Profile Info', props<{ id: number }>());
export const extendedProfileInfoLoaded = createAction(
  '[Profile Effect] Loaded Extended Profile Info',
  props<{ extendedInfo }>(),
);
export const updateTagExtendedInfoExperience = createAction(
  '[Profile Action] Loaded Extended Profile Info with Replaced Experience',
  props<{ replacedExperience }>(),
);

export const loadMediaProfileInfo = createAction('[Profile] Load Media Profile Info', props<{ id: number }>());
export const mediaProfileInfoLoaded = createAction(
  '[Profile Effect] Loaded Media Profile Info',
  props<{ mediaInfo }>(),
);
export const mediaProfileInfoFailedToLoad = createAction('[Profile Effect] Media Profile Info Failed To Load');
export const updateTagMediaInfo = createAction('[Profile Action] Update Tag Media Info', props<{ replacedMedia }>());

export const basicProfileWithServicesProvision = createAction(
  '[Profile Action] Basic Profile With Services Provision',
  props<{ basicInfoWithServicesProvision }>(),
);
