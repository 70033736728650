<header *ngIf="!resetPasswordClicked" class="auth-modal__header">
  <p *ngIf="loginModalData?.normalModal" class="auth-modal__heading">
    {{ 'LOGIN.WELCOME_BACK_TO_JAMMCARD' | translate }}
  </p>
  <p *ngIf="!loginModalData?.normalModal" class="auth-modal__heading">
    {{ 'LOGIN.LOGIN_TO_BOOK' | translate }} {{ loginModalData?.proName }} !
  </p>
</header>
<form
  *ngIf="!resetPasswordClicked"
  [formGroup]="loginForm"
  class="auth-modal__form flex column"
  (ngSubmit)="submitForm(loginForm)"
>
  <section>
    <div class="auth-modal__text-section">
      {{ 'LOGIN.LOGIN_TO_EXPLORE' | translate }}
    </div>
    <div class="auth-modal__text-section">
      {{ 'LOGIN.DONT_HAVE_AN_ACCOUNT' | translate }}
      <span class="marked" (click)="openSignupModal()">{{ 'LOGIN.SIGNUP' | translate }}</span>
    </div>
    <div class="auth-modal__install-info-content">
      <div class="auth-modal__input-section">
        <div class="auth-modal__email">
          <input
            id="loginModalInputEmail"
            class="auth-modal__input form-control"
            name="email"
            formControlName="email"
            [ngClass]="{ 'link-sent': linkSent }"
            type="text"
            required
          />
          <span class="floating-label">{{ 'LOGIN.EMAIL_ADDRESS' | translate }}</span>
        </div>

        <div *ngIf="email.invalid && (email.dirty || !email.pristine)">
          <p *ngIf="email.errors.required" class="help-block">{{ 'LOGIN.EMAIL_REQUIRED' | translate }}</p>
          <p *ngIf="email.errors.email" class="help-block">{{ 'LOGIN.PLEASE_ENTER_VALID_EMAIL' | translate }}</p>
          <p *ngIf="email.errors.notUnique" class="help-block">{{ 'LOGIN.EMAIL_NOT_FOUND' | translate }}</p>
        </div>

        <div class="auth-modal__email">
          <input
            id="loginModalInputPassword"
            class="auth-modal__input form-control"
            name="password"
            formControlName="password"
            [ngClass]="{ 'link-sent': linkSent }"
            type="password"
            required
            [type]="passwordDisplayed ? 'text' : 'password'"
          />
          <span class="floating-label">{{ 'LOGIN.PASSWORD' | translate }}</span>
          <img
            *ngIf="!passwordDisplayed"
            class="eye-closed"
            src="../../../assets/images/icons/eye-closed.svg"
            alt="Closed eye"
            (click)="toggleDisplayPassword()"
          />
          <img
            *ngIf="passwordDisplayed"
            class="eye-opened"
            src="../../../assets/images/icons/eye-open.svg"
            alt="Opened eye"
            (click)="toggleDisplayPassword()"
          />
        </div>

        <div *ngIf="password.invalid && (password.dirty || !password.pristine)">
          <p *ngIf="password.errors.required" class="help-block">{{ 'LOGIN.PASSWORD_REQUIRED' | translate }}</p>
          <p *ngIf="password.errors.invalid" class="help-block">
            {{ 'LOGIN.INVALID_EMAIL_AND_PASSWORD_COMBINATION' | translate }}
          </p>
          <p *ngIf="password.errors.minlength" class="help-block">
            {{ 'LOGIN.PASSWORD_MUST_BE_LONGER' | translate }}
          </p>
        </div>

        <div class="auth-modal__send-btn-container">
          <app-button
            id="loginModalButton"
            [ngClass]="{ 'link-sent': linkSent }"
            [buttonDisabled]="loginRequestSending"
            [buttonLabel]="buttonLabel"
            [buttonType]="'submit'"
          >
          </app-button>
        </div>

        <div class="auth-modal__forgot-pass-container flex justify-center">
          <p class="auth-modal__text-section marked" (click)="resetPassword($event)">
            {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
          </p>
        </div>
      </div>
    </div>
  </section>
</form>

<app-reset-password *ngIf="resetPasswordClicked" (backToLoginClicked)="backToLogin($event)"></app-reset-password>
