import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChannelCardComponent } from './channel-card.component';

import { PipesModule } from '../../../utils/pipes/pipes.module';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { TimeagoModule } from 'ngx-timeago';

@NgModule({
  declarations: [ChannelCardComponent],
  imports: [CommonModule, PipesModule, ContentLoaderModule, TimeagoModule],
  exports: [ChannelCardComponent],
})
export class ChannelCardModule {}
