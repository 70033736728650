import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { JammboardGigMainCardComponent } from './jammboard-gig-main-card.component';
import { PipesModule } from '../../../utils/pipes/pipes.module';
import { ClickOutsideModule } from '../../../utils/directives/click-outside/click-outside.module';

@NgModule({
  declarations: [JammboardGigMainCardComponent],
  imports: [CommonModule, ClickOutsideModule, PipesModule, RouterModule, TranslateModule],
  exports: [JammboardGigMainCardComponent],
})
export class JammboardGigMainCardModule {}
