import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import { BookingByStatus, BookingModal, BasicProfile } from '../../../models';
import { BookModalData } from '../book-modal/book-modal.component';
import { BookingService, PaymentService, ToastService } from '../../../services';
import { createAvatarGroupData, createBookModalData } from '../../../utils';
import { IAvatarGroupData } from '../../molecules/avatar-group/avatar-group.component';
import { InfoModalSuccessComponent } from '../info-modal-success/info-modal-success.component';
import { deleteCurrentBooking, loadPendingBookings } from '../../../store/booking/booking.actions';

@Component({
  selector: 'app-book-modal-pending-request',
  templateUrl: './book-modal-pending-request.component.html',
  styleUrls: ['./book-modal-pending-request.component.scss'],
})
export class BookModalPendingRequestComponent implements OnInit {
  public bookModalPendingRequestData: BookingByStatus;
  public buyer: BasicProfile;
  public bookModalData: BookModalData;
  public avatarGroupData: IAvatarGroupData;
  public buttonLabel: string;
  public fromParent: BookingModal;

  public bookingTitlePlaceholder: string;
  public bookerTitle: string;
  public bookingIdNumberTitle: string;
  public serviceProvisionType: string;
  public gigTitleTitle: string;
  public messagePlaceholder: string;
  private modalInstance: any;
  public paymentUponConformation: string;
  public processingConfirmation: boolean;
  public requestedBookingDateTitle: string;

  constructor(
    private activeModal: NgbActiveModal,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public toastService: ToastService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.translate.get('BOOK').subscribe((res: any) => {
      this.buttonLabel = res.ACCEPT;
      this.bookingTitlePlaceholder = res.REMOTE_RECORDING_FOR_MY_PROJECT;
      this.messagePlaceholder = res.INTRODUCE_YOURSELF;
      this.bookingIdNumberTitle = res.BOOKING_ID_NUMBER;
      this.gigTitleTitle = res.GIG_TITLE;
      this.serviceProvisionType = res.BOOKING_TYPE;
      this.bookerTitle = res.BOOKER;
      this.requestedBookingDateTitle = res.REQUESTED_BOOKING_DATE;
      this.paymentUponConformation = res.PAYMENT_UPON_CONFORMATION;
    });

    this.bookModalPendingRequestData = { ...this.fromParent.booking.booking };
    this.buyer = { ...this.fromParent.buyer };
    this.bookModalData = createBookModalData(this.buyer);
    this.avatarGroupData = createAvatarGroupData(this.buyer);
  }

  closeModal(event): any {
    this.activeModal.close();
  }

  // TODO (Milan): Keep an eye on the behaviour of this part of code when accepting a booking as a Seller.
  // An adjustment was made for this.paymentService.checkStripeAccount method not to return the full response but only the body -
  // here we are throwing an error inside catchError and it hopefully solves the problem. The problem was that the error doesn't seem
  // to bubble up properly from the service when we observe the full response and our logic here is dependent of the error status.
  submitForm(): any {
    this.processingConfirmation = true;
    this.paymentService
      .checkStripeAccount()
      .pipe(
        catchError((err) => {
          throw err;
        }),
      )
      .subscribe(
        (res) => {
          // The user has Stripe account and can acccept the booking
          if (!this.fromParent?.booking?.booking?.id) {
            this.showError('Something went wrong!');
            this.processingConfirmation = false;
          } else {
            this.bookingService.acceptBooking(this.fromParent.booking.booking.id).subscribe(
              (res) => {
                // The booking is accepted and Success modal should be showed and bookings refreshed
                this.store.dispatch(loadPendingBookings());
                this.store.dispatch(deleteCurrentBooking());

                this.modalInstance = this.modalService.open(InfoModalSuccessComponent, {
                  windowClass: 'modal-window',
                  backdrop: 'static',
                });

                this.modalInstance.componentInstance.fromParent = {
                  modalData: {
                    title: "You've accepted the booking request",
                    description:
                      "We've notified the booker. We'll let you know once this booking is confirmed with your expected payout details.",
                    buttonLabel: 'DONE',
                    backgroundImageClass: 'success',
                    redirectTo: 'bookings',
                  },
                };
              },
              (error) => {
                this.showError('Something went wrong!');
                this.processingConfirmation = false;
              },
            );
          }
        },
        (error) => {
          if (error.status === 404) {
            // The user doesn't have Stripe account setup, show the modal that can redirect to Stripe

            // This does not navigate where expected
            // this.router.navigate(['add-payout'], { relativeTo: this.route });
            this.router.navigate([`bookings/${this.bookModalPendingRequestData.id}/pending/add-payout`], {
              state: { pendingBookingNavigatedFrom: this.bookModalPendingRequestData.id },
            });
          } else {
            this.showError('Something went wrong!');
            this.processingConfirmation = false;
          }
        },
      );
  }

  private showError(message: string): void {
    this.toastService.showMessage(message);
  }
}
