import { Pipe, PipeTransform } from '@angular/core';
import { OPPORTUNITY } from '../../constants';

@Pipe({
  name: 'formatOpportunityMessengerTitle',
})
export class FormatOpportunityMessengerTitlePipe implements PipeTransform {
  transform(message: string): string {
    return message
      .replace(OPPORTUNITY.OPPORTUNITY_OPEN_TAG, '')
      .replace(OPPORTUNITY.OPPORTUNITY_CLOSE_TAG, '')
      .replace(OPPORTUNITY.TITLE_OPEN_TAG, '')
      .split(OPPORTUNITY.TITLE_CLOSE_TAG)[0];
  }
}
