import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-see-more-text',
  templateUrl: './see-more-text.component.html',
  styleUrls: ['./see-more-text.component.scss']
})
export class SeeMoreTextComponent implements OnInit {
  @Input() text: string;
  @Input() fontLineHeight: number;
  isOpened: boolean = false;
  isShortened: boolean = false;
  shouldShowButton: boolean = false;
  constructor() { }

  ngOnInit(): void {
    const paragraph = document.querySelector('.see-more__text') as HTMLParagraphElement;
    const observer = new (window as any).ResizeObserver(entries => {
      entries.forEach(entry => {
        this.isShortened = (entry.target.scrollHeight - Math.round(entry.contentRect.height)) > (this.fontLineHeight / 2);
        this.shouldShowButton = 
          (entry.target.scrollHeight > (this.fontLineHeight * 3)) && 
          (this.isShortened || (!this.isShortened && this.isOpened));
        entry.target.classList[ this.isShortened ? 'add' : 'remove']('shortened');
      });
    });
    observer.observe(paragraph);
  }

}
