import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';

import { Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';

import { API_URL } from './constants';

@Injectable()
export class SsrInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const originalUrl = request.url;

    if (request.method !== 'GET') {
      return EMPTY;
    }

    request = request.clone({
      url: this.getAbsoluteUrl(request.url),
    });

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && originalUrl !== request.url) {
          this.transferState.set(makeStateKey(originalUrl), event.body);
        }
      }),
    );
  }

  // TODO (Jovana) Check this out

  private getAbsoluteUrl(relativeUrl: string): string {
    let newRelUrl: string;

    if (/^\/api\/profile/.test(relativeUrl)) {
      newRelUrl = relativeUrl.replace(/^\/api\/profile/, '/profile');
    }

    if (/^\/api\/media/.test(relativeUrl)) {
      newRelUrl = relativeUrl.replace(/^\/api\/media/, '/content');
    }

    if (/^\/api\/user/.test(relativeUrl)) {
      newRelUrl = relativeUrl.replace(/^\/api\/user/, '/user');
    }

    if (/^\/api\/booking/.test(relativeUrl)) {
      newRelUrl = relativeUrl.replace(/^\/api\/booking/, '/booking');
    }

    const apis_url = process.env.APIS_URL || API_URL.DEV;
    return newRelUrl ? apis_url.concat(newRelUrl) : relativeUrl;
  }
}
