import { Pipe, PipeTransform } from '@angular/core';

import { IsBrowserService } from '../../../utils';

@Pipe({
  name: 'ssrTranslate',
})
export class SsrTranslatePipe implements PipeTransform {
  constructor(private isBrowserService: IsBrowserService) {}

  public transform(value: string, ssrValue: string): string {
    if (this.isBrowserService.isBrowser()) {
      return value;
    }

    return ssrValue;
  }
}
