import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { JammboardGigMainCardModule } from '../jammboard-gig-main-card/jammboard-gig-main-card.module';
import { JammboardGigMainCardDetailsComponent } from './jammboard-gig-main-card-details.component';
import { JammboardGigMainCardDetailsEntryComponent } from './jammboard-gig-main-card-details-entry.component';

@NgModule({
  declarations: [JammboardGigMainCardDetailsComponent, JammboardGigMainCardDetailsEntryComponent],
  imports: [CommonModule, ButtonModule, FormsModule, JammboardGigMainCardModule, TranslateModule],
  exports: [JammboardGigMainCardDetailsComponent],
})
export class JammboardGigMainCardDetailsModule {}
