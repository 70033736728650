<div class="flex row" [ngClass]="{ clickable: isClickable }" (click)="goToProfile()">
  <div
    [ngClass]="{ 'no-headline': !avatarGroupData?.profileImage }"
    class="book-modal__avatar"
    style="background-image: url({{ avatarGroupData?.profileImage }});"
  ></div>
  <div class="book-modal__avatar-info flex column justify-center">
    <div *ngIf="avatarGroupData?.proName" class="book-modal__avatar-info-name">
      <span class="book-modal__name">{{ avatarGroupData?.proName }}</span>
      <!--      <img-->
      <!--        *ngIf="avatarGroupData?.proVerified"-->
      <!--        class="book-modal__featured"-->
      <!--        src="../../../assets/images/icons/verified3x.png"-->
      <!--        alt="Verified checkmark"-->
      <!--      />-->
    </div>

    <div>
      <span *ngIf="avatarGroupData?.proCity" class="book-modal__city">{{ avatarGroupData?.proCity }}</span>
    </div>
  </div>
</div>
