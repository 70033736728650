<div [ngSwitch]="this.type">
  <div
    *ngSwitchCase="'email'"
    class="email-inbox-card flex column justify-center"
  >
    <header class="email-inbox__heading">
      <label class="uppercase">Email inbox</label>
    </header>
    <hr />
    <div class="flex row justify-start align-center">
      <div
        class="email-inbox-card__avatar"
        style="background-image: url({{ jammLogoCircleUrl }});"
      ></div>
      <div class="flex column">
        <label class="email-inbox-card__title">Team Jammcard</label>
        <label class="email-inbox-card__subtitle">{{
          emailInboxSubtitle
        }}</label>
        <label class="email-inbox-card__description">{{
          emailInboxDescription
        }}</label>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'success'">
    <img
      src="../../../assets/images/icons/success-white.png"
      alt="Success checkmark"
    />
  </div>
</div>
