<app-header></app-header>
<app-user-page>
  <section class="custom-pro-list__container">
    <div class="custom-pro-list__list-info-container">
      <label class="custom-pro-list__label">{{ (customProList$ | async)?.client }}</label>
      <p class="custom-pro-list__heading">{{ (customProList$ | async)?.name }}</p>
      <p class="custom-pro-list__description">{{ (customProList$ | async)?.description }}</p>
    </div>
    <app-pro-cards-list [proCardsList]="(customProList$ | async)?.memberProfiles"></app-pro-cards-list>
  </section>
  <a href="mailto:team@jammcard.com" rel="noreferrer" class="custom-pro-list__concierge-link"
    >Have some urgency? Contact Jammcard directly.</a
  >
</app-user-page>
