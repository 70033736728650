import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducers';
import { BookingState, BookingStateMap } from './reducers/booking.reducers';
import { BookingByStatus } from '../../models';

export const selectBookings = (state: AppState | any) => state.booking;

export const selectPastBookings = createSelector(selectBookings, (state: BookingStateMap) => state.past);
export const selectUpcomingBookings = createSelector(selectBookings, (state: BookingStateMap) => state.upcoming);
export const selectPendingBookings = createSelector(selectBookings, (state: BookingStateMap) => state.pending);
export const selectCurrentBooking = createSelector(selectBookings, (state: BookingStateMap) => state.current);

export const selectAllPastBookings = createSelector(selectBookings, (state: BookingStateMap) =>
  flattenBookingArrays(state.past),
);
export const selectAllUpcomingBookings = createSelector(selectBookings, (state: BookingStateMap) =>
  flattenBookingArrays(state.upcoming),
);
export const selectAllPendingBookings = createSelector(selectBookings, (state: BookingStateMap) =>
  flattenBookingArrays(state.pending),
);

export const selectAllSellerPendingBookings = createSelector(
  selectBookings,
  (state: BookingStateMap) => state?.pending?.bookings?.bookingsWhereUserIsTheBookee ?? [],
);

export const selectPendingBookingByIdSeller = createSelector(
  selectAllSellerPendingBookings,
  (state: BookingByStatus[], props: { id: number }) => state.filter((booking) => booking.id === props.id).pop() ?? null,
);

export const selectBookerFromPastBookings = createSelector(selectPastBookings, (state: BookingState) => ({
  bookings: state.bookings.bookingsWhereUserIsTheBooker,
  isLoaded: state.isLoaded,
}));
export const selectBookerFromUpcomingBookings = createSelector(selectUpcomingBookings, (state: BookingState) => ({
  bookings: state.bookings.bookingsWhereUserIsTheBooker,
  isLoaded: state.isLoaded,
}));
export const selectBookerFromPendingBookings = createSelector(selectPendingBookings, (state: BookingState) => ({
  bookings: state.bookings.bookingsWhereUserIsTheBooker,
  isLoaded: state.isLoaded,
}));

export const selectBookeeFromPastBookings = createSelector(selectPastBookings, (state: BookingState) => ({
  bookings: state.bookings.bookingsWhereUserIsTheBookee,
  isLoaded: state.isLoaded,
}));
export const selectBookeeFromUpcomingBookings = createSelector(selectUpcomingBookings, (state: BookingState) => ({
  bookings: state.bookings.bookingsWhereUserIsTheBookee,
  isLoaded: state.isLoaded,
}));
export const selectBookeeFromPendingBookings = createSelector(selectPendingBookings, (state: BookingState) => ({
  bookings: state.bookings.bookingsWhereUserIsTheBookee,
  isLoaded: state.isLoaded,
}));

export const flattenBookingArrays = (object: BookingState) => ({
  bookings: [].concat(...Object.values(object.bookings ?? {})),
  isLoaded: object.isLoaded,
});
