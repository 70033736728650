import { Store } from '@ngrx/store';

import { isNil } from 'lodash';
import { iif, of } from 'rxjs';
import { concatMap, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { loadCurrentBookingFromUrl } from '../../store/booking/booking.actions';
import { selectCurrentBooking } from '../../store/booking/booking.selectors';
import { selectCurrentUserShort } from '../../store/auth/auth.selectors';
import { BookingCurrent } from '../../store/booking/reducers/booking.reducers';

export function filterNil() {
  return filter((value) => value !== undefined && value !== null);
}

export function debug(tag: string) {
  return tap({
    next(value) {
      console.log(`%c[${tag}: Next]`, 'background: #009688; color: #fff; padding: 3px; font-size: 9px;', value);
    },
    error(error) {
      console.log(`%[${tag}: Error]`, 'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;', error);
    },
    complete() {
      console.log(`%c[${tag}]: Complete`, 'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;');
    },
  });
}

export function getBookingFromUrl(booking: BookingCurrent, bookingIdRouteParam: number, store: Store) {
  return iif(
    () => isNil(booking.role) || isNil(booking.booking),
    store.select(selectCurrentUserShort).pipe(
      filter((currentUser) => !isNil(currentUser)),
      switchMap((currentUser) => of(store.dispatch(loadCurrentBookingFromUrl({ bookingId: bookingIdRouteParam })))),
      concatMap(() => store.select(selectCurrentBooking)),
      filter((booking) => !isNil(booking.role) || !isNil(booking.booking)),
      take(1),
    ),
    of(booking).pipe(take(1)),
  );
}
