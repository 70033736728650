import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AvailabilityDatepickerModule } from '../../molecules/availability-datepicker/availability-datepicker.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { FastBookingAvailabilityModalComponent } from './fast-booking-availability-modal.component';
import { FastBookingAvailabilityModalEntryComponent } from './fast-booking-availability-modal-entry.component';
import { MentorshipModalModule } from '../../templates/mentorship-modal/mentorship-modal.module';
import { RateSelectPillModule } from '../../molecules/rate-select-pill/rate-select-pill.module';
import { SellerProfileCardModule } from '../../molecules/seller-profile-card/seller-profile-card.module';
import { AvailabilitySlotListModule } from '../availability-slot-list/availability-slot-list.module';

@NgModule({
  declarations: [FastBookingAvailabilityModalComponent, FastBookingAvailabilityModalEntryComponent],
  imports: [
    AvailabilityDatepickerModule,
    AvailabilitySlotListModule,
    ButtonModule,
    CommonModule,
    FormsModule,
    MentorshipModalModule,
    RateSelectPillModule,
    SellerProfileCardModule,
    TranslateModule,
  ],
  exports: [FastBookingAvailabilityModalComponent, FastBookingAvailabilityModalEntryComponent],
})
export class FastBookingAvailabilityModalModule {}
