import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from './authentication.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import {
  COOKIE_CONSENT_GIVEN,
  SERVICE_URL,
  DISTINCT_UUID,
  AFFILIATE_CAMPAIGN_DATA,
  INITIAL_REFERRER,
  UTM_PARAMETERS,
  MOBILE_WEB_VIEW,
} from '../utils';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
  ) {}
  // the service should handle cases when a user is logged in vs when it is a guest user
  // also maybe check here if the cookie consent was not given

  // No need for anything to be returned by the methods
  public trackEvent(eventName: string, properties: any): void {
    const cookieConsentGiven = this.localStorageService.get(COOKIE_CONSENT_GIVEN);
    // The current call of the client is to track by default - this means always track since there is no other options
    // for the user, the app only notifies him that cookies are used. So the condition to tracking is removed for now.
    // This affects where the disctincsId for anonymous user gets created, before it was when accepting cookies but now
    // it should be created on app load if it does not exist already
    const openedFromWebView = this.localStorageService.get(MOBILE_WEB_VIEW);

    const affiliateCampaignData = this.localStorageService.get(AFFILIATE_CAMPAIGN_DATA);
    const currentTime = new Date().getTime();
    // The utm parameters should be valid only for one session so they are store in sessionStorage instead of localStorage
    const utmParamsData = this.sessionStorageService.get(UTM_PARAMETERS);

    if (!!openedFromWebView) {
      properties = { ...properties, webview: openedFromWebView };
    }

    if (!!utmParamsData) {
      properties = { ...properties, ...utmParamsData };
    }

    if (!!affiliateCampaignData && affiliateCampaignData.expirationDate > currentTime) {
      properties = { ...properties, cid: affiliateCampaignData.cid };
    }
    // Track affiliate campaign ID (cid) if user is part of the campaign (has campaign data stored in localStorage)
    const referrerData = this.localStorageService.get(INITIAL_REFERRER);
    const propertiesWithReferrerData = {
      ...properties,
      $initial_referrer: referrerData?.initialReferrer || null,
      $initial_referring_domain: referrerData?.initialReferringDomain || null,
    };

    if (this.authService.isLoggedIn()) {
      this.http
        .post(`${SERVICE_URL.USER}api/track-event`, {
          eventName,
          properties: propertiesWithReferrerData,
        })
        .subscribe();
    } else {
      const distinctId = this.localStorageService.get(DISTINCT_UUID);
      this.http
        .post(`${SERVICE_URL.USER}auth/track-event`, {
          distinctId,
          eventName,
          properties: propertiesWithReferrerData,
        })
        .subscribe();
    }
  }
}
