import {
  IRequestSentModalData,
  RequestSentModalType,
} from '../request-sent-modal/request-sent.config';

export const CHANGE_PASSWORD_VERIFICATION_MODAL_DATA: IRequestSentModalData = {
  type: RequestSentModalType.SUCCESS,
  requestSentTitle: 'Congratulations!',
  requestSentDescription: 'You have successfully changed your password.',
  requestSentButtonLabel: 'Close',
};
