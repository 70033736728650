<label
  *ngIf="rates"
  class="rate-label__container"
  [ngClass]="{
    'rate-label__container--featuredList': featuredList
  }"
>
  {{ rates[0]?.formattedRate }}<span *ngIf="rates[1]">,</span>&nbsp;
  <span *ngIf="rates[1]"> {{ rates[1]?.formattedRate }}<span *ngIf="rates[2]">,</span>&nbsp; </span>
  <span *ngIf="rates[2]">
    {{ rates[2]?.formattedRate }}
  </span>
</label>
