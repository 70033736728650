import { Pipe, PipeTransform } from '@angular/core';

import { PLACEHOLDER_IMAGE_URL } from '../../../utils';

@Pipe({
  name: 'profileImage',
})
export class ProfileImagePipe implements PipeTransform {
  constructor() {}

  public transform(value: any): string {
    if (typeof value === 'string') {
      return value !== '' ? value : PLACEHOLDER_IMAGE_URL.NO_AVATAR;
    }

    return value?.url ? value.url : PLACEHOLDER_IMAGE_URL.NO_AVATAR;
  }
}
