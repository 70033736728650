import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

import { PaymentCard } from '../../../models/booking';

import { IPAD_WIDTH_START } from '../navigation/navigation.component';

@Component({
  selector: 'app-payment-method-tab-group',
  templateUrl: './payment-method-tab-group.component.html',
  styleUrls: ['./payment-method-tab-group.component.scss'],
})
export class PaymentMethodTabGroupComponent implements OnInit {
  @Input() paymentMethods$: BehaviorSubject<PaymentCard[]>;
  @Output() tabActivated: EventEmitter<PaymentCard> = new EventEmitter();

  public activeTab$: BehaviorSubject<PaymentCard> = new BehaviorSubject<PaymentCard>(null);

  private activeTab: PaymentCard;
  private paymentMethods: PaymentCard[];

  @HostListener('window:resize', ['$event'])
  getWindowWidth(event?) {
    return window.innerWidth;
  }

  constructor() {}

  ngOnInit(): void {
    this.paymentMethods$.pipe(skip(1)).subscribe((paymentMethod) => {
      this.paymentMethods = paymentMethod;
      if (this.getWindowWidth() >= IPAD_WIDTH_START) {
        const defaultTab = this.paymentMethods.find((tab) => !!tab.default);
        !!defaultTab ? this.selectTab(defaultTab) : this.selectTab(this.paymentMethods[0]);
      } else {
        this.selectTab(null);
      }
    });
  }

  public selectTab(selectedTab: PaymentCard): void {
    this.activeTab = selectedTab;
    this.activeTab$.next(selectedTab);
    this.tabActivated.emit(selectedTab);
  }
}
