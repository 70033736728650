import { Component, HostBinding, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ASSETS_PATH } from '../../../utils';
import { TruncatedProfile, ServiceProvision, ServiceType } from '../../../models';

@Component({
  selector: 'app-truncated-profile-modal-basic',
  templateUrl: './truncated-profile-modal.component.html',
  styleUrls: ['./truncated-profile-modal.component.scss'],
})
export class TruncatedProfileModalComponent implements OnInit {
  @HostBinding('class') hostClass = 'host-fill-height';

  public hideBookButton: boolean;
  public proTruncatedProfile: TruncatedProfile;
  public serviceForBooking: ServiceProvision;
  public hasAvailability: boolean;
  public isSubscribed: boolean;

  public mentorsThumbnail = ASSETS_PATH.MENTORS_THUMBNAIL;

  private fromParent: {
    proTruncatedProfile: TruncatedProfile;
    serviceForBooking: ServiceProvision;
    hideBookButton: boolean;
  };

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.hideBookButton = this.fromParent.hideBookButton;
    this.proTruncatedProfile = this.fromParent.proTruncatedProfile;
    this.serviceForBooking = this.fromParent.serviceForBooking;
    this.hasAvailability = this.fromParent.proTruncatedProfile.servicesProvision[0].hasAvailability;
    this.isSubscribed = this.fromParent.proTruncatedProfile.servicesProvision[0].isViewerSubscribed;
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
