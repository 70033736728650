import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { BasicProfile, BookingRole, ContactSupportReason } from '../../../models';
import { BookingCurrent } from '../../../store/booking/reducers/booking.reducers';
import { BookModalContactSupportComponent } from './book-modal-contact-support.component';
import { ProfileService, ToastService } from '../../../services';
import { MODAL_DISMISS_REASON } from '../../../utils';
import { selectCurrentBooking } from '../../../store/booking/booking.selectors';
import { getBookingFromUrl } from '../../../utils/operators';

@Component({
  template: '',
})
export class BookModalContactSupportEntryComponent implements OnInit, OnDestroy {
  public modalInstance: any;
  public bookModalData: any;
  public modal$: Subscription;
  public sellerInfo$: Subscription;
  public router$: Subscription;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private toastService: ToastService,
  ) {}

  openModal(booking: BookingCurrent, user: BasicProfile): void {
    this.modalInstance = this.modalService.open(BookModalContactSupportComponent, {
      windowClass: 'modal-window',
      backdrop: 'static',
    });

    // This will be dynamically set in the future, now we know this is the only possible reason
    const contactSupportReason = ContactSupportReason.BOOKING;

    this.modalInstance.componentInstance.fromParent = { booking, user, contactSupportReason };

    this.modalInstance.result.then(
      () => this.router.navigate(['bookings']),
      (reason: string) => {
        return reason !== MODAL_DISMISS_REASON.ROUTE_SWITCH ? this.router.navigate(['bookings']) : null;
      },
    );
  }

  ngOnInit() {
    const bookingIdRouteParam = this.route.snapshot.params.bookingId;

    this.modal$ = this.store
      .select(selectCurrentBooking)
      .pipe(
        take(1),
        mergeMap((booking) => getBookingFromUrl(booking, bookingIdRouteParam, this.store)),
      )
      .subscribe((booking) => {
        if (bookingIdRouteParam !== booking.booking.id.toString()) {
          // TODO (Milan): If Contact Support is not allowed for some booking statuses that should be added to the condition here
          this.showMessage('Action not allowed for selected booking!');
          this.router.navigate(['bookings']);
        } else {
          const profileId =
            booking.role === BookingRole.SELLER ? booking.booking.booker.profileId : booking.booking.bookee.profileId;
          this.profileService.getBasicProfile(profileId).subscribe((user) => this.openModal(booking, user.body));
        }
      });
  }

  ngOnDestroy() {
    this.modal$.unsubscribe();
    //this.router$.unsubscribe();
  }

  private showMessage(message: string): void {
    this.toastService.showMessage(message);
  }
}
