import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { TruncatedProfileModalEntryComponent } from './truncated-profile-modal-entry.component';
import { TruncatedProfileModalComponent } from './truncated-profile-modal.component';
import { MentorshipModalModule } from '../../templates/mentorship-modal/mentorship-modal.module';
import { SellerProfileCardModule } from '../../molecules/seller-profile-card/seller-profile-card.module';
import { SkillsCardModule } from '../../molecules/skills-card/skills-card.module';
import { ExperienceModule } from '../experience/experience.module';
import { TruncatedMediaModule } from '../truncated-media/truncated-media.module';
import { SeeMoreTextModule } from '../../molecules/see-more-text/see-more-text.module';
import { AboutMentorshipCardModule } from '../../molecules/about-mentorship-card/about-mentorship-card.module';

@NgModule({
  declarations: [TruncatedProfileModalComponent, TruncatedProfileModalEntryComponent],
  imports: [
    CommonModule,
    MentorshipModalModule,
    SellerProfileCardModule,
    SkillsCardModule,
    TranslateModule,
    TruncatedMediaModule,
    ExperienceModule,
    SeeMoreTextModule,
    AboutMentorshipCardModule
  ],
  exports: [TruncatedProfileModalComponent, TruncatedProfileModalEntryComponent],
})
export class TruncatedProfileModalModule {}
