import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerLabelComponent } from './messenger-label.component';
import { DateTimeLabelModule } from '../../atoms/date-time-label/date-time-label.module';

@NgModule({
  declarations: [MessengerLabelComponent],
  imports: [CommonModule, DateTimeLabelModule],
  exports: [MessengerLabelComponent],
})
export class MessengerLabelModule {}
