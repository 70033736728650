import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentAndPayoutComponent } from './payment-and-payout.component';
import { PaymentMethodsModule } from '../payment-methods/payment-methods.module';

@NgModule({
  declarations: [PaymentAndPayoutComponent],
  imports: [CommonModule, PaymentMethodsModule],
  exports: [PaymentAndPayoutComponent],
})
export class PaymentAndPayoutModule {}
