import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { ModalHeaderCardComponent } from './modal-header-card.component';

@NgModule({
  declarations: [ModalHeaderCardComponent],
  imports: [CommonModule, ButtonModule],
  exports: [ModalHeaderCardComponent],
})
export class ModalHeaderCardModule {}
