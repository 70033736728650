import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TwilioService } from '../../../services';
import { ConversationSelectedEventData, IConversation } from '../messenger-content/messenger-content.config';

const CONVERSATION_LIST_LOADING_ROW_NUMBER = 10;

// NOTE: After migrating from Twilio Programable Chat to the Twilio Conversations library
// a 'channel' became a 'conversation' and a 'member' became a 'participant',
// however the component names and the class names remained the same as before the migration
// and are still including the words 'channel' and 'member'
@Component({
  selector: 'app-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss'],
})
export class ChannelsListComponent implements OnInit, OnDestroy {
  @Input() conversationsListInView$: BehaviorSubject<boolean>;
  @Output()
  conversationSelected: EventEmitter<ConversationSelectedEventData> = new EventEmitter<ConversationSelectedEventData>();
  @Output() resetConversation: EventEmitter<void> = new EventEmitter<void>();

  private subscription$ = new Subscription();

  public searchTerm$ = new BehaviorSubject<string>('');

  public conversationsData: IConversation[];
  public clientConnected: boolean;
  public isLoadingArray = new Array(CONVERSATION_LIST_LOADING_ROW_NUMBER);
  public selectedConversation: any = {};

  constructor(private twilioService: TwilioService) {}

  ngOnInit(): void {
    this.searchTerm$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged((prev, curr) => prev.trim() === curr.trim()),
        skip(1),
      )
      .subscribe((term) => {
        this.twilioService.searchTerm$.next(term);
        // NOTE: Reset active conversation. The active conversation before
        // searching does not have to be (and most likely won't be) in the search results
        // so keeping it in view would cause problems with events triggered on it and also
        // there would be edge-casses imposible to handle.
        this.resetSelectedConversation();
      });

    this.subscription$.add(
      this.conversationsListInView$.pipe(skip(1)).subscribe((conversationsListInView) => {
        if (conversationsListInView) {
          this.resetSelectedConversation();
        }
      }),
    );

    this.subscription$.add(
      this.twilioService.clientConnected$.subscribe((clientConnected) => {
        this.clientConnected = clientConnected;
      }),
    );

    this.subscription$.add(
      this.twilioService.subbedConversations$.subscribe((subbedConversations) => {
        this.conversationsData = subbedConversations;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public selectConversation(uniqueName: string): void {
    this.selectedConversation = { ...{ uniqueName, [uniqueName]: true } };
    const selectedConversationIndex = this.conversationsData.findIndex(
      (conversation) => conversation.uniqueName === uniqueName,
    );
    const participantFullName = this.conversationsData[selectedConversationIndex].otherParticipant.name;
    this.conversationSelected.emit({ conversationUniqueName: uniqueName, participantFullName });
  }

  private resetSelectedConversation(): void {
    this.selectedConversation = {};
    this.resetConversation.emit();
  }

  public search(term: string): void {
    this.searchTerm$.next(term);
  }

  public bottomReached({ isVisible }): void {
    if (isVisible) {
      this.twilioService.loadUserSubbedConversations();
    }
  }
}
