import { ButtonSwitchModule } from '../button-switch/button-switch.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationSettingsItemsComponent } from './notification-settings-items.component';

@NgModule({
  declarations: [NotificationSettingsItemsComponent],
  imports: [CommonModule, ButtonSwitchModule],
  exports: [NotificationSettingsItemsComponent],
})
export class NotificationSettingsItemsModule {}
