<button
  class="jammcard-btn uppercase"
  [ngClass]="{
    'jammcard-btn--inverted': inverted,
    'jammcard-btn--white': white,
    'jammcard-btn--rounded': rounded,
    'jammcard-btn--rounded-inverted': roundedInverted,
    'jammcard-btn--dark-border': darkBorder,
    'jammcard-btn--red': red,
    'jammcard-btn--gray-disabled': buttonGrayDisabled
  }"
  type="{{ buttonType }}"
  [disabled]="buttonDisabled || buttonGrayDisabled"
  (click)="onClick()"
>
  {{ buttonLabel }}
  <img *ngIf="icon.length > 0" class="hide-icon" src="{{ icon }}" alt="" />
</button>
