<div class="messenger-content flex">
  <app-channels-list
    class="channels-list-wrapper"
    [ngClass]="{ 'hide-show': !(conversationsListInView$ | async) }"
    [conversationsListInView$]="conversationsListInView$"
    (conversationSelected)="conversationSelected($event)"
    (resetConversation)="resetActiveConversation()"
  ></app-channels-list>
  <app-chat
    class="chat-wrapper flex column"
    [ngClass]="{ 'hide-show': (conversationsListInView$ | async), 'has-booking-quick-info': hasQuickInfo }"
    [conversationUniqueName$]="activeConversationUniqueName$"
    [otherParticipant$]="activeConversationOtherParticipant$"
    [loggedUserId]="loggedUserId"
    [loggedUserProfileImage]="loggedUserProfileImage"
    [loggedUserUsername]="loggedUserUsername"
    [messageGroups$]="activeConversationMessageGroups$"
    [newMessage]="newMessage$ | async"
    (topReached)="loadAnotherBatchOfMessages()"
    (bottomReached)="updateReadIndex()"
    (hasBookingQuickInfo)="hasBookingQuickInfo($event)"
  ></app-chat>
</div>
