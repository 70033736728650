import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ChangeNotificationsModule } from '../../organisms/change-notifications/change-notifications.module';
import { ChangePasswordModule } from '../../organisms/change-password/change-password.module';
import { NavigationContentModule } from '../../../utils/directives/navigation-content/navigation-content.module';
import { PaymentAndPayoutModule } from '../../organisms/payment-and-payout/payment-and-payout.module';

import { PipesModule } from '../../../utils/pipes/pipes.module';
import { NavigationComponent } from './navigation.component';

@NgModule({
  declarations: [NavigationComponent],
  imports: [
    CommonModule,
    ChangePasswordModule,
    NgbModule,
    ChangeNotificationsModule,
    PipesModule,
    RouterModule,
    TranslateModule,
    PaymentAndPayoutModule,
    NavigationContentModule,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
