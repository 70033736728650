import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService, LocalStorageService } from '../services';
import { IsBrowserService } from '../utils';
import { userHasRequiredRole } from './helper-functions';
import { USER, PROFILE_USERNAME } from './constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private isBrowserService: IsBrowserService,
    private localStorage: LocalStorageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authenticationService.userValue;

    if (!this.isBrowserService.isBrowser()) {
      return true;
    }

    if (user) {
      // check if route is restricted by role
      if (route.data.roles && !userHasRequiredRole(route, this.localStorage.get(USER))) {
        // role not authorised so redirect to the mentors page
        this.router.navigate(['/mentors']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to the mentors page
    this.router.navigate(['/mentors']);
    return false;
  }
}
